/* boostrap button */
button.btn.btn-success,
.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show>.btn-success.dropdown-toggle {
    background-color: #014a2e;
    border-color: #1c7430;
}
.btn:focus,
button:focus {
    box-shadow: none;
}
.btn {
    font-size: $font-xsm;
}

// button
.btn-primary {
    background-color: #2896f0;
    border-color: #2896f0;
}
// .btn-primary:not(:disabled):not(.disabled).active,
// .btn-primary:not(:disabled):not(.disabled):active {
//     background-color: #0069d9;
//     border-color: #0062cc;
// }
.btn-success:not(:disabled):not(.disabled).active:focus,
.btn-success:not(:disabled):not(.disabled):active:focus,
.show>.btn-success.dropdown-toggle:focus {
    box-shadow: none;
}
.btn.btn-success:hover {
    box-shadow: 0 0px 15px #026c43;
}