$prefix: ".rs-";

$menu1: ".audience";
$menu2: ".campaign";
$menu3: ".dashboard";
$menu4: ".reports";
$menu5: ".preference";

$image: '../../../Images/';
$font:  '../../fonts/';

$fontName: "Mukta";

// ::Font family::
$rs-font-thin:      '#{$fontName}Thin', sans-serif;
$rs-font-light:     '#{$fontName}Light', sans-serif;
$rs-font-regular:   '#{$fontName}Regular', sans-serif;
$rs-font-medium:    '#{$fontName}Medium', sans-serif;
$rs-font-semi-bold: '#{$fontName}SemiBold', sans-serif;
$rs-font-bold:      '#{$fontName}Bold', sans-serif;
$emoji-font:        EmojiMart, "Segoe UI Emoji", "Segoe UI Symbol", "Segoe UI", "Apple Color Emoji", "Twemoji Mozilla", "Noto Color Emoji", "Android Emoji";

$ff         : font-family;

// ::Icon size::
$icons-xs   : 15px;
$icons-svm  : 17px;
$icons-smd  : 21px;
$icons-md   : 24px;
$icons-lg   : 32px;
$icons-xl   : 42px;
$icons-xxl  : 50px;

// ::Font size::
$font-xxs  : 12px;
$font-xs   : 15px;
$font-xsm  : 17px;
$font-sm   : 19px;
$font-smd  : 21px;
$font-md   : 24px;
$font-lg   : 32px;
$font-xl  : 45px;
$font-xxl  : 54px;

// ::Kendo Line height
$k-select-dropdown-lh  : 25px;

$m: margin;
$bc: background-color;
$count1: 8;
$countWidth: 360deg / $count1;

$defaultLetterSpacing: 0.5px;

$sec1: 0.2s;
$sec2: 0.3s;
$sec3: 0.4s;
$sec4: 0.5s;

// ::Curves speed::
$curve1: linear;
$curve2: ease;
$curve3: ease-in;
$curve4: ease-out;
$curve5: ease-in-out;

$defaultWidth:           1182px;

$profile-img-size:       163px; // 153px
$profile-img-size-h:     163px; // 153px

// TOOLTIP
$header-tooltip:         1056;
$pophover-tooltip:       1055;
$modal-tooltip:          1058;
$breadcrumbs-tooltip:    1001;
$header-notis-tooltip:   1002;
$network-status-tooltip: 1005;

$position:               top, right, bottom, left;
$tooltip-position:       (top: top, right: end, bottom: bottom, left: start);
$tooltip-flip:           (top: bottom, right: start, bottom: top, left: end);
$tposition:              up, right, down, left;
$xposition:              right, left;
$yposition:              down, up;
$time:                   monthview, yearview, decadeview, centuryview;
$switch:                 ('on': $switch-enable, 'off': $switch-disable);
$switch2:                ('on': #99cc03, 'off': #fe5758);
$asdesc:                 (asc: '\e013', desc: '\e015');


// THEME
$theme-radius-size: 5px; // 8px
$theme-radius-size-sm: 5px;
$theme-space: 23px;

:root {
    --globalSpace: 41px;
}

@media only screen and (min-width: 1367px) and (-webkit-min-device-pixel-ratio: 1) {
    :root {
        --globalSpace: 41px;
    }
}

$input-line: 1px 15px, 1px 1px, 0 15px;
$input-line-web: 1px 15px, 1px 1px, 0px 15px;