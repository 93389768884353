// base structure
// font size 8 to 100
// height, width, margin, padding, line-height

// 10px	0.625rem
// 11px	0.6875rem
// 12px	0.75rem
// 13px	0.8125rem
// 14px	0.875rem
// 15px	0.9375rem
// 16px	1rem

@for $i from 1 through 72 {
  $x : $i;
  .fs#{$x}rem {
      font-size: #{$x}rem;
  }
}

// base structure
@for $i from 10 through 40 {
  $count: #{$i}px;

  // #{$prefix}text {
  //   width: $count;
  // }
}

// font size 8 to 100
@for $i from 8 through 42 {
  $x: $i;
  .fs#{$x},
  .fs#{$x}-btn button,
  .fs#{$x}-list div {
    font-size: #{$x}px !important;
  }
}

// font size 8 to 100 rem
// ex: fs16r
$rempix : 0.0625;
$rempx : $rempix;
@for $i from 10 through 72 {
    $x : $i * $rempix;
    .fs#{$i}r {
        font-size: #{$x}rem;
    }
}

@for $i from 0 through 30 {
  .h#{$i}rem {height: #{$i}rem !important;}
  .w#{$i}rem {width: #{$i}rem !important;}
  .transY#{$i} { transform: translateY(#{$i}px) !important; }
  .transY-#{$i} { transform: translateY(-#{$i}px) !important; }
}

// height, width, margin, padding, line-height
@for $i from 0 through 35 {
  .top#{$i}, .t#{$i} {top: #{$i}px !important;}
  .top-#{$i}, .t-#{$i} {top: -#{$i}px !important;}

  .left#{$i}, .l#{$i} {left: #{$i}px !important;}
  .left-#{$i}, .l-#{$i} {left: -#{$i}px !important;}

  .right#{$i}, .r#{$i} {right: #{$i}px !important;}
  .right-#{$i}, .r-#{$i} {right: -#{$i}px !important;}
  
  .bottom#{$i}, .t#{$i} {bottom: #{$i}px !important;}
  .bottom-#{$i}, .t-#{$i} {bottom: -#{$i}px !important;}
}
@for $i from 0 through 10 {
  .zIndex#{$i} {
    z-index: #{$i};
  }
}
@for $i from 0 through 100 {
    .height#{$i} {height: #{$i}px !important;}
    // .w#{$i}p {width: #{$i} + '%' !important;}
    .w#{$i}r {width: #{$i}rem !important;}
    .lh#{$i} {line-height: #{$i}px;}
    .top#{$i}, .t#{$i} {top: #{$i}px!important;}
    .bottom#{$i} {bottom: #{$i}px!important;}

    // MARGIN (X, Y)
    .marginY#{$i}, .my#{$i} {
      margin-top: #{$i}px !important;
      margin-bottom: #{$i}px !important;
    }
    .marginX#{$i}, .mx#{$i} {
      margin-right: #{$i}px !important;
      margin-left: #{$i}px !important;
    }
    // MARGIN - (X, Y)
    .marginY-#{$i}, .my-#{$i} {
      margin-top: -#{$i}px !important;
      margin-bottom: -#{$i}px !important;
    }
    .marginX-#{$i}, .mx-#{$i} {
      margin-right: -#{$i}px !important;
      margin-left: -#{$i}px !important;
    }
    // PADDING (X, Y)
    .paddingY#{$i}, .py#{$i} {
      padding-top: #{$i}px !important;
      padding-bottom: #{$i}px !important;
    }
    .paddingX#{$i}, .px#{$i} {
      padding-right: #{$i}px !important;
      padding-left: #{$i}px !important;
    }
    // MARGIN
    .margin#{$i}, .m#{$i} {margin: #{$i}px !important;}
    .margin-#{$i}, .m-#{$i} {margin: -#{$i}px !important;}

    .marginT#{$i}, .mt#{$i} {margin-top: #{$i}px !important;}
    .marginR#{$i}, .mr#{$i} {margin-right: #{$i}px !important;}
    .marginB#{$i}, .mb#{$i} {margin-bottom: #{$i}px !important;}
    .marginL#{$i}, .ml#{$i} {margin-left: #{$i}px !important;}

    .marginT-#{$i}, .mt-#{$i} {margin-top: -#{$i}px !important;}
    .marginR-#{$i}, .mr-#{$i} {margin-right: -#{$i}px !important;}
    .marginB-#{$i}, .mb-#{$i} {margin-bottom: -#{$i}px !important;}
    .marginL-#{$i}, .ml-#{$i} {margin-left: -#{$i}px !important;}

    // PADDING (X, Y)
    .padding#{$i}, .p#{$i} {padding: #{$i}px !important;}
    .paddingT#{$i}, .pt#{$i} {padding-top: #{$i}px !important;}
    .paddingR#{$i}, .pr#{$i} {padding-right: #{$i}px !important;}
    .paddingB#{$i}, .pb#{$i} {padding-bottom: #{$i}px !important;}
    .paddingL#{$i}, .pl#{$i} {padding-left: #{$i}px !important;}
}