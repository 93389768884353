.RRT__container {
  position: relative;
  margin-bottom: 10px;
}

/****************************/
/******** tab styles ********/
/****************************/
.RRT__tabs {
  display: flex;
  // flex-wrap: wrap;
  border-radius: 5px;
  border: 1px solid #c2cfe3;
  background-color: #ffffff;
}

.RRT__accordion {
  flex-direction: column;
}
.RRT__tab {
  background: var(--th-even-color);
  border-style: solid;
  border-color: #ddd;
  border-width: 0;
  cursor: pointer;
  border-left: 1px solid #fff;
  z-index: 1;
  white-space: nowrap;
  height: 41px;
  padding: 8px 27px;
  color: $main-color;
  // @include css3-prefix(transition, 0.3s);
  position: relative;
  @include screen(lg) {
    padding: 8px 10px;
  }
  &:hover {
    background-color: var(--th-even-color);
  }
  &:focus {
    outline: 0;
    background-color: var(--th-even-color);
    z-index: 2;
  }
  &--first {
    border-left-width: 1px;
  }
  &--selected {
    border-color: #ddd #ddd #fff;
    &, &:hover {
      background-color: var(--th-table-color);
    }
    &:focus {
      background-color: var(--th-table-color);
    }
    &:after {
      bottom: -6px;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-top: 7px solid #0c8aff;
      content: "";
      display: block;
      left: 50%;
      position: absolute;
      margin-left: -7px;
      z-index: 100;
    }
    .RRT__removable {
      position: relative;
      &-text {
        margin-right: 10px;
      }
      &-icon {
        position: absolute;
        font-size: $font-sm;
        right: 0.5em;
        top: 0.2em;
      }
    }
  }
  &--disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.RRT__accordion .RRT__tab {
  border-left-width: 1px;
}


/****************************/
/********* panel styles *****/
/****************************/
.RRT__panel {
  margin-top: -1px;
  padding: 1em;
  border: 1px solid #ddd;
}

.RRT__accordion .RRT__panel {
  margin-top: 0;
}

/****************************/
/******* showmore control ***/
/****************************/
.RRT__showmore {
  background: #e9e9eb;
  cursor: pointer;
  z-index: 1;
  white-space: nowrap;
  position: absolute;
  width: 64px;
  right: 0;
  height: 41px;
  &--selected {
    background: white;
    border-bottom: none;
  }
  &-label {
    position: relative;
    z-index: 1;
    border: 0;
    text-align: center;
    font-size: $font-sm;
    line-height: 22px;
    visibility: hidden;

    font-family: 'icomoon';
    // speak: none;
    font-style: normal;
    font-weight: normal;
    -webkit-font-feature-settings: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: $font-sm;
    color: $primary-color;
    padding: 10px 23px;
    height: 41px;
    &:before {
        content: "\E937";
        visibility: visible;
    }
    &--selected {
      background-color: #d4d6de;
      &:hover {
          background-color: #d4d6de;
      }
    }
  }
  &-list {
    position: absolute;
    right: 0px;
    top: 100%;
    display: none;
    &--opened {
      display: block;
      // margin-top: 3px;
      margin-top: 1px;
    }
  }
}

/****************************/
/********** inkbar **********/
/****************************/
.RRT__inkbar-wrapper {
  width: 100%;
}

.RRT__inkbar {
  position: relative;
  bottom: 0;
  height: 2px;
  margin-top: -2px;
  background-color: deepskyblue;
  transition: left 800ms cubic-bezier(0.23, 1, 0.32, 1);
  z-index: 2;
}


.RRT__tab--selected {
  background-color: var(--th-table-color);
  color: #ffffff;
  border-right: 5px;
}

.RRT__tabs .RRT__tab.tab {
  position: relative;
  font-size: $font-sm;
  // @include truncate(250px);
  &:first-child {
      border-left: 0;
      border-radius: $theme-radius-size 0 0 $theme-radius-size;
  }
}

:focus {
  outline: none !important;
}

.RRT__showmore.RRT__showmore--selected {
    background-color: $grey-L100;
    // border-bottom: 2px solid #0c8aff;
    .RRT__tab--selected::after {
      content: inherit !important;;
    }
}

.RRT__showmore .tab.RRT__tab--selected {
    border-bottom: 0;
    background-color: var(--th-primary-color);
}

.RRT__showmore .tab {
    border-left: 0;
}




// Responsive tabs
.RRT__panel {
  border: none;
  padding: 0;
  margin-top: 0;
}

.RRT__tab--selected {
  // border-bottom: 2px solid $primary-color;

  &:after {
    border-top: 7px solid $primary-color;
  }
}

.RRT__showmore-label:before {
  content: "\e93b";
}

.RRT__showmore.RRT__showmore--selected {
  border-bottom: 2px solid $primary-color;
}

.RRT__showmore {
  border: 0;
}

.RRT__tab {
  padding: 8px 20px;
  font-size: $font-sm;
}