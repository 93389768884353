////////////////////////////
//////***************///////
//////*MARKETINGSTAR*///////
//////***************///////
////////////////////////////
$header-color:        var(--th-header-color);
$primary-color:       var(--th-primary-color);
$primary-hvr-color:   var(--th-primary-color);
$secondary-color:     var(--th-secondary-color);
$tab-color:           var(--th-table-color);
$even:                var(--th-even-color);
// $tooltip-color:       var(--th-tooltip-color);
$active-color:        var(--th-secondary-color);

$icon-color:          var(--th-primary-color);

$main-color:         #424242;
$default-color:      #333333;
$remove:             #ff3939;
$button-active:      #06c;
$button-active-hover:#06c;
$button-inactive:    #06c;

$tab-text-color:       var(--th-primary-color);

// SWITCH
$switch-disable:     #7F8C9A;
$switch-enable:        $primary-color;

// DYNAMIC POPUP
$primary-dmedium:   #13367d;
$primary-dlight:    #3c5486;

$primary-light:      #66c8ec;
$primary-medium:     #5ab5e7;
$primary-dark:       #4c96f0;

$odd: #ffffff;

// FONT COLOR
$fontColor1:         #666666; // $main-color // Black medium
$fontColor2:         #b8b8b8; // Gray extra light
$fontColor3:         #fc6a00; // Orange
$fontColor4:         #d4d6de;

// Old theme
$color3:             #666666;

$head-tag:           #333333;



$table-header:         var(--th-table-color);
$noDataBg:           #e1d2b2;