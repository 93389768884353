$tposition: up, right, down, left;
$font-xxs: 12px;

/* title-tip-down */
.title-tip[title] {
    &:hover {
        position: relative;
        &:before, &:after {
            position: absolute;
            top: 100%;
            z-index: 99999;
            font-size: $font-xxs;
        }
        &:before {
            content: attr(title);
            left: -75%;
            margin-top: 0.5em;
            padding: 0.4em;
            width: 80px;    /* change it */
            overflow: hidden;
            word-wrap: break-word;
            color: #FFF;
            text-align: center;
            background-color: $primary-color;
            box-sizing: border-box;
            box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
        }
        &:after {
            content: '';
            left: 50%;
            margin-left: -0.125em;
            width: 0;
            height: 0;
            border: 0.25em dashed transparent;
            border-bottom: 0.25em solid $primary-color;
        }
    }
}
/* title-tip-up */
@each $pos in $tposition {
    .title-tip-#{$pos}[title]:hover {
        // TOP
        @if $pos == up {
            &:before {
                top: auto;
                bottom: 100%;
                margin-top: 0;
                margin-bottom: 0.5em;
            }
            &:after {
                bottom: 100%;
                border: 0.49em dashed transparent;
                border-top: 0.49em solid $primary-color;
                top: -6px;
                left: 10px;
            }
        }
        // RIGHT
        @if $pos == right {
            &:before {
                top: auto;
                left: 100%;
                margin-top: 0;
                margin-left: 0.5em;
            }
            &:after {
                top: 50%;
                left: 100%;
                margin-left: 0;
                margin-top: -0.125em;
                border: 0.25em dashed transparent;
                border-right: 0.25em solid $primary-color;
            }
        }
        // LEFT
        @if $pos == left {
            &:before {
                top: auto;
                left: auto;
                right: 100%;
                margin-top: 0;
                margin-right: 0.5em;
            }
            &:after {
                right: 100%;
                margin-left: 0;
                margin-top: -0.125em;
                border: 0.49em dashed transparent;
                border-left: 0.49em solid $primary-color;
                top: 10px;
                left: -6px;
            }
        }
    }
}
.title-tip[title='Personalize']:before {
    width: auto !important;
    padding-left: 8px !important;
    padding-right: 8px !important;
    left: -50% !important;
    transform: translateX(-13%);
}
.title-tip-up[title='Personalize']:hover:after {
    left: 11px;
}
/* title-tip-right */
.title-tip-right[title]:hover:before {
    top: auto;
    left: 100%;
    margin-top: 0;
    margin-left: 0.5em;
}
.title-tip-right[title]:hover:after {
    top: 50%;
    left: 100%;
    margin-left: 0;
    margin-top: -0.125em;
    border: 0.25em dashed transparent;
    border-right: 0.25em solid $ch_tooltipBgColor;
}

/* title-tip-left */
.title-tip-left[title]:hover:before {
    top: auto;
    left: auto;
    right: 100%;
    margin-top: 0;
    margin-right: 0.5em;
}
.title-tip-left[title]:hover:after {
    top: 50%;
    left: auto;
    right: 100%;
    margin-left: 0;
    margin-top: -0.125em;
    border: 0.49em dashed transparent;
    border-left: 0.49em solid $ch_tooltipBgColor;
    top: 10px;
    left: -6px;
}
.title-tip-image[title]:hover:before {
    width: 45px;
}
.title-tip[title='Personalize']:before {
    width: auto !important;
    padding-left: 8px !important;
    padding-right: 8px !important;
    left: -50% !important;
    transform: translateX(4%);
}
.title-tip-up[title='Personalize']:hover:after {
    left: 16px;
}
.input-below-icon-set {
    .title-tip-up[title='Personalize']:hover:after {
        left: 11px;
    }
    .title-tip[title='Personalize']:before {
        transform: translateX(-13%);
    }
}
.k-toolbar {
    .title-tip-up[title='Personalize']:hover:after {
        left: 11px;
    }
    .title-tip[title='Personalize'] {
        &:before {
            transform: translateX(-8%);
            .k-button-icon {
                margin: 0;
            }
        }
        .k-button-icon {
            margin: 0;
        }
    }
}
.camp-icon-pannel {
    .title-tip[title]:hover {
        &:before {
            left: -50% !important;
            font-size: $font-xxs;
            width: auto;
            padding: 0.4em 8px;
            transform: translateX(-10%);
        }
        &:after {
            left: 7px;
        }
    }
}
.tooltip-head-icon {
    top: 16px !important;
}
.tooltip-powered-icon {
    left: 9px !important;
}