//Colors
// 5.0 open
$body-bg-color: #eff3fc;
$head-band-color: #170180;
$mobile-footer-band-color: #0008a1;

$primary-blue: #0018f9; //Main Heading, icons, save btn
$secondary_blue: #004fdf; //Tab, border-top line, text box active lines, Reach bg
$tertiary-blue: #f0f8ff; //Odd/Even bg color
$quaternary-blue: #c2cfe3; // box border color

$primary-black: #111111; //Heading text
$secondary-black: #333333; //Sub heading text

$primary-grey: #666666; //Place holder, grey text
$secondary-grey: #999999; //Borderlines, check box, Bg fill, Switch deactive
$tertiary-grey: #e9e9e9; //Breadcrumbs, Tab
$quaternary-grey: #f4f4f4; // input disabled

$primary-orange: #fc6a00; //Cancel btn, Engagement bg
$secondary-orange: #fd8f40; //Crown icon color
$tertiary-orange: #ffb900; //Logo thunder
$quaternary-orange: #fd2d32; // Menu active

$primary-red: #ff3939; //Remove
$secondary-red: #ffebeb; //Exclusion bg color

$primary-green: #5ba529; //Successful text, Switch active
$secondary-green: #99cc03; //Successful text, Switch active, Conversion bg

$primary-btn: #005534; //Submit btn
$primary-btn-hover: #026c43; //Submit btn hover

$tertiary-btn: $secondary_blue; //Submit btn
$tertiary-btn-hover: $primary-blue; //Submit btn hover

$quaternary-btn: $tertiary-grey; //Submit btn
$quaternary-btn-hover: $quaternary-grey; //Submit btn hover
// 5.0 close

$ch_tooltipBgColor: $secondary-black;

// Common colors
$tertiaryBlueColor: #f0f8ff;
$ClickOffColor: #f6f7f8;

// STATUS COLOR'S
$draft: #b8b8b8; // b8b8b8, c2cfe3
$completed: #5ba529;
$scheduled: #fc6a00;
$inprogress: #009bdf;
$alert: #dfb82b;
$multi-status: #c43896;
$rejected: #fe6262;

//Audience list
$all-audience: #008489;
$seed-list: #40abaf;
$ad-hoc-list: #70c0c3;
$match-list: #b786ce;
$suppression: #9f65b7;
$auto-cluster: #804097;

// SOCIAL MEDIA - CHANNEL COLOR'S
$email: #fc6a00;
$sms: #dfb82b;
$web-push: #5ba529;
$mobile-push: #99cc03;
$vms: #008489;
$call-center: #33b5e6;
$whatsapp: #009c00;
$qr-code: #666666;
$direct-mail: #fd8f40;
$paid-media: #40abaf;
$twitter: #1d9bf0; //#000000
$facebook: #2a56a3;
$linkedin: #007bb6;
$insta: #c4219c;
$pinterest: #e60023;
$notification: #f05455;
$rcs-message: #f0a039;
$webhook: #1b2d66;
$landing-page: #804097;
$orm: #03a1de;
$social-media: #ef4c4e;
$web-analytics: #2db4e7;
$social-post: #028185;
$webinar: #d765aa;
$video: #ee222a;
$voice: #9447a8;
$line: #00b900;
$google: #bc2f20;
$others: #cccccc;

$zip-code: #5f1147;
$gender: #9e003d;
$male: #00acde;
$female: #c72079;
$age: #da0034;
$country: #ff4418;
$website: #ffc000;
$others: #cccccc;

//MDC
$list-audience: #2a79c2;
$dynamic-audience: #b26aae;

// SENTIMENT COLOR'S
$positive: #5ba529;
$neutral: #009bdf;
$negative: #c3392c;

// WEEK COLOR'S
$sunday: #ff7800;
$monday: #0048ff;
$tuesday: #ff0005;
$wednesday: #46c029;
$thursday: #f5ec00;
$friday: #a9bde4;
$saturday: #5b5b5b;

// DAYS COLOR'S
$earlyMorningColor: #ff7800;
$morningColor: #a9bde4;
$afternoonColor: #5b5b5b;
$eveningColor: #46c029;
$nightColor: #ff0005;

// DEVICE COLOR'S
$android: #4885ed;
$iOSColor: #99cc03;
$macColor: #008f94;
$windowsColor: #33b5e6;
$googlePlayStoreColor: #4885ed;
$youtubeWatchPageColor: #e62117;
$televisionColor: #2896f0;
$fullyConversedColor: #ff6900;

// KNOWN - UNKNOWN COLOR'S
$knownColor: #99cc03;
$unKnownColor: #fd8f40;
$identifiedColor: #274da3;

// LANGUAGE COLOR'S
$englishColor: #99cc03;
$spanishColor: #2896f0;

// CHART COMMON COLOR'S
$chartColor01: #26ADE0;
$chartColor02: #90C830;
$chartColor03: #F58332;
$chartColor04: #DDB219;
$chartColor05: #F05455;
$chartColor06: #35A8AD;
$chartColor07: #D1569E;
$chartColor08: #9B5FAA;
$chartColor09: #B8B6B7;

// OTHERS COLOR'S
$blueColor: #2896f0;
$blueLightColor: #33b5e6;
$greenColor: #99cc03;
$greenLightColor: #b3d942;
$yellowColor: #e7ca60;
$orangeColor: #fd8f40;
$cyanColor: #40abaf;
$greyLightColor: #e9e9eb;
$greyDarkColor: #585858;
$othersColor: #fe5758;


$blue-heavy: #0584c3;
$blue-dark: #0892d5;
$blue-medium: #26ade0;
$blue-light: #5ac3e9;
$blue-lighter: #0540d3;

$green-dark: #51a33c;
$green-medium: #90c830;
$green-light: #aed237;

$orange-dark: #f25e0e;
$orange-medium: #f58332;
$orange-light: #f9a263;

$yellow-dark: #ddb219;
$yellow-medium: #e5c553;
$yellow-light: #ecd47b;

$red-heavy-dark: #ad2513;
$red-dark: #c22f1d;
$red-medium: #f05455;
$red-light: #f17576;

$blue-green-dark: #008288;
$blue-green-medium: #35a8ad;
$blue-green-light: #69bec1;

$grey-dark: #b8b6b7;
$grey-medium: #d1d3dc;
$grey-light: #e8e7e9;

$pink-dark: #c32c8f;
$pink-medium: #d1569e;
$pink-light: #d982b7;

$purple-dark: #7e3896;
$purple-medium: #9b5faa;
$purple-light: #b180bb;

// DEFAULT COLOR'S
$white: #ffffff;
$black: #000000;

$grey-50: #b8b8b8;
$grey-100: #e9e9eb;
$grey-L100: #f6f7f8;
$grey-L200: #fdfdfd;
$grey-L300: #fcfcfc;
$grey-300: #d4d6de;
$grey-400: #585858;

$grey-500: #333333;
$black-medium: #333333;
$black-light: #666666;

$maroon-50: #c3392c;
$maroon-100: #fe8182;
$maroon-200: #fe5758;
$maroon-300: #d8493b;

$green-50: #5ba529;
$green-100: #b3d942;
$green-200: #99cc03; //   #5ba52a;
$green-300: #8bb70b;

$blue-50: $primary-color;
$blue-100: #33b5e6;
$blue-300: #009bdf; //2896f0;

$cyan-100: #70c0c3;
$cyan-200: #40abaf;
$cyan-300: #008f94;

$red: #fe5758;

$yellow-50: #dfb82b;
$yellow-100: #edd788;
$yellow-200: #e7ca60;

$orange-50: #fc6a00;
$orange-100: #feab70;
$orange-200: #f56701;

$color0: transparent;

$facebook: #3a5897;
$fb-text: #6078aa;
$twitter: #5dc8ff;
$linkedin: #2967ad;
$pinterest: #f66059;
$pinterest-100: #ca2026;

$google: #fe5758;


$checkbox-color: $primary-color;
$iconActive: #c1cdd8;

$deactivateColor: #f56701;
$activateColor: #99cc03;

$onlineColor: #005534;
$offlineColor: #9e131e;

// FOOTER COLOR COLOR'S
$footerColor1: #1f00ff;
$footerColor2: #7f04b2;
$footerColor3: #9e131e;
$footerColor4: #b78200;
$footerColor5: #005f35;

// License type colors
$startup: #6d6e71;
$pro: #fd992f;
$enterprise: #0c8aff;

// Delivery type colors
$reach: #0540d3;
$engagement: #f56701;
$conversion: #99cc03;

$success: #5ba529;
$fail: #ff0005;

$labelColor: $default-color; // Black dark

// BACKGROUND COLOR'S
$bg-color1: #f6f7f8;
$bg-color2: #fdfdfd;
$bg-color3: $main-color;
$bg-color4: #f1f1f1;
$bg-color5: $primary-color;
$bg-color6: #e9e9e9;
$bg-color7: #c2cfe3; // b8b8b8

// TABLE INNER TEXT COLOR'S
$table-body-color: $default-color;

$tooltip: $primary-color;

$dropdown-color: #585858;
$border-color: #c2cfe3; // c2cfe3, b8b8b8
$icon-color: #666666; // c2cfe3, b8b8b8
$input-border: #999999; // c2cfe3, b8b8b8

// BORDER COLOR'S
$br-color1: #e9e9eb;
$br-color2: $main-color;
$br-color3: #d4d6de;
$br-color4: #f1f1f1;
$br-color5: #fdfdfd;

$filter-color: #026c43;

$input-color: #333333;
$placeholder-color: #666666;