@charset 'utf-8';
@use "sass:string";

html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

* {
  box-sizing: inherit;

  &:before,
  &:after {
    box-sizing: inherit;
  }
}

body {
  font-size: $font-xsm;
  font-family: $rs-font-regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $main-color;
  background-color: $tm_backgroundBg;
  letter-spacing: 0.5px;
  margin: 0;
  line-height: 1.42857143;
  -webkit-text-stroke: 0.0001em;
  padding-right: 0 !important;

  @if $new-theme-test ==true {
    background-color: $body-bg-color;
  }

  @-moz-document url-prefix() {
    scrollbar-color: $secondary-grey $tertiary-grey;
  }

  @-moz-document url-prefix() {
    scrollbar-width: thin;
  }

  &::-webkit-scrollbar-track {
    background-color: $tertiary-grey;
    border-radius: 0 0 5px 5px;
  }

  &::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    background-color: $tertiary-grey;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $secondary-grey;
    border-radius: 100px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    cursor: pointer;
  }
}

// page wrapper
.header-wrapper {
  background-color: $header-color; // #0f1d8d;
  height: 60px;
  padding: 0 10px 0 24px;
  width: 100%;
  position: fixed;
  align-items: center;
  top: 0;
  z-index: $header-tooltip;
  @include flexbox($x: space-between);

  img {
    max-height: 60px;
  }
}

.layout-page-container {
  min-height: calc(100vh - 57px);
  display: inline-block;
  width: 100%;
  padding: 63px 0 0;
}

.input-icon {
  color: $icon-color; // $border-color
}

.text-orange {
  color: $fontColor3;
  text-decoration: none;

  &:hover {
    color: $fontColor3;
    text-decoration: underline;
  }
}

.mSpace {
  margin-bottom: 30px;
}

// Common
h1.main-title {
  margin-bottom: 15px;
}

// header menu
.header-component {
  z-index: 1561;
  position: relative;

  h1 {
    color: $white;
    display: inline-block;
    margin: 7px 0 3px 0;
    letter-spacing: 0.6pt;
    font-size: $font-lg;
    font-family: $rs-font-bold;
  }
}

.tag-account {
  position: absolute;
  top: -3px;
  right: 155px;

  img {
    width: 60px;
    margin-top: 3px;
  }
}

.header-nav-right-list {
  float: right;
  @include flexbox();
  margin: 0;

  li {
    @include flexbox($x: center);
    padding: 6px;
    width: 44px;
    color: $white;
    position: relative;
    @include image('header-nav-splitter.png', left center);

    &:first-child {
      background-image: none;
    }

    span.label {
      background-color: $active-color;
      position: absolute;
      right: 1px;
      top: -4px;
      padding: 3px 8px;
      font-size: $font-xs;
      line-height: 11px;
      border-radius: 40px;
      cursor: pointer;
    }
  }
}

.rs-navbar-header {
  display: inline-block;
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  top: 20px;
  margin-left: 1px;

  ul {
    margin: 0;
    padding: 0;
    @include flexbox($y: end);

    li {
      @include sizing($wh: 39px);
      margin: 0 3px;
      @include flexbox($y: center, $x: center);

      img {
        display: inline-block;
        width: 24px;
        position: relative;
      }

      a,
      button {
        @include flexbox($x: center);
        background-color: $white;
        border-radius: 50%;
        border: 1px solid $primary-color;

        @if $new-theme-test ==true {
          box-shadow: 0px 5px 6px -3px #00000054;
        }

        &.active {
          background-color: $active-color;
          border-color: $active-color;
          box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.329411764);
        }
      }

      // width
      &:nth-child(1),
      &:nth-child(5) {

        &,
        a {
          @include sizing($wh: 39px);
        }
      }

      &:nth-child(2),
      &:nth-child(4) {

        &,
        a {
          @include sizing($wh: 43px);
        }
      }

      &:nth-child(3) {

        &,
        a {
          @include sizing($wh: 52px);
        }
      }

      span.hicon {
        display: inline-block;
      }

      &:nth-child(1) span.hicon {
        @include image('header-icon/d-icon-1.svg');
        width: 24px;
        height: 34px;
      }

      &:nth-child(1) a.active span.hicon {
        @include image('header-icon/d-icon-1-w.svg');
      }

      &:nth-child(2) span.hicon {
        @include image('header-icon/d-icon-2.svg');
        width: 24px;
        height: 40px;
        position: relative;
        left: -2px;
      }

      &:nth-child(2) a.active span.hicon {
        @include image('header-icon/d-icon-2-w.svg');
      }

      &:nth-child(3) span.hicon {
        @include image('header-icon/d-icon-3.svg');
        width: 35px;
        height: 47px;
      }

      &:nth-child(3) a.active span.hicon {
        @include image('header-icon/d-icon-3-w.svg');
      }

      &:nth-child(4) {
        span.hicon {
          @include image('header-icon/d-icon-4.svg');
          width: 20px;
          height: 40px;
        }

        .dropdown-toggle.active span.hicon {
          @include image('header-icon/d-icon-4-w.svg');
        }
      }

      &:nth-child(4) a.active span.hicon {
        @include image('header-icon/d-icon-4-w.svg');
      }

      &:nth-child(5) span.hicon {
        @include image('header-icon/d-icon-5.svg');
        width: 22px;
        height: 36px;
      }

      &:nth-child(5) a.active span.hicon {
        @include image('header-icon/d-icon-5-w.svg');
      }

      // disabled
      &.idisable {
        pointer-events: none;

        a {
          background-color: #d9d9d9;
          border: 1px solid #4a051b;
        }
      }

      &:nth-child(1).idisable {

        a.active span.hicon,
        span.hicon {
          @include image('header-icon/d-icon-1-disable.svg');
        }
      }

      &:nth-child(2).idisable {

        a.active span.hicon,
        span.hicon {
          @include image('header-icon/d-icon-2-disable.svg');
        }
      }

      &:nth-child(3).idisable {

        a.active span.hicon,
        span.hicon {
          @include image('header-icon/d-icon-3-disable.svg');
        }
      }

      &:nth-child(4).idisable {

        a.active span.hicon,
        span.hicon {
          @include image('header-icon/d-icon-4-disable.svg');
        }
      }

      &:nth-child(5).idisable {

        a.active span.hicon,
        span.hicon {
          @include image('header-icon/d-icon-5-disable.svg');
        }
      }
    }
  }
}

.h-notify {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.h-profile {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid $white;
  background-color: $white;
  overflow: hidden;
  background-color: $white;
  @include image('profile-pic.png', center center);
  background-size: cover;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
  }
}

.breadcrumbs {
  height: 15px;
  position: fixed;
  top: 60px;
  width: 100%;
  z-index: $breadcrumbs-tooltip;
  background-color: $bg-color6;

  .breadcrumb {
    padding: 0;
    font-size: $font-xxs;
    color: $default-color;
    margin: 0;
    @include flexbox($x: end);

    li {
      padding-left: 15px;
      position: relative;
      color: $primary-color;
      line-height: 15px;

      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }

      &:before {
        @include pseudo();
        @include image('arrow-bred.png', center center);
        width: 10px;
        height: 10px;
        right: -12px;
        top: 2px;
      }

      &:last-child {
        &:before {
          content: inherit;
        }
      }

      &.active {
        color: $default-color;

        &:hover {
          cursor: inherit;
          text-decoration: inherit;
        }
      }
    }
  }
}

// Login
.login-valid-icon {

  &.icon-close-small,
  &.icon-tick-circle-small {
    font-size: $font-sm;
  }

  &.icon-close-small {
    top: 5px;
  }

  &.icon-tick-circle-small {
    top: 7px;
  }
}

.login-border {
  border: 1px solid $border-color;
  border-top-color: $primary-color;
  position: relative;

  @if $new-theme-test ==true {
    overflow: hidden;
  }
}

.login-body-form {
  margin-top: 20px;
}

.validateCount {
  @include flexbox();
  flex-wrap: inherit;
  margin-left: 1px;

  input {
    margin-bottom: 0;
    padding-left: 75px;
    margin-left: 0px;
  }

  .input-group {
    width: 65px;
    position: absolute;
    bottom: 1px;

    .input-group-text {
      width: 65px;
      border: 0;
      border-radius: 0;
      background-color: transparent;
      padding: 4px 9px 0;
      color: $main-color;
      margin-left: 1px;

      &:after {
        content: '';
        position: absolute;
        right: 0;
        bottom: 0;
        height: 15px;
        border-right: 1px solid $border-color;
      }
    }
  }
}

.checkbox-alert {
  .validation-message {
    transform: translateY(-6px);
  }
}

.forgot-password-modal {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #fdfdfd;
  padding: 20px 30px;
  z-index: 15;

  .close-modal,
  .close-ico-btn {
    position: absolute;
    right: 10px;
    top: 7px;
  }
}

.forgot-txt {
  display: block;
  margin: 9px auto 0 !important;
}

.close-modal,
.close-ico-btn {
  border-radius: 30px;
  color: $primary-color;

  &:hover {
    cursor: pointer;

    i.icon-sd-close-mini {
      color: #fff;
    }
  }

  i.icon-sd-close-mini {
    position: relative;
    top: 2px;
    left: 6px;
    font-size: $font-xs;

    &:before {
      position: relative;
      left: 1px;
      top: 3px;
    }
  }
}

.close-ico-btn {
  width: 30px;
  height: 30px;

  &:hover {
    background-color: $primary-color;
  }
}

.logged-in-otp {
  .form-login-cam {
    position: relative;

    .forgot-password-modal {
      padding: 13.5px 23px;
      background-color: $white;
      position: absolute;
      top: -74px; //-77px;
      margin: 0 -20px;

      .close-modal,
      .close-ico-btn {
        right: 1px; //0;
        top: 4px; //14px;
      }

      h2 {
        border-bottom: 1px solid $primary-color;
        padding-bottom: 13px;
      }
    }

    #loading {
      position: absolute;

      .loading-content,
      .loading-tick {
        // top: 43%;
      }
    }
  }

  .modal-dialog {
    width: 380px;
  }
}

.form-login-cam {
  width: 100%;
}

.rs-dotted-box {
  position: relative;
}

ul.rs-dotted {
  position: absolute;
  left: 50%;
  top: -66px;
  @include css3-prefix(transform, translate(-50%, 0));

  &.first li:nth-child(1) {
    background-color: $primary-color;
  }

  &.second li:nth-child(2) {
    background-color: $primary-color;
  }

  &.third li:nth-child(3) {
    background-color: $primary-color;
  }

  li {
    width: 10px;
    height: 10px;
    background-color: $grey-50;
    border-radius: 50%;
    float: left;
    margin: 10px 6px;
  }
}

.alert-forgot-success {
  background-color: #99cc03;
  padding: 10px 10px 13px 52px;
  position: relative;

  p,
  i.icon-tick-circle-small {
    color: #fff;
  }

  i.icon-tick-circle-small {
    position: absolute;
    left: 18px;
    font-size: $icons-xs;
    top: 20px;
  }

  p {
    margin: 0;
    font-size: $font-xs;
    padding-right: 15px;
  }

  i.icon-close-medium {
    color: $white;
    position: absolute;
    right: 6px;
    top: 6px;
  }
}

// Features header
// Features container
.features-container {
  padding-left: 30px;
  margin: 60px auto 15px;
  margin-top: 100px;

  .features-content-wrapper {
    margin-top: 34px;
    padding-right: 50px;

    // .checkboxContainer .checkmark {
    //   top: 2px;
    //   &:after {
    //     @include image('checkbox.png', 1px 3px);
    //   }
    // }
  }

  .features-cost-wrapper {
    background-color: $even;
    padding: 46px 30px 30px;
    margin-bottom: 10px;

    .k-widget.k-dropdown {
      margin-bottom: 10px;
    }

    h3 {
      margin-bottom: 0;
    }
  }

  &.new-features {
    padding-left: $theme-space;
    padding-right: $theme-space;
    margin: 98px auto $theme-space;

    .features-content-wrapper {
      margin-top: 0;
      padding-left: $theme-space;
      padding-right: $theme-space;
      background-color: #f4f5fa;
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }

    .features-cost-wrapper {
      background-color: $even;
      padding: 17px 23px 30px;
      width: 32%;
      right: 23px;
      left: inherit;
      top: 98px;
      z-index: 22;
      height: calc(100% - 190px);
      overflow: hidden;

      @if $new-theme-test ==true {
        @extend %new-theme-shadow;
      }

      h6 .k-widget.k-dropdown {
        margin-bottom: 10px;
      }

      h3 {
        margin-bottom: 0;
      }
    }

    &.mobile-field {
      margin: 50px auto 25px;

      .mobile-flip {
        width: 300px;
        margin: 0 auto;
      }

      .device-screen {
        width: 380px;
        margin: 0 auto;
        position: relative;
        overflow: hidden;
        zoom: 0.8;
      }
    }
  }
}

.cost-row-component {
  @include flexbox($y: flex-start, $x: space-between);
  color: $default-color;
  margin: 0 0 20px;

  input {
    margin-bottom: 0;
  }

  button {
    border-radius: $theme-radius-size;
    margin-left: -1px;
    width: auto !important;
    font-size: $font-xsm;
  }

  small {
    color: $fontColor1;
  }
}

// ANIMATE
.features-container .rs-scroll {
  transition: 0.3s all ease;

  &.rs-scroll-init {
    top: 100px;
    height: calc(100% - 120px);
  }

  &.rs-scroll-init-end {
    height: calc(100% - 190px);
  }
}

.features-container .freeze-panel {
  transition: 0.3s all ease;
  position: sticky;
  top: 57px !important;
  z-index: 20;
  &.freeze-panel-init-ar {
    z-index: 20;
  }
}

.features-container .subscription-logged-rest {
  .features-cost-wrapper {
    top: 133px;
  }

  .rs-scroll {
    &.rs-scroll-init {
      top: 83px;
      height: calc(100% - 120px);
    }

    &.rs-scroll-init-end {
      height: calc(100% - 190px);
    }
  }

  .freeze-panel {
    position: sticky;
    transform: translateY(0);
    top: -60px;

    &.freeze-panel-init {
      top: 0 !important;
      transform: translateY(59px);
    }
  }
}

.rsp-btn-select.price-disable.click-off {
  opacity: 1;
}

.features-container .freeze-panel.freeze-panel-init.lanchpad-price {
  top: 57px !important;
}

small.comma-listing {
  div {
    float: left;

    &:before {
      content: ',';
      padding-right: 5px;
    }

    &:first-child:before {
      content: inherit;
    }
  }
}

.comma-seperator small {
  &:first-child:before {
    content: '(';
  }

  &:after {
    content: ', ';
  }

  &:last-child:after {
    content: '';
  }

  &:last-child:after {
    content: ')';
  }
}

// thanks temporarily
.thanks-temp {
  h2 {
    color: $primary-color;
    font-size: $font-lg;
  }

  h5 {
    color: $default-color;
    font-size: $font-xsm;
    line-height: 1.8;
  }
}

.thanks-theme-box-temp {
  margin-top: 15px;
  padding: 63px 30px;
}

// Launchpad
.launchpad-container {
  padding: 0;

  .launchpad-list {
    background-color: $bg-color4;
    border-bottom: 1px solid $br-color1;
    margin-bottom: 30px;

    .mg-camp {
      width: 50%;
      display: inline-block;
      text-align: center;
      padding: 40px 0;
      border-right: 1px solid $br-color1;

      &:hover {
        cursor: pointer;
        background-color: $bg-color5;

        &,
        i {
          color: $white !important;
        }
      }

      &:last-child {
        border-right: 0;
      }

      i {
        font-size: 84px;
        margin-bottom: 15px;
        display: inline-block;
      }

      // .link-text {
      //   font-size: $font-sm;
      // }
    }

    &.light-launchpad {
      background-color: $white;

      .mg-camp {
        position: relative;
        padding: 24px 0;
        border: 1px solid $white;
        margin: 0 15px;
        width: calc(50% - 30px);

        &:last-child {
          border-left: 1px solid $white;

          &:before {
            content: '';
            position: absolute;
            left: -15px;
            top: 24%;
            height: 56%;
            border-left: 1px solid $border-color;
          }
        }

        &:hover {
          background-color: transparent;
          border: 1px solid $border-color;

          i {
            color: $primary-color !important;
          }

          .link-text {
            color: $primary-black;
          }
        }

        i {
          margin-bottom: 25px;
        }

        .link-text {
          font-size: $font-xsm;
        }
      }
    }
  }
}

// payment
.payment-tab-container {
  border: 1px solid $border-color;
  margin-top: 20px;
  border-radius: 5px;
  overflow: hidden;

  .payment-body-form {
    padding: 20px 20px 0;
    background-color: #fff;
  }
}

// Notification
.header-notis {
  border: 1px solid $border-color;
  @include flexbox($dir: column);
  background-color: $white;
  position: fixed;
  top: 60px;
  right: 100px;
  width: 278px;
  z-index: $header-notis-tooltip;

  @if $theme-radius ==true {
    border-bottom-right-radius: $theme-radius-size;
    border-bottom-left-radius: $theme-radius-size;
    overflow: hidden;
  }

  .header-notis-head {
    @include flexbox($x: space-between, $dir: row);
    background-color: #ffffff;
    padding: 7px 10px;
    border-bottom: 1px solid #c2cfe3;

    p {
      color: $primary-color;
    }
  }

  .notify-body {
    max-height: 383px;
  }

  ul.header-notis-list {

    li {
      @include flexbox();
      padding: 10px !important;
      @include transition();
      border-bottom: 1px solid #ccc;

      @if $new-theme-test ==true {
        background-color: #eff3fc;
        border-bottom: 1px solid #c2cfe3;
        align-items: center;

        &:hover {
          cursor: pointer;
          background-color: #f0f8ff;
        }
      }

      .notify-icon {
        margin-right: 10px;
        width: 34px;
        height: 34px;
      }

      p {
        font-size: $font-xs;
        line-height: 1.3;
        color: #111111;
        margin-bottom: -5px;
        @include truncate(205px, $line: 2);
      }

      small {
        color: #666666;
        font-size: $font-xxs;
      }
    }
  }

  .header-notis-footer {
    @include flexbox($x: space-between, $dir: row);
    padding: 8px;
    background-color: $white;

    .rsp-default-button {
      padding: 4px 10px;
      font-size: $font-xxs;
    }

    .rsp-secondary-button {
      background-color: #e9e9e9;
      color: #111111;
      margin-right: 0;

      &:hover {
        text-decoration: none;
      }
    }

    p {
      margin: 0;
    }
  }
}

.circle-view-icon {
  width: 34px;
  height: 34px;
  text-align: center;
  line-height: 40px;
  border-radius: 5px;
  background-color: $bg-color7;
  color: $white;
  display: inline-block;

  &:before {
    top: -4px;
    position: relative;
  }

  &.icon-sd-in-progress-medium {

    // font-size: 30px !important;
    &:before {
      top: -2.9px;
      left: 0.5px;
    }
  }

  &.icon-close-small {
    &:before {
      top: -7px;
    }
  }
}

.visa-input {
  position: relative;

  small {
    position: absolute;
    bottom: 8px;
    right: 0;
    color: $fontColor3;
  }
}

.visa-card-img {
  top: 3px !important;
  width: 30px !important;
}

.slashInput {
  position: relative;

  .slash {
    position: absolute;
    bottom: 15px;
  }
}

// footer
.footer-borders-container {
  position: relative;
  height: 7px;
  width: 100%;
  overflow: hidden;
  padding: 0;
  margin: 10px 0 0;

  li {
    height: 7px;
    width: 20%;
    margin: 0;
    padding: 0;
    float: left;

    &:nth-child(1) {
      @include color($bg: $footerColor1);
    }

    &:nth-child(2) {
      @include color($bg: $footerColor2);
    }

    &:nth-child(3) {
      @include color($bg: $footerColor3);
    }

    &:nth-child(4) {
      @include color($bg: $footerColor4);
    }

    &:nth-child(5) {
      @include color($bg: $footerColor5);
    }
  }
}

ul.copyright {
  @include flexbox($x: center);
  padding-top: 10px;
  padding-bottom: 10px;
  letter-spacing: 0;

  li {
    font-size: $font-xs;
    margin: 0 14px;
    position: relative;

    &:before {
      left: -13px;
      content: '';
      height: 10px;
      top: 7px;
      position: absolute;
      border-left: 1px solid #b7b7b7;
    }

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }

    &:first-child {
      &:before {
        content: inherit;
      }

      &:hover {
        cursor: default;
        text-decoration: none;
      }
    }
  }
}

.powered-by {
  position: absolute;
  bottom: 3px;
  right: 12px;
  @include flexbox($y: center);

  i {
    top: 2px;
    right: -7px;
    position: relative;
    font-size: $font-smd;
    color: #ffb900 !important;
  }
}

// page content
// Preference
.perference-wrapper {
  display: inline-block;
  padding: $theme-space;
  width: 100%;

  .perference-container {
    display: block;

    .per-list {
      width: 150px;
      height: 120px;
      float: left;
      border: 1px solid $br-color5;
      padding: 15px;
      text-align: center;
      margin: 0 20px 0 0;
      line-height: 20px;
      @include transition();

      &:hover {
        border: 1px solid $border-color;
        cursor: pointer;

        @if $new-theme-test ==true {
          background-color: $even;
          border-color: $beta_tm_border;

          border-radius: $theme-radius-size;
        }
      }

      i {
        color: $primary-color;
        display: inline-block;
        height: 42px !important;
        position: relative;

        &.icon-communication-settings-xlarge {
          font-size: $font-xl !important;
        }

        &.icon-user-profile-large .indicator {
          right: 7px;
        }

        &.icon-settings-large .indicator {
          right: 2px;
        }

        &.icon-service-catalogue-xmedium .indicator {
          top: -5px;
          right: -2px;
        }

        .indicator {
          width: 10px;
          height: 10px;
          background: #c70505;
          border-radius: 50%;
          position: absolute;
          top: 0;
          right: 0;
        }
      }

      div {
        margin-top: 5px;
      }
    }
  }

  &.gray-theme {
    .perference-container {
      .per-list {
        border: 1px solid $br-color4;

        &:hover {
          border: 1px solid $border-color;
        }
      }
    }
  }

  &.even {
    .per-list {
      border: 1px solid $even;

      &:hover {
        background: $white;
      }
    }
  }
}

// page title
.header-title {
  align-items: center;

  h1 {
    margin-bottom: 10px;
    color: $primary-color;
  }

  .back-text {
    color: $primary-color;
    position: relative;
    top: 0;

    &:hover {
      cursor: pointer;
    }

    i {
      font-size: $font-xxs;
      margin-right: 1px;
    }
  }
}

.header-title-margin {
  margin-bottom: 10px;
}

// Alert & Notifications
.k-grid.table-alert-notify {
  .k-grid-header {
    display: none;
  }

  td {
    padding: 20px;
    border-width: 0 0px 1px 0px;
    color: $default-color;
  }
}

.rs-dropdown {
  .dropdown-item {
    line-height: 24px;
  }
}

.table-action-btn {

  .rs-dropdown-icon,
  i {
    margin: 0 7px;
  }
}

// .table-action-btn .rs-dropdown-icon,
.table-action-btn i.disabled {
  opacity: 0.5;
  pointer-events: none;
}

// account settings
.k-chip-list {
  @include flexbox();
  height: 54px;
  flex-flow: wrap;

  .k-chip {
    border-radius: 0;
    background-color: $bg-color6;
    border: 0;
    margin-right: 4px;
  }
}

.chips-container {
  margin-bottom: 60px;

  .chips-box {
    border: 1px solid $border-color;
    padding: 5px 5px 2px;
    min-height: 150px;
    border-radius: 5px;

    h6 {
      font-size: $font-xs;
    }

    .k-multiselect {
      ul li:nth-child(odd) {
        background-color: $bg-color6;
      }
    }
  }

  // communication-settings
  &.com-set {
    margin-bottom: 0;

    .chips-box {
      padding: 10px;
      height: 265px;
      max-height: inherit;
      border-top: 1px solid $primary-color;

      .css-scrollbar {
        margin-bottom: 8px;
      }

      .k-multiselect {
        .k-multiselect-wrap .k-button {
          padding: 1px 4px 1px 8px;
          margin: 1px 5px 4px 0px;
          background-color: $bg-color4;
          word-break: break-all;
          text-align: left;

          span {
            color: $default-color;
            font-size: $font-xs;
          }

          .k-i-close:before {
            background-color: $bg-color4;
          }
        }
      }

      .no-close {
        .k-multiselect .k-multiselect-wrap .k-button {
          span {
            .k-i-close {
              display: none;
            }
          }
        }
      }
    }

    h6 {
      font-size: $font-xsm;
      @include flexbox($y: center);

      .count-label {
        background-color: #9A9A9E;
        width: auto;
        height: 20px;
        display: inline-block;
        color: $white;
        border-radius: 50px;
        padding: 2px 6px;
        margin-left: 5px;
        font-size: $font-xs;
      }
    }
  }

  &.campaign-tag {
    .chips-box {
      height: auto;
      min-height: 130px;
      border: 1px solid $primary-color;

      input {
        background: inherit !important;
      }
    }
  }
}

.marginX-10 {
  margin-left: -10px;
  margin-right: -10px;
}

.grid-style {
  overflow-x: hidden;
  border: 1px solid $border-color;
  border-top-color: $primary-color;
  height: 350px;
  background-color: $white;
  border-radius: 5px;
  overflow: hidden;
  @extend %scrollbar;

  li {
    border-bottom: 1px solid $border-color;
    padding: 7px 10px;
    display: flex;

    &:nth-child(odd) {
      background-color: $even;
    }

    &:last-child {
      border-bottom: 0;
    }

    &.active {
      background-color: $bg-color5;
      color: $white;
    }

    &:hover {
      cursor: pointer;
    }

    span {
      @include truncate(99%);
    }
  }
}

.grid-style-arrow {
  margin-top: 160px;
  margin-left: 25px;
  width: 25px;
  height: 25px;
  @include image('icon-status.png', 0 0);

  &:hover {
    cursor: pointer;
  }
}

.sec-list {
  border: 1px solid $border-color;
  border-top-color: $primary-color;
  height: 350px;
  background-color: $white;
  border-radius: 5px;
  overflow: hidden;
}

.sec-listing {
  max-height: 340px;
  overflow-y: auto;

  li {
    padding: 12px 15px;
    border-top: 1px solid $border-color;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 25px;
    &:nth-child(even) {
      background-color: $even;
    }

    &:first-child {
      padding: 7px 15px;
      color: #666;
    }

    &:first-child {
      border-top: 0;
    }

    &:last-child {
      border-bottom: 1px solid $border-color;
    }

    .k-widget.k-dropdown {
      margin-bottom: 0;
    }

    .label {
      width: 180px;
      display: inline-block;
      @include truncate(180px);
      top: 8px;
      position: relative;
      padding-right: 15px;
    }

    .input-field-list {
      width: 200px;
    }

    .sec-icon-list-end {
      position: relative;
      top: 10px;
    }

    .sec-icon-list {
      margin: 0;
      position: relative;
      top: 10px;
      left: 20px;

      i {
        color: $fontColor2;
      }
    }
  }
}

.flip-horizontal {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

// Consumptions
.consume-portlet-box {
  // padding: 15px 10px 10px 15px;
  padding: 15px;
  height: 130px;
  background-color: $white;

  @if $new-theme-test ==true {
    @extend %new-theme-shadow;
  }

  @if $theme-radius ==true {
    border-radius: $theme-radius-size;
    overflow: hidden;
  }

  .consume-portlet-body {
    @include flexbox($y: center, $x: center);
    margin-bottom: 5px;
    margin-top: 5px;

    span {
      padding-left: 8px;
    }
  }

  h2 {
    font-family: $rs-font-bold;
    text-align: center;
  }

  .consume-portlet-footer {
    @include flexbox($y: center, $x: space-between);

    i {
      // padding-left: 10px;
      color: #666666;

      &:hover {
        cursor: pointer;
      }
    }

    small {
      font-size: $font-xxs;
      padding-top: 3px;
    }
  }
}

// Invoice
table.rs-table-sm,
table.rs-table-sm body,
table.rs-table-sm tbody tr {
  height: 26px;
}

table.rs-table-sm tbody tr td:first-child {
  padding-right: 30px;
}

ul {
  padding: 0;
  margin: 0;
}

// .invoice-address {
//   margin-bottom: 25px;
// }
.horizontal-list-row {
  @include flexbox();
  padding: 10px;
  background-color: $bg-color4;

  .horizontal-list {
    width: 100%;
    border-left: 1px solid $border-color;
    padding-left: 20px;

    &:first-child {
      padding-left: 15px;
      border-left: 0;
    }

    h4 {
      margin-bottom: 0;
    }
  }
}

.notification-box {
  p {
    color: $white;
    padding: 9px;
    text-align: center;
  }
}

.border-left-0 {
  border-left: 0px !important;
}

.border-bottom-0 {
  border-bottom: 0px;
}

table.rs-table {
  thead {
    th {
      background-color: $bg-color5;
      color: $white;
      font-weight: normal;
    }
  }

  td,
  th {
    border: 1px solid $border-color;
  }
}

.invoice-note {
  p {
    margin-bottom: 5px;
  }
}

// Communication settings
ul.camp-icon-pannel {
  @include flexbox($y: center, $x: flex-end);

  li {
    margin: 0 7px;

    i:hover {
      cursor: pointer;
    }
  }
}

ul.camp-icon-pannel-2 {
  @include flexbox($y: center, $x: flex-start);

  li {
    margin: 0 7px;

    &:hover {
      cursor: pointer;
    }
  }
}

.tab-vertical-content {
  width: calc(1150px - 120px);
  padding: 20px;
}

.tabs-vertical {
  width: 120px;
  min-width: 120px;
  background-color: $bg-color4;

  @if $new-theme-test ==true {
    border-top-right-radius: 0;
    border-bottom-left-radius: ($theme-radius-size - 1px);
    overflow: hidden;
  }
}

.label-row {
  padding-top: 6px;
  padding-bottom: 6px;
}

.label-dark {
  color: $default-color;
}

.pos-icon-1,
.pos-icon-2 {
  float: right;
  position: absolute;
}

.pos-icon-1 {
  right: -44px;
  bottom: -7px;
}

.pos-icon-2 {
  right: -35px;
  bottom: -6px;
}

// Communication settings - Mobile push
.camp-pannel-content {
  clear: left;
}

.campaign-box-container {
  .rs-grid-listing {
    margin-top: -1rem;
  }

  li.campaign-list-box {
    border: 1px solid $border-color;
    margin-bottom: 0;
    @include flexbox($y: center);

    @if $theme-radius ==true {
      border-radius: $theme-radius-size;
      overflow: hidden;
    }

    position: relative;

    .campaign-label-box {
      padding: 11px 10px;
      width: 25%;
      display: inline-block;

      .campaign-two-label {
        @include flexbox($dir: column);

        h4 {
          margin-bottom: 0;
          margin-top: 5px;
          @include truncate(220px)
        }
      }
    }
  }

  .panel-detail-view {
    margin-top: 0;
    margin-bottom: 0;

    @if $new-theme-test ==true {
      position: relative;
      left: 8px;
      width: calc(100% - 13px);
    }

    .accordian-body {
      border-bottom: 1px solid $border-color !important;
      border-left: 1px solid $border-color !important;
      border-right: 1px solid $border-color !important;
      width: calc(100% - 2.5px);

      @if $new-theme-test ==true {
        border-radius: 0 0 $theme-radius-size $theme-radius-size;
        overflow: hidden;
      }

      thead {
        tr {
          background-color: $white;

          th {
            background-color: $primary-color;
            color: $white;
            padding: 10px;
          }
        }
      }

      tbody {
        tr {
          td {
            white-space: nowrap;

            &:nth-child(1) {
              width: auto;
              position: relative;
              float: none;
              white-space: inherit;
            }

            &:nth-child(2) {
              float: none;
              width: 30%;
            }
          }
        }
      }
    }
  }
}

.account-settings {
  .settings-container {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.expand-icon {
  position: absolute;
  right: 0;
  bottom: 0;
  display: block;
  border-bottom: 30px solid $primary-color; // #2896f0
  border-left: 30px solid transparent;

  &:hover {
    cursor: pointer;
  }

  i {
    color: $white;
    position: absolute;
    right: 2px;
    font-size: $font-xxs !important;
    top: 14px;
  }
}

.rs-accordin-container {
  width: 96%;

  // doubt
  .accordion-item {
    h2 {
      padding: 0.5rem 1rem;
      background-color: #f5f5f5;
      border-bottom: 1px solid #ddd;

      button {
        padding: 0;

        &.accordion-button {
          box-shadow: none;
          background-color: #f5f5f5;
          border-color: #ddd;

          h3 {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

.rs-accordin-body {
  padding: 0 35px 15px 20px;

  &.align-space-left {
    padding-left: 10px;
  }
}

.rs-accordin.accordion {
  position: relative;

  .accordion-item {
    border-radius: 0;

    .accordion-body {
      padding-bottom: 0;
    }

    .rs-accordin-body {
      padding-bottom: 15px;
    }
  }
}

.input-below-icon-set {
  @include flexbox($y: center);
  margin-bottom: 20px;
  margin-top: 5px;

  i,
  .k-dropdown-button {
    margin: 0 5px 0 0;

    .k-button {
      padding: 0;
      margin-top: 4px;
      margin-right: 0;
      margin-bottom: 3px;

      &:hover {
        background: transparent;
      }
    }
  }

  .picker-in {
    margin: 0px 10px 0 2px;
  }

  .emoji-picker-icon {
    position: relative;
    top: 0;
    margin: 0;
    height: 37px;
    padding: 6px 0;
  }
}

.tab-content .rich-push-tab {
  margin-left: 0;
  margin-right: 0;
}

.rs-accordin-header {
  color: $default-color;
  width: 100%;
  @include flexbox($x: space-between);

  h3 {
    margin: 0;
  }
}

.rs-accordin.accordion .accordion-item {
  margin-bottom: 20px;

  &:not(:first-of-type) {
    border-top: 1px solid rgba(0, 0, 0, .125);
  }
}

.onboard-header-label {
  margin-left: 0;
}

.table-action-btn {
  @include flexbox($x: end);

  .rs-dropdown-icon {
    display: inline-block;

    &.dropdown,
    &.dropdown button {
      height: 23px;
    }

    .btn-primary {
      background-color: transparent;
      border: none;
      padding: 0;

      &:after {
        content: inherit;
      }

      &:active,
      &:focus {
        border: 0;
        box-shadow: none;
        outline: none;
        background-color: transparent !important;
      }

      i {
        margin: 0;
      }
    }
  }

  .dropdown-menu {
    min-width: 53px;
    text-align: center;

    .dropdown-item {
      padding: 6px 0;
      text-align: center;

      &:hover,
      &:active {
        background-color: $primary-color;

        i {
          color: $white !important;
        }
      }
    }
  }
}

.progress-steps-box {
  padding: 0;
  background-color: $bg-color4;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0 20px;

  @if $new-theme-test ==true {
    @extend %new-theme-shadow;
    background-color: $white;
    // margin: $theme-space;
    // width: calc(100% - ($theme-space + $theme-space));
  }

  .steps-label {
    position: relative;
    display: flex;
    padding: 8px 15px 8px $theme-space;

    @include screen('xl') {
      padding-left: 0;
    }

    align-items: start;
    flex-direction: column;

    &:before {
      content: '';
      position: absolute;
      left: -15px;
      height: auto;
      top: 0;
      bottom: 0;
      margin-top: 0;
      border-left: 1px solid $border-color;
    }

    &:first-child:before {
      content: inherit;
    }

    label {
      padding: 0;
      font-size: $font-sm;
      @include truncate(230px);
    }
  }

  .steps-label-row-icon {
    .large-icon {
      height: 65px;
      @include flexbox($y: center, $x: center);
      padding: 0;
      width: 50px;
      border-radius: 0 4px 4px 0;
    }
  }
}

.goal-tab-container {
  padding: 0 0 20px;

  .goal-tab-plus-wrapper {
    @include flexbox($y: center);
  }
}

// Data exchange
// card 01
.data-card-view-container {
  padding: 20px $theme-space 0;
  border: 1px solid $border-color;
  width: 100%;
  min-height: 170px;
  border-radius: 5px;

  .data-card-view {
    border: 1px solid $border-color;
    display: inline-block;
    width: 100%;
    height: 178px;
    border-radius: 5px;
    margin-bottom: 20px;
    position: relative;
    text-align: center;
  }
}

.api-setting {
  position: absolute;
  right: 7px;
  top: 4px;
  @include flexbox();
}

.api-setting li {
  padding-left: 7px;
}

.sysicon {
  .icon-sd-circle-plus-fill-edge-medium {
    background-color: $white;
  }

  .icon-sync-medium {
    background: $white;
    border-radius: 50%;
  }
}

.data-card-view-container .data-card-view {
  figure {
    height: 75px;
    display: block;
    padding: 10px;
    margin: 0;

    img {
      height: 100%;
    }
  }

  p {
    border-top: 1px solid $br-color1;
    padding: 9px;
    margin: 0;
  }

  .box-sm {
    background-color: $even;
    border-top: 1px solid $br-color1;
    padding: 9px;

    small {
      color: #9e9e9e;
      display: block
    }

    .sysicon {
      position: absolute;
      right: -10px;
      bottom: -13px;
    }
  }
}

// card 02
.data-card-api {
  border: 1px solid $border-color;
  text-align: center;
  @include center();
  min-height: 100px;
  position: relative;
  margin-bottom: 20px;
  border-radius: 5px;

  &:hover {
    cursor: pointer;
  }

  figure {
    margin-bottom: 0;
  }

  .sysicon {
    position: absolute;
    right: -13px;
    bottom: -16px;

    i {
      background-color: $white;
      border-radius: 50%;
    }
  }
}

// progress
.progress-style1 {
  height: 15px;
  position: relative;
  padding: 2px;
  border-radius: $theme-radius-size;
  clear: both;
  margin-bottom: 10px;
  border: 2px solid #d6d6d6;
  overflow: hidden;

  .progress-bar {
    width: 70%;
    height: 7px;
    max-width: 98.5%;
    border-radius: $theme-radius-size;
    left: 3px;
    position: absolute;
    bottom: inherit;
    background-color: #2896f0;
  }
}

.nodata-add-icon {
  @include center();
  height: 147px;
}

// New Dynamic List
.dynamicListAddIcon {
  position: relative;
  right: -40px;
  bottom: 55px;
  float: right;
}

.rule-close-icon {
  position: absolute;
  right: 10px;
  top: 4px;
}

.k-switch-container {
  width: 60px;
}

.sw-and .k-switch-container {
  width: 63px;
}

.switch-divider {
  position: relative;

  &:before {
    content: '';
    height: 1px;
    position: absolute;
    left: 0;
    top: 15px;
    right: 0;
    background-color: $border-color;
  }
}

.mand-field {
  color: $fontColor4;

  &.red-star {
    color: #fe5758;
  }
}

// .k-icon.k-i-more-vertical {
//   width: 16px;
//   height: 17px;
//   top: -2px;
//   @include image('kendo-sprite.png', -32px -78px);

//   &:before {
//     content: inherit;
//   }
// }

.k-grid-header {

  .k-i-sort-asc-sm,
  .k-i-sort-desc-sm,
  .k-sort-order {
    color: $white;
    top: 0;
  }

  // th.k-filterable.k-header {
  //   &.active {
  //     .k-icon.k-i-more-vertical {
  //       @include image('kendo-sprite.png', -48px -78px);
  //     }
  //   }
  // }
}

// error message
.validation-message {
  &.validate-editor-err {
    top: 72px;
    left: 9px;

    &:before {
      border-width: 0px 8px 7px;
      bottom: inherit;
      top: -7px;
      left: 3px;
    }

    &:after {
      border-width: 0px 7px 7px;
      bottom: inherit;
      top: -6px;
      left: 5px;
    }
  }

  &,
  .validation-message-v2 {
    position: absolute;
    // top: inherit;
    bottom: 100%;
    left: 0;
    z-index: 15;
    min-width: auto;
    padding: 0px 5px;
    transform: translateY(0px);
    border-radius: 0;
    font-size: $font-xs;
    background-color: #f2dede;
    border: 1px solid #eed3d7;
    color: #b94a48;
    line-height: 20px;

    @if $new-theme-test ==true {
      box-shadow: 1px 1px 2px #00000080;
      border: 0;
    }

    @if $theme-radius ==true {
      border-radius: 2px;
    }

    &:before,
    &:after {
      content: '';
      width: 0;
      border-style: solid;
      display: block;
      position: absolute;
    }

    &:before {
      border-width: 7px 8px 0;
      bottom: -7px;
      left: 0px;
      border-color: #eed3d7 transparent;

      @if $new-theme-test ==true {
        border-width: 0;
      }
    }

    &:after {
      border-width: 7px 7px 0;
      bottom: -6px;
      left: 2px;
      border-color: #f2dede transparent;

      @if $new-theme-test ==true {
        bottom: -6px;
        left: 3px;
        filter: drop-shadow(1px 1px 0px #00000050);
      }
    }

    &.inputValidate {
      min-width: inherit;
      bottom: 65px;
      top: inherit;
    }

    &.mobilenoError {
      left: 70px
    }

    &.mobile-valid {
      &:before {
        left: 12px;
      }

      &:after {
        left: 14px;
      }
    }

    &.no-arrow {

      &:before,
      &:after {
        content: inherit;
      }
    }

    &.success_msg {
      background-color: #c3e4c3;
      border: 1px solid #a7cb86;
      color: #37620d;

      &:before {
        border-color: #a7cb86 transparent;
        left: 1px;
      }

      &:after {
        border-color: #c3e4c3 transparent;
      }
    }

    &.multi-select-valid-msg {
      min-width: auto;
      top: inherit;
      bottom: 100%;
      transform: translateY(-10px);
      left: 0px !important;
    }

    &.align-end {
      bottom: inherit;
      top: 40px;

      &:before {
        border-width: 0 8px 7px;
        bottom: inherit;
        top: -7px;
      }

      &:after {
        border-width: 0 7px 7px;
        bottom: inherit;
        top: -6px;
      }
    }

    &.body-text {
      top: 75px;
      left: 8px;
    }
  }

  &.switch-valid {
    transform: translateY(-4px);
  }
}

.validation-message-v2 {
  opacity: 0;
  height: 0;
  border: 0;
  padding: 0;
  @include transition();

  &:before,
  &:after {
    content: inherit;
  }

  &.error-show {
    opacity: 1;
    height: 22px;
    padding: 0px 5px;
    border: 1px solid #eed3d7;
    @extend %animate;

    &:before,
    &:after {
      content: "";
    }
  }

  &.ai-login-check {
    bottom: 29px;
    top: inherit;
  }
}

.panelbar-wrapper {
  @include flexbox($y: center, $dir: row);
  --cheight: 90px;
  height: var(--cheight);
  text-align: left;
  // border: 1px solid $border-color;
  // border-bottom: 1px solid $border-color;
  border-left: 4px solid $border-color;

  @if $theme-radius ==true {
    border-radius: $theme-radius-size;
    padding-left: 10px;
    border-left: 4px solid $border-color;
    background-color: #ffffff;
  }

  .cellPannelInner {
    padding: 8px 10px;
    position: relative;

    p {
      margin-top: 0;
      margin-bottom: 0;
      font-size: $font-xsm;
      white-space: pre;
      @include truncate(330px);

      @include screen(xl, max) {
        @include truncate(290px);
      }

      &.analytics-desc {
        @include truncate(310px);
      }
    }

    small {
      color: $fontColor1;
    }
  }

  .statusIndicator {
    background-color: $bg-color7;
    color: $white;
    height: var(--cheight);
    @include center();

    .status-box {
      @include flexbox($dir: column);
      text-align: center;

      .status-small {
        font-size: $font-xs;
      }

      i {
        margin-bottom: 6px;
      }
    }

    &.status-progress {
      background-color: $inprogress;
    }

    &.status-rejected {
      background-color: $rejected
    }

    &.status-completed {
      background-color: $completed;
    }

    &.status-scheduled {
      background-color: $scheduled;
    }

    &.status-alert {
      background-color: $alert;
    }

    &.status-draft {
      background-color: $draft;
    }

    &.status-multistatus {
      background-color: $multi-status;

      .panelbar-wrapper {
        border-left-color: $multi-status;
      }
    }
  }

  &.campaign-analytics-list {
    .cellPannelInner {
      &:nth-child(1) {
        width: 42%;
      }

      &:nth-child(2) {
        width: 20%;
      }

      &:nth-child(3) {
        width: 15%;
      }
    }

    .statusIndicator {
      min-width: 120px;
      margin-right: 20px;
    }

    .camp-icon {
      width: 185px;
      margin-right: 15px;
    }

    &tr:hover {
      background: inherit;
    }
  }

  &.admin-listing {
    .cellPannelInner {
      &:nth-child(1) {
        width: 145px;
        border-right: 1px solid $border-color;
        height: 80px;
        @include flexbox($y: center, $x: center);
      }

      &:nth-child(2) {
        width: 20%;
      }

      &:nth-child(3) {
        width: 22%;
      }

      &:nth-child(4) {
        width: 12%;
      }

      &:nth-child(5) {
        width: 190px;
        text-align: center;
      }

      &:nth-child(6) {
        width: 16%;
      }

      p {
        font-size: $font-xsm;
        margin-top: 0;
      }
    }
  }
}

.ico-status-progress {
  color: $inprogress;
}

.ico-status-rejected {
  color: $rejected;
}

.ico-status-completed {
  color: $completed;
}

.ico-status-scheduled {
  color: $scheduled;
}

.ico-status-alert {
  color: $alert;
}

.ico-status-draft {
  color: $draft;
}

.ico-status-multistatus {
  color: $multi-status;
}

.bg-status-progress {
  background-color: $inprogress;
}

.bg-status-rejected {
  background-color: $rejected
}

.bg-status-completed {
  background-color: $completed;
}

.bg-status-scheduled {
  background-color: $scheduled;
}

.bg-status-alert {
  background-color: $alert;
}

.bg-status-draft {
  background-color: $draft;
}

.bg-status-multistatus {
  background-color: $multi-status;
}

.camp-icon .camp-icon-pannel li {
  height: 23px;
}

.brand-logo-admin {
  img {
    width: 80px;
  }

  .help-fixed {
    font-size: $font-xxs;
    line-height: 15px;
    font-style: normal;
    z-index: 1;
    position: absolute;
    right: 0px;
    top: 0;
    padding: 2px 3px;
    color: $white;

    &:after {
      content: '';
      display: inline-block;
      position: absolute;
      right: 0px;
      top: 0;
      z-index: -1;
      border-left: 25px solid rgba(0, 0, 0, 0);
      border-top: 25px solid $br-color2;
      border-right: 0px solid rgba(0, 0, 0, 0);
    }

    &.enterprice:after {
      border-top-color: $primary-color !important;
    }

    &.pro:after {
      border-top-color: $orange-50 !important;
    }

    &.free:after {
      border-top-color: #585858 !important;
    }
  }
}

.status-progress .panelbar-wrapper {
  border-left-color: $inprogress;
}

.status-rejected .panelbar-wrapper {
  border-left-color: $rejected
}

.status-completed .panelbar-wrapper {
  border-left-color: $completed;
}

.status-scheduled .panelbar-wrapper {
  border-left-color: $scheduled;
}

.status-alert .panelbar-wrapper {
  border-left-color: $alert;
}

.status-draft .panelbar-wrapper {
  border-left-color: $draft;
}

.status-multistatus .panelbar-wrapper {
  border-left-color: $multi-status;
}

.deactivate .panelbar-wrapper {
  border-left-color: $deactivateColor;
}

.activate .panelbar-wrapper {
  border-left-color: $activateColor;
}

// gallery
.card-gallery-box {
  @extend %boxshadow;
  background-color: $white;
  border: 1px solid #e1e1e1;
  border-top: 2px solid #b3d942;
  height: 362px;
  margin-top: 0;
  margin-bottom: 0;

  @if $new-theme-test ==true {
    @extend %new-theme-shadow;
  }

  @each $name,
  $status-label in $status-labels {
    &.status-#{$name} {
      border-top: 4px solid $status-label !important;
    }
  }

  .box {
    padding: 7px 15px 15px;

    .gallery-head {
      position: relative;
    }

    .img-bor-wrapper {
      position: relative;

      i.action-info {
        position: absolute;
        right: 5px;
        bottom: 6px;
        background-color: $white;
        border-radius: 50%;
        width: 18px;
        height: 18px;
        z-index: 1;

        &.icon-close-small {
          bottom: 8px;
        }
      }
    }

    .img-bor {
      margin-top: 5px;
      height: 280px;
      position: relative;
      display: flex;
      align-items: center;
      overflow: hidden;

      .no-data-container {
        height: 243px;
      }

      .carousel-inner,
      &,
      .carousel-item {
        height: 100%;
      }

      .carousel-inner {
        i.action-info {
          bottom: 10px;
        }
      }

      img.gallery-bg-img {
        margin: 0 auto;
        display: block;
        width: -webkit-fill-available;
        max-width: 100%;
        height: fit-content;
        border-radius: 5px;

        &:hover {
          cursor: pointer;
        }
      }

      i.icon-close-small {
        position: absolute;
        top: 7px;
        right: 12px;
      }
    }

    p {
      margin-bottom: 0;
      color: $default-color;
    }
  }
}

button.k-button.k-button-icon,
button.k-button.k-button-icontext {

  &:hover,
  &:focus {
    background: transparent;
    box-shadow: none !important;
  }
}

.header-dropdown {
  .k-icon {
    margin-top: 5px;
  }

  .k-button-icontext {
    font-size: 34px;
    color: $primary-color;
    display: flex;
    flex-direction: row-reverse;
    padding: 0;
  }
}

.k-button,
.k-button-secondary {
  border: none;
  background: none;
}

.transition {
  @include transition()
}

.dropdown-menu {
  display: block;
  margin-top: -5px;
  margin-right: -10px;
  min-width: 30px;
  max-height: 210px;
  overflow: hidden;
  overflow-y: auto;
  // padding: 3px 0 !important;
  transition: all 0.25s ease 0s;
  opacity: 0;
  visibility: hidden;
  border-radius: 5px;
  padding: 5px !important;
  border: 1px solid $border-color; //$border-color;
  text-align: left;
  background-color: $white;
  @include css3-prefix(box-shadow, 0 3px 3px 0 rgba(0, 0, 0, 0.06));

  &.show {
    margin-top: 0;
    visibility: visible;
    opacity: 1;
  }
}

// create campaign
.campaign-progress-bar {
  padding-top: 12px;

  .list-group {
    &.list-group-steps {
      @if $new-theme-test ==true {
        background-color: $white;
      }

      .list-group-item {
        @if $new-theme-test ==true {
          background-color: $white;
        }
      }
    }
  }
}

.list-group {
  &.list-group-steps {
    width: 100%;
    position: relative;
    display: block;
    padding-top: 14px;

    .list-group-item {
      display: table-cell;
      text-align: center;
      width: 1%;
      border: none;
      background-color: transparent;

      .step {
        border-radius: 100%;
        display: inline-block;
        font-size: $font-sm;
        line-height: 48px;
        position: relative;
        text-align: center;
        width: 48px;
        height: 48px;
        // border: 8px solid #fdfdfd;
        z-index: 2;
        background-color: #c2cfe3;
        color: $white;
        margin-bottom: 5px;

        @if $new-theme-test ==true {
          // box-shadow: 0px 4px 8px -2px #00000040;
          // border: 8px solid $white;
          margin-bottom: 10px;
        }
      }

      .title {
        display: block;
        word-wrap: break-word;
        color: #6e6e6e;
      }

      &:before {
        content: '';
        display: block;
        font-size: 0;
        overflow: hidden;
        position: relative;
        top: 26px;
        width: 110%;
        z-index: 1;
        left: 50%;
        border-top: 1px solid $border-color;
      }

      &:last-child:before {
        content: inherit;
      }
    }

    .complete.list-group-item .step {
      background-color: #99cc03;
    }

    .true.list-group-item {
      .step {
        background-color: #99cc03;
      }
    }

    .pending.list-group-item {
      .step {
        background-color: #c2cfe3;
      }
    }

    .inProcess.list-group-item {
      .step {
        background-color: $orange-50;
      }

      .title {
        color: $orange-50;
      }
    }

    // .inProcess.list-group-item .step {background-color: #fd8f40;}
    .execute.list-group-item .step {
      background-color: #c2cfe3;
    }
  }
}

.communication-completion {
  .list-group {
    &.list-group-steps {
      @if $new-theme-test ==true {
        background-color: $beta_tm_body_bg;
      }

      .list-group-item {
        @if $new-theme-test ==true {
          background-color: $beta_tm_body_bg;
        }
      }
    }
  }

  .progress-steps-box {
    @if $new-theme-test ==true {
      width: 100%;
      margin: 10px 0 $theme-space
    }
  }
}

.smart-link-popup-ui {
  .goal-tab-container {
    padding: 0;
  }
}

.tags-campaign {
  float: left;
  display: flex;
  align-items: center;
  margin-top: 2px;
}

.campaign-tab-bar {
  margin: 10px 0 0;
  position: relative;
}

.campaign-tab-bar .refresh-icon {
  position: absolute;
  right: 10px;
  top: 10px;
}

%tabArrow {
  background-color: $bg-color5;
  color: $white !important;

  &:before {
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid $primary-color;
    bottom: -7px;
    content: '';
    left: 50%;
    margin-left: -8px;
    position: absolute;
  }
}

.choose-or {
  width: 25px;
  margin: 0 10px;
  min-height: 70px;
  position: relative;
  text-align: center;
  z-index: 1;

  *:first-child {
    width: 25px;
    height: 25px;
    margin-top: -12px;
    position: absolute;
    border-radius: 100%;
    top: 50%;
    left: 0;
    padding: 2px 0;
    font-size: $font-xs;
    line-height: 16px;
  }

  &:before {
    border-left: 1px solid $border-color;
    content: '';
    position: absolute;
    display: block;
    top: 0;
    height: 100%;
    left: 50%;
    z-index: -1;
  }
}

.choose-or-small {
  min-height: 40px;

  *:first-child {
    font-size: $font-xxs;
    height: 18px;
    left: 4px;
    margin-top: -9px;
    padding: 0;
    width: 18px;
    background-color: $white;
    color: #7f7f7f;
    border: 1px solid $border-color;
  }
}

.frequency-list {
  @include flexbox($y: center);
  margin-top: 10px;
  margin-bottom: 10px;
  padding-top: 10px;

  li {
    display: inline-block;
    margin-right: 10px;

    input {
      max-width: 83px;
      margin-bottom: 0;
      text-align: center;
    }

    .k-widget.k-dropdown {
      margin-bottom: 0;
    }
    .k-widget.k-timepicker {
      width: 180px;
    }
    i {
      top: 9px;
    }
  }
}

.freq-mlist .checkboxContainer {
  margin-bottom: 0;
}

%or-divider {
  content: "or";
  background-color: $white;
  color: #7f7f7f;
  border: 1px solid $border-color;
  width: 25px;
  height: 25px;
  margin-top: -12px;
  position: absolute;
  border-radius: 100%;
  top: 50%;
  left: calc(100% + 10px);
  padding: 3px 0;
  font-size: $font-xs;
  line-height: 16px;
  pointer-events: none;
}

.or-tab {
  li.tabDefault {
    margin-left: 45px;

    &:first-child {
      margin-left: 0;
    }

    span.or-divider:before {
      content: '';
      border-left: 1px solid $border-color;
      display: inline-block;
      position: absolute;
      bottom: 18%;
      top: 18%;
      right: -23px;
      pointer-events: none;
    }

    span.or-divider:after {
      @extend %or-divider;
    }

    &:last-child {

      span.or-divider:before,
      span.or-divider:after {
        content: inherit;
      }
    }
  }
}

.or-divider {
  &:after {
    @extend %or-divider;
  }

  &.import-or {
    position: absolute;
    right: 22px;
    top: 50%;
    text-align: center;
    z-index: 1;
  }
}

.divider-line-v {
  width: 1px;
  margin: 0 12px;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    left: 50%;
    top: -15%;
    bottom: 0;
    height: 120%;
    width: 1px;
    border-right: 1px solid $border-color;
  }
}

.chips-box {
  border: 1px solid $border-color;
  max-height: 150px;

  &:focus-within {
    border: solid 1px $primary-color;
  }

  ul li {
    padding: 10px 10px 4px;

    &:nth-child(odd) {
      background-color: $even;
    }

    label {
      margin-bottom: 0;
    }
  }
}

// date range picker
.date-range-view-container {
  float: right;
  position: relative;

  &.open .date-title {
    pointer-events: none;
  }

  .date-title {
    @include flexbox($y: center, $x: flex-end);

    &:hover {
      cursor: pointer;

      span {
        // color: $primary-color;
      }
    }

    span {
      display: inline-block;
      font-size: $font-sm;
      color: $default-color;
    }

    i.icon-sd-calendar-medium {
      margin-right: 8px;
      top: -3px;
      position: relative;
    }

    i.icon-dropdown-small {
      font-size: $font-sm;
      color: #888;
    }
  }

  .date-range-dropdown {
    position: absolute;
    z-index: 10;
    background-color: $white;
    right: 0;
    border: 1px solid $border-color;
    padding: 10px;
    @include flexbox();
    opacity: 0;
    visibility: hidden;
    @include shadow();
    @if $theme-radius ==true {
      border-radius: $theme-radius-size;
    }

    &.open {
      visibility: visible;
      opacity: 1;
    }

    .k-daterangepicker {
      display: none;
    }

    &.calendar-box {
      width: 560px;
      height: 295px;
      @include flexbox($x: space-between);

      .k-daterangepicker {
        display: block;
      }
    }

    .date-range-box {
      width: 130px;

      &.dr-right {
        margin-right: 0;
      }

      .date-list-view {
        @include flexbox($dir: column);
        width: 100%;

        li {
          // border: 1px solid $border-color;
          padding: 6px 10px;
          margin-bottom: 8px;
          border-radius: $theme-radius-size;
          // background-color: $bg-color4;
          font-size: $font-xs;
          @extend %animate;

          &:hover {
            background-color: $primary-color;
            color: $white;
            cursor: pointer;
          }

          &.active {

            &,
            &:hover {
              background-color: $even;
              color: $main-color;
            }
          }
        }
      }

      .date-input-view {
        @include flexbox();

        li {
          @include flexbox($dir: column);
          width: 50%;
          float: left;
          margin-right: 8px;
          margin-bottom: 8px;

          &:last-child {
            margin-right: 0;
          }

          div {
            font-size: $font-xxs;
            color: $default-color;
          }

          input {
            background-color: $bg-color4 !important;
            height: 33px;
            font-size: $font-xs;
            padding: 0 6px;
          }
        }
      }
    }
  }
}

.my-drop {
  transition: all 0.25s ease 0s;
  opacity: 0;
  visibility: hidden;
  margin-top: -10px;

  &.open {
    margin-top: 10px;
    visibility: visible;
    opacity: 1;
  }
}

.btn-small {
  button {
    line-height: 15px;
    padding: 8px 11px;
    font-size: $font-xs;
  }

  .rsp-button {
    &.rsp-cancel-button {
      padding: 8px 0;
      margin-right: 23px;
    }
  }
}

.date-range-view-container {
  .k-daterangepicker-wrap {
    display: flex !important;
    width: 100%;
    margin: 0 !important;
    flex-direction: column;

    input {
      padding: 2px 4px;
      font-size: $font-xs;
      background-color: $bg-color4 !important;
    }

    .k-textbox-container {
      width: 50%;
      float: left;
      margin-right: 8px;

      &:last-child {
        margin-right: 0;
      }
    }

    .k-dateinput {
      width: 100%;
      border: 0;
      border-radius: 0;

      &:focus-within {
        box-shadow: none;
      }

      .k-dateinput-wrap input {
        @include inputBorder($input-border, $input-border, $input-border);
        background-color: $bg-color4 !important;
        font-size: $font-xxs;
        padding-top: 5px
      }
    }
  }
}

// temp fix
.date-range-view-container {
  .date-range-dropdown {
    .date-range-box {
      &.dr-right {
        width: 405px;
      }

      .k-daterangepicker-wrap {
        position: relative;
        margin-top: 30px;

        .k-textbox-container {
          position: relative;
          top: -30px;
        }
      }

      .btn-small {
        margin-top: 10px;
        position: relative;
        margin-bottom: 2px;
        z-index: 1;
      }
    }
  }

  .k-widget.k-dateinput .k-input {
    margin-top: 0;
  }
}

%input-set {
  position: absolute;
  right: inherit;
  top: 1px;
  margin-left: -4px;
  left: 100%;
  height: 33px;
  @include flexbox($y: center, $x: center);

  i {
    cursor: pointer;
    display: inline-block;
    padding: 0 5px;
  }
}

.input-outer-set {
  @extend %input-set;
  top: 6px;
  height: 29px;

  .icon-sd-circle-minus-fill-edge-medium {
    font-size: $icons-md !important;
  }

  .picker-in {
    margin-right: 10px;
    margin-left: 5px;

    .picker-icon {
      margin-right: 8px;
    }
  }
}

.input-inner-set {
  @extend %input-set;
  top: -2px;
  right: 14px;
  left: inherit;
  margin-left: 0;
}

.checkbox-listing {
  .validation-message {
    transform: translateY(-8px);
  }

  li {
    display: inline-block;
    margin-right: 25px;
  }

  &.utm-param {
    float: right;

    li {
      width: auto;
      margin-left: 20px;
    }
  }
}

// temp re-structure
// progress-quality-check
.progress-quality-check .list-group-item .step {
  background-color: #fdfdfd !important;
  border: 2px solid $green-200 !important;
}

.progress-quality-check .list-group-item .step {
  padding-top: 6px;
}

ul.listStyle {
  @include flexbox($x: center);

  li {
    width: 120px;
    border-left: 1px solid $border-color;

    &:first-child {
      border: 0;
    }
  }
}

.progressbar-box-status {
  .progress {
    height: 28px;
    border-radius: 0;
  }

  &.reach .progress-bar {
    background-color: #33b5e6;
  }

  &.engagement .progress-bar {
    background-color: $scheduled;
  }

  &.conversion .progress-bar {
    background-color: $inprogress;
  }
}

.insights-list {
  padding-left: 50px;

  li {
    margin-bottom: 10px;
    list-style: disc;
  }
}

// new tab
.nav-tabs.p-tabs-1 {
  border-bottom: 0;
}

.nav-tabs.p-tabs-1>li {
  float: left;
  height: 33px;
  line-height: 33px;
  @include flexbox();
  position: relative;
  margin: 0 10px !important;
  font-size: $font-sm;
}

.nav-tabs.p-tabs-1>li:first-child {
  margin-left: 0;
}

.nav-tabs.p-tabs-1>li:last-child {
  margin-right: 0;
}

.nav-tabs.p-tabs-1 li.active span {
  color: $primary-color;
}

.nav-tabs.p-tabs-1>li.active>span:before {
  border-top: 6px solid $primary-color;
}

.nav-tabs.p-tabs-1>li.active>span:before {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  bottom: -6px;
  content: '';
  display: block;
  left: 50%;
  margin-left: -3px;
  position: absolute;
}

.nav-tabs.p-tabs-1>li.active>span {
  border-bottom: 1px solid $primary-color;
}

.nav-tabs.p-tabs-1>li.active>span:after {
  border-top: 5px solid $white;
}

.nav-tabs.p-tabs-1>li.active>span:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  bottom: -4px;
  content: '';
  display: block;
  left: 50%;
  margin-left: -2px;
  position: absolute;
}

.nav-tabs.p-tabs-1>li.active>span,
.nav-tabs.p-tabs-1>li.active>span:focus,
.nav-tabs.p-tabs-1>li>span,
.nav-tabs.p-tabs-1>li>span:focus {
  border: none;
}

.nav-tabs.p-tabs-1>li:before {
  content: '';
  position: absolute;
  margin-left: -12px;
  top: 16px;
  margin-top: -7px;
  height: 15px;
  border-left: 1px solid #c2cfe3;
}

.nav-tabs.p-tabs-1>li:first-child:before {
  visibility: hidden;
}

.nav-tabs.p-tabs-1>li.active {
  border-bottom: 1px solid $primary-color;
}

.nav-tabs.p-tabs-1>li>span:focus,
.nav-tabs.p-tabs-1>li>span:hover,
.nav-tabs.p-tabs-1>li.active>span:hover {
  border: none;
  text-decoration: none;
  background-color: transparent;
}

.nav-tabs.p-tabs-1>li:hover {
  cursor: pointer;
}

// new tab close
// Dashboard
.sankey-last-col text {
  transform: translateX(-22px);
}

.sankey-filter {
  @include flexbox($y: center);
  position: relative;
  margin-bottom: 30px;

  .k-dropdown {
    margin-bottom: 0;
  }

  &:hover i {
    opacity: 1;
    cursor: pointer;
  }

  i {
    margin-left: 7px;
    top: 4px;
    position: relative;
    opacity: 0;
  }
}

.info-popup {
  position: absolute;
  right: 7px;
  bottom: 1px;
}

.hori-column-chart {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;

  .close-btn {
    position: absolute;
    right: 7px;
    top: 7px;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    font-size: $font-sm;
    text-align: center;
    padding: 7px 0 0 1px;

    &:hover {
      background: $primary-color;
      color: #fff;
    }
  }

  .portlet-xs {
    height: 200px;
    padding: 0 15px;
  }

  .hori-col-head {
    padding: 15px 20px 0;

    p {
      margin: 0;
      font-size: $font-xs;
    }

    h3 {
      margin: 0;
      font-size: $font-md;
    }
  }
}

.portlet-container.show-mini-chart .hori-column-chart {
  opacity: 1;
  visibility: visible;
  background: #fff;
  z-index: 100;
}

// keymetrics
.keymetrics-portlet {
  height: auto;
  padding: 0;
  @include flexbox($x: space-between, $dir: column);

  &.keymetrics-sm {
    height: 185px;
    padding: $theme-space $theme-space 0 $theme-space;

    h4,
    h5 {
      margin: -5px 0 0;
      font-family: $rs-font-medium;
    }
  }

  &.keymetrics-md {
    height: 411px;
    overflow: hidden;
    padding: $theme-space $theme-space 0 $theme-space;

    h4 {
      margin-top: -3px;
    }

    h4,
    p {
      margin-bottom: 0;
    }
  }

  h5 {
    padding-top: 0;
    padding-bottom: 0;
    font-size: $font-sm;
  }

  .keymetrics-list {
    @include flexbox($x: space-around);
    text-align: center;
    margin: 0 -#{$theme-space};

    &.key-border {
      li {
        position: relative;
        @include flexbox($y: center, $dir: column);
      }

      li:before {
        content: '';
        border-left: 1px solid $border-color;
        position: absolute;
        left: 0;
        height: 80%;
        top: 50%;
        transform: translateY(-50%);
      }

      li:first-child:before {
        content: inherit;
      }
    }

    li {
      width: 100%;

      h3 {
        margin-bottom: 0; // 0.5rem
        line-height: 28px;
        font-size: $font-md;
        font-family: $rs-font-medium;
      }

      h4 {
        font-size: $icons-md;
      }

      span {
        margin-top: 4px;
        display: inline-block;
      }

      small {
        position: relative;
        left: 1px;
        line-height: 18px;
        display: inline-block;
        font-size: $font-xs;
      }
    }

    &.keymetrics-theme {
      li {
        color: $white;
        padding: 10px 5px;
        align-items: center;
        display: flex;
        justify-content: center;
        flex-direction: column;

        h3 {
          color: $white;
          font-size: $font-smd;
          font-family: $rs-font-medium;
        }
      }
    }
  }
}

// details
.details-box {
  border: 1px solid $border-color;
  margin-bottom: 20px;
  padding: $theme-space;
  background: $white;
  overflow: hidden;

  @if $new-theme-test ==true {
    @extend %new-theme-shadow;
  }

  .detail-center {
    margin: 10px 0 15px;

    ul {
      @include flexbox();

      li {
        position: relative;
        padding: 0 8px;
        color: $default-color;
        display: flex;
        align-items: center;

        &:after {
          content: '';
          border-right: 1px solid $border-color;
          height: 12px;
          position: absolute;
          left: 0;
          top: 6px;
        }

        &:first-child {
          padding-left: 0;

          &:after {
            content: inherit;
          }
        }

        &:last-child {
          padding-right: 0;
        }

        span {
          font-size: $font-sm;
          margin-left: 8px;
        }
      }
    }
  }

  .detail-end {
    padding: 4px 10px;
    margin: 0 -#{$theme-space} -#{$theme-space};
    color: $white;

    p {
      color: $white;
      font-size: $font-xs;
    }

    span {
      font-size: $font-smd;
    }
  }
}

// sunburst chart
#sunburstChartContainer {
  position: relative;
}

#percentage,
#percentage2 {
  font-size: $font-smd;
  color: $default-color;
}

.explanation-view {
  position: absolute;
  top: 80px;
  left: 190px;
  width: 130px;
  height: 130px;
  text-align: center;
  color: $fontColor1;
  font-size: $font-xxs;
  background-color: $white;
  z-index: 32;
  border-radius: 50%;
  padding: 25px 15px;
}

#trail {
  display: inline-block;

  li {
    float: left;
    background-color: #b5b5b5;
    margin-right: 4px;
    margin-bottom: 5px;
    height: 29px;
    line-height: 29px;
    padding: 0 2px 0 20px;
    font-size: $font-xs;
    white-space: pre;
    letter-spacing: 0;
    color: $white !important;
    cursor: pointer;
    position: relative;
    display: inline-block;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 5px;
      height: 19px;
      width: 20px;
      background: inherit;
      border: inherit;
      border-left-color: transparent;
      border-bottom-color: transparent;
      border-radius: 0 !important;
      margin-top: 0px;
      clip-path: polygon(-5% -5%, 100% 0%, 100% 0%, 110% 115%);
    }

    &:before {
      @include css3-prefix(transform, rotate(45deg));
      background-color: $white;
      left: -10px;
    }

    &:after {
      @include css3-prefix(transform, rotate(45deg));
      right: -10px;
      z-index: 3;
    }

    &:nth-child(2) {
      padding-left: 10px
    }

    &:nth-child(2):before {
      content: inherit;
    }
  }

  #endlabel {
    display: none;
    float: right;
    margin-left: 10px;
  }
}

.sunburst-legend {
  @include flexbox($y: center);
  margin-top: 10px;

  #percentage-list {
    margin: -11px 0 0 16px;
    font-size: $font-xs;
  }
}

// loader
#loading {
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  position: fixed;
  display: block;
  opacity: 1;
  background-color: #ffffff80;
  z-index: 9999;
  text-align: center;
}

.rp-loading {
  &.login-loader {
    position: absolute;
    top: 0;
    height: auto;
    bottom: 0;
  }

  &.login-loader .loading {
    position: absolute;
  }

  .loading {
    display: block;
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #ffffff60;
    left: 0px;
    top: 0px;
    z-index: 999999;

    .loading-tick {
      position: absolute;
      left: 50%;
      margin-left: -11px;
      top: 50%;
      margin-top: -11px;
      width: 23px;
      height: 23px;
      @include image('loading-ticks.png', center center);
    }

    .loading-content,
    .loading-content-circ-path {
      position: absolute;
      left: 50%;
      margin-left: -28px;
      top: 50%;
      margin-top: -28px;
      width: 57px;
      height: 57px;
      animation: spin 1s linear infinite;

      &:before {
        content: '';
        position: absolute;
        width: 43px;
        height: 43px;
        background-color: $white;
        @include css3-prefix(transform, translate(-50%, -50%));
        top: 50%;
        border-radius: 50%;
      }
    }
  }
}

@include keyframes(spin) {
  from {
    @include css3-prefix(transform, rotate(0deg))
  }

  to {
    @include css3-prefix(transform, rotate(360deg))
  }
}

.loading-content-circ-path {
  color: rgba(90, 90, 90, 0.2);
  display: inline-block;
  border: 6px solid;
  border-radius: 50%;
  border-right-color: var(--th-primary-color);
  ;
  animation: rotate 1s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

// loader close
.highcharts-axis-labels.highcharts-radial-axis-labels span {
  transform: translateY(14px) !important;
}

/* bubble chart open */
.bubbleChart-new-1 {
  // width: 429px;
  // height: 325px !important;
  margin: 0 auto;
}

.bubbleChartInsideContainer {
  @include flexbox($dir: column);
  text-align: center;
  position: relative;
  margin-top: -5px;
  &:after {
    content: '';
    position: absolute;
    width: 15px;
    height: 15px;
    background-color: #fff;
    top: 0%;
    right: 0;
    border-radius: 50px;
    opacity: 0.1;
  }
}

.bubbleChartCount {
  font-size: $font-md;
  font-family: $rs-font-medium;
  letter-spacing: 0;
}

.bubbleChartCount small {
  color: $white;
  font-family: $rs-font-medium;
  font-size: $font-xsm;
}

.bubbleChartName {
  font-size: $font-xs;
  margin-top: -8px;
}

.bubbleChart-new-1 .highcharts-label.highcharts-tracker span {
  position: relative;
  top: 0;
  left: 4px;
}

/* bubble chart open */
// varpie-chart
.varpie-chart {
  svg.highcharts-root {
    z-index: 50;
    position: relative;
  }

  .highcharts-tooltip {
    z-index: 50 !important;
  }
}

.highcharts-tooltip span>span {
  font-size: $font-xs !important;
}

.back-text {
  @include flexbox($y: center);
}

.list-of-even {
  li {
    padding: 6px 10px;
    @include flexbox($x: space-between);

    &:nth-child(odd) {
      background-color: $bg-color4;
    }
  }
}

.input-time {
  width: 120px !important;
  max-width: 120px !important;
}

// keyword ranking domain
.key-rank-grid-cell {
  .key-body-cell {
    padding: 10px 10px 5px;
    height: 55px;

    p {
      margin-bottom: 0;
      font-size: $font-xs;
    }

    small {
      font-size: $font-xxs;
    }
  }

  .key-footer-cell {
    background-color: $bg-color4;
    color: $fontColor2;
    @include flexbox($x: space-between);
    padding: 5px 10px;
    font-size: $font-xxs;
  }
}

.steps-box-wrapper {
  @include flexbox($x: space-between);

  .user-interaction {
    @include flexbox($y: center, $x: space-between);
    width: 100%;
    background-color: $bg-color4;
    padding: 16px;

    li {
      @include flexbox($dir: row);
    }

    .k-widget.k-dropdown {
      margin-bottom: 0;
      width: 250px;
    }
  }
}

.steps-icon-box {
  background-color: $primary-color;
  @include flexbox($y: center);
  padding: 20px 10px;
}

.data-flow-chart {
  text-align: center;
  padding: 30px;
}

.legend-list {
  display: inline-block;

  li {
    display: inline-block;
    position: relative;
    padding-left: 12px;
    margin: 0 8px 0 0;
    font-size: $font-xs;
    font-weight: 300;

    &>span {
      display: inline-block;
      letter-spacing: 0.2pt;
      line-height: 16px;
    }

    &.cyan-medium:after {
      background-color: #40abaf;
    }

    &.maroon-medium:after {
      background-color: #fe6262;
    }

    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 5px;
      width: 6px;
      height: 6px;
      background-color: $bg-color7;
    }
  }
}

// progressbar
.rsp-progressbar-pri {
  width: 90%;
  margin: 15px 5% 0;
  display: inline-block;
}

.rsp-progressbar-agerange label {
  float: left;
  margin: 6px 10px 0;
  max-width: 30%;
}

.rsp-progressbar-agerange .rsp-progress-outer {
  width: 75%;
}

.rsp-progressbar-pri .rsp-progressbar-agerange .rsp-progress-outer {
  width: 85%;
}

.rsp-progressbar-agerange .rsp-progress {
  height: auto;
  margin-bottom: 6px;
  display: inline-block;
  width: 100%;
}

.rsp-progressbar-agerange .rsp-progress-bar {
  height: auto;
  position: relative;
  float: left;
  text-align: center;
  padding: 5px;
  min-width: 43px;
  max-width: 82%;
  display: inline-block;
  color: $white;
}

.rsp-progressbar-agerange .rsp-progress-bar>img {
  float: right;
  margin-top: 1px;
}

.rsp-progressbar-agerange .percent {
  margin-left: 10px;
  float: left;
  display: inline-block;
  line-height: 23px;
  padding: 3px 8px;
  font-size: $font-xxs;
  position: relative;
  min-width: 40px;
  background-color: #E4E4E4;
}

.rsp-progressbar-agerange .percent:before {
  border-style: solid;
  border-width: 5px 5px 5px 0;
  content: '';
  display: block;
  left: -5px;
  position: absolute;
  top: 34%;
  width: 0;
}

.rsp-progressbar-agerange .percent:before {
  border-color: rgba(0, 0, 0, 0) #E5E8E8 rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
}

// Advanced Search input open
// Advanced Search input close
// Report
.tabs-sublevel {
  height: 56px;
  padding: 10px 15px 10px 20px;
  margin: $theme-space 0 15px;
  position: relative;
  border-radius: $theme-radius-size;
  z-index: 12;
  background-color: $bg-color6;
  @include flexbox($y: center, $x: space-between);

  &.google-analytics {
    background-color: #f05620;
  }

  &.app-analytics {
    background-color: $primary-color;
  }

  @each $name,
  $socialBg in $socialBgs {
    &.#{$name} {
      background-color: $socialBg;
    }
  }

  .rs-dropdown {
    @include flexbox();

    .dropdown-toggle {
      color: $white;
      height: 56px;

      &:after {
        border-top-color: $white;
      }
    }
  }
}

%dd-img-default {
  font-size: 0 !important;
  display: inline-block;
  height: 40px;

  &:after {
    position: absolute;
  }
}

$socialSplitImg: "socialmedia-list.png";

.#{dd-img-} {
  &#{facebook} button {
    width: 163px;
    min-width: 133px;
    @include image($socialSplitImg, 0 -43px);

    &:after {
      right: -17px;
      position: absolute;
      top: 50%;
    }

    @extend %dd-img-default;
  }

  &#{twitter} button {
    width: 159px;
    display: inline-block;
    min-width: 133px;
    @include image($socialSplitImg, 0 -92px);

    &:after {
      right: -21px;
      top: 42%;
    }

    @extend %dd-img-default;
  }

  &#{linkedin} button {
    min-width: 133px;
    @include image($socialSplitImg, 0 -242px);

    &:after {
      right: -21px;
      top: 42%;
    }

    @extend %dd-img-default;
  }

  &#{pinterest} button {
    width: 148px;
    min-width: 133px;
    @include image($socialSplitImg, 0 -191px);

    &:after {
      right: -21px;
      top: 42%;
    }

    @extend %dd-img-default;
  }

  &#{instagram} button {
    width: 138px;
    min-width: 133px;
    @include image($socialSplitImg, 0 -289px);

    &:after {
      right: -21px;
      top: 42%;
    }

    @extend %dd-img-default;
  }

  &#{line} button {
    width: 75px;
    min-width: 63px;
    @include image($socialSplitImg, 0 -340px);

    &:after {
      right: -21px;
      top: 42%;
    }

    @extend %dd-img-default;
  }
}

.tabs-sublevel {
  .tabs-sub-left {
    @include flexbox();
  }

  .tabs-sub-right {
    @include flexbox($y: center);
  }

  .date-range-view-container {
    .date-title {

      &,
      span,
      i,
      i.blue {
        color: $white !important;
      }
    }

    .date-range-dropdown {
      margin-top: 16px;
      border-top: 0;
      margin-right: -15px;
    }
  }

  .dropdown-menu {
    margin-top: 3px;
    margin-left: -20px;
    border-top: 1px solid $border-color;

    &.show {
      margin-top: 3px;
    }
  }
}

// Split AB
.split-ab-modal {
  text-align: center;

  h3 {
    font-size: $font-xs;
    margin-bottom: 20px;
  }

  .radioContainer {
    margin-bottom: 30px;
  }
}

.circular-chart-splitab {
  margin-bottom: 10px;
  width: 156px;
  display: inline-block;

  .circular-text {
    @include flexbox($dir: column);

    b {
      font-size: $font-xl;
      font-family: $rs-font-regular;

      small {
        font-size: $font-md;
      }
    }

    span {
      font-size: $font-xs;
      margin-top: -10px;
      color: $fontColor2;
    }

    h4 {
      font-size: $font-sm;
      color: $fontColor1;
    }
  }
}

.goal-tab-content ul.mb-3.nav.nav-tabs {
  display: none;
}

// .goal-tab-content {
.rs-accordin {
  margin-bottom: $theme-space;

  .card {
    margin-bottom: 20px;
    border-radius: 0;
    border: 1px solid $border-color;

    @if $theme-radius ==true {
      border-radius: $theme-radius-size;
    }

    .card-header {
      height: 43px;
      cursor: pointer;
      border-bottom: 0;
      @include flexbox($y: center);

      h3 {
        font-size: $font-sm;
      }
    }

    .accordion-collapse {
      .collapse {
        .show {
          border-top: 1px solid $border-color;
        }
      }
    }
  }
}

// }
// radio button
.radioContainer input.rs-radio[type="radio"] {
  cursor: pointer;
  opacity: 0;
  position: absolute;
  margin: -2px 0 0;
}

.radioContainer input.rs-radio~label {
  cursor: pointer;
}

.radioContainer input.rs-radio~label:before {
  border-radius: 50px;
  background-color: $white;
  content: '';
  height: 15px;
  min-width: 15px;
  display: inline-block;
  border: 1px solid $input-border;
  position: relative;
  top: 1px;
  margin-right: 5px;
  @include image('checkbox.png', 10px 10px);
}

.radioContainer input.rs-radio[type="radio"]~.label:before {
  @include image('checkbox.png', 10px 10px, no-repeat);
  content: " ";
}

.radioContainer input.rs-radio[type="radio"]:checked~label:before {
  border: 1px solid #40abaf;
  background-position: -28px 4px;
}

.editor-view-container {
  margin-bottom: 10px;

  @if $new-theme-test ==true {
    border-radius: 0 0 $theme-radius-size $theme-radius-size;
  }

  textarea {
    @if $new-theme-test ==true {
      border-radius: 0 0 $theme-radius-size $theme-radius-size;
    }
  }

  .list-group-item {
    border: 0;

    &:hover {
      cursor: pointer;
    }
  }

  .list-group {
    border: 1px solid $border-color;
    border-bottom: 0;
    border-radius: 0;
    display: grid;
    align-items: center;
    grid-template-columns: repeat(12, 1fr);
    width: 100%;

    @if $new-theme-test ==true {
      border-radius: $theme-radius-size $theme-radius-size 0 0;
    }

    .list-group-item {
      padding: 0;
      @include flexbox($x: center);

      &:last-child {
        grid-column: 12/13;
        position: relative;

        &:before {
          content: '';
          width: 1px;
          height: 24px;
          background-color: $bg-color7;
          left: 0;
          top: 5px;
          position: relative;
        }
      }
    }

    button.k-button {
      padding: 0;

      &:active,
      &:hover {
        background-color: transparent;
      }

      &:focus {
        box-shadow: none;
      }

      i {
        height: 37px;
        padding: 7px 0;
      }
    }
  }
}

.k-dropdown-tooltip-button button.k-button {
  padding: 0;
  background-color: transparent;
}

.editor-ico {
  display: flex;
  align-items: center;
  height: 36px;
  padding: 3px 6px;
}

.modal-image-temp-box {
  margin-top: 20px;

  .modal-image-temp {
    @include flexbox();

    li {
      border: 1px solid #eaeaea;
      margin: 8px;
      padding: 5px;

      &:hover {
        border: 1px solid #2896f0;
      }
    }
  }
}

.upload-large-view {
  color: $fontColor2;
  text-align: center;
  display: inline-block;
  box-sizing: border-box;
  border: 2px dashed #59b5ff;
  padding: 15px 5px;
  width: 100%;
  position: relative;
  background-color: #f9f9f9;

  &:hover {
    background-color: #e0f1ff;
    cursor: pointer;
  }

  span small {
    clear: left;
    display: block;
    line-height: 17px;
  }

  i {
    display: block;
    margin-bottom: 10px;
  }

  input {
    width: 100%;
    cursor: pointer;
    font-size: $font-lg;
    opacity: 0;
    position: absolute;
    right: 0px;
    top: 0px;
  }
}

// preview modal
.preview-dropdown {
  float: right;
  @include flexbox($y: center);
  margin-bottom: 15px;

  &:hover {
    cursor: pointer;
  }

  .p-drop-list {
    color: $primary-color;
    @include flexbox($y: center);
    margin-right: 4px;

    i {
      margin-right: 9px;
      display: inline-block;
      position: relative;
      top: -4px;
    }

    span {
      font-size: $font-sm;
    }
  }
}

.preview-tab-content {
  border: 1px solid $border-color;
  clear: left;
  display: inline-block;
  width: 100%;

  img {
    width: 100%;
  }
}

.preview-dropdown-wrapper {
  position: relative;
}

.preview-dropdown-wrapper {
  .preview-dropdown-list {
    position: absolute;
    right: 0;
    margin-top: 30px;
    background-color: $white;
    border: 1px solid $br-color1;

    li {
      padding: 10px;
      @include flexbox($y: center);

      &:hover {
        cursor: pointer;
        background-color: $primary-color;
        color: $white;
      }

      i {
        margin-right: 10px;
        position: relative;
        top: -3px;
      }
    }
  }
}

.react-tel-input {
  .form-control {
    height: 33px;
    margin-top: 0px !important;
    line-height: 26px;
  }

  .flag-dropdown.open {
    z-index: 16;
  }
}

.custom-column {
  margin: 0;

  &.column-tab {
    .col-md-3 {
      width: 21%;
    }

    .col-md-6 {
      width: 49%;
    }
  }

  >.col-md-3 {
    width: 20%;
  }

  >.col-md-4 {
    width: 25%;
  }

  >.col-md-6 {
    width: 50%;
  }

  >.instant-preview {
    width: 30%;
    padding-right: 0;

    .webpreview {
      margin-top: -38px;
      margin-right: 0;
    }
  }

  .k-switch-md {
    margin-top: 7px;
  }
}

.custom-approval {
  .col-md-3 {
    width: 18.7%;
  }

  .col-md-8 {
    width: 63.9%;
  }
}

.custom-list {
  .col-md-3 {
    width: 18.7%;
  }

  .col-md-8 {
    width: 63.9%;
  }
}

.split-container {
  .custom-column {
    .instant-preview {
      .webpreview {
        margin-right: 10px;
      }
    }
  }
}

// emoji
.emoji-picker-react input.emoji-search {
  display: none;
  border: 0;
  background: $white !important;
  margin: 0 !important;
  width: 100%;
  padding: 0;
}

.emoji-picker-react .content-wrapper .emoji-scroll-wrapper ul.emoji-group:before {
  font-size: $font-xs;
  text-transform: capitalize;
  font-weight: normal;
  font-family: $rs-font-bold;
  line-height: 23px;
  margin-top: 0px;
  padding-bottom: 0;
  color: $fontColor1;
}

aside.emoji-picker-react .content-wrapper:before {
  right: 50px;
  top: -1px;
  line-height: 24px;
  max-height: 24px;
}

aside.emoji-picker-react {
  border: 1px solid $border-color;
  border-radius: 0;
}

.emoji-picker-react ul.skin-tones-list {
  z-index: 2;
  top: 7px;
}

.latitude-to:after {
  content: "to";
  color: $fontColor1;
  position: absolute;
  font-size: $font-xs;
  right: -20px;
  top: 10px;
}

.split-container {
  border: 1px solid $border-color;
  margin-bottom: 30px;

  @if $new-theme-test ==true {
    border-radius: $theme-radius-size;
  }

  overflow: hidden;

  label.label-row {
    margin-left: 20px;
  }

  .tab-content {
    padding-right: 20px;
  }

  .tabs-filled-component {
    @include flexbox($y: center);
  }
}

%picker-border {
  width: 20px;
  height: 4px;
  margin: 0;
  border: 0;
  background-color: #210d0d;
  color: #210d0d;
  display: inline-block;
  position: relative;
  top: 7px;
}

.picker-in {
  margin-top: 2px;

  &.picker-text span {
    @extend %picker-border;
    outline: 1px solid $border-color;

    &:before {
      content: "A";
      width: 20px;
      height: 24px;
      font-size: $font-smd;
      position: relative;
      float: left;
      line-height: 18px;
      text-align: center;
      cursor: pointer;
      margin-top: -18px;
    }
  }

  &.picker-icon {
    margin-right: 8px;

    span {
      @extend %picker-border;
      background-color: $white;
      outline: 1px solid $border-color;

      &:before {
        content: '';
        width: 21px;
        height: 24px;
        font-family: $rs-font-regular;
        font-size: $font-smd;
        position: relative;
        float: left;
        line-height: 18px;
        text-align: center;
        cursor: pointer;
        margin-top: -18px;
        @include image("icon-tool-bgcolor.png", 2px -1px);
      }
    }
  }
}

.refresh-icon {
  position: absolute;
  right: -13px;
  top: 4px;
  cursor: pointer;
}

.pieScatterDBContainer {
  background: transparent;
}

.miniIcons {
  @include image("sprite_mini_icons.png", no-repeat top left);
  width: 17px;
  height: 17px;
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  top: -1px;
}

.miniIcons {
  &.mi-twitter {
    background-position: 0px -20px;
  }

  &.mi-facebook {
    background-position: 0px 0px;
  }

  &.mi-pinterest {
    background-position: 0px -100px;
  }

  &.mi-qrcode {
    background-position: 0px -60px;
  }

  &.mi-email {
    background-position: 0px -80px;
  }
}

.gauge-chart {
  position: relative;
  height: 225px;
  width: 380px;
  margin: 0 auto;
  overflow: hidden;
  @include flexbox($x: center, $dir: column);
  text-align: center;

  .gauge-label {

    // i {
    //   position: relative;
    //   top: 3px;
    // }
    span {
      color: $default-color;
      display: inline;
      @include truncate(230px);
    }
  }
}

.notificationContent {
  border: 1px solid $border-color;
  position: relative;

  @if $new-theme-test ==true {
    border-radius: $theme-radius-size;
    overflow: hidden;
  }

  .notification-header {
    small {
      padding: 3px 7px;
      max-width: 100%;
      display: block;
    }

    p {
      width: 100%;
      max-width: 100%;
      padding: 0 7px;
      display: block;
    }
  }
}

.tab-text-vt {
  @include flexbox($y: center);
  position: relative;
}

.tab-text-vt .remove-btn {
  margin-right: 0;
  position: absolute;
  right: 9px;
}

.tab-text-vt .remove-btn i {
  position: relative;
  top: 2px;
}

// 11855
// 8500
.web-browser-preview {
  border-radius: $theme-radius-size;
  overflow: hidden;
  position: relative;
  background-color: rgb(245, 245, 245);
  box-shadow: rgba(0, 0, 0, 0.14) 0px 3px 13px 1px, rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;

  .s-web-logo {
    @include flexbox($x: space-between);
    margin-bottom: 4px;

    .s-web-title {
      @include flexbox();
    }

    img {
      height: 19.2px;
      width: 19.2px;
    }

    span {
      font-size: $font-xs;
      padding-left: 10px;
    }
  }

  .s-web-body {
    padding-left: 30px;

    .title-header {
      height: auto;
    }

    .s-wm-preview {
      width: 100%;
      max-width: 100%;
      display: flex;
      justify-content: center;
      margin: 10px 0 0;
      overflow: hidden;
      border-radius: $theme-radius-size;
      background-color: #eff3fc;

      img {
        margin: 0;
        max-width: 255px; // 255px
        max-height: 150px;
      }
    }

    h4 {
      font-size: $font-xs;
      // font-family: $emoji-font;
      font-family: $rs-font-bold;
      margin: 0;
      color: #000;
      display: inline-block;
      @include truncate(95%, 95%);
      height: 27px;
      line-height: 27px;
    }

    p {
      font-size: $font-xs;
      // font-family: $emoji-font;
      font-family: $rs-font-regular;
      padding-bottom: 0;
      color: #5c5c5c;
      margin-bottom: 0;
      display: inline-block;
      @include truncate(95%, $line: 2);
    }
  }

  .s-web-action {
    position: absolute;
    right: 10px;
    bottom: 10px;
    display: none;

    .k-dropdown-button {
      .k-button {
        padding: 1px 6px;
        background-color: #e8e8e8;
        box-shadow: 0px 0px 8px 12px $white;
        @include flexbox($dir: row-reverse);

        &:focus {
          box-shadow: 0px 0px 8px 12px $white;
        }

        .k-icon {
          width: 8px;
          font-size: $font-xs;
          position: relative;
          top: 1px;
        }
      }
    }
  }

  .s-web-push {
    padding: 15px;
    background-color: $white;
    width: 100%;
  }
}

.s-wm-preview {
  position: relative;
  background-color: white;
  padding: 10px;
  text-align: center;
  margin: auto;
  border: 1px solid rgb(184, 184, 184);
  float: left;
  display: block;
  width: 273px;
  height: 207px;
  border-radius: 5px;
}

.schedule-even {
  padding: 15px 0 0;
  background-color: $bg-color4;

  .k-datetimepicker .k-picker-wrap {
    background: inherit;
  }

  .k-dateinput .k-dateinput-wrap {
    background-color: $bg-color4;
  }
}

.schedule-even,
.rich-push-tab {
  position: relative;

  @if $new-theme-test ==true {
    border-radius: $theme-radius-size;
    border: 1px solid $beta_tm_border;
    // overflow: hidden;
  }

  &:before {
    content: '';
    left: -15px;
    position: absolute;
    top: 0;
    bottom: 0;
  }
}

.schedule-even:before {
  background-color: $bg-color4;
}

.rich-push-tab:before {
  background-color: $even;
}

.split-container {
  .schedule-even {
    margin: 0;

    .label-row {
      padding-left: 0;
    }
  }

  .split-carousel-content {
    .schedule-even {
      margin-left: 0;
      margin-right: 0;
      background-color: $bg-color4;

      .k-widget.k-datetimepicker .k-picker-wrap,
      .k-widget.k-datetimepicker .k-picker-wrap .k-dateinput .k-dateinput-wrap {
        background-color: transparent !important;
        background: none !important;
      }
    }

    .import-padding {
      padding: 0;
    }
  }

  .large-tab-label {
    padding-left: 8px;
  }
}

.split-container {

  .schedule-even,
  .rich-push-tab {
    @if $new-theme-test ==true {
      border-radius: 0;
      border: 0;
      overflow: inherit;
    }
  }
}

.large-tab-label {
  padding-left: 20px;
}

.rsp-pophover {
  border-radius: 0;
  max-width: 255px;

  &.phover-text {
    z-index: $pophover-tooltip;
  }

  .popover-body {
    padding: 0 6px;
  }

  @each $name,
  $phposition in $phpositions {
    &.bs-popover-#{$name} {
      .popover-arrow:after {
        border-#{$phposition}-color: $ch_tooltipBgColor !important;
      }
    }
  }

  &.phover-img {
    padding: 0;
    background-color: $white;
    border: 1px solid $border-color;

    @each $name,
    $phposition in $phpositions {
      &.bs-popover-#{$name} {
        .popover-arrow:after {
          border-#{$phposition}-color: $white !important;
        }
      }
    }
  }

  &.pophover-sms-con.bs-popover-start .popover-arrow:after,
  &.pophover-sms-con.bs-popover-auto[data-popper-placement^="left"] .popover-arrow:after {
    border-left-color: $white !important;
  }
}

.rsp-pophover-campaign {
  background-color: $white !important;
  border: 1px solid $border-color;

  &.popover {
    z-index: $pophover-tooltip;
  }

  .campaign-preview-wrapper {
    color: #333;
    width: 245px;

    .and-ios {
      margin-top: 0;
    }
  }
}

.model-center-img {
  max-width: 300px;
  margin: 0 auto;
  display: block;
}

.smartlink-table .s-title {
  width: 205px;
}

.s-mobile-push-title {
  @include flexbox($y: center, $x: space-between);
  padding: 5px 10px 0;
  height: 33px;

  img {
    width: 18px;
  }

  small {
    margin-left: 5px;
  }
}

.s-mobile-push-body {
  background-color: $white;
  border-radius: $theme-radius-size;
  padding: 5px 15px 15px;
  display: flex;
  flex-direction: column;
  gap: 5px;

  .s-wm-preview {
    display: flex;
    justify-content: center;
    margin: 10px 0 0;
    overflow: hidden;
    border-radius: $theme-radius-size;
    background-color: #eff3fc;

    img {
      margin: 0;
      max-width: 255px;
      max-height: 196px;
    }
  }

  h4,
  h5,
  p {
    margin: 0;
    font-size: $font-xs;
    max-width: 95%;
  }

  h4,
  h5 {
    font-family: $rs-font-bold;
  }

  h4 {
    @include truncate();
    padding-bottom: 0;
    font-family: $rs-font-bold;
  }

  h5 {
    display: -webkit-box;
    @include truncate();
    font-family: $rs-font-medium;
  }

  p {
    display: inline-block;
    @include truncate($line: 2);
  }

  small {
    margin-left: 5px;
  }
}

.multi-column small {
  clear: left;
  float: left;
  font-size: $font-xxs;
  letter-spacing: 0.5px;
}

.chips-input-container .chips-input-box {
  height: auto;
  padding-left: 0;
  padding-right: 0;

  &:before {
    content: inherit;
  }

  .k-widget.k-multiselect {
    padding-top: 8px; //3px;
    // padding-bottom: 3px;
    // padding-left: 0; // 4px
    // height: 30px;

    .k-multiselect-wrap {
      // max-height: 27px;
      // @extend %scrollbar;

      // right: 4px;
      ul {
        margin-left: 5px;
        margin-right: 3px;
      }

      .k-button {
        margin-top: 0;
        margin-bottom: 2px;
      }
    }

    input {
      padding-left: 10px;
      // &::placeholder {
      //   padding-left: 5px;
      // }
    }
  }
}

.multi-select-custom {
  max-height: 27px;
  line-height: 27px;
  position: relative;
  top: 3px;
  margin-right: -1px;
}

small.subtitle-view {
  position: relative;
  padding-left: 10px;
  display: inline-block;
  @include truncate(230px);

  &:before {
    content: '';
    position: absolute;
    left: 0px;
    top: 6px;
    width: 4px;
    height: 4px;
    background: $bg-color3;
    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
  }
}

// color picker temp
.k-animation-container .popup-content.k-popup {
  .k-flatcolorpicker {
    width: 315px;
  }

  .btn-container {
    margin: 10px 0 0;

    button {
      line-height: 12px;
      border: 0;
      padding: 9px 12px;
      font-size: $font-xsm;
    }
  }

  .k-colorinputs {
    input.k-hex-value {
      width: 80px;
      height: 29px;
    }

    input {
      background: $white !important;
      box-shadow: none !important;
    }
  }

  .k-colorinputs .k-hbox.k-gradient-values>input.k-hex-value {
    margin-right: 13px;
  }

  .k-numerictextbox .k-numeric-wrap input,
  .k-colorinputs .k-hbox.k-gradient-values>div {
    font-size: $font-xs;
  }

  .k-numerictextbox .k-numeric-wrap input {
    padding: 3px 6px 4px;
    height: 27px;
  }

  .k-numerictextbox .k-numeric-wrap {
    height: 29px;
  }

  .k-colorinputs .k-hbox.k-gradient-values {
    &>div {
      width: 12.4em;
      text-align: center;
      color: $fontColor2;
    }

    &>.k-hex-value {
      margin-right: 26px;
    }
  }

  .k-flatcolorpicker .k-hsv-rectangle .k-hsv-gradient .k-draghandle {
    opacity: 0;
  }
}

.k-slider-vertical {
  width: 0px;

  .k-slider-track {
    width: 290px;
  }
}

// color line
.k-slider-vertical {
  .k-slider-selection {
    width: 10px;
    left: 103%;
    right: 0;
  }

  .k-draghandle {
    left: 104%;
    right: 0;
  }
}

// opacity line
.k-slider-vertical.k-alpha-slider {
  .k-slider-selection {
    right: 0px;
    left: 109%;
  }

  .k-draghandle {
    left: 110.3%;
    right: 0;
    transform: translate(-90%, 50%);

    &:active,
    &.k-pressed {
      border-color: #606060;
      background-color: #606060;
      transform: translate(-90%, 50%);
    }

    &:hover {
      border-color: #878889;
      background-color: #878889;
    }
  }
}

.k-slider {
  .k-draghandle {
    border-color: $primary-color;
    color: $white;
    background-color: #878889;
    border-radius: 50%;
    box-shadow: 0 0 02px 0 var(--th-button-color) !important;

    &:hover,
    &:active {
      border-color: $primary-color !important;
      background-color: $primary-color !important;
    }

    &:focus {
      box-shadow: 0 0 02px 0 var(--th-button-color) !important;
    }
  }

  .k-slider-selection {
    background-color: #c3c3c3;
  }
}

// color picker temp
.match-type-input-wrapper {
  @include flexbox();
}

.match-type-input {
  width: 70px;
  display: inline-block;

  .k-input {
    padding-top: 0;
    height: 26px !important;
  }

  .k-dropdown,
  .k-dropdown-wrap {
    height: 26px !important;
    margin-top: 0;
  }
}

.p-close {
  font-size: $font-sm;
  position: relative;
  width: 30px;
  min-width: 30px;
  height: 30px;
  padding: 4px;
  border-radius: 50%;

  &:hover {
    color: $white !important;
    background-color: $primary-color;
  }

  &:before {
    position: absolute;
    top: 2.2px;
    left: 5.2px;
  }
}

// paginate
.paginate {
  @include flexbox($y: center, $x: space-between);
  color: $default-color;

  .paging {
    @include flexbox($y: center);
    font-size: $font-xs;

    li {
      margin: 0 3px;

      input.p-count {
        width: 35px;
        font-size: $font-xs;
        height: 23px;
        text-align: center;
      }

      .page-icon {
        width: 14px;
        height: 1em;
        font-family: "WebComponentsIcons";
        color: #488afe;
        font-size: $font-xsm;
        line-height: 1;
        @include flexbox($type: inline-flex, $x: space-between);
        vertical-align: middle;

        &.disable {
          cursor: default;
          opacity: 0.6;
          pointer-events: none;
        }
      }

      span {
        &.prev:before {
          content: "\e007";
        }

        &.first:before {
          content: "\e00b";
        }

        &.next:before {
          content: "\e005";
        }

        &.last:before {
          content: "\e009";
        }
      }
    }
  }

  span.display {
    font-size: $font-xs;
  }
}

.browser-button {
  .k-button {
    padding: 0;

    &:hover {
      background-color: transparent;
    }

    &:active {
      box-shadow: none;
    }
  }
}

.large-icon {
  height: 61px;
  @include flexbox($y: center, $x: center);
  padding: 0;
  width: 50px;
}

.btn.btn-sm {
  padding: 2px 10px;
  font-size: $font-xxs;
}

.android-btn {
  @include flexbox();

  .btn.btn-sm {
    padding: 2px 5px;
    margin-left: 10px;
    background-color: #eee;

    &:first-child {
      margin-left: 0;
    }

    &:hover {
      background-color: #f4f5f6;
    }
  }
}

.ios-btn {
  @include flexbox($dir: column);
  border-radius: $theme-radius-size;
  width: 150px;
  margin: 10px auto 0;
  overflow: hidden;

  .btn.btn-sm {
    border-bottom: 1px solid $border-color;
    margin-bottom: 0;
    border-radius: 0;
    background-color: #eee;

    &:last-child {
      border-bottom: 0;
    }
  }
}

// Date range pciker
.react-bootstrap-daterangepicker-container:after,
.dropdown-toggle::after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid;
  border-right: .3em solid transparent;
  border-bottom: 0;
  border-left: .3em solid transparent;
}

.react-bootstrap-daterangepicker-container:after,
.dropdown-toggle::after {
  position: relative;
  border-top: 9px solid #888;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  top: 4px;
}

.report-listing {
  tr {
    background-color: $white;

    &.k-detail-row td.k-detail-cell {
      background-color: $white;
      overflow: inherit;
      border: 1px solid $border-color !important;
      width: calc(100% - 2px);

      @if $theme-radius ==true {
        width: calc(100% - 13px);
        border-radius: 0 0 $theme-radius-size $theme-radius-size !important;
        left: 6px;
        position: relative;
        overflow: hidden;
      }
    }
  }

  td {
    .panelbar-wrapper {
      .cellPannelInner {
        &:nth-child(1) {
          width: 440px;
        }

        &:nth-child(3) {
          width: 30%;
        }
      }

      .camp-icon {
        margin-left: 0;
        margin-right: 20px;
      }
    }
  }
}

.dynamic-list-fill.modal .modal-dialog .modal-content {
  .modal-header {
    .btn-close {
      margin-right: 0;
      margin-top: 0; // -14px;
      position: absolute;
      right: 3px;
      top: 3px
    }
  }

  .modal-body {
    padding: 20px;

    .campaign-pop-list {
      li {
        padding: 0 10px;
        border-bottom: 1px solid $border-color;
        padding-bottom: 10px;
        margin-bottom: 9px;

        &:last-child {
          border-bottom: 0;
          padding-bottom: 0;
        }

        small {
          display: block;
        }
      }
    }

    .campaign-pop-list2 {
      border-bottom: 1px solid $border-color;
      padding-bottom: 10px;
      margin-bottom: 15px;

      li {
        @include flexbox($x: space-between);
        padding: 4px 10px;
      }
    }
  }
}

// form generator
.form-generator {
  .refresh-icon {
    right: -5px;
    top: -3px;
  }

  .form-template-radio {
    label {
      @include flexbox($y: center);

      .radioContainer {
        margin-top: 0;
      }

      img {
        padding: 10px;
        border: 1px solid $border-color;
      }
    }

    li.active img {
      border-color: $primary-color;
    }
  }
}

.form-safe-area-container {
  @include flexbox($x: center);

  .form-safe-area {
    width: 960px;
    border: 1px solid $br-color1;
    margin: 20px 0;
    padding: 25px;
  }
}

.form-generator-card {
  border: 1px solid $border-color;
  text-align: center;
  @include center();
  min-height: 84px;
  position: relative;
  margin-bottom: 20px;

  figure {
    margin-bottom: 0;

    img {
      height: 64px;
    }
  }

  .sysicon {
    position: absolute;
    right: -13px;
    bottom: -16px;
  }
}

.card-in-check {
  position: absolute;
  right: -15px;
  bottom: 9px;

  .rs-checkbox {
    width: 209px;
    left: -200px;
    height: 84px;
    top: -76px;
  }
}

.drag-and-drop-container {
  background-color: $bg-color4;
  position: relative;

  .drag-and-drop-lists {
    @include flexbox();
    padding: 15px 0;
    width: 920px;
    margin: 0 auto;

    li {
      width: 12.5%;
      text-align: center;
      cursor: move;

      div {
        @include flexbox($x: center, $dir: column);

        img {
          width: 54px;
          display: inline-block;
          margin: 0 auto;
        }

        label {
          font-size: $font-xs;
          margin-top: 5px;
        }
      }
    }
  }
}

.flex-direction-nav {
  @include flexbox($y: center, $x: space-between);
  position: absolute;
  width: 84%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  font-size: 0;
  left: 50%;

  .flex-prev,
  .flex-next {
    width: 8px;
    height: 12px;
    padding: 10px;

    &:hover {
      cursor: pointer;
    }

    &.flex-disabled {
      opacity: .2 !important;
      filter: alpha(opacity=20);
      cursor: default;
    }
  }

  .flex-prev {
    @include image("icons/comp-prev.png", center);
  }

  .flex-next {
    @include image("icons/comp-next.png", center);
  }
}

.webpreview:hover .brand-logo-icon {
  visibility: hidden;
}

.and-ios .brand-logo-icon,
.webpreview .brand-logo-icon {
  position: absolute;
  height: 33px;
  bottom: 10px;
  right: 10px;
  z-index: 1;
  visibility: visible;
  background-color: $white;
  box-shadow: 0px 0px 8px 8px $white;
}

.and-ios {
  margin-top: -32px;

  .brand-logo-icon {
    height: 30px;
    bottom: inherit;
    top: 29px;
  }

  &:hover .brand-logo-icon {
    bottom: inherit;
    top: 29px;
  }

  .and-ios-body {
    .mobile-push-popup {
      .notificationContent {
        .s-wm-preview {
          img {
            max-width: 100%;
          }
        }
      }
    }
  }
}

.and-ios:hover .s-web-action,
.webpreview:hover .s-web-action {
  visibility: visible;
}

.and-ios .s-web-action,
.webpreview .s-web-action {
  visibility: hidden;
}

.player-wrapper {
  width: 255px !important;
  height: 150px !important;
}

.plus-icon-lg {
  .k-dropdown-wrap .k-input {
    font-size: 30px;
    color: #888888;
  }
}

.symbol-dropdown {
  position: relative;
  top: 8px;
  background-color: #f0f0f0;
  padding: 2px 5px 0;
  height: 26px;
  margin-right: 20px;

  .dropdown-toggle:after {
    content: inherit
  }

  .dropdown-menu {
    .dropdown-item:hover i {
      color: $white;
    }
  }

  i {
    font-size: $icons-xs !important;
    // color: $fontColor1;
  }

  .icon-dropdown-small {
    position: relative;
    top: 2px;
    color: #888;
    font-size: $icons-xs !important;
  }
}

.arrow-hide {
  .dropdown-toggle::after {
    content: inherit;
  }
}

.time-progressbar {
  @include flexbox($y: center);

  .symbol-dropdown {
    top: 4px;
    margin-left: 15px;

    i {
      font-size: $icons-xs !important;
      color: $fontColor2;
    }

    .dropdown-menu i {
      font-size: $icons-xs !important;
    }
  }
}

.progress {
  border-radius: 0;
  box-shadow: none;
  position: relative;
  background-color: $bg-color6;

  &.progress-mini {
    height: 5px;
    width: 50px;
    margin-left: 10px;

    .progress-bar {
      height: 6px;
    }
  }

  .progress-bar {
    width: 100%;
    height: 0;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #428bca;
    -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .15);
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .15);
    -webkit-transition: width .6s ease;
    transition: width .6s ease;
  }
}

// online status
.network-status {
  width: 180px;
  background-color: $onlineColor;
  @include flexbox($y: center, $x: space-between);
  padding: 7px 7px 7px 13px;
  position: fixed;
  right: 10px;
  bottom: 17px;
  border-radius: 4px;
  z-index: $network-status-tooltip;

  &:hover {
    cursor: pointer;

    .icon-close-small {
      display: block;
    }
  }

  .icon-close-small {
    position: absolute;
    right: 7px;
    display: none;
  }

  &.status-online {
    background-color: $onlineColor;

    i {
      color: $onlineColor;
    }
  }

  &.status-offline {
    background-color: $offlineColor;

    i {
      color: $offlineColor;
    }
  }

  p {
    margin: 0;
    color: $white;
    font-size: $font-xs;
    text-shadow: 0px 1px 6px #4a4a4a;
    font-family: $rs-font-medium;
  }

  i {
    background-color: $white;
    width: 23px;
    height: 23px;
    border-radius: 30px;
    padding: 5px;
    font-size: $icons-xs;

    &:before {
      position: relative;
      top: -2px;
    }
  }
}

.network-status-wrapper {
  @include flexbox($y: center, $x: space-between);
  padding: 0;
  position: fixed;
  right: -95px;
  bottom: 85px;
  z-index: $network-status-tooltip - 1;
  border-radius: 31px 0 0 31px;
  background-color: $primary-color;
  box-shadow: 0px 4px 9px -2px #00000080;
  @extend %animate;

  &.net-status-open {
    right: 0;
    @extend %animate;
  }

  .n-status-edit {
    display: flex;
    position: relative;
    align-items: center;

    img.n-status-img {
      width: 38px;
      padding: 4px;
      display: block;
      float: left;
      margin-left: 8px;
    }

    ul {
      display: flex;
      background: whitesmoke;
      height: 46px;
      align-items: center;

      li {
        margin: 0 9px;

        img.nc-status-img {
          width: 27px;
        }

        img.nc-globe-img {
          width: 32px;
        }
      }
    }
  }
}

.overlay-screen {
  background-color: rgba(0, 0, 0, .6);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
}

.campaign-id {
  background-color: $beta_tm_border;
  padding: 1px 6px;
  margin: 0px 5px 0px 0px;
  box-sizing: border-box;
  color: #1c1c1c;
  border-radius: $theme-radius-size;
  font-size: $font-xxs;
  position: relative;
  top: -1px;
  display: inline-block;
  line-height: 16px;
  white-space: pre;
}

.expand-plus {
  position: absolute;
  right: 0;
  bottom: 0;

  .camp-icon-pannel {
    display: none;

    li {
      margin: 0;
    }
  }

  .k-icon.k-i-plus {
    &:after {
      border-bottom: 30px solid $draft;
      border-left: 30px solid transparent;
      position: absolute;
      right: 0px;
      bottom: -2px;
      width: 0;
      height: 0;
      content: "";
      z-index: 0;
    }

    &:before {
      content: inherit;
    }
  }
}

@each $name,
$status-label in $status-labels {
  .status-#{$name} {
    .expand-plus {
      border: 0;
      border-radius: $theme-radius-size;
      width: 34px;
      height: 34px;
      overflow: hidden;

      &:before {
        border-bottom: 28px solid $status-label;
        border-left: 28px solid transparent;
        content: '';
        position: absolute;
        z-index: 0;
        right: 0;
        bottom: 0;
      }
    }

    .expand-plus .k-icon.k-i-plus::after {
      border-bottom-color: $status-label !important;
    }
  }
}

.k-popup.k-child-animation-container .k-widget.k-calendar.k-calendar-range {
  position: relative;
}

// grid
.grid-truncate table {

  thead tr th,
  tbody tr td {
    white-space: pre-wrap;
  }
}

.promo-code-text {
  padding-top: 5px;
  padding-right: 15px;
}

.goldCampaign {
  top: -4px;
  position: relative;
  right: 5px;
  left: 3px;
}

.chart-clock-img {
  position: absolute;
  width: 200px;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 20;
}

.data-even-row {
  padding: 0 20px;
}

// search
.search-default {
  background-color: #9a9a9d;
  float: right;
  padding: 2px;
  border-radius: 20px;
  margin: 15px 0;
  width: auto;
  display: flex;
  position: relative;

  &.open {
    .s-input {
      width: 150px;
      background: $white;
      opacity: 1;
      padding: 0;
      border-radius: 20px;
      margin-right: 3px;
    }
  }

  .s-icon {
    width: 18px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white;
    border-radius: 15px;
    float: left;
    margin: 1px 2px 0 2px;
  }

  .s-input {
    float: left;
    width: 0;
    background: transparent;
    opacity: 0;
    padding: 0;

    input {
      padding-top: 0;
      height: 22px;
      margin: 0;
      background: $white !important;
      display: block;
      border-radius: 20px;

      &:hover,
      &:focus {
        background: none !important;
      }
    }
  }
}

.chart-inner-icon {
  height: 85px;
  position: absolute;
  z-index: 50;
  width: 70px;
  transform: translateX(-50%) translateY(-50%);
  top: 50%;
  left: 50%;
  z-index: 0;

  &.audience-profile {
    @include image('icon-unknown-user.svg', center -42px);
    background-size: 50px;
  }
}

.k-animation-container .k-popup.ic-dropdown {
  overflow: inherit;

  ul.k-list {
    overflow: hidden;

    li {
      direction: ltr !important;

      &:hover {
        cursor: pointer;
      }
      .checkbox-wrapper .lbl {
        white-space: pre;
      }
    }
  }
}

.table-lheight {
  margin-bottom: 0;

  tr td {
    height: 49px;
  }
}

.browse-hidden {
  appearance: none;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
  height: 90px;
  opacity: 0;
  cursor: pointer;
}

.upload-lable-text {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
}

// slider dashboard
.slider-dashboard {
  height: 225px;

  &.carousel.slide {
    .carousel-indicators {
      margin-bottom: -20px;

      [data-bs-target] {
        background-color: $border-color;
        opacity: 1;
        width: 10px;
        height: 10px;
        margin-right: 5px;
        margin-left: 5px;
        border: 0;

        &.active {
          background-color: $primary-color;
        }
      }
    }
  }

  .carousel-control-prev,
  .carousel-control-next {
    width: 43px;
    height: 43px;
    background-color: #fff;
    box-shadow: 0px 4px 9px -2px #0000001f;
    border-radius: 35px;
    border: 1px solid #c8c8c8;
    top: 50%;
    transform: translateY(-50%);

    span {
      background-size: 20px;
    }
  }
}

.copied-text {
  margin-right: 10px;

  label {
    color: #11a811;
    display: flex;
    align-items: center;
    border-radius: 4px;
  }
}

.copy-text {
  display: flex;
  align-items: center;
}

// FILE UPLOAD
.upload-input-view {
  width: 100%;
  margin: 10px auto;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  .file {
    position: relative;
    display: inline-block;
    cursor: pointer;
    height: 2.5rem;

    input {
      min-width: 14rem;
      margin: 0;
      filter: alpha(opacity=0);
      opacity: 0;
    }

    .file-custom {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      z-index: 5;
      height: 2.5rem;
      padding: .5rem 1rem;
      line-height: 1.5;
      color: #555;
      background-color: $white;
      border: .075rem solid #ddd;
      border-radius: .25rem;
      text-align: left;
      box-shadow: inset 0 .2rem .4rem rgba(0, 0, 0, .05);
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      &:after {
        content: "Choose file...";
      }

      &:before {
        position: absolute;
        top: -.075rem;
        right: -.075rem;
        bottom: -.075rem;
        z-index: 6;
        display: block;
        content: "Browse";
        height: 2.5rem;
        padding: .5rem 1rem;
        line-height: 1.5;
        color: #555;
        background-color: #eee;
        border: .075rem solid #ddd;
        border-radius: 0 .25rem .25rem 0;
      }
    }

    /* Focus */
    input:focus~.file-custom {
      box-shadow: 0 0 0 .075rem $white, 0 0 0 .2rem #0074d9;
    }
  }
}

.smart-link-popup-ui .smartlink-popup-lg {
  padding: 3px 10px 30px 0;
  @extend %scrollbar;
}

.even-space-4 .rs-chart-tab.addtab li.tabDefault {
  width: 248px !important;
}

.text-short {
  @include text-shorten();
}

.textarea-max-1 {
  max-width: 476px;
}

.overlap-sankey-download {
  position: absolute;
  top: 15px;
  right: 53px;
  background-color: $white;
}

.no-data-container {
  position: relative;
  pointer-events: none;
}

@include keyframes(zoomApp) {
  0% {
    width: 0;
    opacity: 0;
    margin: 0;
  }
  50% {
    width: 32px;
    opacity: 1;
    margin: 0;
  }
  100% {
    width: 28px;
    margin: 0 3px;
  }
}
.nodata-bar {
  z-index: 2;
  display: flex;
  background-color: #fdfdfd;
  border: 1px solid $border-color;
  border-right-width: 1.5px;
  border-left-width: 1.5px;
  color: #585858;
  height: 33px;
  line-height: 22px;
  padding: 1px 10px;
  @extend %center-div;

  @if $theme-shadow ==true {
    @extend %new-theme-shadow;
  }
  .nodata-icon {
    margin: 0 3px;
    // width: 0;
    // opacity: 0;
    // margin: 0;
    // @include animationField(
    //   $animation: zoomApp,
    //   $duration: 300ms,
    //   $delay: 200ms,
    //   $fillmode: both,
    //   $timefunc: ease-in-out
    // );
  }
  &.add-func {
    pointer-events: all;

    &:hover {
      background-color: $primary-color;
      cursor: pointer;

      p {
        color: $white;
      }

      i:before {
        color: $white;
      }
    }

    p {
      @extend %animate;
    }

    i:before {
      @extend %animate;
    }
  }

  i {
    padding-right: 5px;

    &:before {
      // top: 2px;
      position: relative;
    }

    &.icon-sd-circle-plus-fill-edge-medium {
      &:before {
        top: 3px;
      }
    }
  }

  p {
    display: flex;
    align-items: center;
    margin: 0;
    line-height: 28px;
    white-space: pre;
    font-size: $font-xsm;
    position: relative;
    top: 1px;
  }

  .no-data-holder>p i {
    display: inline-block;
    vertical-align: sub;
    padding: 0 3px;
    position: relative;
    top: 1px;
  }
}

// WEB / MOBILE SDK
.key-int {
  margin-right: 55px;
}

.list-dotted {
  list-style: disc;
}

.list-abc {
  list-style: lower-alpha;
}

.list-abc,
.list-dotted {
  line-height: 30px;
  font-size: $font-xs;
  margin-bottom: 20px;
}

.highlight {
  background-color: #fbfbfb;
  border: 1px solid #e3e3e3;
  padding: 20px;
  margin-top: 10px;
  margin-bottom: 30px;
}

.table-ui thead {
  border-style: hidden;
}

.table-ui thead tr th {
  background-color: #06c;
  color: $white;
  padding-top: 12px;
  border: 0;
  padding-bottom: 12px;
  border-spacing: inherit;
}

.table-ui {
  border: 1px solid $border-color;
}

.table-ui tbody tr:nth-child(even) {
  background-color: #f0f8ff;
}

.add-title {
  line-height: 30px;
}

// ERROR PAGE
.err-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background-color: $header-color;
  padding: 0 10px 0 24px;
  z-index: 33;

  .err-logo {
    img {
      max-height: 60px;
      max-width: 220px;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.error-boundry-wrapper {
  background-color: $noDataBg;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .error-boundry {
    display: flex;
    justify-content: center;
    align-items: center;

    .err-left {
      text-align: center;
    }

    .err-right {
      padding-left: 40px;

      h1,
      h5,
      p {
        color: $white;
      }

      h1 {
        font-size: 55px;
        font-family: "MuktaBold", sans-serif;
        margin-bottom: 5px;
        small {
          font-size: $font-md;
        }
      }
      h5 {
        font-size: $font-md;
        margin-bottom: 30px;
      }
      p {
        font-size: $font-sm;
      }
      .err-seperator {
        width: 2px;
        height: 106%;
        position: absolute;
        background-color: #e9e9eb;
        left: 0;
        top: -5%;
      }
    }
  }
}

.err-footer {
  height: 125px;
  z-index: 32;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  .err-cloud {
    height: 120px;
    width: 100%;
    @include image('cloud.png', left top, repeat-x);
    ul.copyright {
      position: relative;
      top: 70px;
    }
    .powered-by {
      top: 90px;
    }
  }

  ul.footer-borders-container {
    position: fixed;
    bottom: 0;
  }
}

// BROWSER PERVIEW
.preview-lg-browser-view {
  @include image('preview-dummy.jpg');
  height: 581px;
  width: 100%;
  margin: 0;
  position: relative;

  .preview-card {
    width: 370px;
    position: absolute;
    right: 15px;
    bottom: 15px;
    box-shadow: 2px 2px 9px -1px #333;
    background-color: $white;
    padding: 10px;

    .preview-header {
      display: flex;
      align-items: start;

      .preview-img {
        img {
          max-width: 45px;
          height: 46px;
          margin: 0 10px 0 0;
        }
      }

      .preview-text {
        h1 {
          font-size: $font-xsm;
          font-family: $rs-font-bold;
          color: #333;
          margin-bottom: 5px;
        }

        p {
          font-size: $font-xxs;
          margin: 0;
        }
      }

      .preview-android-tag {
        display: flex;
        align-items: center;

        img {
          width: 20px;
        }

        .preview-android-name {
          margin-left: 5px;

          small {
            font-size: $font-xxs;
            margin-left: 5px;

            &:before {
              content: '. ';
            }
          }
        }
      }
    }

    .preview-body img {
      margin-top: 10px;
      width: 100%;
    }

    &.preview-card-android {
      padding: 0;

      .preview-header {
        align-items: end;
        justify-content: space-around;
        padding: 10px;

        .preview-text {
          h1 {
            font-size: $font-xs;
            margin-bottom: 0;
          }

          p {
            display: -webkit-box;
            @include truncate();
          }
        }

        .preview-img {
          order: 2;

          img {
            margin: 0;
          }
        }
      }

      .preview-body {
        img {
          margin-top: 0;
        }
      }

      .android-btn-con {
        background-color: #e9e9e9;
        display: flex;

        .android-btn {
          padding: 10px;
          font-family: $rs-font-medium;
          font-size: $font-xs;

          &:hover {
            background-color: #cacaca;
            cursor: pointer;
          }
        }
      }
    }
  }
}

img.campaign-pop-eye-img {
  // max-width: 200px;
  width: 200px;
  // max-height: 260px;
}

.pophover-sms-con.popover[class*="bs-popover-"] {
  background-color: $white;
  border: 1px solid $border-color;

  .multi-column.popover-body {
    padding: 0;
    width: 200px;
  }
}

img.gallery-bg-img {
  width: -webkit-fill-available;
  height: fit-content;
}

.mobile-screen-bg {
  background-image: url(../../../Assets/Images/preview-mobile-1.png);
  background-repeat: no-repeat;
  background-size: cover;
  width: 255px;
  margin: 0 auto;

  .campaign-sms-wrapper {
    top: 84px;
    margin: 0 auto;
    width: 88%;
    height: 230px;
    min-height: 230px !important;
  }
}

.campaign-sms-wrapper {
  color: #333;
  width: 100%;
  min-height: 244px;
  height: 100%;
  border: 1px solid $border-color;
  background-color: $white;
  position: relative;


  .campaign-sms-header {
    background-color: $grey-L100;
    display: flex;
    justify-content: space-between;
    font-size: $font-xxs;
    padding: 3px 8px;

    .camp-sms-left,
    .camp-sms-right {
      color: #cfcfcf;
    }

    .camp-sms-center {
      max-width: 100px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .arrow-left {
      margin-right: 3px;
    }
  }

  .campaign-sms-body {
    .chat-bubble-left {
      width: 80%;
      margin: 10px auto;
      position: relative;
    }

    .chat-bubble {
      position: relative;
      padding: 10px;
      background: $green-100;
      border-radius: $theme-radius-size;
      font-size: $font-xxs;
      text-align: left;
      line-height: 14px;
      color: #585858;
      @extend %scrollbar;
      max-height: 206px;

      &:after {
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
        left: -12px;
        right: auto;
        top: 0px;
        bottom: auto;
        border: 22px solid;
        border-color: $green-100 transparent transparent transparent;
      }

      >span {
        position: relative;
        z-index: 3;
      }

      .chat-content {
        @extend %scrollbar;
        height: 180px;
        position: relative;
        z-index: 1;

        &::-webkit-scrollbar-track {
          background-color: #b3d942;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #00000020;
        }
      }
    }
  }

  small.message-date {
    font-size: 9px !important;
    color: $fontColor1;
    position: absolute;
    bottom: -18px;
    left: 10px;
  }
}

.k-grid td table td {
  border: 0px;
  padding: 0px;
}

.campaign-sms-footer {
  display: flex;
  clear: left;
  align-items: center;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  font-size: $font-xxs;
  justify-content: space-between;
  padding: 6px;
  border-top: 1px solid #e6e6e6;
  background-color: #fafaf7;
  color: #cfcfcf;

  img.camera {
    height: -webkit-fill-available;
  }

  .in-img {
    width: 12px;
    height: 12px;
    opacity: 0.1;
  }

  .in-msg {
    width: 68%;
    height: 24px;
    border: 1px solid #e6e6e6;
    padding: 3px 5px;
  }
}

.link-status-height {
  max-height: 310px;
}

.detail-title {
  white-space: pre;
  @include truncate(50%);
}

.segment-slide-popup {
  min-height: 410px;

  .carousel-indicators {
    bottom: -20px;
    margin: 0;
  }
}

.template-form-container {
  .css-scrollbar.img-wrap {
    height: 330px;
  }
}

.gallery-scrollbar {

  @if $new-theme-test ==true {
    // background-color: $even;
    border: 1px solid $border-color;
    overflow: hidden;
    border-radius: $theme-radius-size;
  }

  .img-wrap {
    height: 242px;
    width: 241px;
    // background-color: #ecf1f1;
  }
}

.gallery-slide-popup {
  height: 280px;
  width: 100%;

  .carousel-indicators {
    bottom: -20px;
    margin: 0;
  }

  .carousel-control-prev,
  .carousel-control-next {
    width: 32px;
    height: 32px;
    top: 43%;
    transform: translateY(-50%);
    background-color: #fff;
    border-radius: 25px;
    box-shadow: 0px 4px 9px -2px #0000001f;
  }

  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    filter: invert(1) grayscale(100);
    background-size: 20px;
  }

  .carousel-control-next {
    right: -2px;
  }

  .carousel-control-prev {
    left: -2px;
  }

  .img-wrap {
    width: 240px;

    img.gallery-bg-img {
      border-radius: 5px;
      overflow: hidden;
    }
  }
}

.chips-list {
  li {
    padding: 0px 8px;
    border: 1px solid $border-color;
    background-color: $bg-color4;
    float: left;
    font-size: $font-xsm;
    margin: 0 10px 10px 0;
    border-radius: 5px;
  }
}

.max-height-aud-channel {
  max-height: 330px;
}

.max-height-sms-pop {
  max-height: 200px;
  overflow-wrap: break-word;
}

.gallery-grid-view {
  .k-listview-content {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    overflow: inherit;
    grid-gap: 20px;

    @if $theme-shadow ==true {
      padding-bottom: 9px;
    }
  }

  .card-gallery-box {
    width: 273px;

    @include screen('xxl') {
      width: 267px;
    }
  }
}

.k-listview {
  background-color: transparent;
}

.grid-vertical-noscroll {
  .k-grid-content.k-virtual-content {
    overflow-y: inherit;
  }
}

.lengthDynamicGridName {
  max-width: 420px;
}

.icon-shadow {
  position: relative;
  z-index: 1;
  @extend %animate;
  border-radius: 50%;
  height: 32.8px;
  width: 32.8px;

  &:hover {
    box-shadow: 0 0px 15px $primary-color;

    @if $new-theme-test ==true {
      box-shadow: none;
      color: $primary-hvr-color !important;
    }
  }
}

// Split AB
.split-container {
  position: relative;

  .tab-content {
    padding-right: 0;
  }

  .carousel-split {
    border-bottom: 1px solid #efefef;
    padding-bottom: 30px;
  }

  .instant-preview {
    width: calc(30% - 23px);
  }
}

.split-ab-progress-wrapper {
  position: absolute;
  top: 120px; // 127px
  background-color: $white;
  border: 1px solid $border-color;
  padding: 10px;
  margin: 0 30px 30px 10px; // 0 30px 30px
  width: calc(100% - 20px); // calc(100% - 60px)
  z-index: 2;

  ul {
    display: flex;
    align-items: center;

    input {
      margin-bottom: 0;
      width: 100px !important;
    }
  }
}

.split-ab-wrapper-container {
  display: flex;

  .split-ab-wrapper {
    background-color: $primary-color;
    color: $white;
    display: flex;

    .split-ab-list {
      width: 100%;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;

      .listname {
        width: 20px;
        height: 20px;
        background-color: $white;
        color: #6AC8EA;
        border-radius: 50%;
        display: inline-block;
      }

      h5 {
        color: $white;
        font-size: $font-xsm;
        margin: 0;
      }

      &.split-b {
        background-color: #fe6262;
      }
    }
  }

  .split-ab-wrapper2 {
    color: $white;
    padding-left: 20px;
  }
}

.split-ab-temp-container {
  z-index: 1;
}

.split-overlay-open {
  position: relative;

  &:before {
    background-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    top: -20px;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    content: '';
  }
}

.split-box {
  &.space-top {
    margin-top: 10px;
  }
}

.plus-icon-wraper-tooltip {
  width: 40px;
  height: 30px;
  display: inline-block;
  text-align: right;
}

.web-mobile-transaction-icon {
  max-width: 50px;
  padding-top: 7px;
}

.split-line-col {
  .split-line {
    position: relative;

    &:after {
      content: '';
      width: 0;
      border-right: 1px solid $border-color;
      height: 60px;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%)
    }

    &:last-child {
      &:after {
        content: inherit;
      }
    }
  }
}

.preview-mode-img {
  background-color: #ffffff;
  border: 1px solid #e9e9e9;
  padding: 0;
  border-radius: 50%;
  overflow: hidden;
  text-align: center;
  width: 135px;
  height: 135px;
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  img {
    max-height: 135px;
    border-radius: 100px;
    max-width: 135px;
    padding: 0;
    width: auto;
    height: auto;
  }
}

.status-bar-head {
  background-color: #e7ca60;
  color: #fff;
  border: 0;
  position: relative;
  padding: 0px;
  margin-bottom: 20px;
  // display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  overflow: hidden;

  .status-icon-Bg {
    background-color: #dfb82b;
    padding: 10px;
    float: left;
    margin-right: 10px;
  }

  p {
    color: #fff;
    margin: 0;
    font-size: $font-xs;
    padding-top: 3px;
  }
}

aside.emoji-picker-react .content-wrapper:before {
  display: none !important;
}

aside [arial-label="☺️"],
[aria-posinset="34"] {
  display: none !important;
}

.head-ico-wrap {
  height: 24px;
}

.modal-tooltip {
  z-index: $modal-tooltip;
}

.profile-image-wrapper {
  .validation-message {
    max-width: 155px;
    min-width: inherit;
    left: 3px;
    top: 100%;
    transform: translateY(10px);
    height: fit-content;

    &:after {
      filter: inherit;
    }

    &:before,
    &:after {
      transform: rotate(180deg);
      top: -7px;
      bottom: inherit;
      content: inherit;
    }
  }
}

.per-ico-ht {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;

  .k-dropdown-button {
    margin: 0;

    button.k-button {
      margin-right: 0;
    }
  }
}

.modal-prev-valid-search {
  top: -30px;
  left: 59px;
}

.emoji-close {
  display: flex;
  justify-content: center;
  cursor: pointer;
  width: 23px;
  height: 23px;
  text-align: center;
  align-items: center;
  margin: 5px 5px 10px;
}

.k-animation-container {
  .popup-content {
    &.emoji-popup-wrap {
      border: 1px solid $border-color !important;
      background-color: $white !important;
    }

    &.emoji-popup-wrap-modal {
      em-emoji-picker {
        height: 315px;
      }
    }
  }
}

.truncate-line-1 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  max-width: 100%;
  width: 100%;
}

.notificationDetails {
  .description {
    width: 400px;
    word-break: normal;
    white-space: normal;
  }

}

.expand-icon-wrap {
  background-color: $primary-color;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  padding: 15px 4px 10px 5px;

  @if $new-theme-test ==true {
    border-top-left-radius: $theme-radius-size;
    border-bottom-left-radius: $theme-radius-size;
  }

  &:hover {
    cursor: pointer;
  }
}

/*** Gopi ***/

.mdc-precampaign {
  .rs-chart-tab.tabs-filled-component.one-level li.tabDefault {
    border-radius: 4px;
  }

  .rs-chart-tab.mini.tabs-filled-component li.tabDefault {
    width: auto;
  }
}


.input-verify-icon {
  position: absolute;
  right: 17px;
  bottom: 36px;
  color: #008000;
  border-radius: 4px;
  cursor: pointer;
  background: #0080003d;
  padding: 1px 5px;
  display: flex;
  align-items: center;

  i {
    margin-right: 7px;
  }
}

.created-user-id {
  display: flex;

  .created-name {
    display: inline-block;
    @include truncate(77px);
  }
}

.yaxis-7 {
  transform: translateY(-7px);
}

// THEME UI
.theme-setting-lists {
  li {

    &:hover,
    label {
      cursor: pointer;
    }
  }

  >li {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    padding: 10px;

    &:nth-child(even) {
      margin-right: 0;
    }

    &:hover,
    &.active {
      background-color: #f7f7f7;
    }

    label {
      width: 130px;
    }
  }

  .theme-list {
    ul {
      display: flex;

      li {
        height: 28px;
        padding: 5px;
        width: 28px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

.sub-dashed-border {
  display: inline-block;
  outline: 1px dashed $border-color;
  padding: 0 5px;

  &:hover {
    outline: 2px dashed $primary-color !important;
  }
}

body[data-class="keycontact"] {
  .k-animation-container {
    z-index: 1055 !important;
  }
}

body[data-class="default"] {
  .k-animation-container {
    z-index: 1056 !important;
  }
}

.license-close {
  position: absolute;
  right: 8px;
  top: 10px;
  background-color: #dfb72a;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;

  i {
    position: relative;
    top: 0px;
    right: -1px;
  }
}

// WHATSAPP PREVIEW
.whatsapp-portlet {
  min-height: 430px;
  @include image('wapreview-mobile-1.png', top center);

  &.modal-preview {
    .w-inner-view {
      width: 235px;
      left: 1px;
      position: relative;
      margin-top: 30px;

      .w-body {
        height: calc(100% - 68px);

        ul.w-lists {
          margin-bottom: 20px;
        }
      }
    }
  }

  &.popup-preview {
    width: 210px;
  }

  .w-inner-view {
    height: 320px;

    .w-head {
      height: 48px;
      background-color: #005e54;
      display: flex;
      padding: 9px;

      img.w-profile {
        min-width: 30px;
        min-height: 30px;
        max-width: 30px;
        max-height: 30px;
        border-radius: 50%;
        overflow: hidden;
        background-color: #ffffff;
      }

      .w-user-details {
        color: #fff;
        flex-direction: column;
        justify-content: flex-start;
        text-align: left;
        margin-left: 8px;
        float: left;

        .w-user {
          font-size: $font-xs;
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: 130px;
          overflow: hidden;
          display: inline-block;
        }

        .w-status {
          font-size: $font-xxs;
          display: block;
          position: relative;
          top: -9px;
        }
      }
    }

    .w-body {
      height: calc(100% - 118px);
      box-shadow: inset 0 10px 10px -10px #000000;
      overflow-x: hidden;
      padding: 0 16px;
      margin-bottom: 5px;
      @include image('whatsapp-bg.jpg', center center);
    }
  }

  .fadeout {
    position: absolute;
    bottom: 20px;
    height: 1em;
    width: 100%;
    background: -webkit-linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    background-image: -moz-linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    background-image: -o-linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    background-image: linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    background-image: -ms-linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  }
}

.whatsapp-portlet {
  .w-inner-view {
    .w-body {
      ul.w-lists {
        display: inline-block;
        position: relative;
        z-index: 1;
        width: 100%;

        li {
          &.date {
            text-align: center;
            margin-top: 5px;

            small {
              left: 0;
              background-color: #f4f4f4;
              padding: 2px 5px;
              border-radius: 5px;
              color: #453e3e;
              box-shadow: 0px 2px 3px 0px #00000050;
            }
          }

          &.message {
            color: #000;
            clear: both;
            line-height: 15px;
            font-size: $font-xxs;
            padding: 8px;
            position: relative;
            margin: 8px 0;
            min-width: 95px;
            max-width: 85%;
            word-wrap: break-word;
            z-index: -1;

            &:after {
              position: absolute;
              content: "";
              width: 0;
              height: 0;
              border-style: solid;
            }

            &.sent {
              background-color: #e1ffc7;
              border-radius: 5px 0 5px 5px;
              float: right;

              &:after {
                border-width: 0 0 10px 10px;
                border-color: transparent transparent transparent #e1ffc7;
                top: 0;
                right: -9px;
              }
            }

            &.received {
              background: #fff;
              border-radius: 0 5px 5px 5px;
              float: left;

              &:after {
                border-width: 0 10px 10px 0;
                border-color: transparent #fff transparent transparent;
                top: 0;
                left: -9px;
              }
            }

            span.metadata {
              display: block;
              text-align: right;
              margin-top: 3px;

              span.time {
                font-size: $font-xxs;
                color: #00000080;
              }
            }

            img {
              max-width: 150px;
              display: block;
              margin: 0 auto 5px;
              width: 100px;
            }
          }
        }
      }
    }

    .current-time {
      font-size: $font-xxs;
      background-color: white;
      position: absolute;
      top: -17px;
      left: 9px;
    }
  }
}

.image-preview-port {
  margin-top: 24px;

  img.preview-img {
    max-width: 250px;
    max-height: 161px;
  }
}

.multi-column.popover-body {
  .whatsapp-portlet {
    height: 290px;
    min-height: max-content;
    background: none;

    .w-inner-view {
      margin-top: 0;
      height: 290px;

      .w-body {
        height: calc(100% - 50px);

        ul.w-lists {
          li.date {
            small {
              position: relative;
              left: 50%;
              transform: translateX(-50%);
            }
          }
        }
      }
    }
  }
}

.overlay-card {
  padding: 10px;
  border: 1px solid $border-color;
  border-radius: 0;
  margin-top: 10px;
  max-height: 270px;
  overflow: hidden;
  border-radius: 5px;

  .overlay-card-inner {
    max-height: 250px;
    overflow: hidden;

    img {
      width: 100%;
    }
  }
}

.per-listing-box {
  min-height: 100px;
  margin-bottom: 30px;
  padding: 20px 0;
  background-color: #fdfdfd;
  border: 1px solid #e9e9eb;
  border-radius: 5px;

  &:hover {
    border: 1px solid var(--th-header-color);
    background-color: var(--th-header-color);
    color: $white;
  }

  i {
    font-size: 34px;
    margin-bottom: 10px;
  }
}

.card-gallery-box.template-card-status {
  border-top-color: #4a051b;
}

.import-iframe {
  max-width: 600px;
  margin: 0px auto;

  iframe {
    margin-top: 15px;
    border: 1px solid #b8b8b8;
  }
}

.rs-qr-live-preview {
  // border: 1px solid #b8b8b8;
  padding: 15px;
  background: #f1f1f1;
  border-radius: 5px;
}

.short-url-text {
  white-space: pre;
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
  display: inline-block;
}

.rsp-k-icondropdown .k-button {
  padding: 0;
  margin: 0;

  .rsp-primary-button {
    display: flex;
    align-items: center;
  }
}

.dns-recored-grid thead.k-grid-header tr th.k-header:nth-child(1),
.dns-recored-grid tbody tr.k-master-row td:nth-child(1) {
  max-width: 320px;
}

.dns-recored-grid thead.k-grid-header tr th.k-header:nth-child(2),
.dns-recored-grid tbody tr.k-master-row td:nth-child(2) {
  max-width: 340px;
}

.dns-recored-grid table tbody tr td {
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.k-animation-container>.k-popup .k-list {
  @extend %scrollbar;
}

.last-column-view {
  width: 30% !important;
  padding-right: 0;
}

.template-card-status {
  .dropmenu-align button.k-button {
    padding-right: 9px !important;
  }
}

// Dashboard pie chart css start
.pie-chart-radius {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  color: #fff;
  padding-left: 6px;
  display: inline-block;
  padding-top: 5px;
}

.pie-email {
  background-color: $email;
}

.pie-mobile {
  // background-color: $iOSColor;
  background-color: $sms;
}

.pie-qr {
  background-color: $qr-code;
  color: #000 !important;
}

.pie-whatsapp {
  background-color: $whatsapp;
}

.pie-pinterest {
  background-color: $pinterest;
}

.pie-facebook {
  background-color: $facebook;
}

.pie-twitter {
  background-color: $twitter;
}

// Dashboard pie chart css end

//Dropdown Menu start
li.rs-dropdown-wrapper {
  &:nth-child(4) {
    .show.dropdown {
      .dropdown-toggle {
        &.show {
          span.hicon {
            @include image('header-icon/d-icon-4.svg');
          }
        }

        &.active,
        &.active:hover {
          background-color: var(--th-secondary-color);
          border: 1px solid var(--th-secondary-color);

          span.hicon {
            @include image('header-icon/d-icon-4-w.svg');
          }
        }
      }
    }
  }

  .dropdown {
    button.dropdown-toggle {
      width: 43px;
      height: 43px;
      padding: 0;

      &:after {
        display: none;
      }
    }

    .btn:hover {
      background-color: white;
      border: 1px solid $primary-color;
    }

    .active.btn:hover {
      background-color: $active-color;
      border: 1px solid $active-color;

    }

    .btn.show {
      background-color: white;
      border-color: var(--th-primary-color);
      box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.329411764);
    }
  }

  .dropdown-menu {
    border-color: $input-border; //$quaternary-blue;
    padding: 5px !important;
    border-radius: 5px;
    overflow: unset !important;
    margin-left: -3px;

    &::before {
      position: absolute;
      content: "";
      width: 9px;
      height: 9px;
      background-color: $white;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      border: 1px solid $border-color; //$quaternary-blue;
      border-right: 1px solid $border-color;
      border-bottom: 1px solid $border-color;
      border-right: none;
      border-bottom: none;
      left: 20px;
      top: -6px;
    }

    a {
      display: flex;
      justify-content: left;
      align-items: center;
      width: 100% !important;
      -webkit-border-radius: 0;
      -ms-border-radius: 0;
      border-radius: 0;
      color: $primary-grey;
      padding: 0 10px;
      height: 45px;
      border: 0;
      box-shadow: none !important;

      &:hover,
      &:focus {
        background-color: $primary-color;
        color: $white;
      }

      &:hover {
        .icon-hover {
          color: $white;
        }
      }
    }
  }
}

.tooltip-menu {
  width: 100%;
  background-size: 21px;
}

.icon-hover {
  color: $primary-color;

  &:hover {
    color: $white;
  }
}


//Dropdown Menu end

// heat map //
.spanLink .overlayHeatMap {
  position: relative;
  display: block;
}

.spanLink .spanlinkLine {
  content: '';
  position: absolute;
  left: 0;
  height: 70px;
  background-color: #0bc30b;
  width: 1px;
  bottom: 0;
  right: 100%;
}

.spanLink span.spanlinkCount {
  transform: translateY(-20px);
}

.spanLink {
  font-family: verdana, geneva, sans-serif;
  letter-spacing: 0;
}

// email footer

.rsv-tabs-content {
  @include sizing($w: calc(100% - 141px));
  @extend .clearfix;
  float: left;
}

.rs-builder-properties-wrapper {
  background-color: $quaternary-grey;

  .offcanvas-header {
    background-color: $secondary-blue;

    .offcanvas-title {
      color: $white;
      height: 15px;
      padding-top: 4px;
    }

    .btn-close {
      display: none;
      padding: 0;
    }
  }

  .offcanvas-body {
    padding: 0;

    .rsbpw-icons-wrapper {
      background-color: $white;
      padding: 15px;

      img {
        cursor: pointer;
      }

      .click-off {
        //opacity: 0.1;
      }
    }

    .builder-social-properties-block {

      background-color: $white;
      border-bottom: 1px solid $tertiary-grey;
      padding: 0;
      margin: 15px;

      .bspb-top {
        position: relative;
        background-color: $tertiary-blue;
        border-bottom: 1px solid $quaternary-blue;
        margin-bottom: 10px;
        padding: 5px 10px 5px 10px;

        .bspbt-logo {
          span {
            margin-left: 5px;
          }

          img {
            height: 24px;
          }
        }

        .bspbt-delete {
          position: absolute;
          top: 5px;
          right: 10px;
        }
      }

      .bspb-content {
        padding: 0 10px 15px 10px;

        .rs-input-wrapper {
          margin-top: 20px;
        }
      }
    }
  }
}

.rs-form-builder-drop-box {
  //border: 1px dashed $quaternary-grey;
  line-height: 50px;
  background: $quaternary-blue;
  margin: 0 -1px 10px -1px;
}

.rs-builder-elements-holder {
  border-top: 1px solid $quaternary-blue;
  padding: 15px 10px;
  text-align: center;
  margin-bottom: 20px;
  position: relative;

  ul {
    display: flex;
    justify-content: center;
    align-items: flex-start;

    li {
      margin-right: 15px;
      cursor: move;

      i {
        display: block;
        cursor: move;
        padding: 10px;
        border-radius: 5px;
        color: $white;
        background-color: "blue";
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .rsbeh-form-builder {
    align-items: flex-start;

    li {
      width: 135px;
      min-height: 87px;
      padding: 0;
      margin: 0;

      img {
        width: 54px;
      }

      h6 {
        margin: 10px 0 0 0;
      }
    }
  }
}

.rs-form-builder-carousel-wrapper {
  &.carousel {
    position: static;
    min-height: 100px;
    display: flex;
    align-items: center;
  }

  &.gaugeslider-wrapper {
    .carousel-control-prev {
      left: -22px;

      .carousel-control-prev-icon {
        left: 10px;
        opacity: 1;
      }
    }

    .carousel-control-next {
      right: -22px;

      .carousel-control-next-icon {
        right: 10px;
        opacity: 1;
      }
    }

    .carousel-indicators {
      display: none;
    }

    .carousel-inner {
      .carousel-item {
        height: initial;
      }
    }
  }
}

.rs-builder-elements-dropped-wrapper {
  width: 70%;
  margin: auto;
  margin-top: 30px;

  &.rsbedw-form-builder {
    width: 80%;
  }

  &.rsbedw-email-footer-builder {
    width: 600px;

    .rsbecw-row {
      margin: 0;
    }
  }
}

.rs-builder-elements-content-wrapper {
  border: 1px solid $quaternary-blue;
  padding: 20px;

  .rsbecw-row {
    margin-bottom: 15px;
    position: relative;

    .rs-pop-view {
      padding: 12px;
      outline: 1px dashed $secondary-grey;

      &:hover {
        outline: 2px solid var(--th-primary-color);

        .editor-text {
          // outline: 2px solid var(--th-primary-color);
        }

        .pop {
          display: block;
        }

        .rsfbmt-column-remove,
        .rsfbmt-row-remove {
          display: block;
        }
      }
      .hovweIcons.pop {
        z-index: 1299;
        cursor: pointer;
        &:first-child {
          cursor: move;
        }
      }
    }
  }

  .fbc-social-item {
    display: flex;
    align-items: center;

    .fbcsi-content {
      display: flex;
      align-items: center;
      background-color: $primary-grey;
      color: $white;
      border-radius: 5px;
      padding: 2px 8px;
      font-size: $font-xxs;

      i {
        margin-right: 5px;
      }
    }

    .fbcsi-checkbox {
      .checkbox-wrapper {
        label {
          margin-right: 5px;
        }
      }
    }
  }
}

.pop {
  display: none;
}

.data-exchange-wrapper {

  .list-column,
  .data-exchange-border,
  .new-popup-drag {
    border: 1px solid $quaternary-blue;
  }

  .new-popup-drag {
    padding: 15px 15px 0 15px;
  }

  .data-exchange-border {
    border-top: 1px solid $primary-color;
  }

  .dataexchange-heading {
    padding: 10px;
    background-color: $bg-color1;

    h4 {
      padding-left: 0;
      margin: 0;
    }
  }

  .k-listbox .k-list-scroller {
    @extend %scrollbar;
    min-height: 151px;
    max-height: 151px;
  }

  .list-container .list-column .k-listbox-toolbar .k-reset .k-icon.k-i-arrow-60-left {
    background-position: 0 1px;
  }
}

.new-subscribe {
  .page-header.box-bottom-space.container {
    width: 1260px !important;
    max-width: 1260px !important;
  }

  .custom-column {
    span.checkmark {
      top: 3px;
    }
  }
  span.k-switch-container {
    span.k-switch-label-on,
    span.k-switch-label-off {
      font-size: 12px;
      max-width: calc(100% - 35px);
      top: 10px;
    }
  }

  span.k-widget.k-switch.k-switch-off,
  span.k-widget.k-switch.k-switch-on {
    margin-top: 5px;
  }

  .mi {
    .k-dropdown .k-dropdown-wrap .k-input {
      font-size: 15px !important;
    }
  }

  .list-segment-accordin .rs-accordin .card .card-body {
    padding-left: 0;
    padding-right: 0;

    li {
      margin-left: 0;
      margin-right: 8px;
    }
  }

  .search-container.search-advanced.open {
    .search-icon-box {
      width: 285px !important;
    }
  }

  .search-container.search-advanced.search-no-dropdown.open .search-icon-box .search-input {
    width: 251px !important;

    input {
      width: 251px !important;
    }
  }
}

.ic-dropdown.subscribe-list {
  max-height: 210px;
  &::before {
    left: 12px;
    right: unset;
  }
  &::after {
    left: 10px;
    right: unset;
  }
}

// Demo ui update css start
input.color-none {
  background-color: transparent !important;
  background-size: $input-line !important;
  -webkit-background-size: $input-line-web !important;
  padding-right: 0;

  &:hover {
    background-color: transparent !important;
    background-size: $input-line !important;
    -webkit-background-size: $input-line-web !important;
  }
}

input.left-border-none {
  background-size: 0 15px, 1px 1px, 1px 15px !important;
  -webkit-background-size: 0 15px, 1px 1px, 1px 15px !important;
  padding-left: 0;
}

.checkboxTop0 {
  .checkmark {
    top: 5px;
    right: 0;
    left: inherit;
  }
}

.w-max-conent {
  width: max-content;
}

.otp-close {
  .btn-close {
    margin-right: 0 !important;
  }
}

// Demo ui update css end
.rupay-modal.modal {
  z-index: 10562 !important;
  .modal-dialog .modal-content {
    padding: 0;
    border: 0 !important;
    border-radius: 0 !important;
    .modal-body {
      padding: 0;
    }
  }
}

.modal.rupay-modal .modal-dialog {
  margin: 0 !important;
}

// svg
.ExpandAllMini {
  transform: scale(1.8);
  position: relative;
  right: 6px;
  margin-top: -2px;
  margin-bottom: 4px;
}


// TEMPLATE UI
.email-client-container .rs-builder-elements-content-wrapper {
  border: 0;
  .rsbecw-row .rs-pop-view {
    outline: none;
  }
}


.email-client-container .rs-builder-elements-content-wrapper {
  .mce-item-table:not([border]),
  .mce-item-table:not([border]) caption,
  .mce-item-table:not([border]) td,
  .mce-item-table:not([border]) th,
  .mce-item-table[border="0"],
  .mce-item-table[border="0"] caption,
  .mce-item-table[border="0"] td,
  .mce-item-table[border="0"] th,
  table[style*="border-width: 0px"],
  table[style*="border-width: 0px"] caption,
  table[style*="border-width: 0px"] td,
  table[style*="border-width: 0px"] th {
    border: 0 !important;
  }
}

// TINY
.mce-content-body div.mce-resizehandle {
  background-color: var(--th-header-color)!important;
  border-color:var(--th-header-color)!important;
}
.mce-content-body audio[data-mce-selected], .mce-content-body details[data-mce-selected], .mce-content-body embed[data-mce-selected], .mce-content-body img[data-mce-selected], .mce-content-body object[data-mce-selected], .mce-content-body table[data-mce-selected], .mce-content-body video[data-mce-selected] {
  outline: 3px solid var(--th-header-color)!important;
}
.tox .tox-tbtn:hover {
  background-color: var(--th-even-color)!important;
}
.tox .tox-tbtn--enabled {
  &, &:hover {
    background: var(--th-header-color)!important;
    color: #fff!important;
  }
  &, &:hover {
    .tox-icon svg path {
      fill: #fff;
    }
  }
}

.features-container .freeze-panel.pricing-catelogue {
  top: 71px !important;
}
.elementor-widget-container a {
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}