@charset 'utf-8';

@import '../variables/global';

.doc-tab-list {
    padding: 0;
    border: 1px solid #e6e6e6;
    background-color: #ffffff;
    .tabDefault {
        color: #666;
        padding: 7px 10px;
        border-bottom: 1px solid #e6e6e6;
        &.active {
            color: $white;
            background-color: $primary-color;
            &:hover {
                background-color: $primary-color;
            }
        }
        &:hover {
            background-color: $even;
            cursor: pointer;
        }
        &:last-child {
            border-bottom: 0;
        }
    }
}
.doc-code-set {
    margin-top: 20px;
    // .doc-code-editor {
        // border: 1px solid #d9d9d9;
    // }
}
.doc-code-editor {
    font-size: $font-xxs;
    background-color: #f5f5f5;
}
.doc-code-preview {
    padding: 15px;
    // background-color: #fff;
    border-radius: 0 0 5px 5px;
    border: 3px solid #f5f5f5;
    border-top: 0;
    display: inline-block;
    width: 100%;
}
.doc-props-string {
    span {
        color: #fff;
        display: inline-block;
        margin-left: 1rem;
        font-size: $font-xs;
        padding: 2px 8px;
        line-height: 1;
        border-radius: 2px;
        background-color: rgb(83, 146, 228);
    }
    p {
        margin-bottom: 0;
        color: #fff;
    }
}
.doc-props-para {
    code {
        font-size: $font-xsm;
    }
}
.doc-props-box {
    margin: 0 0 55px;
}

.split-box {
    position: relative;
    transition: border 0.2s linear 0s, box-shadow 0.2s linear 0s
}

.space-top {
    margin-top: 10px !important;
}

.dragSlider .dragCont:before,
.dragSlider .dragCont2:before,
.dragSlider .dragCont:after,
.dragSlider .dragCont2:after {
    content: "";
    position: absolute;
    right: 600px;
    top: -12px;
}
.dragSlider .dragCont:after,
.dragSlider .dragCont:before {
    border-bottom: 12px solid #b8b8b8;
    border-left: 11px solid rgba(0, 0, 0, 0);
    border-right: 11px solid rgba(0, 0, 0, 0);
}
.dragSlider .dragCont:after {
    border-bottom: 12px solid #ffffff;
    right: 600px;
    top: -11px;
}
.inSide {
    position: absolute;
    height: 100%;
    left: 0;
    right: auto;
    overflow: hidden;
    width: 30%
}

.split-left {
    width: 50%;
    float: left;
    padding: 5px 10px;
    text-align: center
}

// type range temp

/* === range theme and appearance === */
input[type="range"] {
    font-size: 1.5rem;
    width: 100%;
}

input[type="range"] {
    color: #53ac84;
    --thumb-height: 1.125em;
    --track-height: 0em;
    --track-color: rgba(0, 0, 0, 0.2);
    --brightness-hover: 180%;
    --brightness-down: 80%;
    --clip-edges: 0.125em;
}

input[type="range"].win10-thumb {
    color: #2b2d42;

    --thumb-height: 1.375em;
    --thumb-width: 0.5em;
    --clip-edges: 0.0125em;
}

/* === range commons === */
input[type="range"] {
    position: relative;
    background: #fff0;
    overflow: hidden;
}

input[type="range"]:active {
    cursor: w-resize;
}

input[type="range"]:disabled {
    filter: grayscale(1);
    opacity: 0.3;
    cursor: not-allowed;
}

/* === WebKit specific styles === */
input[type="range"],
input[type="range"]::-webkit-slider-runnable-track,
input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    transition: all ease 100ms;
    height: var(--thumb-height);
}

input[type="range"]::-webkit-slider-runnable-track,
input[type="range"]::-webkit-slider-thumb {
    position: relative;
}

input[type="range"]::-webkit-slider-thumb {
    --thumb-radius: calc((var(--thumb-height) * 0.5) - 1px);
    --clip-top: calc((var(--thumb-height) - var(--track-height)) * 0.5 - 0.5px);
    --clip-bottom: calc(var(--thumb-height) - var(--clip-top));
    --clip-further: calc(100% + 1px);
    --box-fill: calc(-100vmax - var(--thumb-width, var(--thumb-height))) 0 0 100vmax currentColor;

    width: var(--thumb-width, var(--thumb-height));
    background: linear-gradient(currentColor 0 0) scroll no-repeat left center / 50% calc(var(--track-height) + 1px);
    background-color: currentColor;
    box-shadow: var(--box-fill);
    border-radius: var(--thumb-width, var(--thumb-height));

    filter: brightness(100%);
    clip-path: polygon(100% -1px,
            var(--clip-edges) -1px,
            0 var(--clip-top),
            -100vmax var(--clip-top),
            -100vmax var(--clip-bottom),
            0 var(--clip-bottom),
            var(--clip-edges) 100%,
            var(--clip-further) var(--clip-further));
}

input[type="range"]:hover::-webkit-slider-thumb {
    cursor: w-resize;
}

input[type="range"]:active::-webkit-slider-thumb {
    cursor: w-resize;
}

input[type="range"]::-webkit-slider-runnable-track {
    background: linear-gradient(var(--track-color) 0 0) scroll no-repeat center / 100% calc(var(--track-height) + 1px);
}

input[type="range"]:disabled::-webkit-slider-thumb {
    cursor: not-allowed;
}

/* === Firefox specific styles === */
input[type="range"],
input[type="range"]::-moz-range-track,
input[type="range"]::-moz-range-thumb {
    appearance: none;
    transition: all ease 100ms;
    height: var(--thumb-height);
}

input[type="range"]::-moz-range-track,
input[type="range"]::-moz-range-thumb,
input[type="range"]::-moz-range-progress {
    background: #fff0;
}

input[type="range"]::-moz-range-thumb {
    background: currentColor;
    border: 0;
    width: var(--thumb-width, var(--thumb-height));
    border-radius: var(--thumb-width, var(--thumb-height));
    cursor: w-resize;
}

input[type="range"]:active::-moz-range-thumb {
    cursor: w-resize;
}

input[type="range"]::-moz-range-track {
    width: 100%;
    background: var(--track-color);
}

input[type="range"]::-moz-range-progress {
    appearance: none;
    background: currentColor;
    transition-delay: 30ms;
}

input[type="range"]::-moz-range-track,
input[type="range"]::-moz-range-progress {
    height: calc(var(--track-height) + 1px);
    border-radius: var(--track-height);
}

input[type="range"]:disabled::-moz-range-thumb {
    cursor: not-allowed;
}

//Martin s
.progress-bar-password {
    width: 35px;
    height: 33px;
    display: inline-block;
    position: absolute;
    right: 13px;
    bottom: 31px;
    overflow: hidden;
    z-index: 1;
    transition: all 0.3s ease;
    i {
        position:absolute;
        bottom: 8px;
        right: 7px;
        font-size: 21px;
    }
}
.progress-bar-password.status-bad {
    background-color: #ea2228;
    height: 0;
}
.progress-bar-password.status-low {
    background-color: #fa6428;
    height: 8px;
}
.progress-bar-password.status-week {
    background-color: #ffad00;
    height: 16px;
}
.progress-bar-password.status-average {
    background-color: #9cc158;
    height: 24px;
}
.progress-bar-password.status-good {
    background-color: #00b400;
    height: 33px;
}
