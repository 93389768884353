.skeleton-span-con {
  span {
    border-radius: 0;
  }
  .skeleton-span {
      float: left;
      display: flex;
      flex: 1;
      flex-direction: column;
      width: 100%;
      &.circle-left {
        span {
          margin-left: 0;
        }
      }
      span {
        float: left;
        border-radius: 0;
        display: block;
        height: 100%;
        flex: 1;
        margin: 0 auto;
        @if $theme-radius == true {
          border-radius: $theme-radius-size-sm;
        }
        &[class*="css-"] {
          width: 100%;
        }
        &:last-child {
           span {
            height: 100%;
          }
        }
      }
      &.fill {
        span {
          width: 100%;
        }
      }
      &.center {
        span {
          text-align: center;
        }
        .react-loading-skeleton {
          display: inline-block;
          @if $theme-radius == true {
            border-radius: $theme-radius-size-sm;
          }
        }
      }
  }
  hr {
      height: 5px;
      width: 100%;
      float: left;
      border: none;
      margin: 0 !important;
      background: none;
  }
}
.flex-row    { flex-direction: row; }
.flex-column { flex-direction: column; }
.pie-legend-skeleton-wr {
  width: 250px;
}
.skeleton-span-con .semipie-legend-skeleton-wr span {
  width: fit-content;
}
.pie-legend-skeleton {
  position: relative;
}
.mt130 {
  margin-top: 130px !important;
}
// bubble
.bubble-chart-skeleton {
  position: relative;
  height: 320px;
  > span {
    position: absolute;
    top: 60px;
  }
}
.bubble-chart-skeleton > span {
  &:first-child {
    left: 60px;
    top: 40px;
  }
  &:nth-child(2) {
    left: 100px;
    top: 150px;
  }
  &:nth-child(3) {
    left: 180px;
    top: 60px;
  }
  &:nth-child(4) {
    left: 240px;
    top: 140px;
  }
  &:nth-child(5) {
    left: 320px;
    top: 53px;
  }
}
.react-loading-skeleton {
  background-color: #e2e7ee !important;
  &:after {
    // background-image: linear-gradient( 90deg, #e0e5eb, #eeeeee, #e0e5eb);
    background-image: linear-gradient( 90deg, #e2e7ee, #eef2f9, #e2e7ee) !important;
  }
}
.skeleton-bottom-align {
  display: flex;
  justify-content: space-between;
  width: 100%;
  transform: rotate(180deg);
}