// telinput
// bootstrap form
// kendo input
// checkbox
// Radio button
// phone input
// password fill
// input file

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-style: normal;
  color: $placeholder-color;
  opacity: 1; /* Firefox */
  font-style: normal;
  font-family: $rs-font-light;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: $placeholder-color;
}
::-ms-input-placeholder { /* Microsoft Edge */
  color: $placeholder-color;
}

// bootstrap form
.form-control {
  font-size: $font-xsm;
  color: $input-color;
  &:focus {
      color: #4c4c4c;
  }
  &:disabled,
  &[readonly] {
    background-color: $grey-L100 !important;
    &.required, &:required {
      &:hover {
        @include inputBorder($input-border, $input-border, $maroon-200);
        background-color: $grey-L100 !important;
      }
    }
    &:hover {
      @include inputBorder($input-border, $input-border, $input-border);
      background-color: $grey-L100 !important;
    }
  }
}

// telinput
.react-tel-input {
  &.click-off {
    opacity: 1;
    cursor: not-allowed;
    pointer-events: inherit;
    input[type=tel] {
      background-color: $bg-color1 !important;
      pointer-events: none;
    }
  }
  .form-control {
    font-size: $font-xsm !important;
    width: 100% !important;
    margin-bottom: 30px !important;
    padding-left: 63px;
  }
  .selected-flag {
    padding: 0 0 0 14px;
    width: 55px;
    .arrow {
      left: 21px;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid $border-color;
      &.up {
        border-bottom: 5px solid $border-color;
      }
    }
  }
  .flag-dropdown {
    width: 55px;
    &.open .selected-flag {
      background: transparent;
    }
  }
  .country-list {
    margin-top: 1px;
    margin-left: 0;
    border-top: 5px solid #fff;
    border-bottom: 5px solid #fff;
    @extend %scrollbar;
    max-height: 180px !important;
    box-shadow: none;
    border: 1px solid $border-color;
    .country {
      &:hover {
        background-color: $primary-color;
        color: $white;
        .dial-code {
          color: $white;
        }
      }
      .dial-code {
        color: $input-color;
      }
      &.highlight {
        margin-bottom: 0;
        margin-top: 0;
        background-color: $even;
        color: $input-color;
        &:hover {
          background-color: $even;
          color: $input-color;
          .dial-code {
            color: $input-color;
          }
        }
      }
    }
  }
}

%required {
  @include inputBorder($input-border, $input-border, $maroon-200);
  &:hover {
    @include inputBorder($input-border, $input-border, $maroon-200);
  }
  &:focus {
      @include inputBorder($primary-color, $primary-color, $maroon-200);
  }
}
.k-dropdown {
  .k-input {
    padding-top: 2px;
  }
  &.required .k-input {
    @extend %required;
  }
}
.k-dropdown, .k-dropdowntree {
  background-image: none;
}
.k-widget.k-dateinput .k-input {
  height: 24px;
  margin-top: 5px;
}
.k-datepicker.required .k-picker-wrap {
  @extend %required;
}
input.click-off {
  background-color: $bg-color1 !important;
}
.select-timezone-wrapper .css-1s2u09g-control,
.select-timezone-wrapper .css-1pahdxg-control,
.chips-input-container .chips-input-box,
.rsptimer-picker .k-datetimepicker,
.rsptimer-picker .k-timepicker,
.k-datepicker .k-picker-wrap,
input,
select,
.react-tel-input input,
.k-input {
    border: none;
    letter-spacing: 0.3pt;
    border-radius: 0;
    color: $input-color;
    font-size: $font-xsm;
    padding: 0 10px 0;
    width: 100%;
    height: 33px;
    font-family: $rs-font-regular;
    @include inputBorder($input-border, $input-border, $input-border);
    &:focus {
        outline: none;
        color: #4c4c4c;
        box-shadow: none !important;
        @include inputBorder($primary-color, $primary-color, $input-border);
        &:required,
        &.required {
          @extend %required;
        }
    }
    &:required,
    &.required {
      @extend %required;
    }
    &.log {
      @include inputBorder($input-border, $input-border, $maroon-200);
      &:focus {
          @include inputBorder($primary-color, $primary-color, $maroon-200);
      }
    }
}
.k-textbox {
  &:focus {
    @include inputBorder($primary-color, $primary-color, $primary-color);
  }
}

.sub-label {
  font-size: 80%;
}
.chips-input-container .chips-input-box {
  position: relative;
  @include inputBorder($input-border, $input-border, $input-border);
  &:hover { @include inputBorder($input-border, $input-border, $input-border); }
  &:focus { @include inputBorder($primary-color, $primary-color, $primary-color); }
  &:before {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    box-sizing: border-box;

    border-top: 10px solid transparent;
    border-left: 4px solid transparent;
    border-bottom: 6px solid $input-border;
    border-right: 6px solid $input-border;
  }
  .multi-select-custom {
    width: 100%;
  }
}

.react-tel-input input[type=text],
.react-tel-input input[type=tel] {
    border: 0;
    border-radius: 0;
    @include inputBorder($input-border, $input-border, $maroon-50);
    &:hover {
      @include inputBorder($input-border, $input-border, $maroon-50);
    }
    &:focus {
        outline: none;
        color: #4c4c4c;
        @include inputBorder($primary-color, $primary-color, $maroon-50);
    }
}
.react-tel-input {
  .flag-dropdown.open-dropdown .selected-flag,
  .flag-dropdown.open-dropdown,
  .flag-dropdown:hover .selected-flag,
  .flag-dropdown {
    border: none !important;
    @include inputBorder($input-border, $input-border, $input-border);
  }
  &.not-required {
    .flag-dropdown.open-dropdown .selected-flag,
    .flag-dropdown.open-dropdown,
    .flag-dropdown:hover .selected-flag,
    .flag-dropdown {
      @include inputBorder($input-border, $input-border, $input-border);
    }
  }
  &.required {
    .flag-dropdown.open-dropdown .selected-flag,
    .flag-dropdown.open-dropdown,
    .flag-dropdown:hover .selected-flag,
    .flag-dropdown {
      @include inputBorder($input-border, $input-border, $maroon-200);
    }
  }
}
.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
  background-color: inherit;
}

.required .k-header [class*="k-state-"] {
  &:after {
    border: 0!important;
    @include inputBorder($input-border, $input-border, $maroon-200);
  }
  &:focus:after {
    @include inputBorder($primary-color, $primary-color, $maroon-200);
  }
}

// kendo input
.k-textbox.form-control {
  height: 33px;
  padding: 0 36px 0 10px;
}
.k-numerictextbox.required input {
  @include inputBorder($input-border, $input-border, $maroon-200);
  &:hover {
    @include inputBorder($input-border, $input-border, $maroon-200);
  }
  &:focus {
    @include inputBorder($primary-color, $primary-color, $maroon-200);
  }
}

// checkbox
/* The container */
.checkboxContainer {
  display: inline-block;
  position: relative;
  padding-left: 23px;
  margin-bottom: 30px;
  cursor: pointer;
  font-size: $font-xsm;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &.input-desc {
      font-size: $font-xsm;
      line-height: 18px;
      top: 42px;
      .checkmark {
        top: 0;
      }
  }
  &.day-light-checkbox {
    font-size: $font-xs;
    line-height: 19px;
  }
  &.checkbox-icon {
      display: flex;
      align-items: center;
      margin-bottom: 45px;
      .checkmark { top: 3px; }
      i {
        font-size: $font-md;
        margin-left: 5px;
        margin-right: 5px;
        position: relative;
        top: -3px;
        height: 29px;
      }
  }
}

/* Hide the browser's default checkbox */
.checkboxContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #ffffff;
  border: 1px solid #b8b8b8;
}

/* On mouse-over, add a grey background color */
.checkboxContainer:hover input ~ .checkmark {
  background-color: #ffffff;
}

/* When the checkbox is checked, add a blue background */
.checkboxContainer input:checked ~ .checkmark {
  background-color: #ffffff;
  border: 1px solid #008f94;
}

/* Create the checkmark/indicator (hidden when not checked) */
// .checkmark:after {
  // content: "";
  // position: absolute;
  // display: none;
// }

/* Show the checkmark when checked */
// .checkboxContainer input:checked ~ .checkmark:after {
//   display: block;
// }

/* Style the checkmark/indicator */
// .checkboxContainer .checkmark:after {
  // left: 0;
  // top: 0;
  // width: 15px;
  // height: 15px;
  // @include image('checkbox.png', 2px 3px);
// }
input.rs-checkbox[type='checkbox']:checked ~ .checkmark:before,
input.rs-checkbox-sm[type='checkbox']:checked ~ .checkmark:before {
    content: '\2714';
    color: $primary-green;
    line-height: 15px;
    font-size: 15px;
    text-align: center;
    display: inline-block;
    position: absolute;
    top: 1px;
    left: 0;
    width: 15px;
    height: 15px;
}

// Radio button
.radioContainer {
  position: relative;
  display: inline-block;
  margin-top: 6px;
  input[type=radio] {
    margin-right: 5px;
  }
  input, label {
    &:hover {
      cursor: pointer;
    }
  }
  label {
    position: relative;
    top: -1px;
  }
}
.wsn .radioContainer {
  margin-top: 7px;
}

// phone input
input.form-control:focus {
  outline: none;
  box-shadow: none;
  border: 0;
}

// password fill
i.input-icon-fill {
  width: 37px;
  height: 33px;
  right: 0;
  padding: 7px;
  top: -2px;
  color: #666666;
  text-align: center;
  font-size: $font-smd;
  &.status-green {
    background: #99cc03;
    color: #fff;
  }
}
.input-desc,
.input-desc-right {
  position: absolute;
  top: 37px;
  left: 1px;
  line-height: 15px;
  color: $default-color;
}
.input-desc-right {
  right: 10px;
  left: inherit
}


.errorContainer {
  &.rs-input-wrapper {
      // &.rs-input-wrapper-required {
      input {
          border-color: $primary-red;
          &:focus {
              & + label {
                  color: $primary-red;
              }
          }
      }
      label {
          color: $primary-red;
      }
    &.success_msg {
        input {
            border-color: $primary-green;
            &:focus {
                & + label {
                    color: $primary-green;
                }
            }
        }
        label {
            color: $primary-green;
        }
    }
      // }
  }
  &.rs-kendo-multi-dropdownmenu-wrapper {
      .k-floating-label-container {
          .k-multiselect {
              &::after {
                  border-color: $primary-red;
              }
          }
      }
  }
  .k-floating-label-container {
      .k-picker {
          &::after {
              border-color: $primary-red;
          }
          &.k-focus + .k-label {
              font-size: $font-xxs;
          }
      }

      .k-label {
          color: $primary-red;
      }
  }
  &.rs-phone-input {
      input {
          border-color: $primary-red;
      }
      .special-label {
          span {
              color: $primary-red;
              font-size: $font-xxs !important;
          }
      }
  }
  &.rs-picture {
      figure {
          border-color: $primary-red;
      }
      .validation-message {
          bottom: -33px;
          text-align: center;
      }
  }
}
// input file
.profile-image-wrapper {
  position: relative;
  .remove-profile {
    margin: 0 auto;
    display: flex;
    white-space: pre;
    width: 141px;
    align-items: center;
    margin-top: 1px;
    justify-content: center;

    position: absolute;
    z-index: 1;
    background-color: $remove;
    padding: 6px;
    color: $white;
    left: 0;
    bottom: 1px;
    top: inherit;
    right: 0;
    height: 35px;
    i.icon-rs-close-mini {
      font-size: $icons-xs;
      position: relative;
      top: 0;
      left: 1px;
    }
  }
  .rs-picture {
    &.errorContainer {
      .validation-message {
        position: absolute;
        left: 50%;
        transform: translate(-50%, 10px);
      }
    }
  }
}
.rs-picture {
  padding: 0;
  position: relative;
  display: grid;
  justify-content: center;
  align-items: center;
  justify-items: center;
  figure {
      background-color: $white;
      border: 1px solid $tertiary-grey;
      margin: 0;
      padding: 0;
      border-radius: 50%;
      overflow: hidden;
      text-align: center;
      @include sizing($wh: 135px);
      align-items: center;
      display: flex;
      justify-content: center;
      img {
        max-height: 135px;
        border-radius: 100px;
        max-width: 135px;
        padding: 0;
        width: auto;
        height: auto;
      }
  }
  .picture-choose-file {
      @include sizing($wh: 135px);
      position: absolute;
      input {
          @include sizing($wh: 25px);
          position: absolute;
          top: 0;
          right: 0;
          z-index: 1;
          opacity: 0;
          text-indent: -9999px;
      }
      .info {
          position: absolute;
          bottom: 0;
          left: 105px;
          z-index: 0;
          cursor: pointer;
          i {
              background-color: $white;
              border: 1px solid $white;
              border-radius: 50%;
          }
          .pcf-label {
              display: none;
          }
          .pcf-remove-icon {
              margin-left: -23px;
              position: absolute;
              left: -77px;
              z-index: 2;
          }
          .add-icon {
              color: $primary-blue;
          }
          .edit-icon {
              color: $primary-blue;
              margin-top: -2px;
          }
          .remove-icon {
              color: $secondary_blue;
          }
      }
  }
  &.required {
      .picture-choose-file {
          &:before {
              position: absolute;
              content: '*';
              right: 27px;
              top: 0;
              z-index: 9;
              color: $primary-red;
              font-size: $font-sm;
          }
      }
  }
  .validation-message {
      width: 100%;
  }
}
.password-visible-icon {
  right: 48px !important;
  color: #666666;
  &.icon-sd-eye-hide-medium {
    right: 47.7px !important;
  }
}

// input validate warning
input.warning:required {
  @include inputBorder($maroon-200, $maroon-200, $maroon-200);
}

textarea {
  width: 100%;
  height: 98px;
  border: 1px solid $input-border;
  resize: none;
  padding: 7px;
  border-radius: 5px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

// SLIM SCROLL DROPDOWN
.k-list-scroller{overflow-y: auto !important; overflow-x: auto !important;}
.k-list-scroller{scrollbar-color:$border-color #f7f7f7; scrollbar-width:thin;}
.k-list-scroller::-webkit-scrollbar-track{background-color:#f7f7f7;}
.k-list-scroller::-webkit-scrollbar{width:7px;height:7px;background-color:#f7f7f7;}
.k-list-scroller::-webkit-scrollbar-thumb{background-color:$border-color; border-radius:100px; -webkit-border-radius:100px; -moz-border-radius:100px;}
.k-list-scroller {
  border-right: 0 solid $border-color;
  border-left: 0 solid $border-color;
  .k-list{
    border-right: 0;
    // width: max-content;
    min-width: -webkit-fill-available;
  }
}

input.disabled,
input:disabled {
  &, &:hover {
    background-color: $grey-L100 !important;
    // pointer-events: none;
    cursor: not-allowed;
  }
}


// MAC DROPDOWN ISSUE
.k-widget.k-dropdown {
  background-color: transparent;
  border: 0;
}
.k-widget.k-dropdown,
.k-widget.k-dropdown:hover {
  box-shadow: none;
}

// TIMELINE SELECT INPUT
.select-timezone-wrapper {
  &:after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    box-sizing: border-box;
    border-top: 10px solid transparent;
    border-left: 4px solid transparent;
    border-bottom: 6px solid $input-border;
    border-right: 6px solid $input-border;
  }
  .css-1pahdxg-control {box-shadow: none;}
  .css-1okebmr-indicatorSeparator {background: none;display: none;}
  .css-tlfecz-indicatorContainer,
  .css-1gtu0rj-indicatorContainer{display: none;}
  .css-17adf03-Sa {
    padding: 0;
    input {background: none !important;}
    .css-1usy4l0-Sa {margin: 0;padding: 0;}
  }
  .css-14el2xx-placeholder {margin: 0;}
  .css-1s2u09g-control,
  .css-1pahdxg-control {cursor: pointer;}

  .css-26l3qy-menu {
    @extend %scrollbar;
    border: 1px solid red;
  }
}

.input-text-uppercase {
  text-transform: uppercase;
  &::placeholder {
    text-transform: none;
  }
}
.phone-input-short {
  height: 33px;
  width: 62px;
}
.emoji-font {
  // font-family: $emoji-font;
}