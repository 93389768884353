// KENDO CALENDAR
.k-calendar .k-calendar-header .k-today {

    &,
    &:hover {
        color: $primary-color;
    }
}

span.k-icon.k-i-calendar {
    @include image('kendo-sprite.png');
    background-position: -47px -175px;

    &:before {
        opacity: 0;
    }
}

.k-animation-container {
    .k-widget.k-calendar.k-calendar-infinite {
        // border-right: 1px solid $border-color;
        // border-left: 1px solid $border-color;

        table.k-calendar-table.k-calendar-weekdays,
        .k-content.k-calendar-content.k-scrollable table {
            border: 0;
        }

        table.k-calendar-table.k-calendar-weekdays {
            width: 100%;
        }

        .k-content.k-calendar-content.k-scrollable table {
            width: 225px;
        }

        .k-vstack.k-calendar-centuryview .k-content.k-calendar-content.k-scrollable table {
            width: 90%;
        }
    }

    &.k-datetime-container .k-widget.k-calendar.k-calendar-infinite {
        border: 0;
    }
}


span.k-icon.k-i-calendar {
    @include image('kendo-sprite.png');
    background-position: -47px -175px;

    &:before {
        opacity: 0;
    }
}

// kendo calandar
.k-calendar .k-state-selected .k-link {
    border-color: $primary-color;
    color: $white;
    background-color: $primary-color;
}

.k-calendar-navigation .k-calendar-navigation-marker,
.k-calendar .k-calendar-header .k-title,
.k-calendar tbody th {
    font-weight: normal;
    font-family: $rs-font-bold;
}

.k-calendar .k-calendar-navigation li:hover {
    color: #0056b3;
}

.k-calendar .k-content td.k-today {
    color: #333;
}

.k-calendar .k-content .k-today .k-link {
    box-shadow: inset 0 0 0 1px #007bff;
}

/* calendar */
.k-popup.k-child-animation-container .k-calendar .k-calendar-table {
    margin-right: 0;
    width: 185px;
}

.k-calendar-view {
    gap: 8px;
}

@each $value in $time {
    .k-popup.k-child-animation-container .k-calendar .k-calendar-view.k-hstack.k-calendar-#{$value} {
        margin-right: 8px;

        @if $value ==monthview {

            // ...
            .k-calendar-tbody .k-calendar-td {
                .k-link {
                    font-size: $font-xxs;
                }
            }
        }

        @if $value ==yearview {
            .k-calendar-tbody .k-calendar-td {
                width: 45px;
                height: 61.5px;

                .k-link {
                    width: 45px;
                    font-size: $font-xs;
                }
            }
        }

        @if $value ==decadeview {
            .k-calendar-tbody .k-calendar-td {
                width: 40px;
                height: 61.5px;

                .k-link {
                    width: 45px;
                    height: 63px;
                    font-size: $font-xs;
                }
            }
        }

        @if $value ==centuryview {
            .k-calendar-tbody .k-calendar-td {
                width: 40px;
                height: 61.5px;

                .k-link {
                    width: 45px;
                    height: 63px;
                    font-size: $font-xs;
                }
            }
        }

        .k-calendar-table .k-calendar-tbody .k-calendar-tr:nth-child(odd) {
            background: $even;
        }
    }
}

// calendar ui update
.k-calendar .k-calendar-th,
.k-calendar .k-calendar-monthview .k-calendar-td,
.k-calendar .k-calendar-monthview .k-link {
    width: 26px !important;
    height: 31.5px !important;
    font-size: $font-xxs;
}

// date range picker pending focus open
.k-calendar .k-calendar-monthview {
    td.k-calendar-td {
        &.k-state-pending-focus {
            .k-link {
                border: 1px solid #424247;
                border-radius: 0;
            }
        }
    }

    .k-calendar-tbody .k-calendar-tr {
        &:last-child {
            // display: none;
        }
    }
}

// date range picker pending focus close

.k-calendar .k-calendar-monthview .k-calendar-td.k-state-selected.k-today .k-link,
.k-calendar .k-calendar-td.k-state-selected .k-link,
.k-calendar .k-calendar-td.k-state-selected:hover .k-link,
.k-calendar .k-calendar-td.k-state-selected.k-state-hover .k-link {
    border: 0px solid $primary-color;
    border-radius: 0;
    background-color: $bg-color6;
    color: $primary-color;
}

.k-calendar .k-calendar-monthview .k-calendar-td.k-state-selected.k-today .k-link,
.k-calendar .k-calendar-td.k-state-selected .k-link,
.k-calendar .k-calendar-td.k-state-selected:hover .k-link,
.k-calendar .k-calendar-td.k-state-selected.k-state-hover .k-link {
    background-color: inherit;
    color: inherit;
    border: 1px solid $primary-color;
}

.k-calendar .k-range-start,
.k-calendar .k-range-end,
.k-calendar .k-range-mid {
    background: #e9e9eb;
}

.k-calendar .k-calendar-td:hover .k-link,
.k-calendar .k-calendar-td.k-state-hover .k-link {
    background-color: $primary-color;
    color: $white;
    border-radius: 0;
}

.k-calendar .k-range-split-end::after {
    background-image: linear-gradient(to right, rgba(0, 102, 204, 0.25), transparent 100%);
}

.k-calendar .k-calendar-th {
    text-transform: capitalize;
}

.k-calendar .k-calendar-table {
    border: 1px solid $br-color1;
    margin-right: 8px;
}

.k-calendar .k-calendar-view.k-calendar-monthview .k-calendar-table:last-child {
    margin-right: 3px;
}

.k-calendar .k-range-split-start::after,
.k-calendar .k-range-split-end::after {
    background: none;
}

.k-calendar .k-button.k-flat {
    font-weight: normal;
    letter-spacing: 0.5px;
    font-size: $font-xsm;
    padding: 0;
    position: relative;
    top: -1px;
}

.k-calendar .k-button.k-flat:hover:before {
    background: none !important;
}

.k-calendar .k-calendar-yearview .k-link,
.k-calendar .k-calendar-decadeview .k-link,
.k-calendar .k-calendar-centuryview .k-link {
    height: 63px;
}

.k-calendar .k-calendar-yearview .k-link {
    width: 35px;
    font-size: $font-xs;
}

.k-calendar .k-calendar-view {
    min-height: 194px;
}

.k-calendar .k-nav-today,
.k-calendar .k-nav-today:hover,
.k-calendar .k-nav-today.k-state-hover,
.k-calendar .k-nav-today:focus,
.k-calendar .k-nav-today.k-state-focus {
    color: $primary-color;
}

.k-calendar .k-calendar-td.k-state-selected.k-state-focused .k-link {
    box-shadow: none;
}

.k-calendar .k-content .k-today .k-link {
    border-radius: 0;
    box-shadow: none;
    border: 0px solid $primary-color;
    background-color: inherit;
    color: inherit;
}

.k-calendar .k-content td.k-today.k-range-mid .k-link {
    background-color: inherit;
}

.k-calendar .k-content td.k-today:hover .k-link,
.k-calendar .k-content td.k-today.k-range-mid:hover .k-link {
    color: white;
    background-color: $primary-color;
    border: 0;
}

.k-calendar .k-calendar-centuryview td.k-today,
.k-calendar .k-calendar-decadeview td.k-today,
.k-calendar .k-calendar-yearview td.k-today {
    background-color: inherit;
}

.k-calendar-decadeview .k-link {
    width: 55px;
    height: 92px;
}

.k-calendar-centuryview .k-link {
    width: 56px;
    height: 92px;
}

.k-calendar-centuryview .k-link {
    text-align: left;
}

.k-calendar .k-state-focus .k-link,
.k-calendar .k-state-focused .k-link {
    box-shadow: none !important;
}

// date picker
.k-calendar.k-calendar-infinite {
    .k-calendar-view.k-calendar-monthview .k-content.k-scrollable .k-calendar-td {
        width: 32px !important;
        height: 27px !important;
    }

    // yearview
    .k-calendar-yearview .k-link {
        height: 35px;
    }

    // decadeview
    .k-calendar-decadeview .k-link {
        width: 49px;
        height: 56px;
    }

    .k-vstack.k-calendar-decadeview .k-link {
        width: 56px;
        font-size: $font-xs;
    }

    // centuryview
    .k-calendar-centuryview .k-link {
        width: 44px;
        height: 46px;
    }

    .k-vstack.k-calendar-centuryview .k-link {
        width: 60px;
        font-size: $font-xs;
    }
}

// small-size
.k-widget.k-calendar.k-calendar-infinite {
    width: 291px; // 298px

    @include screen(xxl, min) {
        width: 298px;
    }

    .k-calendar-view.k-vstack {
        padding: 0;
    }
}

.k-widget.k-calendar.k-calendar-infinite .k-calendar-navigation,
.k-calendar-navigation .k-content ul,
.k-calendar-navigation .k-calendar-content ul,
.k-widget.k-calendar.k-calendar-infinite .k-calendar-navigation .k-content.k-calendar-content.k-scrollable {
    width: 55px;
}

.k-calendar-navigation .k-content li,
.k-calendar-navigation .k-calendar-content li {
    padding: 0;
}

// calendar ui update close

// TIME PICKER
.k-datepicker .k-select,
.k-datepicker:hover .k-select {
    color: $primary-color;
    background: none;
}

// date input
.k-dateinput .k-dateinput-wrap input {
    margin: 0;
    background: none !important;
}

.rsptimer-picker {
    .k-datetimepicker {
        padding: 0 !important;
    }

    .k-picker-wrap {
        padding: 0 10px !important;
        border: 0;

        &.k-state-focused {
            box-shadow: none;
        }
    }

    span.k-picker-wrap[class*="k-state-"]:before {
        border-bottom-color: $border-color;
        border-right-color: $border-color;
        right: 0;
        bottom: 0;
    }

    .k-select {
        background: none;
    }

    .k-timepicker {
        width: 115px;

        &:focus-within {
            box-shadow: none;
        }

        .k-select:hover {
            background: none;
        }
    }

    span.k-picker-wrap[class*="k-state-"] {
        border-width: 0 !important;
    }

    .k-timepicker .k-select:hover {
        background: none;
    }
    .k-dateinput .k-dateinput-wrap input {
        max-width: 100%;
        padding-right: 0;
        padding-left: 0;
    }

    span.k-picker-wrap {

        &:before,
        &[class*="k-state-"]:after {
            content: inherit;
        }
    }

    .k-widget.k-timepicker {
        height: 33px;
        padding-top: 0;
        padding-right: 2px;

        .k-icon {
            font-size: $font-sm;
            color: $primary-color;
        }
    }
}

// date picker
.rspdate-picker {
    position: relative;

    a.k-select {
        width: 100%;
        position: absolute !important;
        height: 33px;
    }

    span.k-icon.k-i-calendar {
        @extend %calendarUI;
        position: absolute;
        right: 13px;
        top: 4px;
    }

    .k-widget.k-datepicker {
        width: 100%;
        border: 0;

        &:focus,
        &:focus-within,
        &.k-state-focus {
            box-shadow: none;
        }

        .k-dateinput-wrap {
            background: transparent;

            *::selection {
                background-color: inherit !important;
                color: inherit;
            }
        }

        .k-dateinput {
            pointer-events: none;
        }
    }

    input {
        padding-left: 0;
    }

    .k-picker-wrap.k-picker-wrap.k-state-focused {
        // padding: 6px 12px 5px;
        padding: 0 10px;
        background: bottom left linear-gradient($border-color, $border-color) no-repeat, bottom center linear-gradient($border-color, $border-color) repeat-x, bottom right linear-gradient($border-color, $border-color) no-repeat !important;
        background-size: $input-line-web !important;
        -webkit-background-size: $input-line-web !important;

        &:before {
            right: 0px;
            @extend %dropdown-arrow-grey;
        }
    }
}

.k-picker-wrap .k-dateinput {
    height: 31px;
}

// timer picker
.rsptimer-picker {
    span.k-link.k-link-date {
        @extend %calendarUI;
        background-color: transparent;
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        padding-top: 0px;
        margin-top: -1px;

        &:before {
            position: absolute;
            right: 11px;
            top: 0;
        }
    }

    span.k-icon.k-i-calendar {
        display: none;
    }

    .k-widget.k-datepicker {
        width: 100%;

        .k-dateinput-wrap {
            background: transparent;
        }
    }

    .k-datetimepicker .k-picker-wrap,
    .k-timepicker .k-picker-wrap {
        height: 29px;
    }

    .k-datetimepicker:focus-within {
        box-shadow: none;
    }

    .k-datetimepicker {
        padding: 0;
        position: relative;
        top: 1px;
        .k-picker-wrap {
            height: 33px;
            background: inherit !important;
            // padding: 0 10px !important;

            .k-widget.k-dateinput {
                background: inherit !important;
                pointer-events: none;
            }
        }
    }
}

.rspdate-picker span.k-picker-wrap[class*="k-state-"] {
    padding: 0 10px 0;
    width: 100%;
    height: 33px;
    line-height: 33px;
    border-width: 0 0 0 !important;

    &:after {
        bottom: 0px;
        height: 14px;
    }

    &:before {
        right: 1px;
        bottom: 0px;
    }

    &.k-state-focused:after {
        content: inherit;
    }
}

.rspdate-picker .k-picker-wrap.k-state-invalid {
    margin-top: 0px;
}

.rspdate-picker .k-picker-wrap.k-picker-wrap {

    // padding-top: 1px;
    // padding-bottom: 2px;
    .k-select {
        padding: 0;
        position: relative;
        top: -3px;
        right: 0px;
    }

    &:before {
        content: "";
        display: block;
        position: absolute;
        right: 0;
        bottom: 0px;
        @extend %dropdown-arrow-color;
    }

    &.k-state-focused {
        background: bottom left linear-gradient(#fe5758, #fe5758) no-repeat, bottom center linear-gradient($border-color, $border-color) repeat-x, bottom right linear-gradient($border-color, $border-color) no-repeat !important;
        background-size: $input-line-web !important;
        -webkit-background-size: $input-line-web !important;
    }
}

// date time picker
.k-animation-container.k-datetime-container {

    .k-time-tab,
    .k-date-tab {
        border: 1px solid $border-color;
    }
}

.k-animation-container.k-datetime-container .k-datetime-buttongroup {
    padding: 0;
}

// DATE AND TIME PCIKER
// .k-animation-container.k-animation-container-relative.k-datetime-container.k-group.k-reset {background-color: #fff;}
.k-animation-container.k-animation-container-relative.k-datetime-container {
    &.k-group.k-reset {
        .k-button-group {
            border-bottom: 1px solid $border-color;

            .k-button {
                border-radius: 0;
                font-size: $font-sm;
                margin: 0;
            }
        }

        .k-datetime-footer.k-actions {
            margin: 0;
            padding: 0;

            .k-button {
                border-radius: 0;
            }
        }
    }

    @each $value in $time {
        &.k-group.k-reset .k-calendar-view.k-vstack.k-calendar-#{$value} {
            margin: 0 37px;
        }
    }

    &.k-group.k-reset .k-calendar-view.k-vstack.k-calendar-centuryview .k-content.k-calendar-content {
        padding-left: 85px;
    }

    &.k-group.k-reset .k-date-tab.k-datetime-wrap,
    &.k-group.k-reset .k-time-tab.k-datetime-wrap,
    &.k-group.k-reset .k-time-tab .k-widget.k-calendar.k-calendar-infinite {
        width: 300px;
    }

    &.k-group.k-reset .k-datetime-time-wrap {
        flex: 0 0 225px;
    }

    &.k-group.k-reset .k-time-tab button.k-button.k-primary.k-time-now {
        color: #585858;
        border-color: inherit !important;
        background-color: transparent !important;
    }

    &.k-group.k-reset .k-time-tab .k-time-header {
        padding: 4px 0;
    }

    &.k-group.k-reset .k-datetime-footer.k-actions .k-button {
        &.k-time-cancel {
            padding: 9px 20px;
            color: $secondary-color;
            font-size: $font-sm;

            &:hover {
                background-color: transparent;
                text-decoration: underline;
            }
        }

        &.k-time-accept {
            background-color: $primary-color;
            color: #fff;
            padding-right: 20px;
            -webkit-transition: all 0.3s ease;
            transition: all 0.3s ease;
            line-height: 20px;
            border: 0;
            padding: 9px 20px;
	        -webkit-letter-spacing: 0.5pt;
	        -moz-letter-spacing: 0.5pt;
	        -ms-letter-spacing: 0.5pt;
            letter-spacing: 0.5pt;
            border-radius: 5px;
            font-size: $font-sm;
            margin: 15px;

            &:hover {
                box-shadow: 0 0px 15px $primary-color;
            }
        }
    }
}

.k-datetime-container .k-time-tab .k-datetime-selector {
    transform: translateX(-88%);
}

// calender with time single month
.k-animation-container {
    &.k-animation-container-relative.k-datetime-container {

        .k-popup.k-child-animation-container .k-date-tab.k-datetime-wrap .k-calendar-view,
        .k-popup.k-child-animation-container .k-datetime-selector {
            height: 230px;
        }

    &.k-group.k-reset .k-datetime-footer.k-actions .k-button.k-time-accept {
        margin: 0 15px;
    }
        &.k-group.k-reset .k-datetime-footer.k-actions {
            margin-bottom: 15px;
            margin-top: 10px;

            .k-button.k-time-accept {
                margin: 0 15px;
            }
        }

        &.k-group.k-reset .k-datetime-time-wrap .k-time-header {
            display: none;
        }
    }
}

.k-animation-container-shown .k-popup.ic-dropdown {
    &.k-child-animation-container.k-slide-down-enter {
        @extend %arrow-top-right;

        &:before {
            right: 11px;
        }

        &:after {
            right: 8px;
        }
    }

    &.arrow-start.k-child-animation-container.k-slide-down-enter {
        @extend %arrow-top-left;
    }
}

.k-calendar .k-button.k-flat {
    font-family: "MuktaBold", sans-serif;
}

.modal .date-range-view-container .date-range-dropdown {
    left: 0;
    right: inherit;
}