
// TAB MINI
ul.rs-chart-tab {
    &.mini {
        @include flexbox();
        padding: 0;
        list-style-type: none;
        margin-bottom: 30px;
        li.tabDefault {
            position: relative;
            padding: 0 14px;
            line-height: 20px;
            span {
                text-align: center;
                width: auto;
                float: left;
                padding-bottom: 7px;
                font-size: $font-sm;
                position: relative;
                font-family: $rs-font-regular;
            }
            span, i {
                color: #111;
            }
            &:hover { cursor: pointer; }
            // &:first-child { padding-left: 0; }
            // &:last-child { padding-right: 0; }
            &:before {
                @include pseudo();
                left: 0;
                top: 35%;
                margin-top: -7px;
                height: 15px;
                border-left: 1px solid $border-color;
            }
            &:first-child {
              padding-left: 0;
            }
            &:last-child {
              padding-right: 0;
            }
            &:first-child:before {
                content: inherit;
            }
            &.active {
                span, i {
                  color: $primary-color;
                }
                span {
                  border-bottom: 1px solid $primary-color;
                  &::before {
                      @include pseudo();
                      border-left: 5px solid $color0;
                      border-right: 5px solid $color0;
                      bottom: -6px;
                      left: 50%;
                      margin-left: -5px;
                      border-top: 6px solid $primary-color;
                  }
                  &::after {
                      @include pseudo();
                      border-left: 4px solid $color0;
                      border-right: 4px solid $color0;
                      bottom: -4px;
                      left: 50%;
                      margin-left: -4px;
                      border-top: 5px solid $white;
                  }
                }
            }
        }
    }
    &.campaign-type-tab.or-tab {
        li {
          min-width: 146px;
        }
        .or-divider {
          &:before,
          &:after {
            pointer-events: none;
          }
          &:before {
              right: -25px;
          }
          &:after {
              left: calc(100% + 12px);
          }
        }
    }
}
// PAYMENT TAB
.payment-tab-container {
    ul.rs-chart-tab {
      margin: 0;
      padding: 0;
      &.mini li.tabDefault span {
        padding: 0;
      }
      li {
        width: 50%;
        height: 55px;
        @include flexbox($y: center, $x: center);
        background-color: $bg-color6;
        &.tabDefault:last-child:before,
        &.tabDefault.active span::after { content: inherit; }
        &.tabDefault.active span::before {
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-top: 10px solid $primary-color;
          margin-left: -6%;
        }
        &.tabDefault.active {
          position: relative;
          span {
            border: 0;
            padding: 0;
          }
          span, i {
            color: $white;
          }
          &:before {
            bottom: -15px;
            border-top-width: 10px;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            content: '';
            display: block;
            left: 50%;
            position: absolute;
            margin-left: -7px;
            z-index: 12;
            border-top: 9px solid $primary-color;
            top: inherit;
          }
        }
        &.active {
          @include color($bg: $bg-color5);
          &:hover {
            @include color($bg: $bg-color5);
          }
        }
      }
    }
}
// INVOICE LIST
.rs-chart-tab.tabs-filled-component {
    width: 100%;
    display: flex;
    margin-bottom: $theme-space;
    @if $new-theme-test == true {
      border-radius: 5px;
      border: 1px solid $beta_tm_border;
      background-color: #ffffff;
      // overflow: hidden;
    }
    li.tabDefault {
      height: 41px;
      @include flexbox($y: center, $x: center);
      width: 100%;
      background-color: $bg-color6;
      border-bottom: 2px solid $br-color1;
      font-size: $font-sm;
      position: relative;
      @if $new-theme-test == true {
        background-color: $even;
        border-bottom: 0;
        border-right: 1px solid $border-color;
      }
      &:first-child {
        @if $new-theme-test == true {
          border-radius: ($theme-radius-size - 1px) 0 0 ($theme-radius-size - 1px);
        }
      }
      &:last-child {
        @if $new-theme-test == true {
          border-radius: 0 ($theme-radius-size - 1px) ($theme-radius-size - 1px) 0;
          border-right: 0;
        }
      }
      &:hover {
        cursor: pointer;
      }
      span {
        &, &:before, &:after {
          padding: 3px 0 0 !important;
          content: inherit !important;
          border-bottom: 0px !important;
        }
      }
      &.active {
        background-color: $bg-color4;
        border-bottom: 2px solid $table-header;
        @if $new-theme-test == true {
          background-color: $table-header; // $table-header;
          color: $white;
          border-right: 0;
        }
        &:after {
          content: '';
          bottom: -8px;
          border-left: 7px solid transparent;
          border-right: 7px solid transparent;
          border-top: 7px solid $table-header;
          display: block;
          left: 50%;
          position: absolute;
          margin-left: -7px;
          z-index: 0;
        }
      }
      &:before {
        content: inherit !important;
      }
    }
}
.portlet-box-theme {
  .rs-chart-tab.tabs-filled-component {
    li.tabDefault {
      &:first-child {
        @if $new-theme-test == true {
          border-radius: $theme-radius-size 0 0 0;
        }
      }
      &:last-child {
        @if $new-theme-test == true {
          border-radius: 0 $theme-radius-size 0 0;
        }
      }
    }
  }
}
// VERTICAL TAB
.rs-chart-tab.vertical-tabs {
    li {
      height: 90px;
      @include flexbox($y: center, $x: center, $dir: column);
      border-bottom: 3px solid $white;
      &:hover {
        cursor: pointer;
      }
      &:last-child {
        border-bottom: 0;
      }
      i {
        margin-bottom: 5px;
        color: $primary-color;
      }
      &.active {
        background-color: $bg-color5;
        color: $white;
        i {
          color: $white;
        }
      }
      div {
        text-align: center;
        @include flexbox($dir: column);
      }
      span {
        display: inline-block;
        text-align: center;
      }
    }
}
// ADD TAB
.rs-chart-tab {
    &.addtab {
      display: inline-block;
      li.tabDefault {
        display: inline-block;
        background-color: $bg-color6;
        margin-left: 2px;
        &:hover {
          cursor: pointer;
        }
        &.active {
          color: $primary-color;
          background-color: $bg-color4;
          span {
            &:before {
              bottom: 0;
              content: '';
              left: 0;
              position: absolute;
              right: 0;
              border-top: 2px solid $primary-color;
            }
            &:after {
              bottom: -6px;
              border-left: 7px solid transparent;
              border-right: 7px solid transparent;
              border-top: 7px solid $primary-color;
              content: '';
              display: block;
              left: 50%;
              position: absolute;
              margin-left: -7px;
              z-index: 0;
            }
          }
        }
        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 10px;
        }
        span {
          width: 185px;
          height: 38px;
          @include center();
          position: relative;
        }
      }
    }
}
// TAB ICON
.rs-chart-tab {
    &.rs-tab-icon {
      li.tabDefault {
        text-align: center;
        @include flexbox($dir: column);
        line-height: 19px;
        height: 105px;
        vertical-align: middle;
        float: left;
        color: inherit;
        min-width: 95px;
        position: relative;
        padding: 17px 0;
        &:hover { cursor: pointer; }
        .list-view {
          padding: 17px 0;
          &:hover { cursor: pointer; }
        }
        &.active {
          @extend %tabArrow;
          i { color: $white !important; }
        }
        i {
          color: $primary-color;
          margin-bottom: 5px;
        }
      }
      &.rs-tab-horizontal {
        display: inline-block;
        width: 100%;
        background-color: $bg-color4;
        @if $new-theme-test == true {
          border-radius: $theme-radius-size;
          overflow: hidden;
        }
        li.tabDefault {
          height: 85px;
          // width: 102px;
          padding-left: 10px;
          padding-right: 10px;
          border-right: 3px solid $white;
        }
      }
    }
}
// BTN TAB
.rs-chart-tab.rs-btn-tab li.tabDefault {
    display: inline-block;
    padding: 8px 13px;
    border: 1px solid $border-color;
    margin-left: -1px;
    &.active {
      background-color: $bg-color5;
      color: white;
    }
    &:first-child {
      margin-left: 0;
    }
    &:hover {
      cursor: pointer;
    }
}
.split-container {
    .rs-chart-tab.rs-tab-icon {
      li.tabDefault {
        min-width: 22%;
      }
    }
}
// TAB 2
.rs-chart-tab2.addtab li.tabDefault span {
    font-size: $font-sm;
    width: 185px;
    height: 43px;
}
// TAB ICON
ul.rs-chart-tab.tab-icon li.tabDefault {
    padding: 0 8px;
    &.active {
      &:before, &:after {
        z-index: 1;
      }
      &:after {
        border-left: 4px solid #0000;
        border-right: 4px solid #0000;
        content: '';
        display: block;
        left: 50%;
        margin-left: -4px;
        position: absolute;
        border-bottom: 5px solid $white;
        border-top: none;
        bottom: -6px;
      }
      &:before {
        border-left: 5px solid #0000;
        border-right: 5px solid #0000;
        content: '';
        display: block;
        left: 50%;
        margin-left: -5px;
        position: absolute;
        bottom: -5px;
        border-bottom: 6px solid $border-color;
        border-top: none;
        top: inherit;
      }
    }
    .arrowBar:before {
      content: '';
      display: block;
      position: absolute;
      left: -2%;
      top: 35%;
      margin-top: -7px;
      height: 15px;
      border-left: 1px solid $border-color;
    }
    &:first-child {
      padding-left: 8px;
      .arrowBar:before {
        content: inherit;
      }
    }
    &:last-child {
      padding-right: 8px;
    }
}
ul.rs-chart-tab.rs-chart-tab2.addtab {
    @include flexbox();
    li.tabDefault {
      width: 206px;
      .tab-text-vt {
        display: inline-block;
        width: 100%;
        .remove-btn {
          height: 41px;
          display: flex;
          align-items: center;
          top: 0;
        }
      }
      span {
        width: 100%;
      }
    }
}
.rs-chart-tab.addtab li.tabDefault:last-child {
    margin-right: 0;
}
.rs-chart-tab.tabCount5 li.tabDefault span {
    width: 209px;
}
ul.rs-chart-tab.fs16-btn span {
    font-size: $font-xsm !important;
}

.is-tab {
  .css-scrollbar {
    max-height: 151px !important;
  }
}
ul.rs-chart-tab.mini {
  &.is-tab-head li.tabDefault {
    background-color: #f8f8f8;
    padding: 0;
    &:before {
      height: 50%;
      top: 50%;
      transform: translateY(-50%);
      margin-top: 0;
    }
    span {
      padding: 8px !important;
    }
  }
}

// TAB ANIMATE
.rs-chart-tab.mini.animate-tab {
    position: relative;
    li.tabDefault {
      &:first-child { padding-left: 0; }
      &:last-child { padding-right: 0; }
      &.active span {
        border-bottom: 0;
        &:before, &:after {
          content: inherit;
        }
      }
      &.active ~ .animate-key {
        background: $primary-color;
        bottom: 0;
        display: block;
        transition: all 0.3s ease;
      }
      &:nth-child(1).active ~ .animate-key {
        --x: 0%;
        // --w: 99px;
        --w: 103px;
      }
      &:nth-child(2).active ~ .animate-key {
        // --x: 181%;
        // --w: 71px;
        --x: 180%;
        --w: 75px;
      }
    }
    .animate-key {
      position: absolute;
      height: 0.5px;
      width: var(--w);
      transition: transform .4s cubic-bezier(.2, .64, .48, 1.12), opacity .4s ease, color .4s ease;
      transform: translateX(var(--x));
      -webkit-backface-visibility: hidden;
      &:before, &:after {
        content: '';
        display: inline-block;
        left: 50%;
        position: absolute;
      }
      &:before {
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        bottom: -5px;
        margin-left: -5px;
        border-top: 5px solid $primary-color;
      }
      &:after {
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        bottom: -4px;
        margin-left: -5px;
        border-top: 6px solid #ffffff;
      }
    }
}

.even-space-4 .rs-chart-tab.addtab li.tabDefault {
    width: 248px !important;
    @include screen(xxl, max) {
      width: 242px !important;
    }
    @include screen(xl, max) {
      width: 194px !important;
    }
    @include screen(lg, max) {
      width: 134px !important;
    }
}
.split-container {
  .even-space-4 .rs-chart-tab.addtab li.tabDefault {
    width: 245px !important;
  }
}


@if $new-theme-test-2 == true {
  /* tab left */
  .rs-chart-tab {
    &.vertical-tabs {
      li {
        margin: 8px;
        box-shadow: 0px 4px 3px -2px #00000040;
        border-radius: $theme-radius-size;
        border-bottom: 0;
      }
    }
    &.rs-tab-icon {
      li.tabDefault {
        margin: 8px;
      }
    }
  }
  .rs-chart-tab.rs-tab-icon li.tabDefault.active,
  .rs-chart-tab.vertical-tabs li.active {
    margin: 8px;
    border: 0px solid $beta_tm_border !important;
    box-shadow: 0px 4px 3px -2px #00000040;
    border-radius: $theme-radius-size;
  }
  .rs-chart-tab.rs-tab-icon li.tabDefault {
    margin: 8px 15px !important;
  }
  .rs-chart-tab.rs-tab-icon li.tabDefault.active::before {
    border: 0;
  }
  /* campaign or tab */
  .rs-chart-tab {
    &.campaign-type-tab.or-tab {
      li {
        margin: 8px 25px !important;
        &:first-child {
          margin-left: 0 !important;
        }
        &:last-child {
          margin-right: 0 !important;
        }
      }
      .or-divider {
        &:before {
          right: -25px;
        }
        &:after {
          right: -37px;
          left: inherit;
        }
      }
    }
  }

  /* MINI tab */
  .rs-chart-tab.mini {
    border-radius: $theme-radius-size-sm;
    padding: 4px;
    border: 1px solid $beta_tm_border;
    li.tabDefault {
      line-height: 27px;
      &:first-child {
        padding-left: 13px;
      }
      &:last-child {
        padding-right: 13px;
      }
      &.active {
        background-color: $primary-color;
        border-radius: $theme-radius-size-sm;
        span {
          color: $white;
        }
      }
      span {
        padding-bottom: 0;
        border: 0 !important;
        &:before,
        &:after {
          content: inherit !important;
        }
        &:before {
          height: 19px;
          content: inherit;
        }
      }
    }
  }

  /* Login */
  .rs-chart-tab.mini.animate-tab {
    border: 0;
    box-shadow: none;
    .tabDefault.active {
      background-color: transparent;
      span {
        color: $primary-color;
      }
    }
  }
  // android icon
  ul.rs-chart-tab.mini {
    li.tabDefault {
      &.active i:before {
        color: $white;
      }
      i {
        position: relative;
        top: 3px;
      }
      .arrowBar:before {
        content: inherit;
      }
    }
  }
}


// REPORT DETAILS PAGE
.rs-chart-tab.fluid {
  display: flex;
  background-color: #ffffff;
  border: 1px solid $border-color;
  border-radius: 8px;
  li {
    padding: 8px 20px;
    border-left: 1px solid $border-color;
    font-size: $font-xsm;
    position: relative;
    &:hover {
      background-color: $tertiary-blue;
      cursor: pointer;
    }
    &:first-child {
      border-left: 0;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
    &:last-child {
      border-right: 0;
    }
    &.active {
      background: $primary-color;
      color: #fff;
      &:hover {
        background-color: $primary-color;
      }
      &:after {
        content: '';
        position: absolute;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-top: 7px solid $primary-color;
        bottom: -7px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}