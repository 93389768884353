@charset 'utf-8';
.rp-loading {
  .loading {
    .loading-content {
      @include image('loading.png', center center);
    }
  }
}

// new
input#typeinp[type="range"] {
    position: absolute;
    --track-color: none;
    background: none !important;
    top: 53px;
    --clip-edges: 1px !important;
    --thumb-height: 1.125em;
    --track-height: 0em;
    --brightness-hover: 180%;
    --brightness-down: 80%;
}
.split-ab-progress-wrapper .split-ab-progressbar .split-box .inside,
.split-ab-wrapper-container .split-ab-wrapper {
    height: 65px;
}
.darkfade {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 2;background-color: rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 100%;
    &:before {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 1;
        content: '';
    }
}
.split-ab-row {background-color: $white;z-index: 10;position: relative;padding-top: 20px;}
.rs-chart-tab.rs-tab-icon li.tabDefault.click-off,
.rs-chart-tab.rs-tab-icon li.tabDefault.click-off span.or-divider {
    pointer-events: none;
    opacity: 1;
}
.rs-chart-tab.rs-tab-icon li.tabDefault.click-off i,
.rs-chart-tab.rs-tab-icon li.tabDefault.click-off span {
    opacity: 0.4;
}
.adhoc-listpermit {
    margin-bottom: 30px;
    margin-left: 20px;
    li {
        margin-bottom: 15px;
        display: flex;
        align-items: center;
        i {
            position: relative;
            top: -1px;
            margin-right: 10px;
        }
    }
}
.input-r-close {
    position: absolute;
    right: 8px;
    top: 19px;
}
.sp-copy-wrapper {
    border: 1px solid #e8e8e8;
    padding: 10px;
    background-color: $white;
    float: left;
    width: 100%;
    margin-top: 10px;
}
ul.list-tick-bar {
    display: flex;
    li {
        display: flex;
        align-items: center;
        margin-right: 85px;
        margin-top: 15px;
        span {
            margin-left: 6px;
            margin-top: 7px;
        }
    }
}
.web-mail-text-editor-tool .k-widget.k-editor {
    padding: 0;
    .k-widget.k-dropdown {
        width: 185px;
    }
}
.time-row {
    display: flex;
    align-items: end;
    li {
        margin-right: 10px;
        input {
            width: 75px;
        }
    }
}
.fullscreen.fullscreen-enabled {
    background-color: #fff;
    overflow-y: scroll;
    @extend %scrollbar;
}

h1 { color: $tab-text-color; }
.multi-checkbox-space {
    .checkboxContainer {
        margin-bottom: 45px;
    }
}
.brand-logo-login {
    font-size: $font-xl;
}
.login-indicator-wrapper {
    width: 100%;
    height: 100%;
    z-index: 1002;
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    bottom: 0;
    .login-indicator {
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        background-color: $primary-color;
        bottom: 20%;
        margin-top: -75px;
        /*! background: rgba(0, 0, 0,.7); */
        box-shadow: 0 3px 4px rgba(0, 0, 0, 0.5);
        -webkit-box-shadow: 0 3px 4px rgba(0, 0, 0, 0.5);
        -moz-box-shadow: 0 3px 4px rgba(0, 0, 0, 0.5);
        &.login-green {
            background: $footerColor5 !important;
        }
        &.login-red {
            background: $footerColor3 !important;
        }
        &.login-orange {
            background: $footerColor4 !important;
        }
        &.login-metal {
            background: $footerColor2 !important;
        }
        &.login-blue {
            background: $footerColor1 !important;
        }
        &.in-pop-1 {
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: 120px;
        }
        img {
            width: 120px;
            display: inline-block;
        }
        p {
            display: inline-block;
            color: $white;
            margin-bottom: 0;
        }
        button {
            margin-left: 15px;
            border: 1px solid #ccc;
        }
        h3 {
            display: inline-block;
            font-size: $font-md;
            color: #fff;
        }
        .cancel-btn {
            border: 0;
            color: $white;
        }
    }
}
.aud-block {
    border: 1px solid #d4d6de;
    background: $grey-L100;
    padding: 15px;
    position: relative;
    max-height: 150px;
    border-radius: 5px;
    overflow: hidden;
    .dprogress-bar-wr {
        height: 3px;
        position: absolute;
        bottom: 0px;
        left: 0px;
        right: 0px;
        background: #d4d6de;
    }
    .dprogress-bar-wr div {
        width: 0%;
        height: 3px;
    }
    p {
        margin-bottom: 5px;
        @include truncate(90%);
    }
    .aud-kb {
        margin-bottom: 10px;
        color: $fontColor4;
    }
    &.ufc-inprogress .dprogress-bar-wr div {
        background-color: #06c;
    }
    &.ufc-success .dprogress-bar-wr div {
        background-color: #5ba52a;
    }
    &.ufc-failed .dprogress-bar-wr div {
        background-color: #fe5758;
    }
}
.k-widget.k-dropdown,
.k-datepicker {
    border: 0;
    background: none;
    box-shadow: none !important;
}
.k-dropdown:focus,
.k-dropdowntree:focus,
.k-dropdown.k-state-focus,
.k-state-focus.k-dropdowntree,
.rspdate-picker:focus {
    box-shadow: none !important;
}
// list segmentation
.list-segment-view {
    padding: 0;
}
ul.listSegmentsContainer {
    margin: 0;
}
ul.listSegmentsContainer li.list-seg-view {
    padding: 15px 15px 30px;
    width: 100%;
    display: inline-block;
    position: relative;
    &:nth-child(odd) {
        background-color: $even;
    }
    .switch-divider {
        position: absolute;
        top: -17px;
        left: 0;
    }
    .k-dateinput {
        background-color: inherit;
    }
}
.listsegment-nodata {
    padding: 10px;
    text-align: center;
    margin: 30px 0 70px;
    color: $default-color;
}
.segment-add-icon {
    bottom: 30px;
}
.list-segment-accordin {
    border: 1px solid $border-color;
    border-top-color: $primary-color;
    padding: 10px;
    .rs-accordin {
        margin-bottom: 0;
        .card {
            margin-bottom: 2px;
            border: 0;
            &:last-child {
                margin-bottom: 0;
            }
            .card-header {
                border-bottom: 0;
                height: 38px;
                padding: 0 8px;
            }
            .card-body {
                padding: 7px 7px 0;
            }
        }
    }
}
.list-segment-add-list {
    display: inline-block;
    margin-bottom: 10px;
    li {
        position: relative;
        border: 1px solid $border-color;
        padding: 3px 25px 3px 5px;
        font-size: $font-xs;
        float: left;
        cursor: pointer;
        margin: 10px 3px 0px 8px;
        line-height: 17px;
        background-color: $grey-L100;
        span {
            position: absolute;
            right: 0px;
            top: 0px;
            padding: 4px;
            font-size: $font-xsm;
            line-height: 15px;
            border-left: 1px solid $border-color;
        }
    }
}
.pvi-container {
    position: absolute;
    top: 7px;
    right: 0;
    text-align: right;
    span {
        font-size: $font-xs;
    }
}
// Subject line analysis
.line-analysis {
    width: 100px;
}
.circular-text {
    font-size: $font-lg;
}
.qr-generator {
    padding: 20px 14px 14px;
    position: relative;
    background-color: $grey-L100;
    min-height: 20px;
    .qr-refresh {
        position: absolute;
        right: 7px;
        top: 7px;
    }
    .qricon {
        position: relative;
        top: -18px;
    }
    .qrdropwdown {
        position: absolute;
        top: -3px;
        right: 0;
    }
}
.qrlink-slider {
    display: flex;
    position: relative;
    .qrslider {
        position: relative;
        .k-slider.k-slider-horizontal {
            width: 110px;
            .k-slider-track-wrap {
                margin-left: 0 !important;
            }
        }
        small {
            position: absolute;
            top: 19px;
            left: 0;
            font-size: $font-xxs;
        }
    }
    .k-slider .k-slider-track,
    .k-slider .k-slider-selection {
        border-radius: 0;
        margin: 0;
        height: 10px;
        background-color: #ededed;
        color: #222;
        border: 1px solid $border-color;
        top: 10px;
    }
    .k-slider-horizontal .k-slider-selection {
        top: 0px;
        margin: 0;
        background-color: $bg-color7;
        height: 9px;
    }
    .k-slider-horizontal .k-slider-track-wrap .k-draghandle {
        background: $grey-L100;
        border: 1px solid $border-color;
        height: 20px;
        width: 20px;
    }
}
// mobile preview
.mobile-popup-container.modal {
    .preview-mobile-popup {
        width: 260px;
        height: 360px;
        position: relative;
    }
    .modal-dialog {
        .modal-header {
            border: 0;
            padding: 0;
        }
        .modal-body {
            padding-top: 0;
        }
        .modal-content {
            border: 0;
            background-color: transparent;
            padding-top: 0;
        }
    }
}
.mb-content-box {
    // width: 236px;
    height: 280px;
    // position: absolute;
    // top: 45px;
    // left: 16px;
    &.sms-popup {
        .mb-box-head {
            display: flex;
            justify-content: space-between;
            font-size: $font-xxs;
            background-color: $white;
            padding: 9px;
            margin-bottom: 16px;
        }
    }
    &.whatsapp-popup {
        padding-top: 10px;
        .mb-box-head {
            display: flex;
            height: 55px;
            background: #005e54;
            color: $white;
            padding: 0 8px;
            font-size: $font-xsm;
            position: relative;
            z-index: 1;
            align-items: center;
            .wa-profile-name {
                margin-left: 10px;
                .name {
                    font-size: $font-xs;
                }
                .status {
                    font-size: $font-xxs;
                }
            }
            .wa-avatar img {
                border-radius: 30px;
                overflow: hidden;
                width: 24px;
            }
        }
        .mb-box-body {
            padding: 0;
        }
    }
}
.modal-dialog {
    .modal-content {
        .modal-body {
            .preview-mobile-popup {
                display: flex;
                justify-content: center;
                min-height: 340px;
            }
            .mb-content-box {
                // left: 50%;
                // transform: translateX(-50%);
                &.sms-popup {
                    .mb-box-head {
                        margin-top: 21px;
                        background-color: #e5e5e5;
                    }
                }
            }
        }
    }
}

// msg
.message-box {
    li {
        div {
            margin: 5px 0 0;
            padding: 10px;
            font-size: $font-xxs;
            border-radius: 0 12px 12px 12px;
            background-color: $even;
            color: $primary-color;
            position: relative;
            &:after {
                content: " ";
                left: -6px;
                position: absolute;
                top: 0;
                width: 0;
                border-bottom: 10px solid rgba(0, 0, 0, 0);
                border-right: 11px solid $even;
            }
        }
        small {
            font-size: $font-xxs;
            position: relative;
            left: 23px;
            color: $primary-color;
        }
    }
}
// whatsapp
.conversation {
    height: 310px;
    position: relative;
    @include image("whatsapp-bg.jpg", -32px -78px, repeat);
    background-color: #efe7dd;
    z-index: 0;
}
.conversation-container {
    height: 250px;
    box-shadow: inset 0 10px 10px -10px #000000;
    overflow-x: hidden;
    padding: 0 16px;
    margin-bottom: 5px;
    .message {
        color: #000;
        clear: both;
        line-height: 15px;
        font-size: $font-xxs;
        padding: 8px;
        position: relative;
        margin: 8px 0;
        max-width: 85%;
        word-wrap: break-word;
        z-index: -1;
        &:after {
            position: absolute;
            content: "";
            width: 0;
            height: 0;
            border-style: solid;
        }
        .metadata {
            display: inline-block;
            float: right;
            padding: 0 0 0 7px;
            position: relative;
            bottom: -4px;
            .time {
                color: rgba(0, 0, 0, 0.45);
                font-size: 9px;
                display: inline-block;
            }
            .tick {
                display: inline-block;
                margin-left: 2px;
                position: relative;
                top: 4px;
                height: 16px;
                width: 16px;
                svg {
                    position: absolute;
                    transition: 0.5s ease-in-out;
                }
            }
        }
        &.sent {
            background: #e1ffc7;
            border-radius: 5px 0 5px 5px;
            float: right;
            &:after {
                border-width: 0 0 10px 10px;
                border-color: transparent transparent transparent #e1ffc7;
                top: 0;
                right: -10px;
            }
        }
        &.received {
            background: $white;
            border-radius: 0 5px 5px 5px;
            float: left;
            &:after {
                border-width: 0 10px 10px 0;
                border-color: transparent $white transparent transparent;
                top: 0;
                left: -10px;
            }
            .metadata {
                padding: 0 0 0 16px;
            }
        }
    }
}
.fadeout {
    position: relative;
    bottom: 4em;
    height: 4em;
    background: -webkit-linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    background-image: -moz-linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    background-image: -o-linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    background-image: linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    background-image: -ms-linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
}
// whatsapp close
.checkbox-list-box {
    max-height: 150px;
}
.checkbox-list-modal {
    li {
        .checkboxContainer {
            padding: 8px 20px 8px 32px;
            .checkmark {
                left: 9px;
                top: 10px;
            }
        }
    }
}
// multi drag
.multi-drag-input {
    .k-listbox .k-list-scroller {
        border: 1px solid #e7e7e7;
        border-top: 0;
        .k-list {
            border: 0;
            li.k-item {
                border-bottom: 1px solid #e7e7e7;
                padding: 7px 10px;
                &:nth-child(odd) {
                    background-color: #fae9db;
                }
                // &:nth-child(2n + 1):hover {
                //     background-color: #722620;
                //     color: #fff;
                // }
                &.k-state-selected {
                    background-color: #722620;
                    color: $white;
                }
                &:last-child {
                    border-bottom: 0;
                }
            }
        }
    }
    h6.list-box-title {
        background-color: $grey-L100;
        border: 1px solid #e7e7e7;
        padding: 10px;
        font-size: $font-xsm;
        color: $default-color;
        margin: 0;
    }
}
.list-container {
    display: flex;
    justify-content: space-between;
    .list-column {
        width: 45%;
        .k-listbox-toolbar {
            position: absolute;
            right: -70px;
            top: 10%;
        }
    }
    .k-listbox.k-listbox-toolbar-right {
        position: relative;
        .k-button:focus,
        .k-button:hover {
            background-color: transparent;
            box-shadow: none;
        }
    }
}
.list-container .list-column .k-listbox-toolbar .k-reset .k-icon {
    width: 25px;
    height: 25px;
    @include image("icon-status.png", 0 0);
    &:hover {
        cursor: pointer;
    }
    &.k-icon {
        &:before {
            content: inherit;
        }
    }
    &.k-i-arrow-60-left {
        background-position: 0 -34px;
    }
    &.k-i-arrow-60-right {
        background-position: 0 0;
    }
}
// Data exchange > In App Analytics
.data-even-row {
    padding: 15px 25px 0 25px;
}
.attribute-box {
    border: 1px solid rgba(86, 75, 102, 0.26);
    display: inline-block;
    margin-right: 10px;
    width: 25px;
    height: 10px;
    background-color: rgba(157, 133, 194, 0.32);
}
// Kyc Preview 
.kycpreview-wrapper {
    border: 1px solid $border-color;
    margin-top: 15px;
    padding: 15px;
    border-top-color: $primary-color;
    margin-bottom: 20px;
}
ul.kyc-social-connect {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    li {
        margin: 0 30px 0 0;
        display: flex;
        &:last-child {
            margin-right: 0;
        }
        span {
            padding: 3px 10px;
            color: $white;
            background: $bg-color7;
            border-radius: 5px;
        }
    }
}
.bgFacebook {
    background-color: #3a5897 !important;
}
.bgTwitter {
    background-color: #5dc8ff !important;
}
.bgLinkedin {
    background-color: #2967ad !important;
}
.margin-R5 {
    margin-right: 5px !important;
}
.generate-wrapper {
    margin-bottom: 20px;
}
.nav-right {
    margin-bottom: 15px;
}
ul.right-nav {
    display: flex;
    align-items: center;
    justify-content: end;
    height: 42px;
    padding: 10px 0px;
    margin: -10px;
    position: relative;
}
.bgGrey-lighter {
    background-color: $grey-L100 !important;
}
.box-sm {
    padding: 10px;
}
.embed-wrapper {
    margin-bottom: 20px;
}
.choose-or-hr.bluebg {
    width: 95%;
    margin: 0 10px;
    min-height: 20px;
    position: relative;
    text-align: center;
    z-index: 1;
}
.choose-or-hr:before {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    top: 0;
    height: 20px;
    left: 0;
    z-index: -1;
    border-top: 1px solid $border-color;
}
.choose-or-hr *:first-child {
    width: 25px;
    height: 25px;
    margin-top: -12px;
    position: absolute;
    border-radius: 100%;
    top: 0;
    left: 50%;
    padding: 3px 0;
    font-size: $font-xs;
    line-height: 16px;
    background-color: $white;
    color: #7f7f7f;
    border: 1px solid $border-color;
}
.ta-height-2 {
    height: 250px;
}
// Subject line modal 
ul.analysis-list {
    padding: 15px 5px;
    li {
        color: $main-color;
        margin-bottom: 15px;
        font-size: $font-xsm;
        &:last-child {
            margin-bottom: 0;
        }
        b {
            color: #06c;
        }
        h2 {
            color: $main-color;
        }
    }
}
.border-left-1 {
    border-left: 1px solid $border-color;
}
.spam-scale img {
    width: 100%;
    transform: rotate(180deg);
}
.spam-scale-handle {
    position: absolute;
    width: 30px;
    height: 30px;
    background-color: #8a8a8a;
    text-align: center;
    border: 3px solid #f2f2f2;
    border-radius: 100%;
    top: 115px;
    padding: 0;
}
.status-multistatus .expand-plus .k-icon.k-i-plus::after {
    border-bottom-color: $multi-status
}
.tabs-vertical {
    background-color: $even;
}
// Kendo editor
.k-editor > .k-toolbar {
    .k-widget.k-dropdown {
        margin-bottom: 0;
    }
    button.k-button.k-button-icon:hover .k-icon {
        color: #fff;
    }
}
.k-button.k-state-selected,
.k-button-secondary.k-state-selected,
.k-button.k-state-selected:focus {
    background-color: $iconActive;
}
.k-widget.k-dropdown-button button.k-button.k-button-icon:hover {background: inherit;}
button.k-button {border-radius: 0;margin-right: 3px;}
button.k-button.k-button-icon:hover {background-color: $icon-color;}
button.k-button.k-state-selected.k-button-icon {background-color: $icon-color;}
.live-prev-search {
    position: absolute;
    right: 35px;
}
.grid-table-remove-icon {
    position: relative;
    top: 8px;
    margin-right: 8px;
}



// Audience list grid
.audience-list .k-grid .k-grid-header,
.audience-list .k-grid .k-grid-container,
.audience-list .k-grid .k-grid-header-wrap,
.audience-list .k-grid .k-grid-footer-wrap,
.audience-list .k-grid table,
.k-grid th, .k-grid td
{
    overflow: inherit !important;
}

.audience-list .k-grid .k-grid-header .btn-primary,
.audience-list .k-grid .k-grid-header .btn-primary:focus {
    background-color: transparent;
    border: 0;
}
.audience-list .k-grid .k-widget.k-dropdown {
    width: 150px;
}
.audience-list .k-grid .k-widget.k-dropdown .k-input {
    color: #fff;
}
.k-animation-container > .k-popup .k-list .k-item:hover {
    background-color: $primary-color;
}
.k-animation-container > .k-popup .k-list .k-item:hover p {
    color: #ffffff;
}
.audience-list .k-grid .k-grid-header .btn-primary svg path {
    fill: #fff;
}
.k-widget.k-switch.click-off {
    opacity: 0.6;
  }