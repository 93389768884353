.advanced-pricing {
  gap: 15px;
  position: relative;
  padding-right: 15px;
}
.advanced-pricing .first-pricing {
  background-color: #fff;
  z-index: 11;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border: 1px solid #c2cfe3;
}
.advanced-pricing .pricing-field {
  position: relative;
  z-index: 11;
}
.advanced-pricing .pricing-field .price-label {
  flex-direction: row;
}
.advanced-pricing .pricing-text.first-pricing .price-label {
  justify-content: flex-start;
  text-align: left;
}
.advanced-pricing .pricing-column.pricing-field.price-selected {
  border-color: #11a811;
  background-color: #11a81115;
}
.advanced-pricing .pricing-column.pricing-field.price-selected .price-label {
  border-bottom: 1px solid #c2cfe3;
}
.advanced-pricing .pricing-column.pricing-field.price-selected .price-label:last-child {
  border-bottom: 0;
}
.advanced-pricing .pricing-column {
  border-radius: $theme-radius-size;
  background-color: #fff;
  padding: 0;
  box-shadow: 0 4px 9px -2px rgba(0, 0, 0, 0.1215686275);
  border: 1px solid #c2cfe3;
}
.advanced-pricing .pricing-column .price-label {
  height: 70px;
  margin: 0;
  align-items: center;
  display: flex;
  justify-content: center;
  // font-size: 15px;
  padding-left: 15px;
  padding-right: 15px;
  border-bottom: 1px solid #e0dede;
  position: relative;
  text-align: center;
}
.advanced-pricing .pricing-column .price-label p,
.advanced-pricing .pricing-column .price-label .checkboxContainer {
  margin-bottom: 0;
}
.advanced-pricing .pricing-column .price-label:last-child {
  border-bottom: 0;
}
.advanced-pricing.advanced-price-middle .pricing-column {
  border-radius: $theme-radius-size;
}
.advanced-pricing.advanced-price-middle.advanced-price-features .pricing-column {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.advanced-pricing.advanced-price-middle.advanced-price-features .pricing-field {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.advanced-pricing .pricing-column.pricing-field.price-disable {
  pointer-events: none;
}
.advanced-pricing-button {
  gap: 15px;
  text-align: center;
  padding-right: 15px;
}
.advanced-pricing-button.ad-head-mid .rsp-btn-select {
  border: 0;
  background: none;
  box-shadow: none;
}
.advanced-pricing-button.ad-head-mid .rsp-btn-select h3 {
  color:  var(--th-header-color);
}
.advanced-pricing-button .rsp-btn-select {
  text-align: center;
  border-radius: $theme-radius-size;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  color: #333;
  background: #f0f8ff;
  position: relative;
  box-shadow: 0 4px 9px -2px #0000001f;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #c2cfe3;
  border-bottom: 0;
  padding-left: 15px;
  padding-right: 15px;
}
.advanced-pricing-button .rsp-btn-select.price-selected {
  border-color: var(--th-primary-color);
  background-color: var(--th-primary-color);
}
.advanced-pricing-button .rsp-btn-select .rsp-btn-label {
  padding: 15px 0;
  flex: 1;
}
.advanced-pricing-button .rsp-btn-select .rsp-btn-label h3 {
  color: var(--th-header-color);
}
.advanced-pricing-button .rsp-btn-select .rsp-btn-label p,
.advanced-pricing-button .rsp-btn-select .rsp-btn-label h3,
.advanced-pricing-button .rsp-btn-select .rsp-btn-label h4 {
  margin: 0;
}
.advanced-pricing-button .rsp-btn-select .rsp-btn-label p,
.advanced-pricing-button .rsp-btn-select.price-selected .rsp-btn-label h3,
.advanced-pricing-button .rsp-btn-select .rsp-btn-label h4,
.advanced-pricing-button .rsp-btn-select .rsp-btn-label span {
  color: #fff;
}
.advanced-pricing-button .rsp-btn-select.price-disable .rsp-btn-label p,
.advanced-pricing-button .rsp-btn-select.price-disable .rsp-btn-label h3,
.advanced-pricing-button .rsp-btn-select.price-disable .rsp-btn-label h4,
.advanced-pricing-button .rsp-btn-select.price-disable .rsp-btn-label span {
  color: #333;
}
.advanced-pricing-button .rsp-btn-select .rsp-btn-label p {
  margin-bottom: 5px;
}
@media (max-width:1366px) {
  .advanced-pricing-button .rsp-btn-select.price-selected .rsp-btn-label h3, .advanced-pricing-button .rsp-btn-select.price-disable .rsp-btn-label .font-medium {
      font-size: 18px !important;
  }
}
.advanced-pricing-button .rsp-btn-select.price-selected .choose-plan {
  border-color: #ffffff;
  background-color: #ffffff;
  box-shadow: 0 4px 9px -2px #0000001f !important;
}
.advanced-pricing-button.ad-price-footer .rsp-btn-select {
  border-radius: $theme-radius-size;
  background-color: transparent;
  border: 0;
  box-shadow: none;
}
.advanced-pricing-button.ad-price-footer .rsp-btn-select.price-selected .choose-plan {
  border-color: #11a811;
  background-color: #11a811;
  color: #fff !important;;
}
.advanced-pricing-button.ad-price-footer .rsp-btn-select.price-disable .choose-plan {
  color: #333;
  background-color: #fff;
}
.choose-plan {
  border-radius: $theme-radius-size;
  padding: 5px 15px;
  display: inline-block;
  margin-top: 10px;
  color: #333 !important;
  border: 1px solid #c2cfe3;
}
.choose-plan:hover {
  cursor: pointer;
}
.rsp-btn-heading {
  text-align: left;
  font-size: 21px;
  padding: 15px;
  margin: 10px 0;
}
.summary-cost-body-wrapper {
  border-bottom: 1px solid #b8b8b8;
  height: calc(100% - 10px);
}
.summary-cost-body {
  height: calc(100% - 200px);
  padding-top: 0;
  padding-right: 15px;
  @extend %scrollbar;
  margin-right: -15px;
  .summary-cost-list .cost-row-component {
    &:first-child {
      padding-top: 15px;
    }
    &:last-child {
      border-bottom: 1px solid $border-color;
      padding-bottom: 15px;
    }
  }
}
.advanced-pricing-button .rsp-btn-select.plain-bg {
  background-color: #ffffff;
  // box-shadow: none;
  // border: 0;
}
.advanced-pricing.advanced-price-middle.advanced-price-features .pricing-column.first-pricing {
  border-radius: 0 0 5px 5px;
}
.promo-code-btn {
  padding: 6px 0;
  border-radius: 5px;
  font-size: 16px;
  width: 70px;
  text-align: right;
}
.cost-footer-fixed {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  padding: 0 23px 10px;
  box-shadow: 0px 6px 9px 3px #00000054;
}
.advanced-pricing .pricing-column .price-label .rsp-icon {
  width: 24px;
  height: 24px;
}
.advanced-pricing .pricing-column .price-label .rsp-icon i.icon-sd-tick-medium {
  font-size: 18px !important;
}
.advanced-pricing .pricing-column .price-label .rsp-icon i.icon-sd-close-mini {
  font-size: 12px !important;
}


// cost add remove
.rs-add-remove-btn {
  display: flex;
  align-items: center;
}
.rs-add-remove-btn .rs-ar-btn {
  width: 20px;
  height: 20px;
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 15px;
}
.rs-add-remove-btn .rs-ar-sel {
  width: 32px;
  // border-radius: 8px;
  text-align: center;
}
.price-amount {
  width: 60px;
  text-align: right;
}
.price-calc {
  display: flex;
}


.advanced-pricing .pricing-column.pricing-field.price-disable .price-label input,
.advanced-pricing .pricing-column.pricing-field.price-disable .price-label span {
  // display: none;
  pointer-events: none;
}
.advanced-pricing .pricing-column.pricing-field.price-disable .price-label .checkboxContainer {
  // padding: 0;
}
.selected-price-list {
  display: flex;
  justify-content: center;
}
.advanced-pricing-button.start-panel .rsp-btn-select.price-selected .selected-price-list {
  height: 26px;
  transition-property: height;
  transition-duration: 0.3s;
  &::before {
    content: inherit;
  }
}
.advanced-pricing-button.start-panel .rsp-btn-select .selected-price-list {
  display: block;
  position: absolute;
  top: 1px;
  right: 0px;
  &:before {
    content: '';
    position: absolute;
    top: 4px;
    right: 4px;
    width: 20px;
    height: 20px;
    border: 1px solid #c2cfe3;
    background: #fff;
    border-radius: 100%;
  }
}
// .advanced-pricing-button.start-panel .rsp-btn-select {
//   height: 115px;
// }
.coupon-input {
  width: 80%;
  position: relative;
  top: -1px;
  left: -1px;
}
.promo .coupon-input div input {
  width: 100%;
}
.ad-price-footer .selected-price-list .rsp-icon {
  background-color: $primary-green;
  width: 33px;
  height: 33px;
}
.advanced-pricing .price-label .checkboxContainer .checkmark:after {
  left: 0.4px;
  // top: 2.2px;
}
