// popover
.popover {
    z-index: 50;
    border: 0;
    &[class*="bs-popover-"] {
        background-color: $ch_tooltipBgColor;
        color: $white;
        padding: 5px;
        z-index: $pophover-tooltip;
        @include br($theme-radius-size);
        @extend %tooltip-shadow;
        .popover-body {
            color: $white;
            font-size: $font-xsm;
            font-family: $rs-font-regular;
            max-height: 300px;
            overflow: hidden;
            // @extend %tooltip-shadow;
              small {
                font-size: $font-xxs;
                line-height: 18px;
                display: inline-block;
            }
        }
    }
}
.bs-popover-top > .popover-arrow,
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow {
    bottom: calc(-0.5rem - -1px);
}
.bs-popover-auto[x-placement^=top]>.arrow,
.bs-popover-top>.arrow {
    // bottom: calc((.5rem + 0) * -1);
}
.bs-popover-auto[x-placement^=bottom]>.arrow,
.bs-popover-bottom>.arrow {
    // top: calc((.5rem + 0) * -1);
}
[class*="col-md"],
[class~="col-lg"] {
    position: relative;
}
.bs-popover-auto[x-placement^=top]>.arrow::after,
.bs-popover-top>.arrow::after {
    border-top-color: $ch_tooltipBgColor;
}
.bs-popover-auto[x-placement^=bottom]>.arrow::after,
.bs-popover-bottom>.arrow::after {
    border-bottom-color: $ch_tooltipBgColor;
}
.popover[class*=bs-popover-], .k-tooltip, .tooltip .tooltip-inner {
    text-shadow: none;
}
