span.switch-label {
    font-weight: normal;
}

/* boostrap switch */
label {
    font-weight: inherit;
}
input[type=checkbox], input[type=radio] {
    margin: 3px 0 0;
    width: auto;
    line-height: normal;
      -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
}


// form generator
.img-icon {
    width: 54px;
    height: 41px;
    display: inline-block;
    margin: 0 auto;
    &.my-icon-text { @include image("icons/icon-text.png", center) }
    &.my-icon-textbox { @include image("icons/icon-textfield.png", center) }
    &.my-icon-combobox { @include image("icons/icon-combobox.png", center) }
    &.my-icon-radiobtn { @include image("icons/icon-radio.png", center) }
    &.my-icon-checkbox { @include image("icons/icon-checkbox.png", center) }
    &.my-icon-upload { @include image("icons/icon-upload-01.png", center) }
    &.my-icon-date { @include image("icons/icon-dateTime.png", center) }
    &.my-icon-participants { @include image("icons/add-participants.png", center) }
}
.react-form-builder > .row {
  display: grid;
  .react-form-builder-preview {
    width: 100%;
    min-height: 350px;
    order: 2;
    & > div {
      outline: 1px solid #e9e9eb;
      max-width: 960px;
      margin: 20px auto;
      padding: 20px;
    }
  }
  .react-form-builder-toolbar {
    order: 1;
  }
}
.react-form-builder {
  .react-form-builder-toolbar {
    margin-top: 0px;
    width: 100%;
    padding: 0px;
    background-color: $bg-color1;
    position: relative;
    h4 {
      display: none;
    }
    ul {
      justify-content: center;
      display: flex;
      li {
        display: flex;
        flex-direction: column;
        text-align: center;
        border: 0;
        font-size: $font-xs;
        margin: 15px 15px;
        cursor: grab;
        i {
          margin-bottom: 5px;
        }
      }
    }
  }
  .react-form-builder-preview {
    .Sortable {
      cursor: default;
      > div {
        outline: 1px dashed $border-color;
        position: relative;
        margin-bottom: 18px;
      }
      .label-row {
        border: 1px dashed $border-color;
        padding: 2px 9px 2px 0;
        font-size: $font-xs;
        color: #666;
        width: 100%;
        margin-top: 7px;
      }
      .checkbox-list {
        display: flex;
        align-items: center;
      }
      .checkbox-list li {
        margin-left: 10px;
      }
      .checkbox-list li:first-child {
        margin-left: 0;
      }
      .form-place-holder {
        width: 960px;
        margin: 0 auto;
      }
      .btn-file {
        width: 145px;
        margin-left: 15px;
      }
      .toolbar-header {
        height: 0;
        .badge {
          opacity: 0;
        }
      }
      .form-label {
        display: none;
      }
      label {
        cursor: pointer !important;
      }
      input {
        pointer-events: inherit !important;
        cursor: text !important;
        &.upload-input {
          background-color: $bg-color1 !important;
          cursor: not-allowed !important;
          pointer-events: none !important;
        }
      }
      .editable-input {
        font-size: $font-xs;
        color: #666;
        padding: 0;
        background: none !important;
        box-shadow: none;
        outline: 1px dashed $border-color;
        &:hover, &:focus {
          background: none !important;
        }
      }
      .rfb-item {
        &:hover {
          &, .editable-input {
            box-shadow: none;
            outline: 3px solid #0b7cc1;
          }
          .editable-input:focus {
            outline: 2px dashed #6FD99C !important;
          }
        }
        .editable-input:focus {
          outline: 2px dashed #6FD99C !important;
        }
        &.SortableItem {
          &:hover .toolbar-header-buttons {
            display: block !important;
          }
          .form-group {
            pointer-events: inherit;
          }
        }
        .toolbar-header .toolbar-header-buttons {
          position: inherit;
        }
      }
    }
  }
}
.toolbar-header-buttons {
  display: none;
  .btn.is-isolated {
    padding: 0;
    &:nth-child(1) {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateY(-45%);
      z-index: 10;
      i {
        width: 50px;
        height: 50px;
      }
    }
    &:nth-child(2) {
      position: absolute;
      right: -15px;
      top: -15px;
      // display: none;
    }
    &:nth-child(3) {
      position: absolute;
      z-index: 10;
      left: -14px;
      top: -14px;
      i {
        width: 23px;
        height: 23px;
        display: inline-block;
      }
    }
    i {
      width: 26px;
      height: 26px;
      display: inline-block;
    }
    // &:last-child { display: none; }
    .is-isolated {
      &.fas.fa-edit {
        @include image("icons/icon-temp-edit-large.png", center);
        background-size: 100%;
        display: none;
      }
      &.fas.fa-trash {
        @include image("icons/icon-temp-delete.png", center)
      }
      &.fas.fa-arrows-alt {
        @include image("icons/icon-temp-move.png", center);
        background-size: 100%;
        // display: none;
      }
      &.fas.fa-grip-vertical {
        @include image("icons/icon-text.png", center);
        display: none;
      }
    }
  }
}
.sp-tool-pophover {
  background-color: #fff;
  position: absolute;
  width: 280px;
  padding: 10px;
  border: 1px solid #b8b8b8;
  z-index: 1;
  .k-button-group {
    margin: 0 10px 8px 0;
    border: 1px solid #b8b8b8;
    .k-button.k-button-icon {
      border-right: 1px solid #b8b8b8;
      width: 28px !important;
      height: 28px !important;
      // &:hover { background-color: #d2d2d2; }
      &.k-group-start {
        border-right: 1px solid #b8b8b8;
      }
      &:last-child {
        border-right: 0;
      }
    }
    .k-widget.k-colorpicker {
      border-right: 1px solid #b8b8b8;
      &:last-child {
        border-right: 0;
      }
      .k-picker-wrap {
        box-shadow: none;
        border: 0;
        height: 28px;
        align-items: center;
        padding: 0;
        &:before, &:after {
          content: inherit;
        }
        .k-select {
          padding: 0 !important;
          width: 12px;
          border: 0;
          position: absolute;
          right: 3px;
          top: 6px;
        }
        .k-tool-icon {
          &.k-icon {
            padding-right: 12px;
            width: auto;
          }
          .k-selected-color {
            width: 16px;
            margin-top: -2px;
            position: relative;
            top: 2px;
            left: 0px;
          }
        }
      }
    }
    .k-widget.k-dropdown {
      padding: 0 7px;
      margin: 0;
      .k-dropdown-wrap {
        height: 26px;
        font-size: $font-xs;
        margin-top: 4px;
        .k-input {
          height: 26px;
          font-size: $font-xs;
        }
      }
    }
    .k-group-start, .k-button:first-child { border-radius: 0; }
    .k-button.k-state-selected.k-button-icon:hover, .k-button.k-state-selected.k-button-icon:focus { background: $primary-color; }
    .k-group-end, .k-button:last-child, .k-group-start, .k-button:first-child { border-radius: 0; }
  }
}


// kendo popover
.k-dialog-wrapper .k-dialog .k-dialog-titlebar {
  background-color: #fff;
  color: #333;
  border-color: $primary-color !important;
  padding: 0 0 13px;
}
.k-widget.k-window.k-dialog {
  border: 6px solid #e9e9eb;
  padding: 15px 20px 20px;
  border-radius: 0.3rem;
}
.k-widget.k-window.k-dialog .k-window-title.k-dialog-title {
  font-size: 26px;
  color: $primary-color;
}
.k-widget.k-window.k-dialog .k-dialog-buttongroup {
  border: 0;
}
.k-widget.k-window.k-dialog .k-window-content, .k-prompt-container {
  padding: 16px 0 0;
}
.k-widget.k-window.k-dialog .k-dialog-buttongroup {
  padding: 17px 10px;
  margin: 0 -13px;
  button.k-button:hover {
    text-decoration: underline;
  }
  button.k-button.k-primary:hover {
    text-decoration: none;
  }
}
.k-widget.k-window.k-dialog .k-button.k-primary {
  border-color: $primary-color !important;
  background-color: $tab-color !important;
  line-height: 23px;
  border: 0;
  padding: 9px 20px;
  width: auto;
  border-radius: 5px;
}
.k-widget.k-window.k-dialog .k-button.k-primary:hover {
  box-shadow: 0 0px 15px $primary-color;
}
.k-widget.k-window.k-dialog .k-dialog-buttongroup {
  display: flex;
  flex: inherit;
  display: flex;
  flex-flow: inherit;
  align-items: flex-end;
  grid-gap: inherit;
  gap: inherit;
  justify-content: flex-end;
  clear: inherit;
  flex-direction: row;
}
.k-widget.k-window.k-dialog .k-dialog-buttongroup .k-button {
  flex: inherit;
  font-size: $font-xsm;
  font-family: "MuktaLight",sans-serif;
  letter-spacing: 0.5px;
}
.k-widget.k-window.k-dialog button.k-button:hover, button.k-button:focus {
  background-color: transparent;
  box-shadow: none;
}
.k-textbox {
  border: 0;
}
.k-textbox:focus {
  box-shadow: none;
}

.k-window-actions.k-dialog-actions:hover .k-button:before {
  background-color: transparent;
}
.k-window-actions.k-dialog-actions .k-button:active,
.k-window-actions.k-dialog-actions .k-button:after {
  box-shadow: none !important;
  outline: none !important;
}


// template builder
.template-builder-container {
  position: relative;
  z-index: 1002;
}

.k-window-content.k-dialog-content {
  .k-checkbox{
    height: 16px;
    width: 16px;
    background-color: #ffffff;
    border: 1px solid #b8b8b8;
  }
}

.k-edit-form-container {
  .k-checkbox:checked,
  .k-checkbox.k-checked {
    border-color: #ff6358;
    color: white;
    background-color: #ff6358;
  }
}
.k-window-content.k-dialog-content {
  .k-checkbox:checked,
  .k-checkbox.k-checked {
    border-color: #b8b8b8 !important;
    color: white!important;
    background-color: #ffffff !important;
  }
  .k-checkbox {
    background: none !important;
    margin-top: -2px;
    margin-right: 4px;
    border-radius: 0;
  }
  .k-checkbox:checked::before {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAWCAMAAAC13D+jAAAASFBMVEUAAAC4uLi4uLi4uLgZq4QZq4QZq4QZq4Rvsp+4uLgZq4S4uLi4uLgZq4S4uLgZq4S4uLgZq4QZq4S4uLgZq4S4uLgAj5QZq4T6oQ3ZAAAAFXRSTlMAjDXh4b+JMwz8/MC9k3V1XV05GxvIpD5cAAAAgElEQVQ4y+2SyQ6FIAxFEac3Ol/6/38qmksMktgFGxee1Q3pCdDWBJyJQQhNVbchF+XXHoaLHYDOKCIVDwcApWqYRjwLL4Fn9tUnAzTI9BGpme0b+O3VsQHQIO2/f4Vsi27LbiN6E40U/ecpF/19yCW/0/o88w1tk1PUTU657yav7QQLQpLz1mAAAAAASUVORK5CYII=) !important;
    background-position: 2px 3px;
    background-repeat: no-repeat !important;
    height: 14px;
    left: 2px;
    top: -5px;
    width: 15px;
    transform: none !important;
    font-family: inherit;
    font-size: 0;
    content: '';
    display: block;
  }
  .k-checkbox:focus {
    background-color: none !important;
  }
}
