// slider
.carousel-indicators {
    [data-bs-target] {
        background-color: #c2cfe3;
        border: 1px solid #c2cfe3;
        padding: 0;
        width: 10px;
        height: 10px;
        // border-radius: $theme-radius-size;
        border-radius: 50%;
    }
    .active {
        background: $primary-color;
        border: 0;
        width: 12px;
        height: 12px;
    }
}