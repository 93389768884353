@use "sass:meta";

:root {
    --th-header-color:         #4a051b;
    --th-primary-color:        #8c2800; //#4a051b;
    --th-secondary-color:      #ed4f00; //#fc6a00;
    --th-table-color:          #722620;
    --th-even-color:           #fae9db;
    --th-tooltip-color:        #212224; //#4a051b;
    --th-button-color:         #ce7800; //#722620;
}
.bg-th-header { background-color: var(--th-header-color); }
.bg-th-primary { background-color: var(--th-primary-color); }
.bg-th-secondary { background-color: var(--th-secondary-color); }
.bg-th-table { background-color: var(--th-table-color); }
.bg-th-even { background-color: var(--th-even-color); }
.bg-th-tooltip { background-color: var(--th-tooltip-color); }
.bg-th-button { background-color: var(--th-button-color); }

.color-th-header { color: var(--th-header-color); }
.color-th-primary { color: var(--th-primary-color); }
.color-th-secondary { color: var(--th-secondary-color); }
.color-th-table { color: var(--th-table-color); }
.color-th-even { color: var(--th-even-color); }
.color-th-tooltip { color: var(--th-tooltip-color); }
.color-th-button { color: var(--th-button-color); }

.th-header-fill-color { fill: var(--th-header-color); }
.th-primary-fill-color { fill: var(--th-primary-color); }
.th-secondary-fill-color { fill: var(--th-secondary-color); }
.th-table-fill-color { fill: var(--th-table-color); }
.th-even-fill-color { fill: var(--th-even-color); }
.th-tooltip-fill-color { fill: var(--th-tooltip-color); }
.th-button-fill-color { fill: var(--th-button-color); }

.th-primary-fill-disable-color { fill: #999999; }


$theme-default: (
    header:         #4a051b,
    primary:        #8c2800, //#4a051b,
    secondary:      #ed4f00, //#fc6a00,
    table:          #722620, //#722620,
    even:           #f6e6db, //#fae9db,
    tooltip:        #212224, //#4a051b,
    button:         #ce7800, //#722620,
);
$theme-earthy: (
    header:         #bb9098, //#659dbd,
    primary:        #ecc2a7, //#8d8741,
    secondary:      #99aab2, //#8d8741,
    table:          #dd7e69, //#bc986a,
    even:           #f8e2d5, //#fbeec1,
    tooltip:        #0a2c45, //#8d8741,
    button:         #3a7c93, //#8d8741,
);
$theme-coolfresh: (
    header:         #0f6fc6, //#379683,
    primary:        #009dd9, //#05386b,
    secondary:      #09d0d9, //#05386b,
    table:          #7cca61, //#05386b,
    even:           #dbeff9, //#edf5e1,
    tooltip:        #17406d, //#5cdb95,
    button:         #0ecf9b, //#05386b,
);
$theme-striking: (
    header:         #4e67c8, //#0b0c10,
    primary:        #5dccf3, //#45a29e,
    secondary:      #f14123, //#45a29e,
    table:          #ff8021, //#45a29e,
    even:           #b4dcfa, //#ededed,
    tooltip:        #212745, //#1f2833,
    button:         #a7ea53, //#1f2833,
);
$theme-yetwarm: (
    header:         #4a66ac, //#8e8d8a,
    primary:        #297fd5, //#e85a4f,
    secondary:      #619ed2, //#45a29e,
    table:          #59a2ae, //#45a29e,
    even:           #accbf9, //#ededed,
    tooltip:        #242852, //#8e8d8a,
    button:         #7f90a9, //#8e8d8a,
);
$theme-sleek: (
    header:         #775f56, //#0f292f,
    primary:        #dd7f47, //#cb2d6f,
    secondary:      #d8b25c, //#14a098,
    table:          #94b6d2, //#cb2d6f,
    even:           #ebdec3, //#cccccc,
    tooltip:        #a5ab81, //#501f3a,
    button:         #7ba79d, //#14a098,
);
$theme-glamorous: (
    header:         #97677c, //#806543,
    primary:        #e88d83, //#542f34,
    secondary:      #b48666, //#542f34,
    table:          #aba19e, //#542f34,
    even:           #fcc9c6, //#fbd9e7,
    tooltip:        #00535f, //#111111,
    button:         #4b9fc3, //#33266e,
);
$theme-aquagreens: (
    header:         #455f51, //#107050,
    primary:        #009676, //#02231c,
    secondary:      #4ab5c4, //#02231c,
    table:          #c0cf3a, //#107050,
    even:           #e3ded1, //#c7f6ec,
    tooltip:        #549e39, //#107050,
    button:         #8bb832, //#02231c
);

$theme-names: default, earthy, coolfresh, striking, yetwarm, sleek, glamorous, aquagreens;

body[data-theme='default'] { @each $key, $color in $theme-default { --th-#{$key}-color: #{$color} } }
body[data-theme='earthy'] { @each $key, $color in $theme-earthy { --th-#{$key}-color: #{$color} } }
body[data-theme='coolfresh'] { @each $key, $color in $theme-coolfresh { --th-#{$key}-color: #{$color} } }
body[data-theme='striking'] { @each $key, $color in $theme-striking { --th-#{$key}-color: #{$color} } }
body[data-theme='yetwarm'] { @each $key, $color in $theme-yetwarm { --th-#{$key}-color: #{$color} } }
body[data-theme='sleek'] { @each $key, $color in $theme-sleek { --th-#{$key}-color: #{$color} } }
body[data-theme='glamorous'] { @each $key, $color in $theme-glamorous { --th-#{$key}-color: #{$color} } }
body[data-theme='aquagreens'] { @each $key, $color in $theme-aquagreens { --th-#{$key}-color: #{$color} } }

@each $key, $color in $theme-default { .theme-default-#{$key}  { background-color: $color; } }
@each $key, $color in $theme-earthy { .theme-earthy-#{$key}  { background-color: $color; } }
@each $key, $color in $theme-coolfresh { .theme-coolfresh-#{$key}  { background-color: $color; } }
@each $key, $color in $theme-striking { .theme-striking-#{$key}  { background-color: $color; } }
@each $key, $color in $theme-yetwarm { .theme-yetwarm-#{$key}  { background-color: $color; } }
@each $key, $color in $theme-sleek { .theme-sleek-#{$key}  { background-color: $color; } }
@each $key, $color in $theme-glamorous { .theme-glamorous-#{$key}  { background-color: $color; } }
@each $key, $color in $theme-aquagreens { .theme-aquagreens-#{$key}  { background-color: $color; } }