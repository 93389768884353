.howerIcon {
    position: relative;
    display: none;
    &:hover {
        display: block;
    }
    .icons-email-footer {
        position: absolute;
    }
}
.overlay-button  {
    height: 333px !important;
    &:hover ul  {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background: rgba(6,6,6,0.4);
        text-align: center;
        color: #fff;
        display: block;
     li {
        padding-top: 122px;
        &:first-child {
            padding-top: 40px;
        }
        button.rsp-primary-button{
            padding: 7px 14px;
            font-size: $font-xs;
        }
     }
       
    }
    ul {
      display: none;
        } 
}
button.hover_button {
    padding: 7px 14px;
    font-size: $font-xs;
}

.overlay-card-inner ul li:first-child,
.overlay-card-inner ul li:last-child {
    margin-top: 20px;
    transition: 0.3s all ease;
}
.overlay-card-inner:hover ul li:first-child,
.overlay-card-inner:hover ul li:last-child {
    margin-top: 0;
}
.overlay-card-inner:hover ul li:last-child {
    margin-top: -20px;
}
