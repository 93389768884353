.btn:first-child:active {
    background-color: #fff;
    border: 0 solid var(--th-primary-color);
}
.btn.active:first-child:active {
    background-color: var(--th-secondary-color);
    border: 0 solid var(--th-secondary-color);
}
.btn:first-child:active:focus-visible,
.btn.active:first-child:active:focus-visible {
    box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.329411764);
}