.footer-cloud {
    display: flex !important;
    align-items: center;
}
.footer-cloud:before {
    background-color: white;
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    right: 0;
    height: 57px;
}
.footer-cloud:after {
    background-image: url('../Assets/Images/cloud.png');
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    right: 0;
    height: 107px;
}
.footer-cloud .page-header {
    margin-top: 0;
}
.not-found-desc {
    display: flex;
    align-items: center;
    color: #fff;
    position: relative;
}
.not-found-desc h1,
.not-found-desc h3 {
    color: #4a051b;
}
.not-found-desc h1 {
    font-size: 48px;
    margin-bottom: 20px;
}
.not-found-desc h1 span {
    font-size: 34px;
}
.not-found-desc p {
    margin-bottom: 20px;
}
.not-found-desc h3 {
    margin-bottom: 30px;
}
.not-found-desc:before {
    content: "";
    position: absolute;
    left: 0;
    margin-left: -30px;
    top: 24%;
    height: 56%;
    border-left: 2px solid #e9e9eb;
}