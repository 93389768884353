%verticalCenter {
  vertical-align: middle;
  display: table-cell;
  text-align: center;
}

%vertical-center {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  align-items: center;
}

%over-h {
  overflow: hidden;
}

%pre {
  white-space: pre;
}

%boxOver {
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
  border-color: rgba(0, 0, 0, 0.15);
}

%boxshadow {
  border: 1px solid $border-color;
  // box-shadow: 0 0.46875rem 2.1875rem rgba(4, 9, 20, 0.03), 0 0.9375rem 1.40625rem rgba(4, 9, 20, 0.03), 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05), 0 0.125rem 0.1875rem rgba(4, 9, 20, 0.03);
  // border-width: 0;
  // transition: all .2s;
  // &:hover {
  //   box-shadow: 0 0.66875rem 2.3875rem rgba(4, 9, 20, 0.03), 0 1.1375rem 1.60625rem rgba(4, 9, 20, 0.03), 0 0.45rem 0.73125rem rgba(4, 9, 20, 0.05), 0 0.325rem 0.3875rem rgba(4, 9, 20, 0.03);
  // }
}

%button {
  &.btn.btn-success {
    background-color: $button-active;
    border-color: $button-active;

    &:hover {
      background-color: $button-active-hover;
      border-color: $button-active-hover;
    }
  }

  &.btn.btn-default {
    background-color: $button-active;
    border-color: $button-active;

    &:hover {
      background-color: $button-active-hover;
      border-color: $button-active-hover;
    }
  }
}

%center-div {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

%plusLine {

  &:before,
  &:after {
    content: '';
    position: absolute;
    background-color: #cecece;
    right: 0;
  }

  &:nth-child(1):before {
    bottom: 0;
  }

  &:before {
    width: 1px;
    height: 80%;
  }

  &:after {
    width: 80%;
    height: 1px;
    bottom: 0;
  }

  &:nth-child(even):after {
    left: 0;
    right: inherit;
  }

  &:nth-child(even):before {
    display: none;
  }
}

// platform
%scrollbarhori {
  overflow-y: auto !important;
  overflow-x: hidden;
  scrollbar-color: #b8b8b8 #f7f7f7;
  scrollbar-width: thin;
  cursor: default;

  &::-webkit-scrollbar-track {
    background-color: #f7f7f7;
  }

  &::-webkit-scrollbar {
    height: 7px;
    background-color: #f7f7f7;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #b8b8b8;
    border-radius: 100px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
  }
}

%scrollbar {
  overflow-y: auto !important;
  overflow-x: hidden;
  cursor: default;
  scrollbar-width: thin;
  @-moz-document url-prefix() {
    scrollbar-color: $secondary-grey $tertiary-grey;
  }
  @-moz-document url-prefix() {
      scrollbar-width: thin;
  }
  &::-webkit-scrollbar-track {
      background-color: $tertiary-grey;
      border-radius: 0 0 5px 5px;
  }
  &::-webkit-scrollbar {
      width: 7px;
      background-color: $tertiary-grey;
  }
  &::-webkit-scrollbar-thumb {
      background-color: $secondary-grey;
      border-radius: 100px;
      -webkit-border-radius: 100px;
      -moz-border-radius: 100px;
      cursor: pointer;
  }
}

%mainScrollbar {
  overflow-y: auto !important;
  overflow-x: hidden;
  @-moz-document url-prefix() {
    scrollbar-color: $secondary-grey $tertiary-grey;
}
@-moz-document url-prefix() {
    scrollbar-width: thin;
}
  cursor: default;

&::-webkit-scrollbar-track {
    background-color: $tertiary-grey;
    border-radius: 0 0 5px 5px;
}
&::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    background-color: $tertiary-grey;
}
&::-webkit-scrollbar-thumb {
    background-color: $secondary-grey;
    border-radius: 100px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    cursor: pointer;
}

}


%dropdown-arrow-grey {
  border-top: 8px solid transparent;
  border-left: 4px solid transparent;
  border-bottom: 6px solid $input-border;
  border-right: 6px solid $input-border;
}

%dropdown-arrow-color {
  border-top: 8px solid transparent;
  border-left: 4px solid transparent;
  border-bottom: 6px solid $input-border;
  border-right: 6px solid $input-border;
}

%animate {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

%status-color {
  &.status-progress {
    background-color: $inprogress;
  }

  &.status-rejected {
    background-color: $rejected;
  }

  &.status-completed {
    background-color: $completed;
  }

  &.status-scheduled {
    background-color: $scheduled;
  }

  &.status-alert {
    background-color: $alert;
  }

  &.status-draft {
    background-color: $draft;
  }

  &.status-multistatus {
    background-color: $multi-status;

    .panelbar-wrapper {
      border-left-color: $multi-status;
    }
  }
}

%calendarUI {
  font-family: 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $primary-color !important;
  font-size: $font-md;
  background-image: none;

  &:before {
    content: "\e90a";
    opacity: 1;
  }
}

%arrow-top-right {
  position: relative;

  // border-left: 1px solid $border-color;
  &:before,
  &:after {
    content: '';
    position: absolute;
    transform: rotate(45deg);
    top: -3px;
  }

  &:before {
    right: 13px;
    width: 10px;
    height: 10px;
    background-color: white;
    z-index: -1;
  }

  &:after {
    right: 10px;
    width: 15px;
    height: 14px;
    background-color: #b8b8b8;
    z-index: -2;
  }
}

%arrow-top-left {
  position: relative;

  &:before,
  &:after {
    content: '';
    position: absolute;
    transform: rotate(45deg);
    top: -3px;
  }

  &:before {
    left: 12px;
    width: 10px;
    height: 10px;
    background-color: white;
    z-index: -1;
  }

  &:after {
    left: 10px;
    width: 15px;
    height: 14px;
    background-color: #b8b8b8;
    z-index: -2;
  }
}

%new-theme-shadow {
  border: 1px solid #c2cfe3 !important;
  border-radius: $theme-radius-size !important;
  // overflow: hidden;
  transition: 0.3s all ease;

  &:hover {
    box-shadow: 0px 4px 9px -2px #0000001f;
    // -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.05), 0 0 5px 0 rgba(0, 0, 0, 0.05);
    // box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.05), 0 0 5px 0 rgba(0, 0, 0, 0.05);
  }
}

%new-theme-box-shadow {
  box-shadow: 0px 4px 9px -2px #0000001f;
}

%tooltip-shadow {
  text-shadow: 0px 1px 3px #00000080;
  box-shadow: 0px 1px 4px 0px #00000054;
}

%required {
  @include inputBorder($input-border, $input-border, $maroon-200);

  &:hover {
    @include inputBorder($input-border, $input-border, $maroon-200);
  }

  &:focus {
    @include inputBorder($primary-color, $primary-color, $maroon-200);
  }
}