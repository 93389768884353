::selection {
  background-color: $primary-color !important;
  color: $white;
}

a:not([href]):not([tabindex]) {
  font-size: $font-xsm;
  text-decoration: none;
}

.w100p {
  flex: 1;
}

.theme-space-m {
  margin: $theme-space;
}

.theme-space-mb {
  margin-bottom: $theme-space !important;
}

.theme-space-mt {
  margin-top: $theme-space !important;
}

.theme-space-mr {
  margin-right: $theme-space;
}

.theme-space-ml {
  margin-left: $theme-space;
}

.theme-space-mx {
  margin-left: $theme-space !important;
  margin-right: $theme-space !important;
}

.theme-space-p {
  padding: $theme-space;
}

.theme-space-pb {
  padding-bottom: $theme-space;
}

.theme-space-pt {
  padding-top: $theme-space;
}

.theme-space-pr {
  padding-right: $theme-space;
}

.theme-space-pl {
  padding-left: $theme-space;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

.font-light {
  #{$ff}: $rs-font-light
}

.font-medium {
  #{$ff}: $rs-font-medium
}

.font-regular {
  #{$ff}: $rs-font-regular
}

.font-semi-bold {
  #{$ff}: $rs-font-semi-bold
}

.font-bold {
  #{$ff}: $rs-font-bold
}

.fontL {
  font-family: $rs-font-light
}

.fontL-f {
  font-family: $rs-font-light !important
}

.fontR {
  font-family: $rs-font-regular
}

.fontM {
  font-family: $rs-font-medium
}

.fontB {
  font-family: $rs-font-bold
}

.fontB-f {
  font-family: $rs-font-bold !important
}

.fontSB {
  font-family: $rs-font-semi-bold
}

// Overall font sizes
.font-xxs {
  font-size: $font-xxs !important
}
// 12

.font-xs {
  font-size: $font-xs !important
}

// 15
.font-xsm {
  font-size: $font-xsm !important
}

// 17
.font-sm {
  font-size: $font-sm !important
}

// 18
.font-md {
  font-size: $font-md !important
}

// 24
.font-lg {
  font-size: $font-lg !important
}

// 32

.no-shadow {
  box-shadow: none;

  &-imp {
    box-shadow: none !important;
  }
}
.top-auto {
  top: auto !important;
}

.no-bg {
  background-color: transparent;

  &-imp {
    background-color: transparent !important;
  }
}

// icon
@each $key, $size in $fontSizes {
  .icon-#{$key} {
    font-size: $size !important
  }
}

.icons-sm {
  font-size: $icons-xs !important
}

.icons-svm {
  font-size: $icons-svm !important
}

.icons-md {
  font-size: $icons-md !important
}

.icons-lg {
  font-size: $icons-lg !important
}

.icons-xl {
  font-size: $icons-xl !important
}

.icons-xxl {
  font-size: $icons-xxl !important
}

hr {
  // margin: 0.6rem 0;
  margin: 0 0 0.5rem 0 !important;
  background-color: #ccc;
  opacity: 1;
  background-color: transparent !important;
  height: 0px !important;
  opacity: 1;
  border-top: 1px solid $border-color;

  &.dark {
    background-color: $bg-color3;
    border-top: 1px solid $bg-color3 !important;
  }

  &.blue {
    background-color: $primary-color;
    border-top: 1px solid $primary-color;
  }
}

.rsp-default-button {}

.rsp-primary-button {
  background-color: var(--th-button-color);

  &:hover {
    box-shadow: 0 0px 15px var(--th-button-color);
  }
}

.rsp-secondary-button {
  color: var(--th-secondary-color);
}

.underline {
  border-bottom: 1px solid #2896f0;
}

.bg-active {
  background-color: $active-color !important;
}

.success {
  background: $success;
}

.text-underline-h {
  &:hover {
    text-decoration: underline;
  }
}

.clearfix {
  clear: left;
}

input:disabled {
  cursor: not-allowed;
}

.no-drop {
  cursor: no-drop;
}

.blue {
  color: $primary-color;
}

a:hover {
  text-decoration: none;
}

.rs-link {
  cursor: pointer;
  color: $primary-color;

  &:hover {
    text-decoration: underline;
  }
}

ul {
  list-style: none;
  padding: 0;
}

label {
  color: $labelColor;
}

.color-inherit {

  &,
  &:hover {
    color: inherit !important;
  }
}

// color
.grey {
  color: #6e6e6e !important;
}

.pos-r,
.posr {
  position: relative;
}

// Icons Section Styles
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: $head-tag;
  font-family: $rs-font-regular;
}

h1 {
  font-size: $font-lg;
  color: $primary-color;
  margin-bottom: 15px;
}

h2 {
  font-size: $font-md;
}

h3 {
  font-size: $font-smd;
}

h4 {
  font-size: $font-sm;
}

p {
  color: $default-color;
}

.well {
  margin-bottom: 20px;
  position: relative;
  border: 1px solid $border-color;
}

.font-italic {
  font-style: italic;
}

.border-top-default {
  border-top: 1px solid $border-color !important;
}

.radius,
.rs-radius {
  border-radius: $theme-radius-size;
}

.rs-radius-2 {
  border-radius: $theme-radius-size - 1px;
}

.rs-radius-left {
  border-radius: $theme-radius-size 0 0 $theme-radius-size;
}

.rs-radius-right {
  border-radius: 0 $theme-radius-size $theme-radius-size 0;
}

.border-dashed {
  border: 1px dashed $primary-color !important;
}

.border0,
.br0 {
  border: 0 !important;
}

.border1,
.br1 {
  border: 1px solid $border-color !important;
}

.borderR1 {
  border-right: 1px solid $border-color !important;
}

.border2 {
  border: 1px solid $br-color4;
}

.border2R {
  border-right: 1px solid $br-color4;
}

.borderR7 {
  border-right: 1px solid $br-color4;
}

.border7 {
  border: 1px solid $br-color4;
}

.borderB0 {
  border-bottom: 0 !important;
}

.borderB1 {
  border-bottom: 1px solid $border-color !important;
}

.border-color-blue {
  border-color: $primary-color !important;
}

.border-blue {
  border: 1px solid $primary-color !important;
}

.cp,
.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default !important;
}

.btn-campaign {
  font-family: $rs-font-regular;

  button,
  .btn {
    margin-left: 10px;
    @extend %button;
    padding: 6px 20px;
  }
}

.ovid {
  overflow: hidden;
}

.ovid-imp {
  overflow: hidden !important;
}

.cancel {

  &,
  &:hover {
    color: $orange-200;
  }

  &:hover {
    text-decoration: underline;
  }
}

.save {

  &,
  &:hover {
    color: $primary-color;
  }

  &:hover {
    text-decoration: underline;
  }
}

.no-row-margin {

  &>.row,
  .no-margin {
    margin: 0 19px;
  }
}

.word-break {
  &-all {
    word-break: break-all;
  }
}

.btn {
  cursor: pointer;
  padding: 5px 25px;
}

.center-x {
  @include center('horizontal')
}

.center-y {
  @include center('vertical')
}

.center-both {
  @include center('both')
}

.col-xs-6 {
  @include screen(sm) {
    width: 50%;
  }
}

.bt {
  border-top: 1px solid $border-color !important;
}

// kendo button - icon
button.k-button.k-button-icon,
button.k-button.k-button-icontext {
  &.custon-icon {
    padding: 0;
    border: 0;
  }
}

i.icon-question-mark-small:before {
  // content: "\ea11";
  font-family: 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // font-size: $font-xsm;
  width: 20px;
  display: block;
  position: relative;
  right: 0px;
  left: 2px;
  bottom: 0;
  z-index: 2;
}

.space-margin-bottom {
  margin-bottom: 35px;
}

// Scrollbar custom css style - Start
.css-scrollbar {
  overflow-y: auto !important;
  overflow-x: hidden;
  scrollbar-color: #b8b8b8 #f7f7f7;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 8px;
    cursor: pointer;
    width: 7px;
    background-color: #f7f7f7;
  }

  &::-webkit-scrollbar-thumb {
    cursor: pointer;
    background-color: #b8b8b8;
    border-radius: 100px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
  }

  &::-webkit-scrollbar-track {
    cursor: pointer;
    background-color: #f7f7f7;
  }
}

// button
.btn-container {
  margin-top: 20px;
  // margin-bottom: 20px;
}

.icon-app-performance-large:before {
  top: -7px;
  position: relative;
}

.page-header {
  margin-top: 19px;
}

// Dropdown bootstrap
.rs-dropdown {
  float: left;

  &.dropdown button.btn:active {
    background-color: transparent;
  }
  &.dropdown-btn-primary button:active {
    border: 0 !important;
  }
  .btn-primary {
    &.dropdown-toggle {
      background-color: $color0;
      border: 0;
      color: $default-color;
      padding: 0;

      &:active,
      &:focus {
        background-color: $color0;
        box-shadow: none;
      }

      &:active:focus {
        box-shadow: none;
      }
    }
  }

  .dropdown-menu {
    @extend %scrollbar;
    border-radius: 5px;

    &.show {
      overflow: inherit !important;
    }

    .dropdown-item {
      padding-right: 18px;
      border-radius: 5px;
    }

    &:before {
      border-color: $border-color transparent; //$border-color 
      border-style: solid;
      border-width: 0 7px 7px;
      content: "";
      display: block;
      position: absolute;
      top: -7px;
      left: 8px;
      right: auto;
    }

    &:after {
      border-color: #fff transparent;
      border-style: solid;
      border-width: 0 7px 7px;
      top: -6px;
      content: "";
      display: block;
      position: absolute;
      left: 9px;
      right: auto;
    }

    &[x-placement="top-start"] {

      &:before,
      &:after {
        top: inherit;
        border-width: 10px 7px 0px;
        right: 31px !important;
        width: 6px;
        height: 6px;
      }

      &:before {
        bottom: -9px;
      }

      &:after {
        bottom: -8px;
      }
    }
  }

  .dropdown-item {

    &:hover,
    &:focus {
      color: #fff;
      background-color: $primary-color;
    }

    &.active,
    &:active {
      background-color: $primary-color;
    }
  }

  &.align-right {
    .dropdown-menu {
      left: inherit !important;
      right: -2px !important;
      overflow: inherit !important;
      border-radius: 5px;

      &:before {
        right: 11px;
        left: auto;
      }

      &:after {
        right: 11px;
        left: auto;
      }
    }
  }
}

.rs-dropdown {
  &.primary {
    .btn-primary {
      color: $white;

      &:focus,
      &:active {
        color: $white;
      }
    }
  }

  &.default {
    .btn-primary {
      padding: 0;
      color: $grey-400;

      &:focus,
      &:active {
        color: $default-color;
      }
    }
  }
}

// css scrollbar
.css-scrollbar::-webkit-scrollbar-track {
  background-color: transparent;
  cursor: pointer;
}

.css-scrollbar::-webkit-scrollbar {
  width: 8px;
  background-color: transparent;
  cursor: pointer;
}

.css-scrollbar::-webkit-scrollbar-thumb {
  background-color: #eee;
  cursor: pointer;
}

.bgColor4 {
  background-color: $bg-color4 !important;
}

.click-off {
  pointer-events: none;
  opacity: 0.4;

  .click-off {
    opacity: 1;
  }
}
.pointer-none {
  pointer-events: none;
}

input.click-off {
  background-color: $bg-color1 !important;
  opacity: 1;
}

.click-off-dropdown {
  cursor: not-allowed;

  .k-dropdown .k-input {
    background-color: $bg-color1 !important;
  }

  .k-widget.k-dropdown {
    pointer-events: none;
  }
}

.text-wrap-1 {
  max-width: 84%;
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.wsn {
  white-space: nowrap;
}

.wsp {
  white-space: pre;
}

b,
strong {
  font-weight: normal;
  font-family: $rs-font-bold
}

table thead th {
  font-weight: normal;
  font-family: $rs-font-medium
}

// file upload
.btn-file {
  position: relative;
  overflow: hidden;
  display: inline-block;

  button {
    line-height: 23px;
    padding: 5px 18px;
  }

  input[type=file] {
    position: absolute;
    top: 0;
    bottom: 0;
    // height: 100px;
    right: 0;
    // min-width: 100%;
    // min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    background: white;
    cursor: inherit;
    display: block;
    &:before {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      z-index: 1;
      content: '';
    }
  }
}

.highcharts-link.highcharts-point {

  &,
  &.highcharts-point-hover,
  &.highcharts-point-inactive {
    opacity: 0.6
  }
}

.highcharts-label.highcharts-tooltip span {
  font-size: $font-xs !important;
}

.highcharts-label.highcharts-tooltip span span:first-child {
  font-size: $font-xs !important;
}

// truncate
.truncate,
.truncate70 {
  display: inline-block;
}

.truncate50p {
  @include truncate(50%);
}

.truncate {
  @include truncate(95%);
}

.truncate70 {
  @include truncate(70px);
}

.truncate220 {
  @include truncate(220px);
}

.truncate330 {
  @include truncate(330px);
}

.truncate400 {
  @include truncate(400px);
}

.truncate500 {
  @include truncate(500px);
  white-space: pre;
}

$port-sizes: sm, md, lg, xl;
$size-res: 1152px;
@media (min-width: 1200px) {
  .container {
    max-width: $size-res !important;
  }

  @each $size in $port-sizes {
    .container-#{$size} {
      max-width: $size-res !important;
    }
  }
}

.css-scrollbar {
  @extend %scrollbar;
}

.overflow-dropdown {
  max-height: 160px;
}

.icon-sd-email-medium.icons-md {
  font-size: $icons-md !important;
}

.box-bottom-space {
  margin-bottom: 30px;
}

.hide {
  display: none;
}

ol {
  li::marker {
    font-size: $font-smd;
  }
}


.modal {
  z-index: 1055 !important;
}

.modal-backdrop {
  z-index: 1055 !important;
}

.modal.show .modal-dialog {
  margin: 1.75rem auto 1.75rem;
}

.modal.modal-top-fixed .modal-dialog {
  display: block;
  margin: 5.75rem auto 30px !important;
  min-height: calc(100% - 123px);
}

// .fade.modal.show:before {
//   content: '';
//   position: fixed;
//   top: 0;
//   left: 0;
//   right: 0;
//   height: 74px;
//   background: #ffffff75;
//   z-index: 1056 !important;
// }
.k-animation-container {
  z-index: 1056 !important;
}


// HIGHCHARTS
rect.highcharts-point {
  transform: translateY(-1px);
}


span.color-dotted {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: #ffffff45;
    right: 5px;
    top: 8px;
    border-radius: 50%;
  }
}

.w-max-content {
  min-width: max-content !important;
}

.wsp {
  white-space: pre;
}

// COLORS
.color-dotted {
  width: 100%;
  height: 30px;
  border-radius: 8px 8px 0 0;
  display: inline-block;
  margin-right: 10px;
  height: 80px;
  position: relative;
  margin-bottom: 10px;

  &:before {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: #ffffff45;
    right: 5px;
    top: 8px;
    border-radius: 50%;
  }
}

.color-text {
  padding: 0 10px 10px;
}

.color-list {
  .view-box {
    display: flex;
    // border: 1px solid #c2cfe3;
    align-items: self-start;
    margin-bottom: 23px;

    .view-box-list {
      // border: 1px solid #c2cfe3;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      background-color: #ffffff;
      border: 1px solid #e6e6e6;
      padding: 10px;
      border-radius: 8px;
      transition: 0.3s all ease;

      &:hover {
        box-shadow: 0px 4px 9px -2px #00000040;
      }
    }
  }
}

span.labelcss,
span.labeljs {
  background-color: #0300ed0a;
  border: 1px solid $border-color;
  padding: 0px 8px;
  margin-left: 5px;
  border-radius: 20px;
  font-size: $font-xxs;
  font-family: $rs-font-bold;
}

span.labeljs {
  background-color: #ff88000a;
  color: #ff8800;
}

span.labelcss {
  background-color: #0013ff0a;
  color: #0013ff;
}

small,
.small {
  font-size: $font-xs;
}
.btn:focus-visible {
  color: inherit;
  background-color: #fff;
  border-color: #8c2800;
  outline: 0;
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.329411764);
}

.bottomBB10 {
  bottom: calc(100% + 10px) !important;
}