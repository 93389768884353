@charset 'utf-8';

@include keyframes(advanceSearchFadeOut) {
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 0; }
}
@include keyframes(advanceSearchFadeIn) {
  0% { opacity: 0; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
}

$search-height: 33px;
$search-weight: 33px;
$search-transition: all 0.3s ease;
$search-radius: 50px;

$search-container-w: 311px;
$search-container-i: 300px - 20px;

.search-container {
  * { transition: $search-transition; }
  position: relative;
  z-index: 2;
  &.search-advanced {
    border-radius: $search-radius;
    overflow: hidden;
    transition: $search-transition;
    position: relative;
    .dropdown-toggle:after {
      border-top-color: $white;
    }
    .expand-menu-icon:after {
      display: inline-block;
      content: "";
      border-bottom: 0;
    }
    .search-icon-box {
      width: $search-weight;
      height: $search-height;
      @include flexbox();
      background-color: #9a9a9d;
      border-radius: $search-radius;
      padding: 0;
      border-radius: $search-radius;
      .search-icon {
        width: $search-weight;
        height: $search-height;
        background: #9a9a9d;
        @include flexbox($y: center, $x: center);
        border-radius: $search-radius;
        position: relative;
        z-index: 2;
        &:hover {
          cursor: pointer;
        }
        .icons-sm.icon-sd-close-mini{
          font-size: 11px !important;
          padding-right: 3px;
        }
      }
      .search-input {
        margin-right: 0;
        visibility: hidden;
        height: $search-height;
        width: 0;
        transition: $search-transition;
        @include flexbox();
        .filterSection {
          position: absolute;
          left: 60px;
          // @include flexbox($y: center);
          width: auto;
          top: 33px;
          background: $white;
          z-index: 2;
          max-height: 200px;
          overflow-y: scroll;
           
          ul{
            background: $white;
            min-width: 200px;
          }
          li {
            font-size: $font-xs;
            padding: 5px 5px;
            cursor: pointer;
            display: block;
            span {
              padding: 0px 5px;
              display: block;
            }
            &:hover {
              background-color: var(--th-primary-color);
              color: $white;
            }
          }
        }
        input {
          height: 29px;
          background: $white !important;
          margin: 2px 0 0;
          padding: 0 33px 0 10px;
          line-height: 20px;
          border-top: 5px solid $white;
          border-bottom: 5px solid $white;
          border-radius: $search-radius 0 0 3px;
        }
        .dropdown {
          .dropdown-menu {
            margin-top: 15px;
            margin-left: 10px;
            @extend %arrow-top-right;
            overflow: inherit;
            padding: 0 !important;
            border-radius: 8px;
            &:before,
            &:after {
              top: -5.5px;
              right: inherit;
              left: 9px;
            }
            &:after {
              left: 7px;
              top: -5.5px;
            }
            .css-scrollbar {
              overflow: inherit;
              border-radius: 8px;
            }
            // .dropdown-item {
            //   &:first-child {
            //   }
            // }
            .dropdown-item:hover {
              color: #fff;
              background-color: $primary-color;
            }
          }
        }
      }
      .input-search-icon {
        top: 3px;
        position: absolute;
        right: 0;
        visibility: hidden;
        i {
          transform: scale(0);
        }
      }
      .dropdown {
        position: relative;
        z-index: -1;
        padding: 7px 0;
        @include animationField(
          $animation: advanceSearchFadeOut,
          $duration: 300ms,
          $delay: 200ms,
          $fillmode: both,
          $timefunc: ease-in-out
        );
        #dropdown-basic {
          &::after {
            transform: rotate(0deg);
            transition-duration: 0.1s;
          }
        }
        &.show {
          #dropdown-basic {
            &::after {
              transform: rotate(180deg);
              transition-duration: 0.1s;
            }
          }
        }
        .btn {
          margin: 0;
          padding: 0 5px 0 10px;
          // padding: 0;
          @include flexbox($y: center);
          width: 38px;
          border: 0;
        }
      }
    }
    .mega-menu-icon {
      display: none;
      & ~ .dropdown-menu {
        min-width: 1154px;
        max-height: none;
        border-top-color: $primary-color !important;
        right: -85px !important;
      }
    }
    &.open {
      border-radius: $search-radius;
      overflow: inherit;
      transition: $search-transition;
      .search-icon-box {
        width: $search-container-w;
        height: $search-height;
        border-radius: $search-radius;
        .search-icon {
          border-radius: $search-radius;
          width: 30px; //$search-weight;
          height: $search-height;
          i.icon-close-small {
            position: relative;
            top: -2px;
            left: -2px;
          }
        }
        .search-input {
          width: $search-container-i;
          margin: 0px;
          position: relative;
          visibility: visible;
          height: $search-height;
          .dropdown {
            cursor: pointer;
            @include flexbox();
            z-index: 1;
            position: relative;
            @include animationField(
              $animation: advanceSearchFadeIn,
              $duration: 300ms,
              $delay: 200ms,
              $fillmode: both,
              $timefunc: ease-in-out
            );
            #dropdown-basic {
              &::after {
                display: none;
              }
            }
          }
          input {
            border-radius: $search-radius;
            width: 240px;
            transition: all 0.3s ease;
            @include truncate(90%);
          }
          .input-search-icon {
            position: absolute;
            right: 5px;
            @include flexbox($y: center);
            top: 3px;
            bottom: 3px;
            padding: 8px;
            // border-left: 1px solid #ccc; // vertical line
            height: auto;
            cursor: pointer;
            left: inherit;
            visibility: visible;
            i {
              animation-name: searchSmoothOpen;
              animation-duration: 600ms;
              animation-iteration-count: 1;
              animation-timing-function: ease;
              animation-fill-mode: forwards;
              transition: all ease 0.3s;
            }
          }
        }
        .mega-menu-icon {
          display: inline;
          align-items: center;
          height: 100%;
          font-size: $icons-xs;
          i {
            display: none;
          }
        }
      }
    }
    &.search-no-dropdown {
      &.open {
        .search-icon-box {
          width: 265px;
          // input {margin-left: 2px;}
          .search-input {
            width: 230px;
            input {
              width: 225px;
              max-width: 100%;
              margin-left: 2px;
            }
          }
        }
      }
    }
  }
}


.advanceSearchLeft {
  width: 100%;
}
.advanceSearchRight {
  width: 33px;
  margin-left: 15px;
  display: flex;
  align-items: center;
}
@include keyframes(searchSmoothOpen) {
  0% {
    transform: scale(0);
  }
  60% {
    transform: scale(0);
  }
  80% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.dropdown-item.active, .dropdown-item:active{

  background-color: var(--th-primary-color);
}
.search-max-drop-h {
  max-height: 125px;
}


.search-container.search-advanced .search-icon-box .search-input .filterSection {
  border: 1px solid #b8b8b8;
}
.search-container.search-advanced .search-icon-box .search-input .filterSection ul {
  // margin-top: 45px;
}
.search-icon-box .search-input .filterSection * {
  transition: inherit !important;
}