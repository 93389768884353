// modal
.modal-backdrop {
    z-index: 10562;
    &.show {
        background-color: #fff;
        opacity: .8;
    }
}
.modal {
    z-index: 10562;
    &.show {
        .modal-dialog {
            margin: 1.75rem auto 1.75rem;
        }
    }
    .modal-full {
        min-width: 105%;
        .modal-content {
            border-radius: 0 !important;
        }
    }
    .modal-dialog {
        .modal-header {
            padding: 0 0 10px;
            border-bottom: 1px solid $primary-color;
            h2 {
                color: $primary-color;
                margin-bottom: 0;
            }
            .btn-close {
                margin-right: 0px;
                background-image: none;
                font-family: 'icomoon';
                font-size: $font-xsm;
                width: 15px;
                height: 15px;
                padding: 4px;
                color: $primary-color;
                border-radius: 50%;
                opacity: 1;
                &:hover {
                    color: #fff;
                    background-color: $primary-color;
                }
                &:before {
                    content: "\e99d";
                    top: -6px;
                    position: relative;
                }
                &:focus {
                    box-shadow: none;
                }
            }
        }
        .no-title-text.modal-header {
            border: 0;
            .btn-close {
                margin-right: -10px;
            }
        }
        .modal-content {
            border: 1px solid $border-color;
            border-top: 2px solid $primary-color;
            padding: 15px $theme-space $theme-space;
            border-radius: 0;
            max-height: max-content;
            @if $new-theme-test == true {
                @extend %new-theme-shadow;
                // border-top: 2px solid $primary-color !important;
            }
            .btn-close {
                font-size: $font-smd;
                width: 30px;
                height: 30px;
                padding: 0px;
                position: absolute;
                right: 5px;
                top: 12px;
                &:before {
                    position: relative;
                    top: -3px;
                }
            }
        }
        .modal-body {
            padding: 20px 0 0;
        }
        .modal-footer {
            padding: 0;
            border: 0;
            .btn-container {
                margin: 0;
            }
        }
    }
}
.modal-adoc-popup {
    min-width: 103% !important;
}
.modal-xlg {
    width: 956px;
    max-width: 97%;
}
.modal-xl {
    @include screen(min, xl) {
        max-width: 1150px;
    }
}
.modal.fade .modal-dialog {-webkit-transform: translate(0,0)scale(.5);transform: translate(0,0)scale(.5);}
.modal.show .modal-dialog {-webkit-transform: translate(0,0)scale(1);transform: translate(0,0)scale(1);}
.modal {
    .modal-full {
        min-width: 100%;
        .modal-content {
            background-color: $primary-color;
            border: 0 !important;
            padding: 30px 20px;
            .modal-header,
            .modal-body,
            .modal-footer {
                min-width: 1150px;
                margin: 0 auto;
                border: 0 !important;
            }
            &,
            .modal-header h2,
            .modal-body h3,
            .modal-footer button {
                color: #fff;
            }
        }
    }
}