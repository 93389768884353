:root {
  --primary-color: #0540d3;
  --seconday-color: #fe5758;
  --button-slide: #0c8aff;
}
.sp-split-progress-main-wrapper {
  border: 1px solid #b8b8b8;
  padding: 15px 15px 0 15px;
  margin-bottom: 15px;
  display: inline-block;
  background-color: #ffffff;
}
.sp-split-progress-main {
  height: 67px;
  position: relative;
  /* margin-top: 40px; */
}
.sp-split-progress-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  align-items: center;
  z-index: 5;
}
.sp-split-progress-container > div > div > div > div,
.sp-split-progress-container > div > div {
  background: transparent !important;
}
.sp-split-progress-container > div > div {
  background: transparent !important;
}
.sp-split-progress-container > div > div > div {
  background-image: url("https://resulticks.team/Images/sql-slider.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-color: var(--button-slide) !important;
  border-radius: 50px !important;
  box-shadow: none !important;
  width: 2em !important;
  height: 2em !important;
}
.sp-split-progress-container > div > div > div:focus-visible {
  outline: none;
}
.sp-split-progress-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #fff;
  /* margin-top: -47px; */
  border: 1px solid #b8b8b8;
}
.sp-split-progress-ab {
  background-color: #fff;
  display: flex;
  height: 67px;
}
.sp-split-progress-wrap {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.sp-split-progress {
  color: #fff;
  display: flex;
  align-items: center;
  padding: 6px;
  width: 100%;
  text-align: center;
  justify-content: center;
  overflow: hidden;
}
.sp-circle {
  width: 25px;
  height: 25px;
  background-color: #fff;
  color: #6ac8ea;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sp-percentage {
  padding-left: 5px;
  margin-top: 5px;
}
.sp-split-progress-a {
  background-color: var(--primary-color);
}
.sp-split-progress-b {
  background-color: var(--seconday-color);
}
.sp-split-progress-text {
  padding-left: 20px;
}
