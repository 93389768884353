// TYPE 2
// TYPE 3
// TYPE 4

$breakpoints: (
  small: 767px,
  medium: 992px,
  large: 1200px
);

@mixin respond-to($setsize) {
    @media (min-width: #{map-get($breakpoints, $setsize)}) {
      @content;
    }
}

// TYPE 2
// @include breakpoint(large);
@mixin breakpoint($point) {
   @if $point == large {
      @media only screen and (min-width: 1366px) {
         @content;
      }
   }
   @else if $point == desktop {
      @media only screen and (min-width: 1024px) {
         @content;
      }
   }
   @else if $point == tablet {
      @media only screen and (min-width: 600px) {
         @content;
      }
   }
}

// TYPE 3
// @include screen(small);
// $breakpoint-sm: 575.98px;
// $breakpoint-md: 767.98px;
// $breakpoint-lg: 991.98px;
// $breakpoint-xl: 1199.98px;
// $breakpoint-xxl: 1367px;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1400px;

@mixin screen($size, $type: max, $pixels: $breakpoint-sm) {
  @if $size == 'sm' {
    @media screen and ($type + -width: $breakpoint-sm) {
        @content;
    }
  }
  @else if $size == 'md' {
    @media screen and ($type + -width: $breakpoint-md) {
        @content;
    }
  }
  @else if $size == 'lg' {
    @media screen and ($type + -width: $breakpoint-lg) {
        @content;
    }
  }
  @else if $size == 'xl' {
    @media screen and ($type + -width: $breakpoint-xl) {
        @content;
    }
  }
  @else if $size == 'xxl' {
    @media screen and ($type + -width: $breakpoint-xxl) {
        @content;
    }
  }
  @else if $size == 'custom' {
    @media screen and ($type + -width: $pixels + px) {
     @content;
    }
  }
  @else {
    @content;
  }
}
// TYPE 4
$breakpoints2: (
    "phone":        400px,
    "phone-wide":   480px,
    "phablet":      560px,
    "tablet-small": 640px,
    "tablet":       768px,
    "tablet-wide":  1024px,
    "resulticks":   1050px,
    "desktop":      1248px,
    "desktop-wide": 1440px
);
@mixin mq($width, $type: min) {
    @if map_has_key($breakpoints2, $width) {
        $width: map_get($breakpoints2, $width);
        @if $type == max {
            $width: $width - 1px;
        }
        @media only screen and (#{$type}-width: $width) {
            @content;
        }
    }
}


//
// .foo {
//   @include screen(large) { width: 20%; }
//   @include screen(med) { width: 40%; }
//   @include screen(med-small) { width: 60%; }
//   @include screen(small) { width: 80%; }
//   @include screen(custom, max, 400) { width: 100%; }
// }
