@import '../variables/colors-brand';
@import '../variables/colors-default';

.sub-label          { color: $color3 }

.fontColor2         { color: $fontColor1; }

// Social Media Color
.facebook           { background-color: $facebook }
.facebookapp        { background-color: $facebook }
.twitter            { background-color: $twitter  }
.linkedin           { background-color: $linkedin }
.pinterest          { background-color: $pinterest}

// Key metric
// RED
.redlev-2 { @extend .bgMaroon-medium; }
.redlev-1 { @extend .bgMaroon-dark; }

// BLUE
.bluelev-2 { @extend .bgBlue-light; }
.bluelev-1 { @extend .bgBlue-medium; }

/* Background Classes
--------------------------------------------- */
.bgBlue             { background-color: $primary-color !important}
.bgBlue-medium      { background-color: $blue-300      !important}
.bgBlue-light       { background-color: $blue-100      !important}
.bgBlue-lighter     { background-color: $primary-light !important}
.bgGreen            { background-color: $green-50      !important}
.bgGreen-medium     { background-color: $green-200     !important}
.bgGreen-light      { background-color: $green-100     !important}
.bgGrey             { background-color: $grey-50       !important}
.bgGrey-lighter     { background-color: $grey-L100     !important}
.bgGrey-lighter1    { background-color: $grey-L200     !important}
.bgGrey-lighter2    { background-color: $grey-L300     !important}
.bgMaroon           { background-color: $maroon-50     !important}
.bgMaroon-medium    { background-color: $maroon-200    !important}
.bgMaroon-light     { background-color: $maroon-100    !important}
.bgMaroon-dark      { background-color: $maroon-300    !important}
.bgOrange           { background-color: $orange-50     !important}
.bgOrange-medium    { background-color: $orange-200    !important}
.bgOrange-light     { background-color: $orange-100    !important}
.bgYellow           { background-color: $yellow-50     !important}
.bgYellow-medium    { background-color: $yellow-200    !important}
.bgYellow-light     { background-color: $yellow-100    !important}
.bgRed              { background-color: $red           !important}
.transparent        { background:       none           !important}
.even               { background-color: $even          !important}
.odd                { background-color: $odd           !important}
.bgWhite            { background-color: $white         !important}
.bgFacebook         { background-color: $facebook      !important}
.bgTwitter          { background-color: $twitter       !important}
.bgLinkedin         { background-color: $linkedin      !important}
.bgGrey-L100        { background-color: $grey-L100; }

/* Text Color Styles
--------------------------------------------- */
.blue               { color: $primary-color            !important}
.blue-medium        { color: $blue-300                 !important}
.green              { color: $green-50                 !important}
.green-medium       { color: $green-200                          }
.black-medium       { color: $grey-500                 !important}
.grey-dark          { color: $grey-400                 !important}
.grey               { color: $black-light              !important} // $grey-50
.grey-medium        { color: $grey-50                  !important}
.maroon-medium      { color: $maroon-200               !important}
.orange             { color: $orange-50                !important}
.orange-medium      { color: $orange-200               !important}
.orange-light       { color: $orange-100               !important}
.yellow-medium      { color: $yellow-200               !important}
.cyan-medium        { color: $cyan-200                 !important}

.white              { color: $white                    !important}
.red                { color: $red                      !important}

/* Border Color Styles
--------------------------------------------- */
.border-blue        { border-color: $primary-color     !important}
.border-grey-medium { border-color: $grey-300          !important}

.bg-color4          { background-color: #f1f1f1; }
.no-data-bg         { background-color: $noDataBg      !important}