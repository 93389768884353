.box-design {
    @extend .primary-box-shadow;
    @include bor($width: 1px, $color: $quaternary-blue);
    @include br($radius: 5px);
    @include color($bg: $white);
    // @include space($padding: 30px);
    @include space($padding: 23px);
    &.bd-top-border {
        @include border-radius-all($brtl: 0, $brtr: 0);
        border-top: 2px solid $secondary_blue;
    }
    .row {
        //--bs-gutter-y: 1.9rem;
        --bs-gutter-x: 1.9rem;
        &.gx-5 {
            --bs-gutter-x: 3rem;
        }
        &.g-5 {
            --bs-gutter-x: 3rem;
        }
    }
    &.no-box-shadow {
        box-shadow: none !important;
    }
    &.no-padding {
        padding: 0 !important;
    }
    .rs-kendo-grid-table .rs-kendo-fixed-grid table {
        box-shadow: none !important;
    }
    .rs-kendo-grid-table .k-grid-aria-root,
    .chips-container .chips-box {
        box-shadow: none !important;
        border: 0 !important;
        padding: 0;
    }
    // .list-of-even {
    //     box-shadow: none !important;
    //     border: 0 !important;
    //     padding: 0;
    // }
}

.errorContainerCheckbox {
    .validation-message {
        top: -25px;
    }
}
.validation-message {
    @include abs-position($position: absolute, $left: 0, $right: 0, $zindex: 15);
    @include sizing($fs: $font-xxs, $lh: 16px, $mw: max-content, $w: fit-content);
    @include color($color: $primary-red);
    @include space($padding: 5px 0px);
    &.body-text {
        top: -25px;
    }
}
.rs-radio-error-message-container {
    .validation-message {
        top: -30px;
    }
}
.rs-radio-wrapper.errorContainer,
.rs-textarea-wrapper {
    .validation-message {
        top: -26px;
    }
}

label, .lbl {
    vertical-align: middle;
}
.rs-list-inline {
    @extend .clearfix;
    li {
        display: inline-block;
        // @extend %show-zoom;
        .rs-bootstrap-dropdown {
            .rs-dropdown {
                .dropdown-toggle {
                    height: initial;
                }
            }
        }
    }
}
.no-border { border: 0 !important; }

// temp
.rs-list-inline .errorContainerCheckbox .validation-message {
  display: none;
}
.rs-list-inline li:first-child .errorContainerCheckbox .validation-message {
  display: block;
}

// Bootstrap <Row> gutters
.res-g-0 {
    --bs-gutter-y: 0rem !important;
    --bs-gutter-x: 0rem !important;
}
.res-gy-0 {
    --bs-gutter-y: 0rem !important;
}
.res-gx-0 {
    --bs-gutter-x: 0rem !important;
}
.res-g-1 {
    --bs-gutter-y: 1.9rem !important;
    --bs-gutter-x: 1.9rem !important;
}
.res-gy-1 {
    --bs-gutter-y: 1.9rem !important;
}
.res-gx-1 {
    --bs-gutter-x: 1.9rem !important;
}
.res-g-2 {
    --bs-gutter-y: 3.8rem !important;
    --bs-gutter-x: 3.8rem !important;
}
.res-gy-2 {
    --bs-gutter-y: 3.8rem !important;
}
.res-gx-2 {
    --bs-gutter-x: 3.8rem !important;
}


.box-left-border {
    border-left: 1px solid $tertiary-grey;
}
.rs-box-min-height {
    .box-left-border {
        min-height: 290px;
    }
}


.primary-box-shadow {
    box-shadow: 0 0 5px 0 rgb(0 0 0 / 5%), 0 0 5px 0 rgb(0 0 0 / 5%) !important;
}
.secondary-box-shadow {
    box-shadow: 5px 5px 5px -5px rgb(0 0 0 / 10%), 5px 5px 5px -5px rgb(0 0 0 / 10%);
}
.pc-my-profile {
    margin-top: 20px;
    .alert {
        @include space($padding: 5px, $mt: 20px);

        p {
            @include sizing($fs: 12px);
        }
    }
}

// STRICKY
.stricky-window {
  position: sticky;
  &.vertical-notification {
    top: 88px;
  }
  &.push-content {
    top: 79px;
    z-index: 20;
  }
  &.my-profile {
    top: 100px;
  }
}

.ptg { padding-top: var(--globalSpace); }
.pbg { padding-bottom: var(--globalSpace); }

.pyg { padding-top: var(--globalSpace); padding-bottom: var(--globalSpace); }
.pxg { padding-right: var(--globalSpace); padding-left: var(--globalSpace); }