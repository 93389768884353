@charset 'utf-8';
@use 'sass:string';

// Kendo grid (Table)
.k-widget {
    &.k-grid {
        background-color: transparent;
        border: 0;
        color: $primary-black;
        // .tooltip {
        //     position: absolute !important;
        // }
        &.noDataAvailable {
            @extend .no-box-shadow;
            border: 0;
            .k-grid-header {
                display: none;
            }
        }
        .k-grid-header {
            border-radius: 5px 5px 0 0;
            overflow: hidden;
        }
        .k-grid-header-wrap table {
            background-color: var(--th-primary-color);
            color: $white;
            font-family: $rs-font-regular;
            font-size: $font-xsm;
            font-weight: normal;
            --bs-body-font-weight: normal;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            padding: 0;
            thead {
                tr {
                    th.k-header {
                        vertical-align: middle;
                    }
                }
            }
            th {
                padding: 8px 10px;
                border-color: $tertiary-grey;
                .k-grid-header-menu {
                    position: relative;
                    right: 0;
                    top: 0;
                    padding: 0;
                    margin: 0;
                    width: auto;
                    height: auto;
                    display: flex;
                    justify-content: flex-end;
                    .k-icon {
                        color: $white;
                        position: relative;
                        left: 0;
                    }
                    &:hover {
                        background-color: transparent;
                    }
                }
                .k-cell-inner {
                    justify-content: space-between;
                    margin: 0;
                    .k-link {
                        padding: 0;
                        .k-icon {
                            color: $white;
                            &.k-i-sort-asc-small {
                                position: absolute;
                                right: 0;
                            }
                            &.k-i-sort-desc-small {
                                position: absolute;
                                right: 0;
                            }
                        }
                    }
                }
                &.k-filterable.k-header {
                    padding: 0px 10px;
                }
                &:first-child {
                    border-top-left-radius: 5px;
                }
                &:last-child {
                    border-top-right-radius: 5px;
                }
            }
        }
        table {
            color: $primary-black;
            width: 100% !important;
            tbody {
                tr {
                    background-color: $white !important;
                    td {
                        font-family: $rs-font-regular;
                        font-size: $font-xsm;
                        padding: 10px;
                        border-color: $quaternary-blue;
                        // overflow: inherit;
                        // overflow: visible;
                        .rs-tooltip-wrapper {
                            span {
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                display: block;
                            }
                        }
                        .actionBlockCol {
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            .rs-tooltip-wrapper {
                                line-height: 0;
                            }
                        }
                    }
                    &.k-alt {
                        background-color: var(--th-even-color) !important;
                    }
                    &:last-child {
                        td {
                            border-bottom: 0;
                        }
                    }
                }
            }
        }
    }
}

// Pagination
.k-pager {
    background-color: $white;
    border: 1px solid $quaternary-blue;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 30px;
    padding-right: 20px;
    margin: 30px auto 0 auto;
    width: auto;
    overflow: visible;
    height: 30px;
    border-radius: 30px;
    color: $secondary-grey;
    .k-link {
        height: auto;
        background-color: transparent;
        outline: none !important;
        box-shadow: none !important;
        color: $secondary-grey;
        &:hover {
            background-color: transparent;
            color: $secondary-blue;
            //text-decoration: underline;
        }
        &.k-pager-nav {
            .k-icon {
                font-family: $rs-font-regular;
                font-size: 14px;
                &.k-i-arrow-end-left {
                    &:before {
                        content: 'First';
                        margin-right: 20px;
                    }
                }
                &.k-i-arrow-60-left {
                    &:before {
                        content: 'Prev';
                    }
                }
                &.k-i-arrow-60-right {
                    &:before {
                        content: 'Next';
                        margin-right: 15px;
                    }
                }
                &.k-i-arrow-end-right {
                    &:before {
                        content: 'Last';
                        margin-left: 5px;
                    }
                }
            }
        }
    }
    .k-pager-numbers-wrap {
        margin: 0 10px 0 0;
        .k-pager-numbers {
            margin: 0 10px;
            .k-link {
                color: $secondary-grey;
                // font-family: $rs-font-light;
                font-size: $font-xs;
                position: relative;
                top: 0;
                &.k-state-selected {
                    font-family: $rs-font-regular;
                    border-radius: 50%;
                    background-color: $primary-color;
                    color: $white;
                    min-width: 35px;
                    min-height: 35px;
                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
    }
    .k-pager-sizes {
        padding-left: 20px;
        margin-right: 0;
        border-left: 1px solid $secondary-grey;
        color: transparent;
        position: relative;
        &.k-label::after {
            content: 'per page';
            position: absolute;
            font-family: $rs-font-regular;
            font-size: 14px;
            color: $secondary-grey;
            right: 0;
        }
        .k-widget.k-dropdown {
            margin-bottom: 0;
        }
        // .k-dropdownlist {
        .k-dropdown-wrap {
            height: 20px;
            max-width: 50px;
            margin-right: 10px;
            position: relative;
            // top: -2px;
            .k-button {
                width: auto;
            }
            // .k-input-value-text {
            .k-input {
                font-size: $font-xs;
                //text-align: right;
                line-height: 1;
                padding-right: 5px;
                color: $primary-black;
                height: 20px;
            }
        }
    }
}


// LISTING
.panel-detail-view {
  table.accordian-body {
    border-spacing: 0 0em !important;
    border-radius: 0 0 5px 5px;

    p {
      margin-bottom: 0;
      @include truncate(170px);
    }

    .friendCampaignName {
      margin-bottom: 0;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 225px;
    }

    tr {
      background-color: $white;
    }

    thead {
      th {
        background-color: $bg-color4;
        border-left: 0;
        border-bottom-color: $br-color3;
      }
    }

    tbody {
      tr {
        &:last-child td {
          border-bottom: 0;
        }

        td {
          padding: 8px 12px;
          border-left: 0;
          overflow: inherit;
          border-bottom: 1px solid $border-color;
        }

        &.odd {
          background-color: $odd;
        }
      }
    }
  }
}

.rs-grid-listing {
  table tbody.k-table-tbody>.k-table-row {
    background-color: transparent;
  }

  tr {
    &.k-detail-row td.k-detail-cell {
      background-color: $white;
      border: 1px solid $border-color !important;
      width: calc(100% - 13px);

      @if $new-theme-test ==true {
        @extend %new-theme-shadow;
        overflow: hidden;

        tbody tr:not(.k-detail-row):hover,
        tbody tr:hover {
          background-color: $tm_backgroundBg;
          border-radius: $theme-radius-size;
        }
      }
    }
  }

  tr.k-master-row {
    td {
      &[class*='status-'] {
        @if $new-theme-test ==true {
          // background-color: $white;
          background-color: transparent;
          border-radius: $theme-radius-size;
        }
      }

      &:nth-child(1) {
        a {
          padding: 0;
        }
      }
    }
  }

  .k-hierarchy-cell {
    .k-icon {
      &:before {
        z-index: 1;
        position: relative;
        color: $white;
        right: -4px;
        bottom: -1px
      }

      &:after {
        border-bottom: 28px solid transparent;
        border-left: 28px solid transparent;
        position: absolute;
        right: 0px;
        bottom: -1px;
        width: 0;
        height: 0;
        content: "";
        z-index: 0;
      }
    }
  }
}

.rs-grid-listing {
  border: 0;
  padding: 0;

  @if $new-theme-test ==true {
    background-color: transparent;

    tr.k-master-row {
      background-color: $beta_tm_body_bg;
      @extend %new-theme-box-shadow;
      // @extend %new-theme-shadow;

      &:hover {
        @if $theme-radius ==true {
          border-radius: $theme-radius-size;
        }
      }
    }

    table {
      padding: 0;
      border: 0px solid $border-color;
      box-shadow: none;
      border-radius: 0;
      width: 100%;

      tbody tr:first-child td {
        border-top: 0 !important;
      }

      tbody tr td:first-child {
        border-left: 0 !important;
      }
    }

    .k-pager.k-grid-pager {
      margin-top: 10px;
    }
  }

  table {
    border-collapse: separate;
    border-spacing: 0 1em !important;
    border: 0 !important;
  }

  .colgroup,
  .k-grid-header {
    display: none;
  }

  tr {
    &.k-master-row {
      position: relative;

      &.k-alt,
      &.k-alt:hover {
        background-color: $beta_tm_body_bg !important;
      }

      td {
        &:nth-child(1) {
          width: 2%;
          float: right;
          position: absolute;
          right: 0;
          bottom: 0;
          border: 0;
          padding: 0;
        }

        &:nth-child(2) {
          width: 100%;
          float: left;
          padding: 0;
          border: 0;
        }
      }
    }

    &.k-detail-row {
      display: block;
      margin-top: -17px;
      position: relative;
      background-color: transparent !important;

      @if $new-theme-test ==true {
        display: inline-block;
        width: 100%;
        background-color: transparent;
      }

      .accordian-body thead {
        // display: none;
      }

      td {
        &.k-hierarchy-cell {
          position: absolute;
          width: 2%;
          float: right;
          border: 0;
        }

        &.k-detail-cell {
          width: calc(100% - 2px);
          float: left;
          padding: 0;
          border: 0;

          @if $new-theme-test ==true {
            width: calc(100% - 13px);
            border-radius: 0 0 $theme-radius-size $theme-radius-size !important;
            left: 6px;
            position: relative;
          }
        }
      }
    }
  }

  .k-pager-wrap {
    top: -10px;
  }

  &.pager-extra-space {
    .k-pager-wrap {
      top: -30px;
    }
  }

  &.rs-child-table-border {
    tr {
      &.k-master-row {
        td {

          &:nth-child(1),
          &:nth-child(2) {
            border-bottom: 1px solid $border-color !important;
            padding: 8px 12px;
          }
        }
      }
    }

    .accordian-body tr {
      &.k-master-row {
        td {

          &:nth-child(1),
          &:nth-child(2) {
            border-bottom: 1px solid $border-color !important;
          }
        }
      }

      &:last-child td {
        border-bottom: 0 !important;
      }
    }
  }
}

.rs-grid-listing.admin-listing-table {
  tr.k-master-row.k-alt {
    background-color: $even;
  }
}

.k-widget.k-grid {
    &.rs-grid-listing {
        table {
            border-radius: 0 0 4px 4px;
            tbody {
                tr {
                    background-color: $body-bg-color !important;
                }
            }
            .panel-detail-view {
                table.accordian-body tbody tr {
                    background-color: $white !important;
                }
            }
        }
    }
    .k-pager-info {
        display: none;
    }
}

.k-grid-container {
    table {
        border-radius: 0 0 5px 5px;
        // border: 1px solid $border-color;
        border-top: 0;
    }
}

.k-grid-content.k-virtual-content {
    // border: 1px solid $border-color;
    // border-top: 0;
    border: 0;
    border-radius: 0 0 5px 5px;
}

.k-widget.k-grid.grid-replaceable table {
//   border-radius: 8px;
//   border: 1px solid $border-color;
//   padding: 5px;
}
.rs-kendo-grid-table .rs-kendo-scrollable-grid,
.k-widget.k-grid.grid-replaceable {
  padding: 5px;
  border-radius: 5px;
  border: 1px solid $border-color;
  box-shadow: none;
  background-color: $white;
  margin-bottom: 50px;
}
.rs-kendo-grid-table .rs-kendo-scrollable-grid .k-pager,
.k-widget.k-grid.grid-replaceable .k-pager {
  margin-top: 0;
  margin-bottom: -25px;
  top: 20px !important;
}