.rsp-icon {
    border-radius: 28px;
    @extend %animate;
    &, i {
        display: flex !important;
        align-items: center !important;
        justify-content: center;
    }
    // &:hover {
        // background-color: #fff;
        // box-shadow: 1px 3px 4px -3px #00000069;
    // }
    &.inline {
        display: inline-block !important;
    }
    &.rsp-icon-sm,
    &.rsp-icon-default {
        &, i {
            width: 28px;
            height: 28px;
        }
    }
    &.rsp-icon-md {
        &, i {
            width: 26px;
            height: 26px;
        }
    }
    &.rsp-icon-lg {
        &, i {
            width: 33px;
            height: 33px;
        }
    }
    &.rsp-circle {
        &:hover {
            background-color: $primary-color;
            i {
                color: #ffffff;
            }
        }
        i {
            color: $primary-color;
            @extend %animate;
        }
    }
}
.rsp-icon-group {
    display: inline-block;
    .rsp-icon {
        float: left;
        margin-left: 15px;
        &.rsp-icon-default {
            margin-left: 8px;
        }
        &:first-child {
            margin-left: 0;
        }
    }
}






// ICON CUSTOM ICOMOON
.icon-dropdown-small:before {
    content: "\e9b9";
    font-family: 'icomoon';
}

.icon-plus-mini,
.icon-plus-small,
.icon-plus-medium,
.icon-plus-large,

.icon-sd-plus-mini,
.icon-sd-plus-small,
.icon-sd-plus-medium,
.icon-sd-plus-large {
    &:before {
        content: "\ea0c";
        font-family: 'icomoon';
    }
}
.icon-minus-mini,
.icon-minus-small,
.icon-minus-medium,
.icon-minus-large,

.icon-sd-minus-mini,
.icon-sd-minus-small,
.icon-sd-minus-medium,
.icon-sd-minus-large {
    &:before {
        content: "\e9f9";
        font-family: 'icomoon';
    }
}

.multi-icon-wrap {
  position: relative;
  .multi-icon-small {
    position: absolute;
    right: -7px;
    bottom: 3px;
    background-color: #fff;
    border-radius: 25px;
    border: 1px solid #fff;
  }
}