@import
'../../functions/breakpoints';
// Tooltip
.tooltip .tooltip-inner {
  position: relative;
}
.tooltip.bs-tooltip-bottom .tooltip-inner {
  top: 7px;
}
.tooltip.bs-tooltip-top .tooltip-inner {
  bottom: 7px;
}
.tooltip.bs-tooltip-start .tooltip-inner {
  right: 7px;
}
.tooltip.bs-tooltip-end .tooltip-inner {
  left: 7px;
}

.tooltip {
    position: fixed;
    top: 0;
    left: 0;
    pointer-events: none;
    z-index: 10561;
    font-family: $rs-font-regular;
    transition: inherit;
    &.show {
      opacity: 1;
    }
    .tooltip-inner {
      color: $white;
      text-align: center;
      background-color: $ch_tooltipBgColor !important;
      @include br(4px);
      font-size: $font-xxs;
      position: relative;
    }
    @if $tooltipAnimation == true {
        & {
          .tooltip-arrow:before {
            content: inherit;
          }
          .tooltip-inner {
              animation: stretch-animation 0.2s ease-out forwards .05s;
              padding: 4px 10px;
              @extend %tooltip-shadow;
              letter-spacing: 0.5px;
              &:before {
                  content: '';
                  position: absolute;
                  width: 8px;
                  height: 8px;
                  background-color: $ch_tooltipBgColor;
                  // top: 0;
                  margin-left: -3px;
                  // margin-top: -4px;
                  transform: rotate(45deg);
              }
          }
          &.bs-tooltip-start {
            .tooltip-inner {
              &:before {
                  top: 50%;
                  margin-right: -4px;
                  margin-top: -4px;
                  right: 0;
              }
            }
          }
          &.bs-tooltip-end {
            .tooltip-inner {
              &:before {
                  top: 50%;
                  margin-left: -4px;
                  margin-top: -4px;
                  left: 0;
              }
            }
          }
          &.bs-tooltip-bottom {
            .tooltip-inner {
              &:before {
                  top: 0;
                  left: 50%;
                  margin-top: -4px;
              }
            }
          }
          &.bs-tooltip-top {
            .tooltip-inner {
              &:before {
                  bottom: 0;
                  left: 50%;
                  margin-bottom: -4px;
              }
            }
          }
        }
    }
}
.k-tooltip {
  border-color:  $ch_tooltipBgColor;
  color: white;
  background-color:  $ch_tooltipBgColor;
  border-radius: $theme-radius-size-sm;
  animation: stretch-animation 0.2s ease-out forwards .05s;
  letter-spacing: 0.5px;
  @extend %tooltip-shadow;
  .k-callout {
    color: $ch_tooltipBgColor;
  }
}
@each $key, $i in $tooltip-position {
    .bs-tooltip-#{$i} {
      @if($i == start) {
        margin-right: 8px;
      }
    }
    .bs-tooltip-auto[x-placement^=#{$i}] .arrow:before,
    .bs-tooltip-#{$i} .arrow:before {
        border-#{$key}-color: $ch_tooltipBgColor;
    }
    .bs-tooltip-#{$i} .tooltip-arrow::before,
    .bs-tooltip-auto[data-popper-placement^=#{$i}] .tooltip-arrow::before {
        border-#{$key}-color: $ch_tooltipBgColor !important;
    }
}
@keyframes stretch-animation {
    0% {
      transform: scale(1,1)
    }
    // 10% {
    //   transform: scale(1.1,.9)
    // }
    // 30% {
    //   transform: scale(.9,1.1)
    // }
    50% {
      transform: scale(1.08,.90)
    }
    100% {
      transform: scale(1,1)
    }
}