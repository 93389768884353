.k-widget {
    font-size: $font-xsm;
    &.k-dropdown {
        margin-bottom: 30px;
        margin-top: 0;
    }
}
.k-widget.k-datepicker.k-header,
.k-widget.k-dropdown.k-header {
    color: #95918d;
    background-image: none;
}
span.k-picker-wrap[class*="k-state-"],
.k-multiselect-wrap,
.k-dropdown.k-header .k-dropdown-wrap.k-state-default {
    border: none;
    background-image: none;
    border-width: 0 0 1px !important;
    border-color: #c5c5c5;
    border-top-color: transparent;
    border-bottom-color: $border-color;
    border-style: solid;
    padding: 3px 2px 0;
}
span.k-picker-wrap[class*="k-state-"]:before,
.k-multiselect-wrap:before,
.k-dropdown.k-header .k-dropdown-wrap.k-state-default:before {
    @include pseudo();
    right: -1px;
    bottom: -1px;
    @extend %dropdown-arrow-grey;
}
span.k-picker-wrap[class*="k-state-"]:after,
.k-multiselect-wrap:after,
.k-dropdown.k-header .k-dropdown-wrap.k-state-default:after {
    @include pseudo();
    left: 0;
    right: 0;
    bottom: -1px;
    height: 10px;
    z-index: 0;
    border-left: 1px solid $border-color;
    border-right: 1px solid $border-color;
}
.k-filtercell {
    .k-dropdown .k-dropdown-wrap .k-select {
        display: block;
    }
    .k-textbox {
        display: none !important;
    }
}
.k-dropdown .k-dropdown-wrap .k-select {
    display: none;
}
// KENDO hover
.k-multiselect-wrap.k-state-focused,
.k-multiselect .k-multiselect-wrap,
.k-multiselect-wrap:hover,
.k-multiselect-wrap:focus,
span.k-picker-wrap[class*="k-state-"].k-state-focused,
span.k-picker-wrap[class*="k-state-"]:hover,
span.k-picker-wrap[class*="k-state-"]:focus,
.k-dropdown.k-header .k-dropdown-wrap.k-state-default:hover,
.k-dropdown.k-header .k-dropdown-wrap.k-state-default:focus {
    background: none;
    background-image: none;
    box-shadow: none;
}
.k-multiselect-wrap.k-state-focused,
.k-multiselect .k-multiselect-wrap,
.k-multiselect-wrap:hover,
.k-multiselect-wrap:focus,
span.k-picker-wrap[class*="k-state-"].k-state-focused,
span.k-picker-wrap[class*="k-state-"]:hover,
span.k-picker-wrap[class*="k-state-"]:focus,
.k-dropdown.k-header .k-dropdown-wrap.k-state-default:hover,
.k-dropdown.k-header .k-dropdown-wrap.k-state-default:focus {
    background: none !important;
    background-image: none !important;
    box-shadow: none !important;
    border: none !important;
}
// KENDO hover border color
.k-multiselect-wrap,
span.k-picker-wrap[class*="k-state-"],
.k-dropdown.k-header .k-dropdown-wrap.k-state-default {
    &:hover {
        border-color: $border-color;
        border-bottom-color: $border-color;
        &:before {
            border-bottom: 6px solid $border-color;
            border-right: 6px solid $border-color;
        }
    }
}
span.k-picker-wrap[class*="k-state-"]:hover:after,
.k-dropdown.k-header .k-dropdown-wrap.k-state-default:hover:after {
    border-left: 1px solid $border-color;
    border-right: 1px solid $border-color;
}
// k-state focused
.k-multiselect-wrap.k-state-focused,
span.k-picker-wrap[class*="k-state-"].k-state-focused,
.k-dropdown.k-header .k-dropdown-wrap.k-state-default.k-state-focused {
    border-color: var(--th-primary-color);
    border-bottom-color: var(--th-primary-color);
    &:before {
        border-bottom: 6px solid var(--th-primary-color);
        border-right: 6px solid var(--th-primary-color);
    }
    &:after {
        border-left: 1px solid var(--th-primary-color);
        border-right: 1px solid var(--th-primary-color);
    }
}
// .k-pager-wrap {
//     font-size: $font-xxs;
// }
.noHeaderBg .k-grid-header {
    background-color: transparent;
    color: #333;
    font-family: $rs-font-bold;
}
// KENDO LIST
.k-listview {
    background-color: $tm_backgroundBg;
    @if $new-theme-test == true {
        background-color: $beta_tm_body_bg;
    }
}
.k-grid {
    color: $table-body-color;
    border-color: $border-color;
    background-color: $tm_backgroundBg;
    @if $new-theme-test == true {
        background-color: $beta_tm_body_bg;
    }
    &.rs-grid-listing {
        table {
            background-color: $tm_backgroundBg;
            @if $new-theme-test == true {
                background-color: $body-bg-color;
            }
        }
    }
    table {
        @if $new-theme-test == true {
            background-color: #ffffff;
        }
        @if $theme-radius == true {
            // border-radius: $theme-radius-size;
            overflow: hidden;
        }
    }
    td {
        border-width: 0 0px 1px 1px;
    }
    &.grid-vertical-noscroll {
        table {
            border: 0;
            border-radius: 0;
            @if $new-theme-test == true {
                background-color: transparent;
            }
        }
        .k-grid-header {
            border-radius: 5px 5px 0 0;
            overflow: hidden;
            .k-grid-header-wrap table {
                border: 0;
            }
        }
        .k-grid-container {
            border-radius: 0 0 5px 5px;
            overflow: hidden;
        }
    }
}
.k-list .k-item {
    &:hover {
        background-color: #e9e9eb;
        &.k-state-selected {
            color: $input-color;
        }
    }
    &.k-state-selected {
        &,
        &:hover {
            background-color: $even;
            color: $input-color;
        }
    }
}
.k-grid-header {
    background-color: $table-header;
    color: $white;
    padding-right: 0;
}
.k-grid tr.k-alt {
    background-color: $even;
    &:hover {
        background-color: $even !important;
    }
}
.k-grid.rs-grid-listing {
    tr.k-alt {
        &:hover {
            background-color: #f4f5fa !important;
        }
    }
}
.k-grid tbody tr:not(.k-detail-row):hover,
.k-grid tbody tr:hover {
    // background-color: $tm_backgroundBg;
    background-color: transparent;
    // @if $new-theme-test == true {
    //     background-color: $beta_tm_body_bg !important;
    // }
}
label.k-pager-sizes.k-label select {
    -webkit-appearance: none;
}
.myKendoTable .k-icon {
    color: $white;
}
button.k-button {
    padding: 6px 20px;
    border-radius: 5px;
}
.no-k-alt tr.k-master-row.k-alt {
    background-color: transparent;
}
// kendo table
.k-pager-wrap.k-pager a.k-link span,
.k-pager-wrap.k-pager a.k-link.k-state-disabled span {
    color: var(--th-primary-color);
}
.k-pager-numbers {
    .k-state-selected {
        background-color: var(--th-primary-color);
        border-radius: 50%;
    }
    .k-link {
        color: var(--th-primary-color);
    }
}
.k-pager-wrap .k-link,
.k-pager-numbers .k-link {
    border-radius: 50%;
}
a.k-link.k-state-selected {
    color: $white;
}
.k-pager-numbers .k-link:focus,
.k-pager-wrap .k-link:focus {
    box-shadow: none !important;
}
col.k-sorted,
th.k-sorted {
    background-color: transparent;
}
@each $key, $name in $asdesc {
    .k-i-sort-#{key}-sm::before {
        content: #{$name}
    }
}
// .k-pager-wrap.k-grid-pager {
//     background-color: transparent;
//     border: 0;
//     padding: 4px 0;
// }
.myKendoTable {
    border: 0;
    &.chartTable {
        table {
            width: 94%;
            margin: 3%;
        }
    }
    &.noBorder {
        table {
            border: 0 solid $color0;
        }
    }
    .k-grid-container {
        border: 1px solid rgba(0, 0, 0, 0.08);
    }
    table {
        border: 1px solid rgba(0, 0, 0, 0.08);
    }
}
.tableHeader {
    padding: 15px 15px 10px;
    margin: 0;
    h3 {
        font-size: $font-xsm;
    }
}
.k-grid-header,
.k-header,
.k-grid-header-wrap,
.k-grouping-header,
.k-grouping-header .k-group-indicator,
.k-grid td,
.k-grid-footer,
.k-grid-footer-wrap,
.k-grid-content-locked,
.k-grid-footer-locked,
.k-grid-header-locked,
.k-filter-row>td,
.k-filter-row>th {
    border-color: $border-color !important;
}
// drop down
.k-dropdown {
    width: 100%;
    .k-dropdown-wrap {
        line-height: 33px;
        background: inherit !important;
        border: none !important;
        box-shadow: none !important;
        height: 33px;
        &:hover {
            cursor: pointer;
        }
        &.k-state-focused {
            box-shadow: none !important;
        }
        .k-input {
            font-size: $font-xsm;
            color: $input-color;
            font-family: $rs-font-regular;
            height: 33px;
            letter-spacing: 0.3pt;
            display: block;
            @include text-shorten();
            &:before {
                content: '';
                position: absolute;
                right: 0;
                bottom: 0;
                box-sizing: border-box;
                @extend %dropdown-arrow-color;
            }
        }
    }
}
.k-list {
    background-color: $white;
    @include bor();
    border-top: none;
    box-shadow: none;
    border: 0;
    li {
        font-size: $font-xs;
    }
    .k-item:hover {
        background-color: var(--th-primary-color);
        color: $white
    }
}
// test campaign approval -- table
.k-master-row td,
.white-space-table table td {
    // white-space: pre;
}
.white-space-table .k-grid-header table thead tr th {
    background-color: var(--th-primary-color) !important;
}
// kendo checkbox
.k-checkbox-label {
    &::after {
        transition: max-width linear 280ms;
        font-size: $font-xxs;
        font-weight: bold;
        margin: -1px -1px;
    }
    &:hover {
        &::before {
            border-color: $border-color;
        }
    }
    &::before {
        border-color: $border-color;
        border-radius: 0px;
        width: 16px;
        height: 16px;
    }
}
.k-checkbox:checked+.k-checkbox-label::after {
    color: #4a2e2e;
}
.k-radio:checked+.k-radio-label,
.k-checkbox:checked+.k-checkbox-label {
    &::before {
        border-color: $checkbox-color;
        background-color: $white;
    }
    &::after {
        @include css3-prefix(transform, scale(1));
        color: $checkbox-color;
        top: 5px;
    }
}
.k-checkbox-label {
    line-height: inherit;
    &::after {
        font-size: $font-xxs;
        transition: max-width linear 280ms;
        max-width: 0;
        -ms-flex-pack: start;
        justify-content: flex-start;
        text-indent: 2px;
    }
}
// radio
.k-radio:checked+.k-radio-label,
.k-radio:indeterminate+.k-radio-label {
    &:before {
        background-color: #fff;
        border-color: var(--th-primary-color);
        box-shadow: 0 0 1px 0 #fff inset;
    }
    &:after {
        background-color: var(--th-primary-color);
        transition: -webkit-transform linear 280ms;
        transition: transform linear 280ms;
        transition: transform linear 280ms, -webkit-transform linear 280ms;
    }
}
.k-checkbox:checked+.k-checkbox-label,
.k-checkbox:indeterminate+.k-checkbox-label {
    &::after {
        max-width: 20px
    }
}
.k-radio:checked:focus+.k-radio-label,
.k-checkbox:checked:focus+.k-checkbox-label {
    &::before {
        box-shadow: 0 0 0 2px rgba(var(--th-primary-color), 0.5);
    }
}
.k-list-optionlabel.k-state-selected,
.k-list-optionlabel:hover.k-state-selected {
    background-color: $checkbox-color;
}
.k-radio:checked+.k-radio-label::after,
.k-checkbox:checked+.k-checkbox-label::after {
    color: $checkbox-color;
}

// drop down normal
span.k-dropdown.dropdown-normal {
    .k-input {
        background: none !important;
    }
    .k-dropdown-wrap {
        width: 80px; // auto, 80px
        .k-input:before {
            content: inherit;
        }
    }
}
// table pagination section
.grid-pagenation {
    border: 0;
    table {
        border: 1px solid $border-color;
        border-bottom: 0;
    }
    .k-pager-wrap {
        // display: flex;
        // justify-content: space-between;
        align-items: center;
        // list view grid
        width: 100%;
        border: 0;
        padding: 0 0 8px;
        // list view grid
        background-color: $tm_backgroundBg;
        @if $new-theme-test == true {
            background-color: transparent; // $beta_tm_body_bg;
        }
        .k-link {
            padding: 0;
            position: relative;
            // top: 9px;
            display: inline-block;
            min-width: 17px;
            height: 20px;
        }
        .k-pager-sizes {
            position: absolute;
            right: 0;
            display: flex;
            flex-direction: row-reverse;
            align-items: end;
            margin: 0px 0 0;
            font-size: 0;
            top: -6px;
            .k-dropdown,
            .k-dropdown .k-input {
                // height: 26px;
                @include inputBorder($input-border, $input-border, $input-border);
            }
            .k-dropdown .k-dropdown-wrap {
                height: 26px;
            }
            .k-dropdown .k-input {
                font-size: $font-xs;
                display: flex;
                justify-content: center;
            }
            span.k-widget.k-dropdown {
                font-size: $font-xxs;
                width: 45px;
                margin-top: 2px;
            }
            &:before {
                content: "Items per page";
                position: absolute;
                left: -95px;
                display: inline-block;
                width: 120px;
                height: 20px;
                font-size: $font-xs;
                top: 8px;
            }
        }
        .k-pager-input {
            display: inline-block;
            position: relative;
            font-size: $font-xs;
            margin: 0 7px 0;
            // list view grid
            &.k-label {
                text-transform: lowercase;
                &::first-letter {
                    text-transform: capitalize;
                }
            }
            // list view grid
            input.k-textbox {
                height: calc(1.4285714286em + 6px);
                width: 2.9em;
                border: 0;
                position: relative;
                top: -4px;
                text-align: center;
                padding: 4px 8px 0px;
                &:focus {
                    box-shadow: none;
                }
            }
        }
        .k-pager-info {
            display: none;
        }
        .k-widget.k-dropdown {
            margin: 0 0 0 10px;
        }
    }
    &.grid-vertical-noscroll {
        .k-grid-container {
            border-left: 1px solid $border-color;
            border-right: 1px solid $border-color;
            border-bottom: 1px solid $border-color;
            table.k-grid-table {
                border-left: 0;
                border-right: 0;
            }
        }
    }
}
.grid-new-theme {
    @if $new-theme-test == true {
        table {
            padding: 20px 20px 100px;
            border: 1px solid $border-color;
            box-shadow: 0px 4px 9px -2px #0000001f;
            border-radius: 6px;
            width: calc(100% - 40px);
            tbody tr:first-child td {
                border-top: 1px solid $border-color !important;
            }
            tbody tr td:first-child {
                border-left: 1px solid $border-color !important;
            }
            tbody tr td:last-child {
                border-right: 1px solid $border-color !important;
            }
        }
    }
}
// table
.k-grid {
    th {
        padding: 10px 12px;
    }
    td {
        padding: 12px 12px;
    }
}
.grid-show-link {
    &.k-grid td:first-child {
        word-wrap: break-word;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 400px;
    }
}

// kendo filter
.k-grid-columnmenu-popup {
    // border: 1px solid $border-color;
    border: 0;
    width: 190px;
    border-top: 0;
    border-bottom: 0;
}
.k-columnmenu-item {
    display: none;
}
.k-columnmenu-item-content {
    .k-filter-menu {
        .k-widget.k-dropdown {
            margin-bottom: 0;
        }
        .k-actions {
            margin: 7px 0;
        }
        .k-button[type="reset"] {
            background-color: transparent !important;
            color: #fc6a00 !important;
            font-size: $font-xs;
            border: 0;
            width: 48%;
            background-image: none;
            padding: 6px 0;
            &:hover {
                text-decoration: underline;
            }
        }
        .k-button.k-primary {
            background-color: var(--th-primary-color) !important;
            color: #fff !important;
            border: none !important;
            font-size: $font-xs;
            width: 48%;
            border-radius: 0;
            padding: 4px 0;
        }
        .k-widget.k-filter-and {
            width: -webkit-fill-available;
        }
        .k-textbox,
        .k-textbox:focus {
            border: 0;
            box-shadow: none;
        }
        .k-dropdown .k-dropdown-wrap .k-input {
            font-size: $font-xs;
        }
    }
}
.k-popup.k-child-animation-container.k-slide-down-enter.k-slide-down-enter-active.k-slide-down-exit.k-slide-down-exit-active {
    max-width: inherit;
}
.k-columnmenu-item-content .k-filter-menu .k-dropdown .k-dropdown-wrap .k-input {
    font-size: $font-xs;
}
.k-filter-menu .k-button[type="reset"]:focus {
    box-shadow: none;
}
.k-animation-container {
    margin: 2px 0;
    box-shadow: none;
    .k-popup {
        &.range-calender {
            position: relative;
            top: -67px;
            box-shadow: none !important;
            border: 0 !important;
        }
        .k-timeselector {
            border: 1px solid $border-color;
        }
        .k-list-scroller {
            max-height: 164px !important;
            @if $theme-radius == true {
                overflow: hidden;
            }
        }
    }
}
.k-filter-menu-container {
    padding: 0;
    gap: 2px;
}
.k-grid-header .k-grid-filter,
.k-grid-header .k-header-column-menu {
    height: 39px;
    padding-top: 5px;
    position: relative;
    top: -1px;
}
// DROPDOWN
// .k-animation-container {
//     &, >.k-popup {
//     }
//     &>.k-popup.k-child-animation-container {
//         &[class*="k-slide-"] {
//         }
//     }
// }
.k-list-optionlabel {
    background-color: $white;
    border-top: none;
    border-bottom: none;
    &:hover {
        background-color: var(--th-primary-color) !important;
        color: $white !important;
    }
}
.k-list-optionlabel.k-state-selected {
    background-color: $even !important;
    color: #333 !important;
    &:hover {
        background-color: $even !important;
        color: #333 !important;
    }
}
.k-animation-container {
    .k-list {
        li {
            font-size: $font-xsm;
            letter-spacing: 0.5px;
            box-shadow: none !important;
        }
    }
}
// SELECT DROPDOWN
.k-animation-container {
    .k-popup.k-list-container {
        max-height: 200px;
    }
    &.k-list-container .k-popup {
        &.k-child-animation-container {
            $position: down, up;
            @each $value in $yposition {
                &.k-slide-#{$value}-enter .k-list {
                }
            }
        }
        .k-list li {
            font-size: $font-xs; //$font-xsm;
            line-height: $k-select-dropdown-lh;
            display: block;
        }
    }
}
.k-animation-container-shown {
    .k-list {
    }
    .k-popup.k-child-animation-container {
        @each $value in $yposition {
            &.k-slide-#{$value}-enter {
                border-top: 1px solid $border-color;
                border-bottom: 1px solid $border-color;
                margin-top: -1px;
                .k-list {
                    border-top: 0;
                    border-bottom: 0;
                    // min-width: min-content;
                    // width: inherit
                }
            }
        }
        // NO DATA
        @each $value in $yposition {
            .k-slide-#{$value}-enter {
                .k-list {
                    border-top: 0;
                    border-bottom: 0;
                    border-left: 0;
                }
            }
        }
        // NO DATA
    }
}
.k-animation-container-shown {
  border-radius: 5px;
}
.k-animation-container > .k-popup {
    border: 1px solid $border-color;
    @if $new-theme-test == true {
        border: 1px solid #c2cfe3;
        box-shadow: 0px 4px 9px -2px #0000001f;
        border-radius: $theme-radius-size-sm;
        overflow: hidden;
    }
    .k-list {
        background-color: $white;
        @if $theme-radius == true {
          border-radius: $theme-radius-size-sm;
          overflow: hidden;
        }
        li {
            font-size: $font-xsm;
        }
        .k-item:hover {
            background-color: $primary-color;
            color: $white
        }
    }
}

// NO DATA
.k-list-container .k-nodata {
    // border-right: 1px solid $border-color;
    // border-left: 1px solid $border-color;
    min-height: 50px !important;
    padding-top: $theme-space;
    padding-bottom: $theme-space;
    &:before,
    &:after {
        content: '';
        position: absolute;
        bottom: -1px;
        left: 0;
        right: 0;
        z-index: 1;
    }
    &:after {
        border-bottom: 1px solid $border-color;
        top: 0px;
    }
    h4 {
        margin-bottom: $theme-space;
        span {
            margin-right: 5px;
        }
        br {
            display: none;
        }
    }
    div {
        text-transform: lowercase;
        &::first-letter {
            text-transform: capitalize;
        }
    }
}
.table > {
    :not(:last-child)> :last-child>* {
        border-bottom-color: $border-color;
    }
    :not(:first-child) {
        border-top: 0px solid currentColor;
    }
}
.k-button-group .k-button:focus {
    box-shadow: none !important;
}
.k-button.k-state-selected,
.k-button-secondary.k-state-selected,
.k-button.k-state-selected:focus {
    background-color: var(--th-primary-color);
}
.k-button-primary,
.k-button.k-primary,
.k-button-primary:hover,
.k-button.k-primary:hover,
.k-button-primary.k-state-hover,
.k-state-hover.k-button.k-primary {
    border-color: var(--th-primary-color) !important;
    background-color: var(--th-primary-color) !important;
    color: #fff;
}
.k-time-list-wrapper .k-title {
    font-size: $font-xs;
    font-weight: normal;
    font-family: "MuktaBold", sans-serif;
}
.k-time-header .k-title {
    font-weight: normal;
}
.k-time-header .k-time-now,
.k-time-header .k-time-now:hover,
.k-time-header .k-time-now:focus {
    font-family: "MuktaBold", sans-serif;
    color: #333;
}
.k-time-list .k-item:hover {
    color: var(--th-primary-color);
}
.k-grid-header .k-grid-filter:hover,
.k-grid-header .k-header-column-menu:hover,
.k-grid-header .k-hierarchy-cell .k-icon:hover {
    background-color: $table-header;
}
.k-grid-header .k-grid-filter:hover {
    background-color: transparent;
}
.k-button.k-primary:focus {
    box-shadow: none;
}
.k-list-scroller {
    // border-left: 1px solid $border-color;
}
.k-listbox .k-list-scroller {
    border: 1px solid $border-color;
    border-top-color: var(--th-primary-color);
    ul.k-list {
        border: 0;
        li.k-item {
            padding: 7px 8px;
            cursor: pointer;
            border-bottom: 1px solid #e9e9eb;
            &:nth-child(odd) {
                background-color: #f0f8ff;
                color: #666;
            }
            &:nth-child(even) {
                background-color: #fff;
                color: #666;
            }
            &.k-state-selected {
                background-color: var(--th-primary-color);
                color: #fff;
            }
        }
    }
}
.k-listbox-toolbar ul.k-reset {
    margin-top: 30px;
    li {
        .k-button-icon {
            .k-icon {
                width: 25px;
                height: 25px;
                background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAB+BAMAAADM9MyMAAAAJFBMVEVkaIMAAABkaINkaINkaINkaINkaINkaINkaINkaINkaIP///9XVknzAAAAC3RSTlOAAH4PO3pxXCtUU1qhDv4AAAEBSURBVDjL7ZQ96gIxEMUf4f9RpAprs1aCIGq3nkArWyuxFE9g4QkED6BH8ATpczmTWR/s6xQUG4dA8njDTDI/CEII1XK3PjT5gHy+Ise0MXGCxaaI3qAVbpHFEfcYB1Qrit8G/bJHU2fMypZMDDE3YdYP9mXzZv1jC9D6wwCg5URImhSQ0tq07lxHLipP0Mfps0N1KeeJDcRGNeKoGB8S7yAH3yGHRHKcNcmlSHI0jBwNOBGSJgWktDatYdaX3EPkfJecj3mRnE9lkVyKZRk5Wi05Wk6EpEkBKa1N6yy+5J76LWNLjlM0cpy1keOsjRwNI0cDToSkSQEprU1rXufl5G5BhJ8318IKEAAAAABJRU5ErkJggg==);
                background-position: 0 0;
                background-repeat: no-repeat;
                &:before {
                    content: inherit;
                }
                &.k-i-arrow-60-left {
                    transform: rotate(180deg);
                }
            }
            &:hover,
            &:focus {
                background: none;
                box-shadow: none;
            }
        }
    }
}
.k-listbox.k-listbox-toolbar-right .k-listbox-toolbar {
    margin-left: 18px;
}
// COLOR PICKER
.k-animation-container {
    .k-popup.popup-color-picker {
        padding: 10px;
        border: 1px solid $border-color;
        background-color: #fff !important;
        .k-colorgradient {
            &:focus {
                box-shadow: none !important;
            }
            button.k-button:hover {
                background-color: #fff !important;
            }
            .k-widget.k-numerictextbox {
                border: 0;
            }
            .k-widget.k-numerictextbox:focus-within,
            .k-hex-value.k-textbox:focus-within {
                box-shadow: none;
            }
        }
    }
}
// CUSTOM K 2 B DROPDOWN
.kb-dropdown {
    margin: 0 !important;
    .k-dropdown-wrap .k-input {
        background: none !important;
        font-size: $font-sm;
        padding: 0;
        color: #fff;
        flex-direction: row-reverse;
        &:before {
            display: inline-block;
            margin-left: 0.255em;
            vertical-align: 0.255em;
            content: '';
            border-bottom: 0;
            position: relative;
            border-top: 9px solid #fff;
            border-right: 7px solid transparent;
            border-left: 7px solid transparent;
            top: 0px;
        }
    }
}
// LISTBOX
.listbox-toolbox {
    .k-listbox-toolbar {
        .k-reset {
            top: 50%;
            position: relative;
            transform: translateY(-50%);
            margin-top: 0;
        }
    }
}
.picker-default-set {
    position: absolute;
    right: 0;
    z-index: 1;
    width: 38px;
    height: 33px;
    &:hover {
        cursor: pointer;
    }
}
.k-animation-container > .k-popup.k-child-animation-container .k-item.k-custom-item .k-icon.k-i-plus {
    display: none;
}
.k-item.k-custom-item {
    word-break: break-all;
}

// calender with time single month
.k-animation-container.k-animation-container-relative.k-datetime-container {
    .k-popup.k-child-animation-container .k-date-tab.k-datetime-wrap .k-calendar-view,
    .k-popup.k-child-animation-container .k-datetime-selector {
        height: 230px;
    }
    .k-date-tab .k-datetime-buttongroup .k-button-group .k-button.k-state-selected,
    .k-time-tab .k-datetime-buttongroup .k-button-group .k-button.k-state-selected{
        background-color: var(--th-primary-color);
    }
    &.k-group.k-reset .k-datetime-footer.k-actions .k-button.k-time-accept {
        margin: 0 15px;
    }

    &.k-group.k-reset .k-datetime-footer.k-actions {
        margin-bottom: 15px;
        margin-top: 10px;
    }

    &.k-group.k-reset .k-datetime-time-wrap .k-time-header {
        display: none;
    }
}

.k-animation-container.k-animation-container-relative.k-datetime-container .k-popup.k-child-animation-container .k-datetime-selector .k-datetime-calendar-wrap .k-widget.k-calendar .k-calendar-view.k-vstack.k-calendar-monthview .k-content.k-calendar-content.k-scrollable {
    // overflow-y: inherit;
}

.k-animation-container-shown .k-popup.ic-dropdown {
    &.k-child-animation-container.k-slide-down-enter {
        @extend %arrow-top-right;

        &:before {
            right: 11px;
        }

        &:after {
            right: 8px;
        }
    }

    &.arrow-start.k-child-animation-container.k-slide-down-enter {
        @extend %arrow-top-left;
    }
}

// Rizwan
.k-widget.k-editor {
    border: 1px solid $border-color;
    border-radius: 5px;
    overflow: hidden;
}

.k-widget.k-toolbar {
    border-bottom: 1px solid $border-color;
}

.k-popup {
    .k-item {
        width: 100%;
        word-break: break-all;
    }
}

.editor-view {
    .k-widget.k-toolbar {
        overflow: inherit;

        .k-widget.k-dropdown {
            width: 170px;
        }
    }
}

.k-list-container.k-reset.k-group.ic-dropdown.marginT5.k-popup.k-child-animation-container.k-slide-down-enter.k-slide-down-enter-active {
    @extend %arrow-top-right;
}

.k-editor-content {
    *::selection {
        background-color: transparent !important;
    }
}

.k-widget.k-window {
    .k-edit-form-container .k-edit-label {
        text-align: left;
    }
    &.k-dialog .k-dialog-buttongroup .k-button {
        color: $active-color;
        &.k-primary {
          color: #ffffff;
        }
    }
}

.k-i-x.k-icon {
    font-family: 'icomoon';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: $font-sm;
    width: 30px;
    height: 30px;
    padding: 0px;
    color: $primary-color;
    border-radius: 50%;

    &:hover {
        color: #fff;
        background-color: $primary-color;
    }

    &:before {
        content: '\e99d' !important;
        position: relative;
        top: -2px;
    }
}

// .k-filterable.k-header.active {
//     .k-icon.k-i-more-vertical {
//         background-position: -48px -78px;
//     }
// }

.no-grid-pagenation {
    border-bottom: 0;
    .k-pager-wrap.k-pager {
        display: none;
    }
}
.dropmenu-align {
    button.k-button {
        padding-right: 0 !important;
        margin-right: 0;
    }
}
.total-link-wrap-col {
    tbody tr {
        td {
            &:nth-child(2) {
                max-width: 240px;
                word-wrap: break-word;
            }
        }
    }
}
.personalize-dropdown {
    .k-list {
        max-height: 155px;
        overflow-y: scroll;
    }
}

.pe-none {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-left: 10px;
  > span:first-child {
    top: 5px;
    position: relative;
  }
  .k-dateinput {
    width: 80px !important;
  }
}