
// d3 chart
.portlet-container.product-portlet.fullscreenView {
    svg {
        transform: scale(1.5);
        transition: all 0.4s ease 0s;
    }
    .portlet-body {
        text-align: center;
    }
    .portlet-chart {
        margin: 6% auto;
        display: inline-block;
    }
}
.portlet-box-theme {
    padding: $theme-space;
    background-color: $bg-color2;
    border: 1px solid $border-color;
    border-top-color: $primary-color;
    display: inline-block;
    width: 100%;
    .portlet-box-theme {
      border: 0px !important;
    }
    @if $new-theme-test == true {
      @extend %new-theme-shadow;
      .k-grid.rs-grid-listing table,
      .k-grid.rs-grid-listing table tr.k-master-row,
      .k-grid.rs-grid-listing table tr.k-master-row.k-alt,
      .panel-detail-view table.accordian-body tbody tr.odd:hover {
          background-color: #ffffff !important;
      }
      .k-grid.rs-grid-listing tbody tr:not(.k-detail-row):hover,
      .k-grid.rs-grid-listing tbody tr:hover {
          background-color: #f0f8ff !important;
      }
      ul.rs-chart-tab.tabs-filled-component {
        border: 0;
        border-radius: 5px 5px 0 0;
        li.tabDefault {
          @if $new-theme-test == true {
            border-bottom: 1px solid $border-color;
          }
          &.active:after {
            bottom: -6px;
          }
        }
      }
    }
}
.portlet-box-head {
    font-size: $font-xl;
    font-family: $rs-font-bold;
    color: $primary-color;
    text-align: center;
    height: 81px;
    padding-top: 5px;
    margin-top: -38px;
    margin-bottom: 0px;
    .brand-logo-login {
      font-family: $rs-font-bold;
    }
}
.k-grid.portlet-grid {
    td {
     padding: 13px 12px 10px;
   }
}

/* portlet common open */
.portlet-container {
  border: 1px solid $border-color;
  padding: $theme-space;
  position: relative;
  background-color: $white;
  margin-bottom: $theme-space;
  height: auto;
  @if $new-theme-test == true {
    @extend %new-theme-shadow;
  }
  /* .portlet-container, */
  @each $key, $height in $portlet-height {
    &.portlet-#{$key} {
      height: #{$height}px;
    }
  }
}
/* portlet header */
.portlet-container {
  &.sp-slider {
    height: 325px;
  }
  .portlet-header {
    // padding: 9px 0;
    height: 33px;
    line-height: 33px;
    margin-top: -9px;
    margin-bottom: 9px;
    .p-nav {
      &-left, &-right {
        @include flexbox();
        padding-top: 6px;
        .p-icon-sm {
          height: 33px;
          @include flexbox($y: center);
        }
      }
      &-left {
        float: left;
        align-items: center;
        .p-icon-sm {
          padding-right: 10px;
        }
        .dropdown {
          margin-right: 10px;
        }
      }
      &-right {
        float: right;
        .p-icon-sm {
          padding-left: 15px;
        }
        .dropdown {
          margin-left: 10px;
        }
        .new-dropdown.dotted {
          position: relative;
          right: -14px;
          .dropdown-menu {
            margin-right: 7px;
          }
        }
      }
    }
    /* portlet header left/right close */
    h4 {
      margin-bottom: 0;
      display: inline-block;
      line-height: 33px;
      font-size: $font-sm;
    }
  }
  /* portlet body */
  .portlet-header,
  .portlet-body {
    clear: both;
  }
  &.expand-full-view {
    width: 1151px;
    background: #fff;
    z-index: 4;
    @include screen(xxl) {
      width: 1128px;
    }
    @include screen(xl) {
      width: 935px;
    }
  }
}
.portlet-container.fullscreenView {
  position: fixed;
  top: 75px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: $header-notis-tooltip;
  height: auto !important;
  margin-bottom: 0;
  border: 0;
  padding: 0 20px 15px;
  &:before {
    content: '';
    position: absolute;
    top: -102px;
    height: 100px;
    left: 0;
    right: 0;
    background: rgba(255,255,255,0.7);
    z-index: 999;
  }
  .explanation-view {
    top: 48px;
    left: 159px;
    width: 194px;
    height: 194px;
    padding: 58px 15px;
  }
  .sunburst-legend {
    margin-top: 80px;
  }
}
/* sm */
%prt-sm-height { height: 215px; }
%prt-md-height { height: 292px; }
%prt-lg-height { height: 362px; }
$port-sizes: sm, md, lg;
@each $size in $port-sizes {
  .portlet-#{$size}-body-height { @extend %prt-#{$size}-height; }
  .portlet-container {
    &.portlet-#{$size} {
      .portlet-body {
        &, &-image {
          @extend %prt-#{$size}-height;
        }
        .tab-content.p-tabs-content-1 {
          &, .tab-pane {
            @extend %prt-#{$size}-height;
          }
        }
        &, .portlet-chart, .portlet-list {
          @extend %prt-#{$size}-height;
        }
      }
      &.portlet-footer-text {
        .portlet-body {
          &, .portlet-chart {
            @extend %prt-#{$size}-height;
          }
          .portlet-list {
            @extend %prt-#{$size}-height;
          }
        }
      }
    }
  }
}
/* md */
.portlet-container.portlet-md .portlet-body .portlet-list {height: 302px;}
/* lg */
.portlet-container.portlet-lg .portlet-body {
  .portlet- {
    &chart, &list {height: 372px;}
  }
}
.portlet-container {
  .portlet-list {
    @extend %scrollbar;
    li {
      padding: 15px 0;
      position: relative;
      float: left;
      width: 100%;
      border-top: 1px solid $br-color1;
      @include center($justify: space-between);
      &:first-child {
        border: none;
      }
      p {
        margin-bottom: 0;
        @include truncate(330px);
      }
      .percentage {
        font-size: $font-md;
        font-weight: 300;
        display: inline-block;
        vertical-align: middle;
        @include center();
        color: $default-color;
      }
      i {
        vertical-align: middle;
      }
      .user-infoicon {
        float: left;
        margin-right: 10px;
        img {
          border: 1px solid $br-color1;
          border-radius: 100%;
        }
      }
      small { display: block; }
    }
  }
  &.portlet-footer-label .portlet-info-text {
    bottom: 55px;
  }
  .portlet-image {
    @include flexbox($y: center, $x: center);
    height: 100%;
    img {
      margin-left: auto;
      margin-right: auto;
    }
  }
  .portlet-body {
    h2.user-count{
      font-size:40px;
      font-weight:bold;
      margin: 0;
    }
    h3 {
      margin: 0;
    }
  }
}
// portlet map open
$map-size: (
    sm: 192,
    md: 290,
    lg: 340
);
@each $key, $size in $map-size {
    .portlet-container.portlet-#{$key}.portlet-footer-label .portlet-map {
        height: #{$size}px;
        overflow: hidden;
    }
}
// portlet map close
/* portlet two label open */
.portlet-two-label {
  height: 45px;
  background-color: $even;
  margin: 0 -#{$theme-space};
  border-radius: 0 0 $theme-radius-size $theme-radius-size;
  ul {
    height: 100%;
    @include flexbox($y: center, $x: left);
    li {
      padding-left: 15px;
      &:first-child {
        font-size: $font-md;
        small { font-size: $font-xsm; }
      }
      &:last-child {
        font-size: $font-xs;
        padding-left: 7px;
      }
    }
  }
}
%prt-fot-sm-height { height: 192px; }
%prt-fot-md-height { height: 308px; }
%prt-fot-lg-height { height: 342px; padding-bottom: 30px; }
@each $key, $size in $port-sizes {
  .portlet-container.portlet-#{$key}.portlet-footer-label {
    .portlet-body {
      &, &-image {
        @extend %prt-fot-#{$key}-height
      }
      .tab-content.p-tabs-content-1 {
        &, &.tab-pane {
          @extend %prt-fot-#{$key}-height
        }
      }
      .portlet- {
        &chart, &list {
          @extend %prt-fot-#{$key}-height
        }
      }
    }
  }
}
%prt-bimg-h-sm { max-height: 172px; }
%prt-bimg-h-md { max-height: 280px; }
%prt-bimg-h-lg { max-height: 292px; }
@each $size in $port-sizes {
  .portlet-container.portlet-#{$size} {
    .portlet-image img {
      @extend %prt-bimg-h-#{$size};
    }
    .bubbleChart-new-1 {
      .highcharts-container  {
        // margin-top: 30px;
      }
    }
  }
}
.portlet-info-text {
  bottom: 10px;
  padding-right: 5px;
  position: absolute;
  left: 15px;
  z-index: 999;
  font-size: $font-xxs;
}
.v-center-inner {
  width: 100%;
  align-self: center;
  height: 100%;
  @include flexbox($type: flex, $y: center, $x: center, $dir: column);
  grid-gap: 15px;
}
.two-label-container {
  @include flexbox();
  width: 100%;
  .two-label {
    width: 100%;
    text-align: center;
    p {
      margin-bottom: 0;
    }
  }
}
.legendList {
  margin-top: 15px;
  text-align: center;
  li {
    position: relative;
    display: inline-block;
    padding-left: 30px;
    color: $default-color !important;
    font-size: $font-xsm;
    &:hover {
      cursor: pointer;
    }
    &.orange-light:before {
      background: $orange-200;
    }
    &.green:before {
      background: $green-200;
    }
    &.known:before {
      background: #fd8f40;
    }
    &.unknown:before {
      background: #99cc03;
    }
    &.identified:before {
      background: $green-200;
    }
    &:before {
      content: '';
      position: absolute;
      width: 8px;
      height: 8px;
      top: 50%;
      margin-top: -4px;
      left: 15px;
    }
  }
}
/* portlet two label close */
/* portlet common close */

.hori-column-chart {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  .close-btn {
    position: absolute;
    right: 7px;
    top: 3px;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    font-size: $font-sm;
    text-align: center;
    padding: 2px 0 0 2px;
    &:hover {
      background: $primary-color;
      color: #fff;
    }
  }
  .portlet-xs {
    height: 200px;
    padding: 0 15px;
  }
  .hori-col-head {
    padding: 15px 20px 0;
    p {
      margin: 0;
      font-size: $font-xs;
    }
    h3 {
      margin: 0;
      font-size: $font-md;
    }
  }
}
.portlet-container.show-mini-chart .hori-column-chart {
    opacity: 1;
    visibility: visible;
    background: #fff;
    z-index: 100;
    border-radius: 5px;
}

$port-sizes: (sm: 234, md: 331, lg: 362);
@each $name, $value in $port-sizes {
  .portlet-#{$name}-body-height {
    height: $value;
  }
  .portlet-container {
    &.portlet-#{$name} {
      .portlet-body {
        &, &-image {
          height: #{$value}px;
        }
        .tab-content.p-tabs-content-1 {
          &, .tab-pane {
            height: #{$value}px;
          }
        }
        &, .portlet-chart, .portlet-list {
          height: #{$value}px;
        }
      }
      &.portlet-footer-text {
        .portlet-body {
          height: #{$value - 20}px;
          .portlet-chart {
            height: #{$value - 19}px;
          }
          .portlet-list {
            height: #{$value}px;
          }
        }
      }
      &.portlet-footer-label.portlet-footer-text {
        .portlet-body {
          .portlet-chart {
            height: #{$value - 30}px;
          }
        }
      }
    }
  }
}
.portlet-chart {
    margin: 0 -10px;
    .portlet-list {
      margin: 0 10px;
    }
}
.portlet-table {
  .table-striped tbody {
      border-top: 0;
      tr {
          &:hover {
              // background-color: inherit;
          }
      }
      td {
          padding: 1rem 0.5rem;
      }
  }
  > table.table.table-striped.st-grid-knownUser {
    > :not(caption) > * > * {
      padding: 15.8px 0.5rem;
    }
  }
}
.portlet-container.portlet-md {
  &.portlet-footer-label {
    .chart-clock-img {
      top: 50%;
      width: 190px;
    }
  }
  .chart-clock-img {
    top: 54%;
  }
}
.portlet-chart.gauge-chart-del svg g > g:nth-child(2) > g:nth-child(2) g:nth-child(3) > g {
    &:nth-child(1),
    &:nth-child(2) {
      display: none;
    }
}
.portlet-import {
  border: 1px solid $border-color;
  @if $new-theme-test == true {
    @extend %new-theme-shadow;
    border-radius: $theme-radius-size;
  }
}