$colors: (
    body-bg-color: #f5f7fc, //#eff3fc,
    head-band-color: #4a051b,

    green: #5ba529,
    orange: #fc6a00,
    submit: #005534,
    remove: #ff3939,

    primary-blue: #0018f9,
    secondary-blue: #004fdf,
    tertiary-blue: #f0f8ff,
    quaternary-blue: #c2cfe3,

    primary-black: #111111,
    secondary-black: #333333,

    primary-grey: #666666,
    secondary-grey: #999999,
    tertiary-grey: #e9e9e9,
    quaternary-grey: #f4f4f4,

    primary-orange: #fc6a00,
    secondary-orange: #fd8f40,
    tertiary-orange: #ffb900,
    quaternary-orange: #fd2d32,

    primary-red: #ff3939,
    secondary-red: #ffebeb,

    primary-green: #5ba529,
    secondary-green: #99cc03,
    primary-btn: #005534,
    primary-btn-hover: #026c43,
    
    all-audience: #008489,
    seed-list: #40abaf,
    ad-hoc-list: #70c0c3,
    match-list: #b786ce,
    suppression: #9f65b7,
    auto-cluster: #804097,

    completed: #5ba529,
    scheduled: #fc6a00,
    inprogress: #009bdf,
    alert: #dfb82b,
    multi-status: #c43896,
    draft: #b8b8b8,

    email: #fc6a00,
    sms: #dfd82b,
    web-push: #5ba529,
    mobile-push: #99cc03,
    vms: #008489,
    call-center: #33b5e6,
    whatsapp: #00d954,
    qR-code: #d4d6de,
    direct-mail: #fd8f40,
    paid-media: #40abaf,
    twitter: #00ccff,
    facebook: #0080ff,
    linkedin: #2967ad,
    insta: #c4219c,
    google: #bc2f20,
    pinterest: #e60023,
    line: #0018f9,

    zip-code: #5f1147,
    notification: #f05455,
    gender: #9e003d,
    male: #00acde,
    female: #c72079,
    age: #da0034,
    country: #ff4418,
    website: #ffc000,
    others: #cccccc,

    list-audience: #2a79c2,
    dynamic-audience: #b26aae,

    monday: #0048ff,
    tuesday: #ff0005,
    wednesday: #46c029,
    thursday: #f5ec00,
    friday: #a9bde4,
    saturday: #5b5b5b,
    sunday: #ff7800,

    positive: #5ba529,
    neutral: #009bdf,
    negative: #c3392c,

    startup: #6d6e71,
    pro: #fd992f,
    enterprise: #0c8aff,

    heavy-dark-blue: #0584c3,
    dark-blue: #0892d5,
    medium-blue: #26ade0,
    light-blue: #5ac3e9,

    dark-green: #51a33c,
    medium-green: #90c830,
    light-green: #aed237,

    dark-orange: #f25e0e,
    medium-orange: #f58332,
    light-orange: #f9a263,

    dark-yellow: #ddb219,
    medium-yellow: #e5c553,
    light-yellow: #ecd47b,

    heavy-dark-red: #ad2513,
    dark-red: #c22f1d,
    medium-red: #f05455,
    light-red: #f17576,

    dark-blue-green: #008288,
    medium-blue-green: #35a8ad,
    light-blue-green: #69bec1,

    dark-grey: #b8b6b7,
    medium-grey: #d1d3dc,
    light-grey: #e8e7e9,

    dark-pink: #c32c8f,
    medium-pink: #d1569e,
    light-pink: #d982b7,

    dark-purple: #7e3896,
    medium-purple: #9b5faa,
    light-purple: #b180bb,

    even-color: #fae9db,
);

@each $key, $value in $colors {
    #{'.color-' + $key} {
        color: $value !important;
    }
    #{'.bg-' + $key} {
        background-color: $value !important;
    }
    #{'.bg-' + $key + '-20'} {
        background-color: #{$value} + 20 !important;
    }
}