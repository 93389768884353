.k-dropdownlist-popup {
    .k-list {
        .k-list-content {
            @extend %scrollbar;
        }
    }
}


// END SELECT DROPDOWN
.k-animation-container {
    margin: 2px 0;
    .k-popup {
        border: 1px solid $border-color;
        @if $new-theme-test == true {
            border: 1px solid $border-color;
            box-shadow: 0px 4px 6px -1px #0000001f;
            border-radius: $theme-radius-size-sm;
            overflow: hidden;
        }
        .k-list-ul {
            background-color: $white;
            @if $theme-radius == true {
            //   border-radius: $theme-radius-size-sm;
              border-radius: 0;
              overflow: hidden;
            }
            li.k-list-item {
                font-size: $font-xs;
                letter-spacing: 0.5px;
                padding-bottom: 7px;
                &.k-selected {
                    &:hover {
                        color: $secondary-black;
                    }
                }
                &:hover {
                    background-color: $secondary_blue;
                    color: $white
                }
            }
        }
    }
}
.k-list-item:focus,
.k-list-optionlabel:focus,
.k-list-item.k-focus,
.k-focus.k-list-optionlabel {
    box-shadow: none !important;
}
.k-list-item.k-selected,
.k-selected.k-list-optionlabel {
    background-color: $even;
    color: #333;
}
.k-list-item:hover, .k-list-optionlabel:hover,
.k-list-item.k-hover,
.k-hover.k-list-optionlabel {
    background-color: $even;
}
.k-list-item.k-selected:hover,
.k-selected.k-list-optionlabel:hover,
.k-list-item.k-selected.k-hover,
.k-selected.k-hover.k-list-optionlabel {
    background-color: $secondary_blue;
}



// NEW
.k-floating-label-container {
    width: 100%;
    font-family: $rs-font-regular;
    margin-top: 0;
    padding: 0;
    .k-dropdownlist,
    .rs-kendo-multi-dropdown {
        & + .k-label {
            left: 0 !important;
            font-size: $font-xxs;
            top: -17px;
        }
    }
    .k-label {
        max-width: 100%;
        font-size: $font-xxs;
    }
    &.k-empty {
        .k-label {
            font-size: $font-xsm;
            left: 0;
        }
    }
    &:focus-within {
        .k-label {
            //top: 9px;
            font-size: $font-xxs;
        }
        .rs-kendo-multi-dropdown {
            .k-label {
                top: 0;
            }
        }
    }
}
.k-dropdownlist .k-icon-button .k-icon.k-i-caret-alt-down:before,
.rs-dt-arrow-icon:before {
    content: '';
    position: relative;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 7px solid $secondary-grey;
}

.rs-kendo-dropdown {
    min-width: 80px;
}

.rs-kendo-dropdownmenu-wrapper {
    color: $primary-grey;
    &.click-off {
        pointer-events: inherit;
        opacity: 1;
        .k-dropdownlist.rs-kendo-dropdown {
            pointer-events: none;
            opacity: 0.4;
        }
    }
    &.kendo-dd-right-align {
        .k-dropdownlist {
            .k-input-inner {
                .k-input-value-text {
                    text-align: right;
                }
            }
            .k-button {
                width: 20px;
            }
        }
    }
    &.kendo-dd-no-bottom-border {
        .k-dropdownlist {
            border: 0;
        }
    }
    .k-floating-label-container {
        &.k-empty {
            margin-top: -1px;
        }
    }
}
.rs-kendo-dropdownmenu-wrapper .rs-inputIcon-wrapper {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 9;
}
.rs-kendo-dropdownmenu-wrapper .k-floating-label-container.k-empty > .k-label {
    top: 0;
}
.rs-kendo-dropdownmenu-wrapper .k-floating-label-container > .k-label,
.rs-kendo-dropdownmenu-wrapper .k-floating-label-container.k-focus > .k-label {
    top: -17px;
}
.rs-kendo-dropdownmenu-wrapper .k-picker .k-input-inner .k-input-value-text {
    position: relative;
    //bottom: -4px;
}

// line
.k-picker {
    background: none !important;
    border-radius: 0;
    box-shadow: none !important;
    border: 0;
    width: 100%;
    //padding-top: 1px;
    line-height: normal;
    overflow: visible;
    &::after {
        border-bottom: 1px solid $secondary-grey;
        content: '';
        width: 100%;
        height: 1px;
        position: absolute;
        bottom: 0;
        z-index: 3;
    }
    &:focus {
        &::after {
            border-bottom: 1px solid $secondary_blue;
        }
    }
    /*.k-input-button {
        color: $secondary-grey;
        padding-right: 0;
        span {
            position: relative;
            right: -7px;
        }
    }*/
    .k-input-inner {
        padding: 0;
        //padding-right: 0;
        //padding-left: 0;
        .k-input-value-text {
            font-size: $font-xsm;
            height: 27px;
        }
    }
}





// required
.rs-kendo-dropdown-required,
.rs-kendo-multi-dropdown-required {
    & + .k-label {
        padding-right: 10px;
        &:after {
            content: '*';
            position: absolute;
            right: 0;
            color: $primary-red;
            font-family: $rs-font-bold;
            display: none;
        }
    }
}

.rs-input-wrapper .rs-input-wrapper-required:before,
.rs-kendo-dropdownmenu-wrapper .rs-kendo-dropdown-required:before,
.rs-kendo-datepicker-wrapper .k-datepicker.required:before,
.rs-kendo-datetimepicker-wrapper .k-datetimepicker.required:before,
.rs-phone-input.rs-phone-input-required .react-tel-input:before
{
    content: '';
    width: 15px;
    height: 1px;
    border-bottom: 3px solid $primary-red;
    position: absolute;
    bottom: -2px;
    z-index: 4;
}

.rs-input-wrapper .rs-input-wrapper-required:focus:before,
.rs-kendo-dropdownmenu-wrapper .rs-kendo-dropdown-required:focus:before {
    display: none;
}

.rs-kendo-dropdown-required,
.rs-kendo-multi-dropdown-required {
    & + .k-label {
        padding-right: 10px;
        &:after {
            content: '*';
            position: absolute;
            right: 0;
            color: $primary-red;
            font-family: $rs-font-bold;
            display: none;
        }
    }
}

// Arrow
.k-dropdownlist .k-icon-button .k-icon.k-i-caret-alt-down:before,
.rs-dt-arrow-icon:before {
    content: '';
    position: relative;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 7px solid $secondary-grey;
}

// Dropdown - caret
.k-dropdownlist .k-button .k-i-caret-alt-down:before,
.k-dropdownlist .k-button .k-svg-i-caret-alt-down:before {
  content: "";
  position: relative;
  width: 0;
  height: 0;
  right: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 7px solid $secondary-grey;
  top: 1px;
}
.k-dropdownlist .k-button .k-i-caret-alt-down,
.k-dropdownlist .k-button .k-svg-i-caret-alt-down {
  right: 0;
  position: absolute;
}
.k-dropdownlist .k-button svg {
  display: none;
}


// FORM popup (font name)
.k-animation-container {
  .k-popup.k-dropdown-sm {
    .k-list-ul li.k-list-item {
      font-size: $font-xxs;
    }
    .k-list-md .k-list-optionlabel {
      font-size: $font-xxs;
    }
  }
}

.persona-dd {
    .k-button-md {
        padding-left: 0 !important;
        padding-top: 0px !important;
        position: relative;
        top: -3px;
    }
}