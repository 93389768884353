.k-grid-columnmenu-popup {
  input  {
    @include inputBorder($input-border, $input-border, $input-border);
    &:focus-visible {
      outline: none;
    }
  }
}

.k-grid-columnmenu-popup {
  width: 230px;
  padding: 15px;
}
.k-grid-columnmenu-popup {
  input {
    //   margin-bottom: 20px;
      padding: 3px 9px;
      font-size: $font-xsm;
      width: 100%;
  }
  .k-datepicker {
    input {
      padding: 0px;
      background: transparent !important;
    }
  }
}
.k-grid-columnmenu-popup .k-dropdownlist {
  margin-bottom: 8px;
}
.k-grid-columnmenu-popup .rsp-button {
  font-size: $font-xs;
}
.grid-filter-btn {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
}

.k-grid-header .k-grid-filter:hover, .k-grid-header .k-header-column-menu:hover, .k-grid-header .k-grid-header-menu:hover, .k-grid-header .k-hierarchy-cell .k-icon:hover, .k-grid-header .k-hierarchy-cell .k-svg-icon:hover {
  background-color: $primary-color;
  color: #ffffff;
}
.k-grid .k-grid-filter, .k-grid .k-header-column-menu, .k-grid .k-grid-header-menu, .k-grid .k-hierarchy-cell .k-icon, .k-grid .k-hierarchy-cell .k-svg-icon {
  color: #ffffff !important;
}
.k-grid-header .k-grid-filter:focus, .k-grid-header .k-grid-filter.k-focus, .k-grid-header .k-header-column-menu:focus, .k-grid-header .k-header-column-menu.k-focus, .k-grid-header .k-grid-header-menu:focus, .k-grid-header .k-grid-header-menu.k-focus, .k-grid-header .k-hierarchy-cell .k-icon:focus, .k-grid-header .k-hierarchy-cell .k-icon.k-focus, .k-grid-header .k-hierarchy-cell .k-svg-icon:focus, .k-grid-header .k-hierarchy-cell .k-svg-icon.k-focus {
  box-shadow: none;
}