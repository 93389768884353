@mixin br($radius) {
  -webkit-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}
@mixin border-radius-all($brtl: false, $brtr: false, $brbl: false, $brbr: false, $borderStyle: false) {
  @if $borderStyle {
      border-style: $borderStyle;
  }
  @if $brtl {
      border-top-left-radius: $brtl;
  }
  @if $brtr {
      border-top-right-radius: $brtr;
  }
  @if $brbr {
      border-bottom-right-radius: $brbr;
  }
  @if $brbl {
      border-bottom-right-radius: $brbl;
  }
}
// text-align & text-decoration common use
@mixin textalign($ta: false) {
  @if ($ta) {
      text-align: $ta;
  }
}
@mixin border-radius($top-left: 8px, $top-right: null, $bottom-right: null, $bottom-left: null) {
  -webkit-border-radius: $top-left $top-right $bottom-right $bottom-left;
  -ms-border-radius: $top-left $top-right $bottom-right $bottom-left;
  border-radius: $top-left $top-right $bottom-right $bottom-left;
}
@mixin border($direction, $width, $color) {
  border#{-$direction}: $width solid $color;
}
@mixin bor($width: 1px, $color: $border-color) {
  border: $width solid $color;
}
// @include bor(1px);

@mixin shadow() {
  // box-shadow: 0 1px 6px 0 rgba(32,33,36,0.28);
  box-shadow: 5px 5px 5px -5px rgba(0, 0, 0, 0.1), 5px 5px 5px -5px rgba(0, 0, 0, 0.1);;
}
// @include shadow();

@mixin btnColor($value) {
    background-color: $value;
    border-color: $value;
    color: $white !important;
}
// @include btnColor(red);

@mixin linear-gradient($top-color, $bottom-color, $opacity) {
    background: -moz-linear-gradient(top, rgba($top-color, $opacity) 0%, rgba($bottom-color, $opacity) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba($top-color, $opacity) 0%, rgba($bottom-color, $opacity) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba($top-color, $opacity) 0%, rgba($bottom-color, $opacity) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='rgba($top-color, $opacity)', endColorstr='rgba($top-color, $opacity)',GradientType=0 ); /* IE6-9 */
}
// @include linear-gradient(red, blue, 0.5);

@mixin mybutton {
    padding: 10px;
    color: $white;
    background-color: red;
    font-size: $font-xs;
    width: 150px;
    margin: 5px 0;
    text-align: center;
    display: block;
}
// @include mybutton();

@mixin border-radius($radius, $moz: true, $webkit: true, $ms: true) {
    @if $moz {-moz-border-radius: $radius;}
    @if $webkit {-webkit-border-radius: $radius;}
    @if $ms {-ms-border-radius: $radius;}
}
// @include border-radius(4px);

@mixin linearGradient($top, $bottom){
    background: $top; /* Old browsers */
    background: -moz-linear-gradient(top,  $top 0%, $bottom 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,$top), color-stop(100%,$bottom)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  $top 0%,$bottom 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  $top 0%,$bottom 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  $top 0%,$bottom 100%); /* IE10+ */
    background: linear-gradient(to bottom,  $top 0%,$bottom 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000',GradientType=0 ); /* IE6-9 */
}

// using
@mixin sizing(
    $ff: false,
    $fs: false,
    $w: false,
    $h: false,
    $lh: false,
    $wh: false,
    $mh: false,
    $fw: false,
    $mw: false,
    $maxw: false,
    $maxh: false
) {
    @if $ff {
        font-family: $ff;
    }
    @if $fs {
        font-size: $fs;
    }
    @if $w {
        width: $w;
    }
    @if $h {
        height: $h;
    }
    @if $lh {
        line-height: $lh;
    }
    @if $wh {
        width: $wh;
        height: $wh;
    }
    @if $mh {
        min-height: $mh;
    }
    @if $fw {
        font-weight: $fw;
    }
    @if $mw {
        min-width: $mw;
    }
    @if $maxw {
        max-width: $maxw;
    }
    @if $maxh {
        max-height: $maxh;
    }
}
@mixin color($color: false, $bg: false) {
  @if $color { color: $color }
  @if $bg { background-color: $bg }
}

@mixin box($count) {
  width: $count;
  height: $count;
}
@mixin strip($height, $top, $color) {
  content: '';
  position: absolute;
  border-left: 1px solid $color;
  height: $height;
  top: $top;
  right: 0;
  &:lastchild {
    border-left: 0;
  }
}

@mixin inputBorder($right, $bottom, $left, $height: 15px) {
  background: bottom left -webkit-linear-gradient($left, $left) no-repeat, bottom center -webkit-linear-gradient($bottom, $bottom) repeat-x, bottom right -webkit-linear-gradient($right, $right) no-repeat !important;
  background: bottom left linear-gradient($left, $left) no-repeat, bottom center linear-gradient($bottom, $bottom) repeat-x, bottom right linear-gradient($right, $right) no-repeat !important;
  background-size: 1px $height, 1px 1px, 1px 10px !important;
  -webkit-background-size: 1px $height, 1px 1px, 1px 10px !important;
  border: 0;
  border-radius: 0;
}
// @include inputBorder(0, 0, 0, 15px);

// header
@mixin navIcon($value) {
  background-image: url(#{$image}header/d-icon-#{$value}.svg)
}
// @include navIcon2(1);

@mixin navIcon2($value) {
  background-image: url(#{$image}header/d-icon-#{$value}-w.svg)
}
// @include navIcon2(1);

@mixin image($value, $position: center, $repeat: no-repeat) {
  background-image: url(#{$image}#{$value});
  background-position: $position;
  background-repeat: $repeat;
}
// @include image('', center, no-repeat);

// best mixins
@mixin css3-prefix($props, $value) {
  -webkit-#{$props}: #{$value};
  -moz-#{$props}: #{$value};
  -ms-#{$props}: #{$value};
  -o-#{$props}: #{$value};
  #{$props}: #{$value};
}
// @include css3-prefix(transform, scale3d(2.5, 2, 1.5));

@mixin vertical-center {
  position: relative;
  top: 50%;
  @include css3-prefix(transform, translate(0, -50%));
}
// @include vertical-center();

@mixin horizontal-center {
  position: relative;
  left: 50%;
  @include css3-prefix(transform, translate(-50%, 0));
}
// @include horizontal-center();

// KEYFRAME ANIMATIONS
@mixin keyframes($name) {
   @-webkit-keyframes #{$name} {
     @content;
   }
   @-moz-keyframes #{$name} {
     @content;
   }
   @keyframes #{$name} {
     @content;
   }
}
@mixin animationField (
    $animation, 
    $duration, 
    $delay: 0s, 
    $direction: forward, 
    $fillmode: fowards, 
    $iteration: 1, 
    $timefunc: ease
  ) {
  -webkit-animation-name: $animation;
  -webkit-animation-duration: $duration;
  -webkit-animation-delay: $delay;
  -webkit-animation-fill-mode: $fillmode;
  -webkit-animation-direction: $direction;
  -webkit-animation-iteration-count: $iteration;
  -webkit-animation-timing-function: $timefunc;

  -moz-animation-name: $animation;
  -moz-animation-duration: $duration;
  -moz-animation-delay: $delay;
  -moz-animation-fill-mode: $fillmode;
  -moz-animation-direction: $direction;
  -moz-animation-iteration-count: $iteration;
  -moz-animation-timing-function: $timefunc;

  animation-name: $animation;
  animation-duration: $duration;
  animation-delay: $delay;
  animation-fill-mode: $fillmode;
  animation-direction: $direction;
  animation-iteration-count: $iteration;
  animation-timing-function: $timefunc;
}

@mixin pseudo($display: block, $pos: absolute, $content: '') {
  content: $content;
  display: $display;
  position: $pos;
}
// @include pseudo();

// RETINA-READY IMAGES
@mixin retina-image($image, $width, $height) {
   @media (min--moz-device-pixel-ratio: 1.3),
   (-o-min-device-pixel-ratio: 2.6/2),
   (-webkit-min-device-pixel-ratio: 1.3),
   (min-device-pixel-ratio: 1.3),
   (min-resolution: 1.3dppx) {
       background-image: url($image);
       background-size: $width $height;
   }
}
// @include retina-image('', 40px, 40px);

// ABSOLUTE POSITIONING
// ABSOLUTE POSITIONING
@mixin abs-position(
    $position: null,
    $top: null,
    $right: null,
    $bottom: null,
    $left: null,
    $zindex: null,
    $contentcommon: null,
    $dc: null
) {
    // position: absolute;
    position: $position;
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
    z-index: $zindex;
    content: $contentcommon;
    display: $dc;
}
// @include abs-position(0, 0, 0, 0);

// TRANSITION
@mixin transition($for: all, $time-in: 250ms, $type: ease-in-out, $time-out: 0s) {
  -webkit-transition: $for $time-in $type $time-out;
      -moz-transition: $for $time-in $type $time-out;
      -o-transition: $for $time-in $type $time-out;
      transition: $for $time-in $type $time-out;
}

// ARROW WITH FOUR OPTIONAL DIRECTIONS
@mixin arrow($direction: down, $size: 5px, $color: #555) {
   width: 0;
   height: 0;
   @if ($direction == left) {
      border-top: $size solid transparent;
      border-bottom: $size solid transparent;
      border-right: $size solid $color;
   }
   @else if ($direction == right) {
      border-top: $size solid transparent;
      border-bottom: $size solid transparent;
      border-left: $size solid $color;
   }
   @else if ($direction == down) {
      border-left: $size solid transparent;
      border-right: $size solid transparent;
      border-top: $size solid $color;
   }
   @else {
      border-left: $size solid transparent;
      border-right: $size solid transparent;
      border-bottom: $size solid $color;
   }
}
// without arguments (default)
// @include arrow();

// with custom arguments
// @include arrow(up, 10px, #efefef);

// FIXED ASPECT RATIO
@mixin aspect-ratio($width, $height) {
   position: relative;
   &:before {
      display: block;
      content: '';
      width: 100%;
      padding-top: ($height / $width) * 100%;
   }
   > .inner-box {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
   }
}
// @include aspect-ratio(4, 3);

// TEXT SHORTENING
@mixin text-shorten {
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: nowrap;
}
// @include text-shorten();

@mixin truncate($mwidth: false, $width: false, $line: 1) {
    @if($mwidth) { max-width: $mwidth; }
    @if($width) { width: $width; }
    display: -webkit-box;
    -moz-box-orient: vertical;
    white-space: pre-wrap;
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
}
// @include truncate(120px);

// Centering Elements
@mixin center-position($position) {
  position: absolute;

  @if $position == 'vertical' {
    top: 50%;
    @include css3-prefix(transform, translateY(-50%))
  }
  @else if $position == 'horizontal' {
    left: 50%;
    @include css3-prefix(transform, translateX(-50%))
  }
  @else if $position == 'both' {
    top: 50%;
    left: 50%;
    @include css3-prefix(transform, translate(-50%, -50%))
  }
}
// @include center-position('vertical');
// @include center-position('horizontal');
// @include center-position('both');

//Padding mixin
@mixin p($top, $right, $bottom, $left) {
  padding-top: $top;
  padding-right: $right;
  padding-bottom: $bottom;
  padding-left: $left;
}
// @include p(0, 0, 0, 0);

//Margin mixin
@mixin m($top, $right, $bottom, $left) {
  margin-top: $top;
  margin-right: $right;
  margin-bottom: $bottom;
  margin-left: $left;
}
// @include m(0, 0, 0, 0);

// Quick Gradients
@mixin background-gradient($start-color, $end-color, $orientation) {
  background: $start-color;
  $i: linear-gradient;
  $j: radial-gradient;
  @if $orientation == 'vertical' {
    background: -webkit-#{$i}(top, $start-color, $end-color);
    background: #{$i}(to bottom, $start-color, $end-color);
  } @else if $orientation == 'horizontal' {
    background: -webkit-#{$i}(left, $start-color, $end-color);
    background: #{$i}(to right, $start-color, $end-color);
  } @else {
    background: -webkit-#{$j}(center, ellipse cover, $start-color, $end-color);
    background: #{$j}(ellipse at center, $start-color, $end-color);
  }
}
// demo
// @include background-gradient(red, black, 'vertical');
// @include background-gradient(red, black, 'horizontal');
// margin padding letter-spacing list-style
@mixin space(
    $margin: false,
    $padding: false,
    $mt: false,
    $ml: false,
    $mr: false,
    $mb: false,
    $pt: false,
    $pl: false,
    $pr: false,
    $pb: false,
    $letterspace: false,
    $liststyle: false
) {
    @if ($margin) {
        margin: $margin;
    }
    @if ($padding) {
        padding: $padding;
    }
    @if ($mt) {
        margin-top: $mt;
    }
    @if ($ml) {
        margin-left: $ml;
    }
    @if ($mr) {
        margin-right: $mr;
    }
    @if ($mb) {
        margin-bottom: $mb;
    }
    @if ($pt) {
        padding-top: $pt;
    }
    @if ($pl) {
        padding-left: $pl;
    }
    @if ($pr) {
        padding-right: $pr;
    }
    @if ($pb) {
        padding-bottom: $pb;
    }
    @if ($letterspace) {
        letter-spacing: $letterspace;
    }
    @if ($liststyle) {
        list-style: $liststyle;
    }
}

@mixin center(
    $align: center,
    $justify: center,
    $direction: row,
    $flow: false,
    $wrap: false
  ) {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: $align;
    align-items: $align;
    -webkit-justify-content: $justify;
    justify-content: $justify;
    -webkit-flex-direction: $direction;
    flex-direction: $direction;
    @if $wrap {
      -webkit-flex-wrap: wrap;
      flex-wrap: wrap;
    }
    @if $flow {
      -webkit-flex-flow: $flow;
      flex-flow: $flow;
    }
}

@mixin flexbox(
    $x: false,
    $y: false,
    $dir: false,
    $type: flex
  ) {

    @if($type) { display: $type; }
    @if($x) { justify-content: $x; }
    @if($y) { align-items: $y; }
    @if($dir) { flex-direction: $dir; }
}
// @include flexbox($type: flex, $x: space-between, $y: center, $dir: column);

@mixin icon(
  $content: '',
  $color: $secondary_blue,
  $font-size: $icons-md,
  $font-weight: 400,
) {
  content: $content;
  color: $color !important;
  font-family: 'smartdx' !important;
  font-size: $font-size;
  font-style: normal;
  font-variant: normal;
  font-weight: $font-weight;
  line-height: 1;
  text-transform: none;
}
@mixin findBrowser($browser) {
  @if($browser == 'chrome') {
    @content;
  }
  @if($browser == 'firefox') {
    @content;
  }
}

// Dropdown tip up arrow start
@mixin Dropdownuparrow() {
  @include abs-position($position: absolute, $contentcommon: '');
  @include sizing($wh: 9px);
  background-color: $white;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  @include bor($color: $quaternary-blue);
  border-right: none;
  border-bottom: none;
}
// Dropdown tip up arrow end