.footer-cloud {
    display: flex !important;
    align-items: center;
    &:before {
        background-color: white;
        position: absolute;
        content: '';
        bottom: 0;
        left: 0;
        right: 0;
        height: 57px;
    }
    &:after {
        @include image('cloud.png', top center, repeat);
        position: absolute;
        content: '';
        bottom: 0;
        left: 0;
        right: 0;
        height: 107px;
    }
    .page-header {
        margin-top: 0;
    }
}
.not-found-desc {
    display: flex;
    align-items: center;
    color: #fff;
    position: relative;
    h1, h3 {
        color: #fff;
    }
    h1 {
        font-size: $font-xxl;
        margin-bottom: 20px;
        span {
            font-size: $font-lg;
        }
    }
    h3 {
        margin-bottom: 25px;
    }
    p {
        margin-bottom: 20px;
        color: #fff;
        font-size: $font-sm;
    }
    &:before {
        content: '';
        position: absolute;
        left: 0;
        margin-left: -15px;
        top: 3%;
        height: 94%;
        border-left: 2px solid #83c5db;
    }
}
.no-data-bg {
    background-image: linear-gradient(to right, #18419b, #0ee3d4);
    .error-bg-img {
        width: 340px;
        margin-right: 15px;
    }
}